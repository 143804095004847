import React, { useContext, useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Icon,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Button,
  Image,
  Center,
  Link,
} from "@chakra-ui/react";
import AuthContext from "../../hooks/UseAuth";
import { IoIosArrowForward } from "react-icons/io";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { RiFacebookFill, RiPhoneFill } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import user from "../../assets/Images/icons/user.png";
import pass from "../../assets/Images/icons/pass.png";
import UserContext from "../../context/UserContext";
import RoundedButton from "../../components/Button/RoundedButton";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { loginHandler, removeCookies } = useContext(AuthContext);
  const { btnSpinner } = useContext(UserContext);

  useEffect(() => {
    removeCookies();
  }, []);

  return (
    <VStack w={"xs"} align={"flex-start"} spacing={8}>
      <Text color={"#45A735"} fontWeight={"medium"} fontSize={27}>
        Нэвтрэх
      </Text>
      <VStack w={"full"} spacing={4}>
        <InputGroup alignItems={"center"} justifyContent={"center"}>
          <InputLeftElement
            children={<Image src={user} w={4} h={4} objectFit={"contain"} />}
          />
          <Input
            variant={"flushed"}
            placeholder={"Хэрэглэгчийн нэр"}
            type={"text"}
            width={"full"}
            fontSize={15}
            fontWeight={"normal"}
            color={"#000"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            _hover={{
              borderColor: "#45A735",
            }}
            _focusVisible={{
              borderColor: "#45A735",
            }}
          />
        </InputGroup>

        <InputGroup justifyContent={"center"} alignContent={"center"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Image src={pass} w={4} h={4} objectFit={"contain"} />}
          />
          <Input
            variant={"flushed"}
            placeholder={"Нууц үг"}
            type={show ? "text" : "password"}
            width={"full"}
            fontSize={15}
            fontWeight={"normal"}
            color={"#000"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            _hover={{
              borderColor: "#45A735",
            }}
            _focusVisible={{
              borderColor: "#45A735",
            }}
            onKeyDown={(e) => {
              if (username && password) {
                if (e.key === "Enter") {
                  loginHandler(username, password);
                }
              }
            }}
          />
          <InputRightElement
            children={
              <Icon
                as={show ? FiEye : FiEyeOff}
                w={4}
                h={4}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow(!show)}
                cursor={"pointer"}
              />
            }
          />
        </InputGroup>

        <HStack w={"full"} align={"center"} justify={"space-between"}>
          <Button
            variant={"link"}
            fontWeight={"normal"}
            fontSize={15}
            // color={"#000"}
            onClick={() => history.push("/forgot")}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Нууц үг сэргээх
            <Icon as={IoIosArrowForward} ml="1" />
          </Button>
          <Button
            variant={"link"}
            fontWeight={"normal"}
            fontSize={15}
            // color={"#000"}
            onClick={() => history.push("/signup")}
          >
            Бүртгүүлэх
          </Button>
        </HStack>
      </VStack>
      <RoundedButton
        title={"Нэвтрэх"}
        width={"full"}
        onClick={() => loginHandler(username, password)}
        disable={username && password && !btnSpinner ? false : true}
      />

      <HStack w={"full"} justify={"space-between"}>
        <Link href="tel:+97672722072" _focusVisible={{ outline: "none" }}>
          <HStack>
            <Center
              rounded={"full"}
              w={10}
              h={10}
              borderColor={"#ddd"}
              borderWidth={"thin"}
            >
              <Icon as={RiPhoneFill} w={5} h={5} />
            </Center>
            <VStack spacing={0} align={"flex-start"}>
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Лавлах утас
              </Text>
              <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
                72722072
              </Text>
            </VStack>
          </HStack>
        </Link>
        <HStack>
          <Link
            href="https://www.facebook.com/viot.mon/"
            isExternal
            _focusVisible={{ outline: "none" }}
          >
            <Center
              rounded={"full"}
              w={10}
              h={10}
              borderColor={"#ddd"}
              borderWidth={"thin"}
            >
              <Icon as={RiFacebookFill} w={5} h={5} />
            </Center>
          </Link>

          <Link href="https://viot.mn" isExternal>
            <Center
              rounded={"full"}
              w={10}
              h={10}
              borderColor={"#ddd"}
              borderWidth={"thin"}
            >
              <Icon as={TbWorld} w={5} h={5} />
            </Center>
          </Link>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default Login;
