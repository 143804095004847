import axios from "../axios";
import React, { createContext, useContext, useEffect } from "react";
import UserContext from "../context/UserContext";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";
import jwtDecode from "jwt-decode";
import firebase from "firebase/compat/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useColorMode } from "@chakra-ui/react";
const AuthContext = createContext();

export const UserAuth = (props) => {
  const {
    setIsLoading,
    setBtnSpinner,
    setIsLoggedIn,
    setAccessToken,
    setRefreshToken,
    setUsername,
    setNotifBadge,
    setNotifToken,
    setHouseTrigger,
  } = useContext(UserContext);
  const toast = useToast();
  const id = "toast";
  const { Auth } = axios();
  const { colorMode, toggleColorMode } = useColorMode();

  // --- firebase config ---
  const firebaseConfig = {
    apiKey: "AIzaSyDyj38KAo_xOHn24lzISHcj25W7Ilamq7Y",
    authDomain: "viot-f2c45.firebaseapp.com",
    projectId: "viot-f2c45",
    storageBucket: "viot-f2c45.appspot.com",
    messagingSenderId: "904573792111",
    appId: "1:904573792111:web:ba5c53bb35cf91500f429a",
    measurementId: "G-2PVNP6EVQF",
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  // --- request Token ---
  const requestForToken = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BPddh5iA4YbGVQ3pv0UYoTT-7Qx-7sZzIK3qtqiLc2L08qwDgaz04jNapCTYEMgrf1mStXU8dpWRTjMN_cJWR1U",
      });
      // console.log("login fcmtoken", token);
      Cookies.set("fcmToken", token);
      setNotifToken(token);
    } catch (err) {
      // console.log(err);
    }
  };

  const loginSuccess = (accToken, refToken, user) => {
    Cookies.set("accessToken", accToken);
    Cookies.set("refreshToken", refToken);
    Cookies.set("username", user);
    Cookies.set("isLoggedIn", true);
    Cookies.set("badge", 0);
    setAccessToken(accToken);
    setRefreshToken(refToken);
    setUsername(user);
    setIsLoggedIn(true);
    setNotifBadge(0);
  };

  const loginHandler = (user, pass) => {
    setIsLoading(true);
    setBtnSpinner(true);
    const fcmToken = Cookies.get("fcmToken");
    Auth.post("/auth/login", {
      username: user,
      password: pass,
      notifToken: fcmToken ? fcmToken : "",
    })
      .then((result) => {
        // console.log("login result -->", result.data);
        const decodeData = jwtDecode(result.data.accessToken);
        // console.log("jwt", decodeData);
        loginSuccess(
          result.data.accessToken,
          result.data.refreshToken,
          decodeData.username
        );
        requestForToken();
        setIsLoading(false);
        setBtnSpinner(false);
      })
      .catch((err) => {
        // console.log("login err -->", err.response);
        setIsLoading(false);
        setBtnSpinner(false);

        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error,
          });
        }
      });
  };

  const removeCookies = () => {
    Cookies.remove("username");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
    Cookies.remove("fcmToken");
    Cookies.remove("badge");
    Cookies.remove("house");
    setHouseTrigger(true);
  };

  const logoutHandler = () => {
    setIsLoading(true);
    const refToken = Cookies.get("refreshToken");
    const notifToken = Cookies.get("fcmToken");
    Auth.post("/auth/logout", {
      refreshToken: refToken,
      notifToken: notifToken,
    })
      .then((result) => {
        // console.log(result.data);
        if (result.data.success) {
          removeCookies();
          if (colorMode === "dark") toggleColorMode();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    requestForToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loginHandler,
        logoutHandler,
        removeCookies,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
