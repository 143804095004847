import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import share from "../../assets/Images/icons/share.png";
import shareDark from "../../assets/Images/icons/shareDark.png";
import { FiTrash2 } from "react-icons/fi";
import EditButton from "./EditButton";
import axios from "../../axios";
import Cookies from "js-cookie";
import UserContext from "../../context/UserContext";

const SharedListCard = ({ data, setTrigger }) => {
  const [isDisable, setIsDisable] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const deleteHandler = (value) => {
    if (value) {
      setIsDisable(true);
      let source = Core.CancelToken.source();
      const data = {
        accounts: value.split("-"),
        devices: [],
        houseId: houseId,
      };
      Core.post(
        "/user_v2/device_share/share",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          setIsDisable(false);
          setTrigger(true);
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <HStack
      justify={"space-between"}
      align={"center"}
      className="settingsInput"
      w={{ md: "full", lg: "4xl" }}
      shadow={"md"}
      rounded={"xl"}
      py={4}
      px={6}
    >
      <HStack>
        <Center
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          w={"10"}
          h={"10"}
          rounded={"lg"}
          border={
            colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
          }
        >
          <Image
            src={colorMode === "dark" ? shareDark : share}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </Center>
        <Text fontSize={15} fontWeight={"normal"}>
          {data.username}
        </Text>
      </HStack>
      <Center bg={"#D2E7C9"} w={"xs"} h={"10"} rounded={"md"}>
        <Text fontSize={15} fontWeight={"normal"} color={"#45A735"}>
          Нийт{" "}
          <Text as={"span"} fontSize={15} fontWeight={"medium"}>
            {data.count}
          </Text>{" "}
          төхөөрөмж
        </Text>
      </Center>
      <HStack spacing={4}>
        <EditButton phoneNumber={data.username} setTrigger={setTrigger} />
        <Center
          transition={"ease-in-out .3s"}
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          w={"10"}
          h={"10"}
          rounded={"lg"}
          border={
            colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
          }
          onClick={() => onOpen()}
          cursor={"pointer"}
          _hover={{
            shadow: "md",
          }}
        >
          <Icon as={FiTrash2} color={"#ff0000"} w={"5"} h={"5"} />
        </Center>
      </HStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteHandler(data.username)}
              _focusVisible={{ outline: "none" }}
              isDisabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default SharedListCard;
