import React from "react";
import { useLocation } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Image,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../../components/Button/BackButton";
import DeviceName from "../../../../../components/Cards/DeviceDetail/DeviceName";
import DeviceWifiSignal from "../../../../../components/Cards/DeviceDetail/DeviceWifiSignal";
import DeviceRoom from "../../../../../components/Cards/DeviceDetail/DeviceRoom";
import DeviceType from "../../../../../components/Cards/DeviceDetail/DeviceType";
import DeviceShared from "../../../../../components/Cards/DeviceDetail/DeviceShared";
import DeviceNotif from "../../../../../components/Cards/DeviceDetail/DeviceNotif";

import powrW from "../../../../../assets/Images/CardIcons/powrW.png";
import powrWDark from "../../../../../assets/Images/CardIcons/powrWDark.png";
import powrV from "../../../../../assets/Images/CardIcons/powrV.png";
import powrVDark from "../../../../../assets/Images/CardIcons/powrVDark.png";
import powrA from "../../../../../assets/Images/CardIcons/powrA.png";
import powrADark from "../../../../../assets/Images/CardIcons/powrADark.png";

const PowrDetail = () => {
  const { colorMode } = useColorMode();
  const location = useLocation();

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack spacing={4} w="full" justify={"start"}>
        <BackButton />
        <Text fontWeight={"normal"}>Төхөөрөмжийн дэлгэрэнгүй</Text>
      </HStack>

      {/*     BODY    */}
      <Stack
        justify={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "center",
        ]}
        rounded={"md"}
        px={20}
        h="full"
        alignItems={"start"}
        pt={[6, 6, 6, 6, 10]}
        w="full"
        spacing={[4, 4, 4, 4, 8]}
        direction={["column", "column", "column", "column", "row"]}
      >
        {/* COLUMN 1  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceName deviceName={location.state.deviceName} />

          {/*    Утга    */}
          <HStack
            spacing={5}
            transition={"ease-in-out .3s"}
            w={["full", "full", "full", "full", "96"]}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
          >
            <Text>Утга</Text>
            {location.state.status === "active" ? (
              location.state.deviceType === "powr" ? (
                <HStack spacing={6}>
                  {/* V */}
                  <HStack>
                    <Image
                      w="4"
                      objectFit={"contain"}
                      src={colorMode === "dark" ? powrVDark : powrV}
                    />
                    <Text fontWeight={"medium"}>{location.state.voltage}</Text>
                  </HStack>

                  {/* A */}
                  <HStack>
                    <Image
                      w="4"
                      objectFit={"contain"}
                      src={colorMode === "dark" ? powrADark : powrA}
                    />
                    <Text fontWeight={"medium"}>{location.state.current}</Text>
                  </HStack>

                  {/* W */}
                  <HStack>
                    <Image
                      w="4"
                      objectFit={"contain"}
                      src={colorMode === "dark" ? powrWDark : powrW}
                    />
                    <Text fontWeight={"medium"}>{location.state.power}</Text>
                  </HStack>
                </HStack>
              ) : null
            ) : (
              <Text color="#ff0000" fontWeight={"medium"}>
                Холболтгүй
              </Text>
            )}
          </HStack>
        </VStack>

        {/* COLUMN 2 */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceWifiSignal wifiSignal={location.state.wifiSignal} />
          <DeviceRoom roomName={location.state.roomName} />
          <DeviceType deviceType="Мэдрэгч" />
        </VStack>

        {/* COLUMN 3  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceShared
            isOriginal={location.state.isOriginal}
            origin={location.state.origin}
          />
          <DeviceNotif
            blocked={location.state.blocked}
            id={location.state.id}
            notifEnabled={location.state.notifEnabled}
          />
        </VStack>

        {/*   BODY  END    */}
      </Stack>
    </VStack>
  );
};

export default PowrDetail;
