import React from "react";
import { HStack, Image, Input, Text, useColorMode } from "@chakra-ui/react";

const InsertName = ({ title, img, imgDark, value, onChange }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack align={"center"} justify={"flex-end"} w={"full"} spacing={4}>
      <HStack
        align={"center"}
        justify={"center"}
        display={["none", "none", "none", "none", "flex"]}
      >
        <Image
          src={colorMode === "dark" ? imgDark : img}
          w={"5"}
          h={"5"}
          objectFit={"contain"}
        />
        <Text fontWeight={"normal"} fontSize={15}>
          {title}
        </Text>
      </HStack>
      <Input
        placeholder="Нэр оруулна уу"
        _placeholder={{
          color: colorMode === "dark" ? "#C7C7C7" : "#939393",
        }}
        w={"xs"}
        bg={colorMode === "dark" ? "#252B25" : "#fff"}
        rounded={"full"}
        fontSize={15}
        fontWeight={"normal"}
        px={"6"}
        maxLength={40}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        _focusVisible={{
          outline: "none",
          borderColor: "#45A735",
        }}
        _hover={{
          borderColor: "#45A735",
        }}
      />
    </HStack>
  );
};

export default InsertName;
