import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import contact from "../../../assets/Images/CardIcons/contact.png";
import contactDark from "../../../assets/Images/CardIcons/contactDark.png";
import inContact from "../../../assets/Images/CardIcons/inContact.png";
import inContactDark from "../../../assets/Images/CardIcons/inContactDark.png";
import noContact from "../../../assets/Images/CardIcons/noContact.png";
import noContactDark from "../../../assets/Images/CardIcons/noContactDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import socket from "../../../hooks/Socket";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";

const Contact = ({
  roomName,
  status,
  id,
  deviceName,
  isOriginal,
  origin,
  deviceType,
  wifiSignal,
  notifEnabled,
  contactStatus,
  alarmStatus,
  batteryPercentage,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    contactStatus: contactStatus,
    alarmStatus: alarmStatus,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("contact data --->", value);
      if (id === value._id && value.status && value.contactStatus) {
        setDatas((prevState) => ({
          ...prevState,
          contactStatus: value.status === "active" ? value.contactStatus : null,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('contact name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      onClick={() =>
        history.push("/home/sensor-detail", {
          roomName: datas.room,
          status: datas.status,
          id,
          deviceName: datas.name,
          isOriginal,
          origin,
          deviceType,
          wifiSignal,
          notifEnabled: datas.notifEnabled,
          contactStatus: datas.contactStatus,
          alarmStatus: datas.alarmStatus,
          batteryPercentage,
          blocked,
        })
      }
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack w={"full"}>
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? contactDark : contact}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <HStack w={"60"} justify={"flex-end"}>
            <Image
              src={
                datas.contactStatus === "inContact"
                  ? colorMode === "dark"
                    ? inContactDark
                    : inContact
                  : colorMode === "dark"
                  ? noContactDark
                  : noContact
              }
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontWeight={"normal"} fontSize={14}>
              {datas.contactStatus === "inContact" ? "Хаалттай" : "Нээлттэй"}
            </Text>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Contact;
