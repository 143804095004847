import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import motion from "../../../assets/Images/CardIcons/motion.png";
import motionDark from "../../../assets/Images/CardIcons/motionDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import notifOn from "../../../assets/Images/CardIcons/notifOn.png";
import notifOnDark from "../../../assets/Images/CardIcons/notifOnDark.png";
import notifOff from "../../../assets/Images/CardIcons/notifOff.png";
import notifOffDark from "../../../assets/Images/CardIcons/notifOffDark.png";
import socket from "../../../hooks/Socket";
import Blocked from "../Blocked";
import Cookies from "js-cookie";
import axios from "../../../axios";
import UserContext from "../../../context/UserContext";

const Motion = ({
  roomName,
  status,
  id,
  deviceName,
  isOriginal,
  origin,
  deviceType,
  wifiSignal,
  notifEnabled,
  alarmStatus,
  batteryPercentage,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    alarmStatus: alarmStatus,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const toggleRef = useRef();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  const switchHandler = () => {
    if (isLoading) return;
    if (blocked) return;

    setIsLoading(true);
    if (datas.alarmStatus === "inActive" || datas.alarmStatus === "active") {
      const data = {
        state: datas.alarmStatus === "inActive" ? "active" : "inActive",
        _id: datas.id,
      };
      // console.log("data", data);
      let source = Core.CancelToken.source();
      Core.post(
        "/user/zigbee_sensor_alarm",
        { data: { ...data } },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          // console.log("resuuuuuuult =============>", result.data.data);
          setDatas((prevState) => ({
            ...prevState,
            alarmStatus: result.data.data.alarmStatus,
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          toast({
            description: "Алдаа гарлаа!",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        });
    } else {
      setIsLoading(false);
      toast({
        description: "Алдаа гарлаа!",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("occupancy data --->", value);
      if (id === value._id && value.status) {
        setDatas((prevState) => ({
          ...prevState,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('occupancy name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          onClick={() =>
            history.push("/home/sensor-detail", {
              roomName: datas.room,
              status: datas.status,
              id,
              deviceName: datas.name,
              isOriginal,
              origin,
              deviceType,
              wifiSignal,
              notifEnabled: datas.notifEnabled,
              alarmStatus: datas.alarmStatus,
              batteryPercentage,
              blocked,
            })
          }
          w={"full"}
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? motionDark : motion}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Box
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            w={"36"}
            h={"12"}
            p={1}
            rounded="xl"
            onClick={switchHandler}
            cursor={
              blocked ? "not-allowed" : isLoading ? "not-allowed" : "pointer"
            }
          >
            <HStack w="full" h="full" pos="relative" spacing={0}>
              <Center w="50%">
                <Image
                  src={
                    colorMode === "dark"
                      ? notifOffDark
                      : datas.alarmStatus === "inActive"
                      ? notifOffDark
                      : notifOff
                  }
                  w={"4"}
                  objectFit={"contain"}
                  zIndex={1}
                />
              </Center>

              <Center w="50%">
                <Image
                  src={
                    colorMode === "dark"
                      ? notifOnDark
                      : datas.alarmStatus === "active"
                      ? notifOnDark
                      : notifOn
                  }
                  w={"4"}
                  objectFit={"contain"}
                  zIndex={1}
                />
              </Center>
              <Box
                ref={toggleRef}
                right={
                  datas.alarmStatus === "active"
                    ? "0"
                    : datas.alarmStatus === "inActive"
                    ? "50%"
                    : null
                }
                position={"absolute"}
                w="50%"
                h="full"
                bg="#45A735"
                transition={"all 0.2s ease-in-out"}
                rounded="lg"
                zIndex={isLoading ? 1 : "unset"}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
              >
                {isLoading ? <Spinner color="#fff" size="sm" /> : null}
              </Box>
            </HStack>
          </Box>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/sensor-detail", {
            roomName: datas.room,
            status: datas.status,
            id,
            deviceName: datas.name,
            isOriginal,
            origin,
            deviceType,
            wifiSignal,
            notifEnabled: datas.notifEnabled,
            alarmStatus: datas.alarmStatus,
            batteryPercentage,
            blocked,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Motion;
