import React from "react";
import { Center, HStack, Image, Text, useColorMode } from "@chakra-ui/react";
import room from "../../assets/Images/icons/room.png";
import roomDark from "../../assets/Images/icons/roomDark.png";
import device from "../../assets/Images/icons/device.png";
import EditButton from "./EditButton";

const RoomControlCard = ({ data, setTrigger }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      className="settingsInput"
      w={"2xl"}
      justify={"space-between"}
      align={"center"}
      shadow={"md"}
      rounded={"xl"}
      py={4}
      px={6}
    >
      <HStack>
        <Center
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          w={"10"}
          h={"10"}
          rounded={"lg"}
          border={
            colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
          }
        >
          <Image
            src={colorMode === "dark" ? roomDark : room}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </Center>
        <Text fontSize={15} fontWeight={"normal"}>
          {data.name}
        </Text>
      </HStack>
      <HStack spacing={10}>
        <HStack
          bg={"#D2E7C9"}
          w={"60"}
          py={"1"}
          rounded={"md"}
          align={"center"}
          justify={"center"}
        >
          <Image src={device} w={"4"} h={"4"} objectFit={"contain"} />
          <Text fontSize={15} fontWeight={"normal"} color={"#45A735"}>
            Нийт{" "}
            <Text as={"span"} fontSize={15} fontWeight={"normal"}>
              {data.countDevices}
            </Text>{" "}
            төхөөрөмж
          </Text>
        </HStack>
        <EditButton data={data} setTrigger={setTrigger} />
      </HStack>
    </HStack>
  );
};

export default RoomControlCard;
