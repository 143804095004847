import React, { useState, useContext } from "react";
import {
  HStack,
  Icon,
  Spinner,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { BiSearchAlt } from "react-icons/bi";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import ZbridgeAddDevice from "./ZbridgeAddDevice";
import UserContext from "../../../../context/UserContext";

const ZbridgeSearch = ({ DeviceId, setTrigger }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [device, setDevice] = useState({});
  const [searchDevice, setSearchDevice] = useState(true);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const searchHandler = () => {
    setIsLoading(!isLoading);
    let source = Core.CancelToken.source();
    let cancel = false;
    if (searchDevice) {
      setSearchDevice(false);
      Core.post(
        "/user/zigbee_search",
        {
          _id: DeviceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setDevice(result.data.data);
          setTrigger(true);
          setSearchDevice(true);
        })
        .catch((err) => {
          // console.log('search catch err', err);
          setSearchDevice(true);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    } else {
      Core.post(
        "/user/zigbee_search_cancel",
        { _id: DeviceId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          if (cancel) return;
          // console.log('cancel then ---', result.data);
          setSearchDevice(true);
        })
        .catch((err) => {
          // console.log('cancel err', err);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  return (
    <>
      {device && device.deviceType ? (
        <ZbridgeAddDevice
          data={device}
          setDevice={setDevice}
          setTrigger={setTrigger}
        />
      ) : (
        <HStack
          transition={"ease-in-out .3s"}
          w={"sm"}
          py={"2"}
          bg={"#45A735"}
          rounded={"full"}
          align={"center"}
          justify={"center"}
          shadow={"md"}
          onClick={() => searchHandler()}
          cursor={"pointer"}
          _hover={{
            shadow: "lg",
          }}
        >
          {!searchDevice ? <Spinner size="sm" color="#fff" /> : null}
          <Text color={"#fff"} fontSize={15} fontWeight={"normal"}>
            {!searchDevice ? "Хайж байна" : "Төхөөрөмж хайх"}
          </Text>
          <Icon as={BiSearchAlt} w={"5"} h={"5"} color={"#fff"} />
        </HStack>
      )}
    </>
  );
};

export default ZbridgeSearch;
