import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { QRCodeSVG } from "qrcode.react";
import axios from "../../axios";
import UserContext from "../../context/UserContext";

const PayButton = ({
  radio,
  invoiceId,
  organizationId,
  setOrganizationId,
  organizationName,
}) => {
  const [qrData, setQrData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  //cmt - Baiguulaga bolon huwi hvneer Invoice vvsgeh heseg
  const createInvoiceHandler = () => {
    onOpen();
    setIsLoading(true);
    if (organizationId) {
      Core.post(
        "/user/qpay/invoice_qpay",
        {
          invoiceId: invoiceId,
          isOrganization: true,
          organizationId: organizationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          // console.log(result.data.data);
          setQrData(result.data.data.qr_text);
          setIsLoading(false);
          setOrganizationId("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    } else {
      // console.log('Хувь хүн------------------');
      Core.post(
        "/user/qpay/invoice_qpay",
        {
          invoiceId: invoiceId,
          isOrganization: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          // console.log(result.data.data.qr_text);
          setQrData(result.data.data.qr_text);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  };

  //cmt - Invoice tologdson esehiig shalgah heseg
  const checkHandler = () => {
    setIsDisable(true);
    Core.post(
      "/user/qpay/invoice",
      { _id: invoiceId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        // console.log(result.data.data);
        setIsDisable(false);
        if (result.data.data.paid === true) {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Төлсөн",
            });
          }
        } else {
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: "Төлөөгүй",
            });
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsDisable(false);
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <>
      <Button
        w={"lg"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        px={"10"}
        py={"6"}
        rounded={"md"}
        fontSize={15}
        fontWeight={"normal"}
        color={colorMode === "dark" ? "#fff" : "#000"}
        borderWidth={"thin"}
        borderColor={"#45A735"}
        onClick={() => createInvoiceHandler()}
        isDisabled={
          radio === "0"
            ? true
            : radio === "2"
            ? organizationName.length > 0
              ? false
              : true
            : false
        }
        _focusVisible={{
          outline: "none",
        }}
      >
        Төлбөр төлөх
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Qpay төлбөр</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            {isLoading ? (
              <Center w={"full"}>
                <Spinner size="md" color="#45A735" />
              </Center>
            ) : qrData.length > 0 ? (
              <VStack w={"full"} spacing={6}>
                <QRCodeSVG
                  value={qrData ? qrData : "Test"}
                  size={250}
                  bgColor="transparent"
                  fgColor={colorMode === "dark" ? "#fff" : "#000"}
                />
                <Button
                  w={"full"}
                  fontWeight={"normal"}
                  fontSize={15}
                  onClick={() => checkHandler()}
                  _focusVisible={{ outline: "none" }}
                  isDisabled={isDisable}
                >
                  {isDisable ? <Spinner mr={2} size="sm" /> : null}
                  Төлбөр шалгах
                </Button>
              </VStack>
            ) : (
              <Center w={"full"}>
                <Text fontSize={14} fontWeight={"normal"}>
                  Алдаа гарсан тул төлбөр төлөх товчин дээр дахин дарна уу!
                </Text>
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PayButton;
