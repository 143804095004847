import React, { useEffect, useState } from "react";
import {
  Flex,
  HStack,
  Icon,
  SkeletonText,
  Stack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import Cookies from "js-cookie";
import axios from "../../../axios";
import ChangeNameCard from "../../../components/Device/ChangeNameCard";
import { FiInfo } from "react-icons/fi";

const DeviceChangeName = () => {
  const [devices, setDevices] = useState([]);
  const [room, setRoom] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { Core } = axios();

  //----------------------- get room -------------------------
  useEffect(() => {
    setRoom([]);
    if (houseId) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          setRoom(result.data.data);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  }, []);

  // ---------------------------- Get device --------------------------------
  useEffect(() => {
    if (houseId && trigger === true) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;

      Core.get("/user_v2/device/device_data_new", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          const deviceData = result.data.data.sort((a, b) =>
            a.key > b.key ? 1 : b.key > a.key ? -1 : 0
          );
          setDevices(deviceData);
          setTrigger(false);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setTrigger(false);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  return (
    <VStack
      w={"full"}
      h={"full"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={8}
    >
      <HStack spacing={4}>
        <BackButton />
        <Text fontSize={15} fontWeight={"normal"}>
          Төхөөрөмж нэр солих
        </Text>
      </HStack>
      <Flex
        className="bgColor"
        flexDirection={"column"}
        w={"full"}
        // h={"full"}
        rounded={"md"}
        p={"8"}
        spacing={0}
      >
        <HStack
          bg={colorMode === "dark" ? "#1D211D" : "#fff"}
          w={"full"}
          p={"2"}
          px={"4"}
          roundedTopLeft={"md"}
          roundedTopRight={"md"}
        >
          <Text fontSize={15} fontWeight={"normal"} w={"32"}>
            №
          </Text>
          <Text fontSize={15} fontWeight={"normal"} w={"full"}>
            Төхөөрөмжийн нэр
          </Text>
          <Text fontSize={15} fontWeight={"normal"} w={"full"}>
            Өрөө
          </Text>
          <Text fontSize={15} fontWeight={"normal"} w={"full"}>
            Төлөв
          </Text>
        </HStack>
        {isLoading ? (
          <HStack bg={"transparent"} w={"full"} p={"4"}>
            <Stack w={"32"}>
              <SkeletonText noOfLines={1} w={"6"} />
            </Stack>
            <Stack w={"full"}>
              <SkeletonText noOfLines={1} w={"32"} />
            </Stack>
            <Stack w={"full"}>
              <SkeletonText noOfLines={1} w={"32"} />
            </Stack>
            <Stack w={"full"}>
              <SkeletonText noOfLines={1} w={"32"} />
            </Stack>
          </HStack>
        ) : devices.length > 0 ? (
          devices.map((e, index) => (
            <ChangeNameCard
              key={index}
              index={index}
              data={e}
              room={
                room.find((f) => f._id === e.room)
                  ? room.find((f) => f._id === e.room).name
                  : "-"
              }
              setTrigger={setTrigger}
            />
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            alignSelf={"center"}
            color="gray.600"
            spacing={1}
            mt={6}
          >
            <Icon as={FiInfo} w={6} h={6} />
            <Text fontSize={14} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </Flex>
    </VStack>
  );
};

export default DeviceChangeName;
