import React, { useContext, useState } from "react";
import {
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Image,
  InputRightElement,
  Icon,
  HStack,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import pass from "../../assets/Images/icons/pass.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import UserContext from "../../context/UserContext";
import axios from "../../axios";
import RoundedButton from "../../components/Button/RoundedButton";

const ForgotPassword = () => {
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const id = "error";
  const { btnSpinner, setBtnSpinner, handledHttpErrors } =
    useContext(UserContext);
  const { Auth } = axios();

  const forgotHandler = () => {
    if (password === confirmPassword) {
      setBtnSpinner(true);
      Auth.post("auth/reset_password", {
        authenticationKey: location.state.authenticationKey,
        password: password,
      })
        .then((result) => {
          // console.log("result -->", result.data);
          setBtnSpinner(false);
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Нууц үг амжилттай солигдлоо",
          });
          history.push("/login");
        })
        .catch((err) => {
          // console.log("err -->", err);
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error,
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Нууц үг тохирохгүй байна",
        });
      }
    }
  };

  // Жижиг үсэг, Тоо, Тусгай тэмдэгт заавал байна. Том үсэг байж болно.
  const validPassword = new RegExp(
    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&.,])[a-zA-Z\d@$!%*#?&.,]{8,}$/
  );

  return (
    <VStack w={"xs"} align={"flex-start"} spacing={10}>
      <HStack w={"full"} spacing={4}>
        <IconButton
          bg={"#F7F7F7"}
          icon={<Icon as={IoIosArrowBack} w={5} h={5} />}
          onClick={() => history.goBack()}
          _focusVisible={{
            outline: "none",
          }}
        />
        <Text color={"#45A735"} fontWeight={"medium"} fontSize={23}>
          Нууц үг
        </Text>
      </HStack>
      <VStack w={"full"}>
        <InputGroup justifyContent={"center"} alignContent={"center"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Image src={pass} w={4} h={4} objectFit={"contain"} />}
          />
          <Input
            variant={"flushed"}
            placeholder={"Нууц үг"}
            type={show ? "text" : "password"}
            width={"full"}
            fontSize={15}
            fontWeight={"normal"}
            color={"#000"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            _hover={{
              borderColor: "#45A735",
            }}
            _focusVisible={{
              borderColor: "#45A735",
            }}
          />
          <InputRightElement
            children={
              <Icon
                as={show ? FiEye : FiEyeOff}
                w={4}
                h={4}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow(!show)}
                cursor={"pointer"}
              />
            }
          />
        </InputGroup>
        <InputGroup justifyContent={"center"} alignContent={"center"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Image src={pass} w={4} h={4} objectFit={"contain"} />}
          />
          <Input
            variant={"flushed"}
            placeholder={"Нууц үг давтах"}
            type={show1 ? "text" : "password"}
            width={"full"}
            fontSize={15}
            fontWeight={"normal"}
            color={"#000"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            _hover={{
              borderColor: "#45A735",
            }}
            _focusVisible={{
              borderColor: "#45A735",
            }}
            onKeyDown={(e) => {
              if (password && confirmPassword) {
                if (e.key === "Enter") {
                  forgotHandler(password);
                }
              }
            }}
          />
          <InputRightElement
            children={
              <Icon
                as={show1 ? FiEye : FiEyeOff}
                w={4}
                h={4}
                mr={"2"}
                color="#45A735"
                onClick={() => setShow1(!show1)}
                cursor={"pointer"}
              />
            }
          />
        </InputGroup>
      </VStack>
      <VStack w={"full"} spacing={8}>
        <RoundedButton
          title={"Бүртгүүлэх"}
          width={"full"}
          onClick={() => forgotHandler()}
          disable={
            validPassword.test(password) &&
            validPassword.test(confirmPassword) &&
            !btnSpinner
              ? false
              : true
          }
        />
        <Button
          variant={"link"}
          rounded={"full"}
          w={"full"}
          fontWeight={"normal"}
          fontSize={15}
          // color={"#000"}
          onClick={() => history.push("/signup")}
          alignSelf={"center"}
        >
          Буцах
        </Button>
      </VStack>
    </VStack>
  );
};

export default ForgotPassword;
