import React from "react";
import {
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import notifOn from "../assets/Images/icons/notifOn.png";
import notifOnDark from "../assets/Images/icons/notifOnDark.png";

export const NotifAlert = ({ title, body, ...style }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      {...style}
      className="header"
      alignItems="center"
      justifyContent="flex-start"
      py={4}
      px={4}
      shadow={"lg"}
      rounded={"md"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#000" : "#fff"}
    >
      <Image
        src={colorMode === "dark" ? notifOnDark : notifOn}
        w={"5"}
        h={"5"}
        objectFit={"contain"}
      />
      <VStack alignItems="flex-start" justifyContent="flex-start">
        <Heading as="h4" fontSize="md" fontWeight={"medium"}>
          {title}
        </Heading>
        {body ? (
          <Text fontSize={15} fontWeight={"normal"}>
            {body}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  );
};
