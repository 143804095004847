import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import check from "../../../../assets/Images/icons/check.png";
import checked from "../../../../assets/Images/icons/checked.png";
import UserContext from "../../../../context/UserContext";

const TtrAutoSwitchStatus = ({ data, setChangeState, onClose }) => {
  const [state, setState] = useState("");
  const { colorMode } = useColorMode();
  const { setTtrAct } = useContext(UserContext);

  const submitHandler = () => {
    setTtrAct((prevState) => [
      ...prevState,
      {
        _id: data._id,
        device: data.name,
        type: "automation",
        actionType: "state",
        command: state,
      },
    ]);
    setState();
    setChangeState(false);
    onClose();
  };

  return (
    <VStack w={"full"} spacing={6}>
      <VStack w={"full"}>
        <HStack
          w={"full"}
          h={"20"}
          px={"8"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          borderWidth={"medium"}
          borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
          rounded={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
          onClick={() => setState(1)}
          cursor={"pointer"}
        >
          <Text fontWeight={"normal"} fontSize={15}>
            Асах
          </Text>
          <Image
            src={state === 1 ? checked : check}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </HStack>
        <HStack
          w={"full"}
          h={"20"}
          px={"8"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          borderWidth={"medium"}
          borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
          rounded={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
          onClick={() => setState(0)}
          cursor={"pointer"}
        >
          <Text fontWeight={"normal"} fontSize={15}>
            Унтрах
          </Text>
          <Image
            src={state === 0 ? checked : check}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </HStack>
      </VStack>
      <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          onClick={() => setChangeState(false)}
          _focusVisible={{ outline: "none" }}
        >
          Буцах
        </Button>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          color={"#45A735"}
          borderWidth={"thin"}
          borderColor={"#45A735"}
          onClick={() => submitHandler()}
          isDisabled={state === 0 || state === 1 ? false : true}
          _focusVisible={{ outline: "none" }}
        >
          Хадгалах
        </Button>
      </HStack>
    </VStack>
  );
};

export default TtrAutoSwitchStatus;
