import React, { useState, useContext } from "react";
import {
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Spinner,
  Icon,
  Stack,
  Input,
  Button,
  Textarea,
  useToast,
  Link,
  Center,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import send from "../../../assets/Images/icons/send.png";
import {
  FiFacebook,
  FiTwitter,
  FiPhone,
  FiMail,
  FiMapPin,
} from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import axios from "../../../axios";
import UserContext from "../../../context/UserContext";

const Feedback = () => {
  const [mail, setMail] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const validEmail = new RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  );

  // --------   feedback явуулах   --------
  const submitHandler = () => {
    if (loading) return;

    if (!validEmail.test(mail) && description) {
      toast({
        duration: 2000,
        position: "top",
        status: "error",
        description: "Хүчинтэй имэйл хаяг оруулна уу!",
      });
    } else {
      setLoading(true);

      Core.post("/user/feedback", {
        mail: mail,
        desc: description,
        rate: 5,
      })
        .then((result) => {
          setLoading(false);
          // console.log(result);

          if (result.data.success) {
            toast({
              duration: 2000,
              position: "top",
              status: "success",
              description: result.data.message,
            });

            setMail("");
            setDescription("");
          }
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err);
          if (handledHttpErrors.includes(err.response.status)) return;

          toast({
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        });
    }
  };

  return (
    <VStack h="full" spacing={0} w="full">
      <HStack w="full" spacing={4}>
        <BackButton />
        <Text fontWeight={"normal"}>Санал хүсэлт илгээх</Text>
      </HStack>

      {/* BODY */}
      <HStack spacing={"56"} justify={"center"} h="85vh" w={"full"}>
        <VStack w={"lg"} spacing={5}>
          <Stack
            w={"full"}
            align={"center"}
            justify={"center"}
            direction={{ base: "column", md: "row" }}
            spacing={3}
          >
            <Input
              placeholder="И-мэйл хаягаа оруулна уу"
              w={"full"}
              bg={colorMode === "light" ? "#fff" : "#252B25"}
              fontSize={15}
              fontWeight={"normal"}
              py={6}
              px={8}
              border="none"
              rounded={"xl"}
              _hover={{
                borderColor: "#45A735",
                borderWidth: "thin",
              }}
              _focusVisible={{
                outline: "none",
                boxShadow: "none",
                borderColor: "#45A735",
                borderWidth: "thin",
              }}
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </Stack>
          <Textarea
            py={6}
            px={8}
            placeholder="Санал хүсэлтээ бичнэ үү..."
            w={"full"}
            height={"60"}
            bg={colorMode === "light" ? "#fff" : "#252B25"}
            fontSize={15}
            fontWeight={"normal"}
            rounded="xl"
            border="none"
            _hover={{
              borderColor: "#45A735",
              borderWidth: "thin",
            }}
            _focusVisible={{
              outline: "none",
              boxShadow: "none",
              borderColor: "#45A735",
              borderWidth: "thin",
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Button
            py={6}
            fontSize={17}
            fontWeight={"normal"}
            w={"full"}
            bg={colorMode === "light" ? "#fff" : "#252B25"}
            shadow={"sm"}
            onClick={submitHandler}
            isDisabled={
              loading === true ? true : mail && description ? false : true
            }
            border="1px solid #45A735"
            rounded={"xl"}
            _hover={{
              bg:
                colorMode === "dark" ? "rgba(255, 255, 255, 0.16)" : "#F5F5F5",
            }}
            _focusVisible={{
              outline: "none",
            }}
          >
            {loading === true ? (
              <Spinner size="sm" color="#45A735" />
            ) : (
              <React.Fragment>
                Илгээх{" "}
                <Image
                  src={send}
                  objectFit={"contain"}
                  w={"5"}
                  h={"5"}
                  ml={"4"}
                />
              </React.Fragment>
            )}
          </Button>
        </VStack>

        <VStack spacing={10}>
          <HStack spacing={6}>
            <Link href="https://www.facebook.com/viot.mon/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"lg"}
                p={"3"}
                color={"#000"}
                bg={colorMode === "light" ? "#fff" : "#252B25"}
                _hover={{
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon
                  color={colorMode === "dark" ? "white" : "#000"}
                  as={FiFacebook}
                  w={8}
                  h={8}
                />
              </Center>
            </Link>
            <Link href="https://www.facebook.com/viot.mon/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"lg"}
                p={"3"}
                color={"#000"}
                bg={colorMode === "light" ? "#fff" : "#252B25"}
                _hover={{
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon
                  color={colorMode === "dark" ? "white" : "#000"}
                  as={BsInstagram}
                  w={8}
                  h={8}
                />
              </Center>
            </Link>
            <Link href="https://web.viot.mn/" isExternal>
              <Center
                transition={"ease .3s"}
                rounded={"lg"}
                p={"3"}
                color={"#000"}
                bg={colorMode === "light" ? "#fff" : "#252B25"}
                _hover={{
                  padding: 3,
                  color: "#45A735",
                }}
              >
                <Icon
                  color={colorMode === "dark" ? "white" : "#000"}
                  as={FiTwitter}
                  w={8}
                  h={8}
                />
              </Center>
            </Link>
          </HStack>

          <VStack
            w={"full"}
            h={"full"}
            align={"flex-start"}
            spacing={6}
            mt={"20"}
          >
            <VStack align={"flex-start"}>
              <HStack spacing={4}>
                <Icon
                  color={colorMode === "dark" ? "white" : "#000"}
                  as={FiMail}
                  w={6}
                  h={6}
                />
                <Text
                  color={colorMode === "dark" ? "white" : "#000"}
                  fontSize={17}
                  fontWeight={"medium"}
                >
                  И-мэйл хаяг : info@viot.mn
                </Text>
              </HStack>
            </VStack>

            <HStack spacing={4}>
              <Icon
                color={colorMode === "dark" ? "white" : "#000"}
                as={FiPhone}
                w={6}
                h={6}
              />
              <Text
                color={colorMode === "dark" ? "white" : "#000"}
                fontSize={17}
                fontWeight={"medium"}
              >
                Утас : +976 72722072
              </Text>
            </HStack>

            <HStack align={"start"} spacing={4}>
              <Icon
                color={colorMode === "dark" ? "white" : "#000"}
                as={FiMapPin}
                w={6}
                h={6}
              />
              <Text
                fontSize={17}
                textAlign={"justify"}
                maxW={"xs"}
                fontWeight="medium"
                color={colorMode === "dark" ? "white" : "#000"}
              >
                Улаанбаатар хот, Баянгол дүүрэг, 19-р хороо 4-р хороолол
                Энэбишийн 78
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Feedback;
