import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import automation from "../../../assets/Images/icons/automation.png";
import automationDark from "../../../assets/Images/icons/automationDark.png";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import TtrAutomationState from "./TtrAutomationState";
import TtrAutomationNotif from "./TtrAutomationNotif";

const TtrAddAutomation = () => {
  const { colorMode } = useColorMode();

  return (
    <Accordion w={"full"} allowMultiple>
      <AccordionItem w={{ md: "full", lg: "sm" }} border={"none"}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              transition={"ease-in-out .3s"}
              w={"full"}
              h={"20"}
              px={"8"}
              bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
              borderWidth={"medium"}
              borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              align={"center"}
              justify={"space-between"}
              cursor={"pointer"}
              _hover={{
                shadow: "lg",
              }}
              _focusVisible={{
                outline: "none",
              }}
            >
              <HStack flex={1} textAlign="left">
                <Image
                  src={colorMode === "dark" ? automationDark : automation}
                  w={"5"}
                  h={"5"}
                  objectFit={"contain"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  Автоматжуулалт
                </Text>
              </HStack>
              {isExpanded ? (
                <Icon as={IoIosArrowDown} w={"6"} h={"6"} color={"#45A735"} />
              ) : (
                <Icon
                  as={IoIosArrowForward}
                  w={"6"}
                  h={"6"}
                  color={"#45A735"}
                />
              )}
            </AccordionButton>
            <AccordionPanel py={4}>
              <VStack w={"full"}>
                <TtrAutomationState />
                <TtrAutomationNotif />
              </VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TtrAddAutomation;
