import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Icon,
  Stack,
  Box,
} from "@chakra-ui/react";
import BackButton from "../../../../../components/Button/BackButton";
import { AiOutlineRight } from "react-icons/ai";
import PowrValue from "../../../../../components/Cards/DeviceDetail/PowrDetail/PowrValue";
import purpleWave from "../../../../../assets/Images/purpleWave.png";
import yellowWave from "../../../../../assets/Images/yellowWave.png";
import greenWave from "../../../../../assets/Images/greenWave.png";
import blueWave from "../../../../../assets/Images/blueWave.png";
import yellowWaveSharp from "../../../../../assets/Images/yellowWaveSharp.png";
import socket from "../../../../../hooks/Socket";
import { motion } from "framer-motion";

const PowrDash = () => {
  const location = useLocation();
  const [datas, setDatas] = useState(location.state);
  const history = useHistory();
  const { colorMode } = useColorMode();
  const descFontSize = 13;
  const descColor =
    colorMode === "light" ? "rgba(0, 0, 0, 0.4)" : "rgba(255, 255, 255, 0.4)";

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("zbAqi control socket data --->", value);
      if (datas.id === value._id && value.status && value.data) {
        // console.log("id", id, value._id);
        setDatas((prevState) => ({
          ...prevState,
          data: value.data,
          status: value.status,
        }));
      }

      if (datas.id === value._id && value.for) {
        // console.log('Th name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>{location.state.deviceName}</Text>
        </HStack>

        <HStack
          cursor={"pointer"}
          spacing={2}
          borderRadius={"3xl"}
          px="16"
          py={2}
          className="btn"
          _hover={{
            shadow: "md",
          }}
          transition={"linear"}
          transitionDuration={".3s"}
          onClick={() =>
            history.push("/home/sensor-detail", {
              roomName: datas.roomName,
              status: datas.status,
              id: datas.id,
              deviceName: datas.deviceName,
              isOriginal: datas.isOriginal,
              origin: datas.origin,
              deviceType: datas.deviceType,
              wifiSignal: datas.wifiSignal,
              notifEnabled: datas.notifEnabled,
              blocked: datas.blocked,
              data: datas.data,
            })
          }
        >
          <Text>Дэлгэрэнгүй</Text>
          <Icon color={"#45A735"} as={AiOutlineRight} />
        </HStack>
      </HStack>

      {/*     BODY    */}
      <Box
        display={"flex"}
        w="full"
        h="full"
        justifyContent={"center"}
        alignItems={"center"}
        px={[20, 20, 20, 20, 52]}
      >
        <Stack
          h="fit-content"
          w="full"
          spacing="28"
          rounded={"md"}
          direction={["column", "column", "column", "column", "row"]}
        >
          {/* --------------------- TODO: COLUMN 1 ------------------- */}
          <VStack w={"full"} spacing={6}>
            {/* TODO: CO2 */}
            <HStack
              position={"relative"}
              borderRadius={"md"}
              px="12"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              overflow={"hidden"}
            >
              <motion.div
                initial={{
                  height: "0%",
                }}
                animate={{
                  height: "100%",
                }}
                transition={{
                  type: "tween",
                  duration: 0.7,
                  ease: "easeOut",
                }}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundImage: `url(${purpleWave})`,
                  backgroundSize: "cover",

                  display: "flex",
                  padding: "0 48px",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <VStack alignItems={"start"} spacing={0}>
                  <Text>CO2</Text>
                  <Text fontSize={descFontSize} color={descColor}>
                    Нүүрстөрөгчийн давхар исэл
                  </Text>
                </VStack>
                <VStack pb="5" justify={"end"} h="full">
                  {datas.status === "active" ? (
                    typeof datas.data.Co2 === "number" ? (
                      <PowrValue
                        value={datas.data.Co2}
                        valueUnit="ppm"
                        sm={false}
                      />
                    ) : (
                      <PowrValue value={"-"} valueUnit="" sm={false} />
                    )
                  ) : (
                    <Text color="#ff0000" fontWeight={"medium"}>
                      Холболтгүй
                    </Text>
                  )}
                </VStack>
              </motion.div>
            </HStack>

            {/* TODO: FORMALDEHYDE / HCHO */}
            <HStack
              borderRadius={"md"}
              px="12"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              pos="relative"
            >
              <motion.div
                initial={{
                  height: "0%",
                }}
                animate={{
                  height: "100%",
                }}
                transition={{
                  type: "tween",
                  duration: 0.7,
                  ease: "easeOut",
                  delay: 0.1,
                }}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundImage: `url(${yellowWave})`,
                  backgroundSize: "cover",

                  display: "flex",
                  padding: "0 48px",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <VStack alignItems={"start"} spacing={0}>
                  <Text>HCHO</Text>
                  <Text fontSize={descFontSize} color={descColor}>
                    Формальдегид
                  </Text>
                </VStack>
                <VStack pb="5" justify={"end"} h="full">
                  {datas.status === "active" ? (
                    typeof datas.data.Formaldehyde === "number" ? (
                      <PowrValue
                        value={datas.data.Formaldehyde}
                        valueUnit="mg/m3"
                        sm={false}
                      />
                    ) : (
                      <PowrValue value={"-"} valueUnit="" sm={false} />
                    )
                  ) : (
                    <Text color="#ff0000" fontWeight={"medium"}>
                      Холболтгүй
                    </Text>
                  )}
                </VStack>
              </motion.div>
            </HStack>

            {/* TODO: VOC */}
            <HStack
              borderRadius={"md"}
              px="12"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              pos="relative"
            >
              <motion.div
                initial={{
                  height: "0%",
                }}
                animate={{
                  height: "100%",
                }}
                transition={{
                  type: "tween",
                  duration: 0.7,
                  ease: "easeOut",
                  delay: 0.2,
                }}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundImage: `url(${greenWave})`,
                  backgroundSize: "cover",

                  display: "flex",
                  padding: "0 48px",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <VStack alignItems={"start"} spacing={0}>
                  <Text>VOC</Text>
                  <Text fontSize={descFontSize} color={descColor}>
                    Дэгдэмхий органик нэгдлүүд
                  </Text>
                </VStack>
                <VStack pb="5" justify={"end"} h="full">
                  {datas.status === "active" ? (
                    typeof datas.data.VOC === "number" ? (
                      <PowrValue
                        value={datas.data.VOC}
                        valueUnit="ppm"
                        sm={false}
                      />
                    ) : (
                      <PowrValue value={"-"} valueUnit="" sm={false} />
                    )
                  ) : (
                    <Text color="#ff0000" fontWeight={"medium"}>
                      Холболтгүй
                    </Text>
                  )}
                </VStack>
              </motion.div>
            </HStack>
          </VStack>

          {/* --------------------- TODO: COLUMN 2 ------------------- */}
          <VStack w={"full"} spacing={6}>
            {/* TODO: TEMPERATURE  */}
            <HStack
              borderRadius={"md"}
              px="12"
              justify={"space-between"}
              w="full"
              h="50%"
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              alignItems={"start"}
              pt={"14"}
              position="relative"
            >
              <motion.div
                initial={{
                  height: "0%",
                }}
                animate={{
                  height: "50%",
                }}
                transition={{
                  type: "tween",
                  duration: 0.7,
                  ease: "easeOut",
                  delay: 0.3,
                }}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundImage: `url(${yellowWaveSharp})`,
                  backgroundSize: "cover",
                }}
              />

              <VStack alignItems={"start"} pt={2} spacing={0}>
                <Text>Temperature</Text>
                <Text fontSize={descFontSize} color={descColor}>
                  Температур
                </Text>
              </VStack>

              <VStack pb="5" h="full">
                {datas.status === "active" ? (
                  typeof datas.data.Temperature === "number" ? (
                    <PowrValue
                      value={datas.data.Temperature}
                      valueUnit="°C"
                      sm={false}
                    />
                  ) : (
                    <PowrValue value={"-"} valueUnit="" sm={false} />
                  )
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </VStack>
            </HStack>

            {/* TODO: HUMIDITY */}
            <HStack
              borderRadius={"md"}
              px="12"
              justify={"space-between"}
              w="full"
              h="50%"
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              alignItems={"start"}
              pt={"14"}
              position="relative"
            >
              <motion.div
                initial={{
                  height: "0%",
                }}
                animate={{
                  height: "50%",
                }}
                transition={{
                  type: "tween",
                  duration: 0.7,
                  ease: "easeOut",
                  delay: 0.4,
                }}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundImage: `url(${blueWave})`,
                  backgroundSize: "cover",
                }}
              />
              <VStack alignItems={"start"} pt={2} spacing={0}>
                <Text>Humidity</Text>
                <Text fontSize={descFontSize} color={descColor}>
                  Чийгшил
                </Text>
              </VStack>

              <VStack pb="5" h="full">
                {datas.status === "active" ? (
                  typeof datas.data.Humidity === "number" ? (
                    <PowrValue
                      value={datas.data.Humidity}
                      valueUnit="%"
                      sm={false}
                    />
                  ) : (
                    <PowrValue value={"-"} valueUnit="" sm={false} />
                  )
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </VStack>
            </HStack>
          </VStack>

          {/* ----------------------------------------------- */}
        </Stack>
      </Box>
    </VStack>
  );
};

export default PowrDash;
