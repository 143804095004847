import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import plus from "../../../assets/Images/icons/plus.png";
import plusDark from "../../../assets/Images/icons/plusDark.png";
import Cookies from "js-cookie";
import axios from "../../../axios";
import ControlCard from "../../../components/House/ControlCard";
import UserContext from "../../../context/UserContext";

const HouseControl = () => {
  const [houses, setHouses] = useState([]);
  const [houseName, setHouseName] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setHouseTrigger, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    if (trigger) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get(
        "/user/house",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (cancel) return;
          //   console.log(result.data.data);
          setHouses(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          setTrigger(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (houseName.length > 0) {
      setIsDisable(true);
      let source = Core.CancelToken.source();
      Core.post(
        "/user_v2/house/create",
        { name: houseName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((res) => {
          // console.log(res.data);
          setHouseName("");
          setTrigger(true);
          setIsDisable(false);
          setHouseTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай нэмэгдлээ!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"} h={"full"} spacing={4}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontSize={15} fontWeight={"normal"}>
            Байшингийн удирдлага
          </Text>
        </HStack>
        <Center
          transition={"ease-in-out .3s"}
          className="btn"
          w={"xs"}
          py={2}
          rounded={"full"}
          onClick={() => onOpen()}
          cursor={"pointer"}
          _hover={{
            shadow: "md",
          }}
        >
          <Text fontSize={15} fontWeight={"normal"}>
            Байшин нэмэх
          </Text>
          <Image
            src={colorMode === "dark" ? plusDark : plus}
            w={"4"}
            h={"4"}
            objectFit={"contain"}
            ml={"2"}
          />
        </Center>
      </HStack>
      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"md"}
        align={"center"}
        justify={"flex-start"}
        spacing={4}
        py={"6"}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px",
            borderRadius: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
            borderRadius: "8px",
          },
        }}
      >
        {trigger ? (
          <Spinner size="lg" color="#45A735" />
        ) : (
          houses.map((e, index) => (
            <ControlCard
              key={index}
              data={e}
              setTrigger={setTrigger}
              housesCount={houses.length}
            />
          ))
        )}
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Байшин нэмэх</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Байшингийн нэр оруулна уу!
              </FormLabel>
              <Input
                placeholder={"-"}
                maxLength={30}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={houseName}
                onChange={(e) => setHouseName(e.target.value)}
                focusBorderColor={"#45A735"}
                autoFocus
                isDisabled={isDisable}
                onKeyDown={(e) => {
                  if (houseName && !isDisable) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={houseName.length > 0 && !isDisable ? false : true}
            >
              {isDisable ? <Spinner mr={"2"} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default HouseControl;
