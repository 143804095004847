import React, { useState, useContext } from "react";
import { HStack, Text, useColorMode, Switch, useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../axios";
import UserContext from "../../../context/UserContext";

const DeviceType = ({ notifEnabled, id, blocked }) => {
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [notif, setNotif] = useState(notifEnabled);
  const [isLoading, setIsLoading] = useState(false);
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  function submitHandler() {
    setIsLoading(true);
    let source = Core.CancelToken.source();
    Core.put(
      "user/device_notif",
      {
        _id: id,
        notifEnabled: !notif,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        // console.log(result);
        setIsLoading(false);
        setNotif(!notif);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        if (handledHttpErrors.includes(err.response.status)) return;

        toast({
          description: "Алдаа гарлаа",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  }

  return (
    <HStack
      spacing={5}
      transition={"ease-in-out .3s"}
      w="full"
      h={"20"}
      px={"8"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
    >
      <Text>Мэдэгдэл</Text>
      {notifEnabled === true || notifEnabled === false ? (
        <Switch
          isDisabled={blocked || isLoading ? true : false}
          isChecked={notif}
          colorScheme="green"
          size="md"
          css={`
            > span:first-of-type {
              box-shadow: unset;
            }
          `}
          onChange={submitHandler}
        />
      ) : (
        <Text>-</Text>
      )}
    </HStack>
  );
};

export default DeviceType;
