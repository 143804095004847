import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import lgt from "../../../assets/Images/CardIcons/lgt.png";
import lgtDark from "../../../assets/Images/CardIcons/lgtDark.png";
import ec from "../../../assets/Images/CardIcons/ec.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";

const LoraLight = ({
  roomName,
  deviceName,
  status,
  illumination,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    room: roomName,
    name: deviceName,
    illumination: illumination,
    id: id,
    status: status,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("lgt data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          illumination: value.status === "active" ? value.data.illumination : 0,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('lgt name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      onClick={() =>
        history.push("/home/sensor-detail", {
          roomName: datas.room,
          deviceName: datas.name,
          status: datas.status,
          illumination: datas.illumination,
          id,
          isOriginal,
          origin,
          wifiSignal,
          notifEnabled: datas.notifEnabled,
          deviceType,
          blocked,
        })
      }
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <Center
          w={"12"}
          h={"12"}
          rounded={"md"}
          bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
          alignSelf={"flex-start"}
        >
          <Image
            src={colorMode === "dark" ? lgtDark : lgt}
            w={"5"}
            objectFit={"contain"}
          />
        </Center>
        {datas.status === "active" ? (
          <HStack spacing={1}>
            <Image src={ec} w={"4"} h={"4"} objectFit={"contain"} />
            <Text fontSize={14} fontWeight={"normal"}>
              {datas.illumination + " LUX"}
            </Text>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default LoraLight;
