import React, { useContext } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Image,
  Text,
  useColorMode,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import logo from "../../assets/Images/logo.png";
import logoDark from "../../assets/Images/logoDark.png";
import logout from "../../assets/Images/icons/logout.png";
import logoutDark from "../../assets/Images/icons/logoutDark.png";
import AuthContext from "../../hooks/UseAuth";
import Cookies from "js-cookie";
import { SideData } from "./SidebarData";

const SideBarMobile = ({ showSideBar, toggleSidebar }) => {
  const history = useHistory();
  const location = useLocation();
  const badge = Cookies.get("badge");
  const { colorMode } = useColorMode();
  const { logoutHandler } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Drawer
      onClose={toggleSidebar}
      isOpen={showSideBar}
      size={"xs"}
      placement={"left"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton _focusVisible={{ outline: "none" }} />
        <DrawerBody h={"full"}>
          <VStack
            h={"full"}
            align={"flex-start"}
            justify={"space-between"}
            py={"8"}
          >
            <VStack w={"full"} align={"flex-start"} spacing={14}>
              <Image
                src={colorMode === "dark" ? logoDark : logo}
                objectFit={"contain"}
                w={"full"}
              />
              <VStack spacing={3} align={"flex-start"} w={"full"}>
                {SideData.map((e, index) => (
                  <HStack
                    transition="all .3s"
                    key={index}
                    bg={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? colorMode === "dark"
                          ? "#1D211D"
                          : "#F3F3F3"
                        : "transparent"
                    }
                    w={"full"}
                    h={"10"}
                    px={"4"}
                    align={"center"}
                    rounded={"md"}
                    onClick={() => {
                      history.push(e.toLink);
                      toggleSidebar();
                    }}
                    cursor={"pointer"}
                    _hover={{
                      bg: colorMode === "dark" ? "#1D211D" : "#F3F3F3",
                    }}
                    position={"relative"}
                  >
                    <Image
                      src={colorMode === "dark" ? e.iconDark : e.icon}
                      w={5}
                      h={5}
                      objectFit={"contain"}
                      alignSelf={"center"}
                    />
                    <Text
                      fontWeight={
                        location.pathname.split("/")[1] ===
                        e.toLink.split("/")[1]
                          ? "medium"
                          : "normal"
                      }
                      fontSize={15}
                    >
                      {e.title}
                    </Text>

                    {e.title === "Мэдэгдэл" ? (
                      <Badge
                        key={index}
                        variant="solid"
                        colorScheme={"red"}
                        position={"absolute"}
                        top={1}
                        left={5}
                        borderRadius={"full"}
                        fontSize={"x-small"}
                        zIndex={100}
                      >
                        {badge > 0 ? badge : null}
                      </Badge>
                    ) : null}
                  </HStack>
                ))}
              </VStack>
            </VStack>
            <HStack
              w={"full"}
              h={"10"}
              px={"4"}
              onClick={onOpen}
              cursor={"pointer"}
            >
              <Image
                src={colorMode === "dark" ? logoutDark : logout}
                w={5}
                h={5}
                objectFit={"contain"}
                alignSelf={"center"}
              />
              <Text fontWeight={"medium"} fontSize={14}>
                Гарах
              </Text>
              <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered={true}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Та гарахдаа итгэлтэй байна уу?</ModalHeader>
                  <ModalCloseButton _focusVisible={{ outline: "none" }} />
                  <ModalFooter>
                    <Button
                      onClick={onClose}
                      mr={3}
                      _focusVisible={{ outline: "none" }}
                    >
                      Болих
                    </Button>
                    <Button
                      colorScheme="green"
                      _focusVisible={{ outline: "none" }}
                      onClick={() => logoutHandler()}
                    >
                      Тийм
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarMobile;
