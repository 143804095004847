import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Icon,
  Image,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../../components/Button/BackButton";
import { AiOutlineRight } from "react-icons/ai";
import powrW from "../../../../../assets/Images/CardIcons/powrW.png";
import powrWDark from "../../../../../assets/Images/CardIcons/powrWDark.png";
import powrV from "../../../../../assets/Images/CardIcons/powrV.png";
import powrVDark from "../../../../../assets/Images/CardIcons/powrVDark.png";
import powrA from "../../../../../assets/Images/CardIcons/powrA.png";
import powrADark from "../../../../../assets/Images/CardIcons/powrADark.png";
import PowrValue from "../../../../../components/Cards/DeviceDetail/PowrDetail/PowrValue";

const PowrDash = () => {
  const history = useHistory();
  const location = useLocation();
  const { colorMode } = useColorMode();

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>Цахилгаан хэмжигч</Text>
        </HStack>

        <HStack
          cursor={"pointer"}
          spacing={2}
          borderRadius={"3xl"}
          px="16"
          py={2}
          className="btn"
          _hover={{
            shadow: "md",
          }}
          transition={"linear"}
          transitionDuration={".3s"}
          onClick={() =>
            history.push("/home/power-detail", {
              roomName: location.state.roomName,
              status: location.state.status,
              id: location.state.id,
              deviceName: location.state.deviceName,
              isOriginal: location.state.isOriginal,
              origin: location.state.origin,
              deviceType: location.state.deviceType,
              wifiSignal: location.state.wifiSignal,
              subDeviceCount: location.state.subDeviceCount,
              voltage: location.state.voltage,
              current: location.state.current,
              power: location.state.power,
              ApparentPower: location.state.ApparentPower,
              ReactivePower: location.state.ReactivePower,
              Factor: location.state.Factor,
              Today: location.state.Today,
              Yesterday: location.state.Yesterday,
              Total: location.state.Total,
              notifEnabled: location.state.notifEnabled,
              blocked: location.state.blocked,
            })
          }
        >
          <Text>Дэлгэрэнгүй</Text>
          <Icon color={"#45A735"} as={AiOutlineRight} />
        </HStack>
      </HStack>

      {/*     BODY    */}
      <Stack
        h="full"
        w="full"
        justify={"center"}
        align={"center"}
        px={[20, 20, 20, 20, 52]}
        spacing="28"
        rounded={"md"}
        direction={["column", "column", "column", "column", "row"]}
      >
        {/* --------------------- COLUMN 1 ------------------- */}
        <VStack w={"full"} spacing={6}>
          <HStack
            borderRadius={"md"}
            px="12"
            justify={"space-between"}
            w="full"
            h="28"
            bg={colorMode === "dark" ? "#1D211D" : "#fff"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
          >
            <HStack spacing={4}>
              <Image
                w="7"
                objectFit={"contain"}
                src={colorMode === "dark" ? powrVDark : powrV}
              />
              <Text>Voltage</Text>
            </HStack>

            <VStack pb="5" justify={"end"} h="full">
              {location.state.status === "active" ? (
                <PowrValue
                  value={location.state.voltage}
                  valueUnit="V"
                  sm={false}
                />
              ) : (
                <Text color="#ff0000" fontWeight={"medium"}>
                  Холболтгүй
                </Text>
              )}
            </VStack>
          </HStack>

          <HStack
            borderRadius={"md"}
            px="12"
            justify={"space-between"}
            w="full"
            h="28"
            bg={colorMode === "dark" ? "#1D211D" : "#fff"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
          >
            <HStack spacing={4}>
              <Image
                w="7"
                objectFit={"contain"}
                src={colorMode === "dark" ? powrADark : powrA}
              />
              <Text>Current</Text>
            </HStack>

            <VStack pb="5" justify={"end"} h="full">
              {location.state.status === "active" ? (
                <PowrValue
                  value={location.state.current}
                  valueUnit="A"
                  sm={false}
                />
              ) : (
                <Text color="#ff0000" fontWeight={"medium"}>
                  Холболтгүй
                </Text>
              )}
            </VStack>
          </HStack>

          <HStack
            borderRadius={"md"}
            px="12"
            justify={"space-between"}
            w="full"
            h="28"
            bg={colorMode === "dark" ? "#1D211D" : "#fff"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
          >
            <HStack spacing={4}>
              <Image
                w="7"
                objectFit={"contain"}
                src={colorMode === "dark" ? powrWDark : powrW}
              />
              <Text>Power</Text>
            </HStack>

            <VStack pb="5" justify={"end"} h="full">
              {location.state.status === "active" ? (
                <PowrValue
                  value={location.state.power}
                  valueUnit="W"
                  sm={false}
                />
              ) : (
                <Text color="#ff0000" fontWeight={"medium"}>
                  Холболтгүй
                </Text>
              )}
            </VStack>
          </HStack>
        </VStack>

        <HStack w={"full"} spacing={[10, 10, 10, 10, 28]}>
          {/* ------------------  COLUMN 2  ----------------------- */}
          <VStack
            pos="relative"
            p="0"
            spacing={6}
            w={["full", "full", "full", "full", "52"]}
          >
            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Apparent</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue
                    value={location.state.ApparentPower}
                    valueUnit={"VA"}
                  />
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>

            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Reactive</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue
                    value={location.state.ReactivePower}
                    valueUnit={"VAr"}
                  />
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>

            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Factor</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue value={location.state.Factor} valueUnit={"VAr"} />
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>
            <Text
              color={colorMode === "dark" ? "#ffffff" : "#919191"}
              top="-71"
              left="0"
              pos="absolute"
            >
              Power
            </Text>
          </VStack>

          {/* ----------------- COLUMN 3 ----------------------- */}
          <VStack
            pos="relative"
            spacing={6}
            w={["full", "full", "full", "full", "52"]}
          >
            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Today</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue value={location.state.Today} valueUnit={"kWh"} />
                ) : (
                  <Text color="#ff0000" fontWeight="medium">
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>

            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Yesterday</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue
                    value={location.state.Yesterday}
                    valueUnit={"kWh"}
                  />
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>

            <VStack
              borderRadius={"md"}
              py="5"
              px="6"
              justify={"space-between"}
              w="full"
              h="28"
              bg={colorMode === "dark" ? "#1D211D" : "#f3f3f3"}
              // borderWidth={"medium"}
              // borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              align="start"
            >
              <Text>Total</Text>
              <HStack w="full" justify={"end"}>
                {location.state.status === "active" ? (
                  <PowrValue value={location.state.Total} valueUnit={"kWh"} />
                ) : (
                  <Text color="#ff0000" fontWeight={"medium"}>
                    Холболтгүй
                  </Text>
                )}
              </HStack>
            </VStack>
            <Text
              color={colorMode === "dark" ? "#ffffff" : "#919191"}
              top="-71"
              left="0"
              pos="absolute"
            >
              Energy
            </Text>
          </VStack>
          {/* ----------------- COLUMN 3  END ----------------------- */}
        </HStack>
      </Stack>
    </VStack>
  );
};

export default PowrDash;
