import React from "react";
import {
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import light from "../../../../assets/Images/CardIcons/light.png";
import lightDark from "../../../../assets/Images/CardIcons/lightDark.png";
import rgb from "../../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../../assets/Images/CardIcons/rgbDark.png";
import knx from "../../../../assets/Images/CardIcons/knx.png";
import knxDark from "../../../../assets/Images/CardIcons/knxDark.png";
import ir from "../../../../assets/Images/CardIcons/ir.png";
import irDark from "../../../../assets/Images/CardIcons/irDark.png";
import g4 from "../../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../../assets/Images/CardIcons/g4Dark.png";

const TtrActSwitch = ({
  data,
  roomName,
  index,
  setChangeState,
  setDevice,
  setSelectChannel,
  setSelectedNewG4,
}) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        if (data.deviceType === "newG4") {
          setSelectedNewG4(data);
          setSelectChannel(true);
        } else {
          setDevice(data);
          setChangeState(true);
        }
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={
            data.deviceType === "switch" ||
            data.deviceType === "Switchsc" ||
            data.deviceType === "zbSwitch"
              ? colorMode === "dark"
                ? lightDark
                : light
              : data.deviceType === "RGB" || data.deviceType === "WLED"
              ? colorMode === "dark"
                ? rgbDark
                : rgb
              : data.deviceType === "knx_switch" ||
                data.deviceType === "knx_dimmer"
              ? colorMode === "dark"
                ? knxDark
                : knx
              : data.deviceType === "irSub"
              ? colorMode === "dark"
                ? irDark
                : ir
              : data.deviceType === "g4" ||
                data.deviceType === "G4ADB" ||
                data.deviceType === "newG4"
              ? colorMode === "dark"
                ? g4Dark
                : g4
              : null
          }
          w={"6"}
          h={"6"}
          objectFit={"contain"}
        />
        <VStack align={"flex-start"} justify={"center"} spacing={0}>
          {roomName ? (
            <Text color={"#919191"} fontWeight={"normal"} fontSize={12}>
              {roomName}
            </Text>
          ) : null}
          <Text fontWeight={"normal"} fontSize={15}>
            {data.for}
          </Text>
        </VStack>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default TtrActSwitch;
