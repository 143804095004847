import React, { useEffect, useState, useContext } from "react";
import {
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import AutoDevice from "../../../../assets/Images/icons/AutoDevice.png";
import AutoDeviceDark from "../../../../assets/Images/icons/AutoDeviceDark.png";
import Cookies from "js-cookie";
import axios from "../../../../axios";
import { FiInfo } from "react-icons/fi";
import ActionDevices from "./ActionDevices";
import ActDeviceState from "./ActDeviceState";
import UserContext from "../../../../context/UserContext";

const AddActionDevice = () => {
  const [devices, setDevices] = useState([]);
  const [room, setRoom] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [device, setDevice] = useState({});
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (houseId) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          // if (cancel) return;
          let data = result.data.data;
          setRoom(data);
        })
        .catch((err) => {
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      // return () => {
      //   cancel = true;
      //   source.cancel();
      // };
    }
  }, []);

  const getDeviceHandler = () => {
    if (houseId) {
      setIsLoading(true);
      onOpen();
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("user/automaticdevices", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          // result.data.data.map(e => console.log(e));
          let data = result.data.data;
          // @TODO RGB Нэмнэ шүүүүүүүүүүү!!!
          setDevices(
            data.filter((e) => {
              return (
                e.deviceType === "switch" ||
                e.deviceType === "knx_switch" ||
                e.deviceType === "knx_dimmer" ||
                e.deviceType === "irSub" ||
                e.deviceType === "Curtain" ||
                e.deviceType === "g4" ||
                e.deviceType === "Switchsc" ||
                e.deviceType === "WLED" ||
                e.deviceType === "zbSwitch"
              );
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          onClose();
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        py={"4"}
        px={"4"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        borderWidth={"medium"}
        borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
        rounded={"md"}
        shadow={"md"}
        align={"center"}
        justify={"center"}
        onClick={getDeviceHandler}
        cursor={"pointer"}
        _hover={{
          shadow: "lg",
        }}
      >
        <Image
          src={colorMode === "dark" ? AutoDeviceDark : AutoDevice}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
        />
        <Text>Төхөөрөмж</Text>
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>
            {changeState ? "Төлөв өө сонгоно уу!" : "Төхөөрөмжөө сонгоно уу!"}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setChangeState(false);
            }}
            _focusVisible={{ outline: "none" }}
          />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            {!changeState ? (
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Text fontWeight={"normal"} fontSize={15}>
                  Автоматжуулах боломжтой төхөөрөмжүүд
                </Text>
                {isLoading ? (
                  <Center w={"full"}>
                    <Spinner color="#45A735" size="md" />
                  </Center>
                ) : devices.length > 0 ? (
                  devices.map((e, i) => (
                    <ActionDevices
                      key={i}
                      data={e}
                      roomName={
                        room.filter((f) => f._id === e.roomId).length > 0
                          ? room.filter((f) => f._id === e.roomId)[0].name
                          : null
                      }
                      setChangeState={setChangeState}
                      setDevice={setDevice}
                    />
                  ))
                ) : (
                  <VStack
                    w={"full"}
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                  >
                    <Icon as={FiInfo} w={6} h={6} />
                    <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
                  </VStack>
                )}
              </VStack>
            ) : (
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Text fontWeight={"normal"} fontSize={15}>
                  Сонгосон төхөөрөмжийн хийгдэх төлөв
                </Text>
                <ActDeviceState
                  data={device}
                  setChangeState={setChangeState}
                  onClose={onClose}
                />
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddActionDevice;
