import React from "react";
import {
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import automation from "../../../../assets/Images/icons/automation.png";
import automationDark from "../../../../assets/Images/icons/automationDark.png";

const TtrGrpNotif = ({ data, index, setChangeState, setGroup }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        setGroup(data);
        setChangeState(true);
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={colorMode === "dark" ? automationDark : automation}
          w={"6"}
          h={"6"}
          objectFit={"contain"}
        />
        <VStack align={"flex-start"} justify={"center"} spacing={0}>
          <Text fontWeight={"normal"} fontSize={15}>
            {data.name}
          </Text>
        </VStack>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default TtrGrpNotif;
