import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import check from "../../../../assets/Images/icons/check.png";
import checked from "../../../../assets/Images/icons/checked.png";
import UserContext from "../../../../context/UserContext";

const ActDeviceState = ({ data, setChangeState, onClose }) => {
  const [state, setState] = useState("");
  const { colorMode } = useColorMode();
  const { setAutoAct } = useContext(UserContext);

  const submitHandler = () => {
    setAutoAct((prevState) => [
      ...prevState,
      {
        _id: data._id,
        device: data.for,
        actionType: data.deviceType,
        command: state,
      },
    ]);
    setState("");
    setChangeState(false);
    onClose();
  };

  return (
    <VStack w={"full"} spacing={6}>
      {data.deviceType === "switch" || data.deviceType === "WLED" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "Curtain" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("open")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Нээх
            </Text>
            <Image
              src={state === "open" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("close")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Хаах
            </Text>
            <Image
              src={state === "close" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "g4" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "Switchsc" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "zbSwitch" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : null}
      <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          onClick={() => setChangeState(false)}
          _focusVisible={{ outline: "none" }}
        >
          Буцах
        </Button>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          color={"#45A735"}
          borderWidth={"thin"}
          borderColor={"#45A735"}
          onClick={() => submitHandler(data.account)}
          isDisabled={state.length === 0 ? true : false}
          _focusVisible={{ outline: "none" }}
        >
          Хадгалах
        </Button>
      </HStack>
    </VStack>
  );
};

export default ActDeviceState;
