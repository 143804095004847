import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Icon,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  useToast,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import convert from "color-convert";
import dimmerLight from "../../../../../assets/Images/icons/dimmer.png";
import dimmerDark from "../../../../../assets/Images/icons/dimmerDark.png";
import saturationLight from "../../../../../assets/Images/icons/saturation.png";
import saturationDark from "../../../../../assets/Images/icons/saturationDark.png";
import WledHuePicker from "./WledHuePicker";
import Cookies from "js-cookie";
import axios from "../../../../../axios";
import { TbArrowBackUp } from "react-icons/tb";
import UserContext from "../../../../../context/UserContext";
import socket from "../../../../../hooks/Socket";

const WledControl = () => {
  const history = useHistory();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const [hex, setHex] = useState(location.state.color);
  const [color, setColor] = useState("");
  const [dimmer, setDimmer] = useState((location.state.dimmer * 100) / 255);
  const [mode, setMode] = useState(location.state.mode);
  const [state, setState] = useState(location.state.state);
  const [hue, setHue] = useState(location.state.hue ? location.state.hue : 0);
  const [sat, setSat] = useState(location.state.sat ? location.state.sat : 0);
  const [status, setStatus] = useState(location.state.status);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const [saveLoading, setSaveLoading] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [currentHue, setCurrentHue] = useState(0);
  const [currentSat, setCurrentSat] = useState(0);
  const [currentDimmer, setCurrentDimmer] = useState(0);
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);
  const [lastHue, setLastHue] = useState(0);
  const [canSendSocket, setCanSendSocket] = useState(false);
  const id = location.state.id;
  const [deviceName, setDeviceName] = useState(location.state.deviceName);
  const [socketColorReceived, setSocketColorReceived] = useState(0);
  const houseId = Cookies.get("house");

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("WLED data --->", value);

      if (id === value._id && value.status && value.data) {
        setStatus(value.status);
        if (value.status === "active") {
          setState(value.data.state);
          setDimmer(value.data.dimmer);
          setHex(value.data.color);
          setMode(value.data.mode);
          if (hex !== value.data.color) setSocketColorReceived(Math.random());
        }
      }

      if (id === value._id && value.for) {
        // console.log("rgb name:", value.for);

        setDeviceName(value.for);
      }
    };
    socket.on(id, socketSetData);
    return () => {
      socket.off(id, socketSetData);
    };
  }, []);

  const handleSave = () => {
    setSaveLoading(true);

    if (
      hue >= 0 &&
      hue <= 360 &&
      sat >= 0 &&
      sat <= 100 &&
      dimmer >= 1 &&
      dimmer <= 100
    ) {
      let source = Core.CancelToken.source();

      Core.post(
        "/user_v2/command/wled_color",
        {
          color: hex,
          dimmer: (dimmer * 255) / 100,
          _id: location.state.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          // console.log(" color request RESPONSE ================= >", result.data.data);
          let rgb = convert.hex.rgb(result.data.data.color);
          let hsl = convert.rgb.hsl(rgb);

          setCurrentColor(result.data.data.color);
          setCurrentHue(hsl[0]);
          setCurrentSat(hsl[1]);
          setState("on");
          setSaveLoading(false);
        })
        .catch((err) => {
          // console.log("error  ------>", err);
          setSaveLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            position: "top",
          });
        });
    } else {
      setSaveLoading(false);
    }
  };

  // door ogogdson hue bol hsl color iin hue
  function chooseRed() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(2);
  }

  function choosePurple() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(275);
  }

  function chooseBlue() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(227);
  }

  function chooseGreen() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(120);
  }

  function chooseYellow() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(59);
  }

  function chooseOrange() {
    if (status !== "active") return;
    setCanSendSocket(true);
    setHue(35);
  }

  useEffect(() => {
    //   HEX  -->  RGB  -->  HSL    conversion
    let rgb = convert.hex.rgb(hex);
    let hsl = convert.rgb.hsl(rgb);
    setHue(hsl[0]);
    setSat(hsl[1]);

    hsl = `hsl(${hsl[0]}, ${hsl[1]}%, ${50}%)`;

    setColor(hsl);
    setCurrentColor(hsl);
    setCurrentHue(hue);
    setLastHue(hsl[0]);
    setCurrentSat(sat);
    setCurrentDimmer(dimmer);
  }, [socketColorReceived]);

  // mouse аар өнгө сонгох үед ажиллана (бас sat, dimmer 2 өөрчлөгдөх үед)
  useEffect(() => {
    let hsl = `hsl(${hue}, ${sat}%, 50%)`;
    let rgb = convert.hsl.rgb(hue, sat, 50);
    let hex = convert.rgb.hex(rgb);

    setHex(`#${hex}`);
    setColor(hsl);
  }, [hue, sat, dimmer]);

  // hue өөрчлөгдөх үед socket оор датагаа өгж байна
  useEffect(() => {
    setLastHue(hue);
    if (socket.connected === true) {
      if (canSendSocket) {
        let rgb = convert.hsl.rgb(hue, sat, 50);
        let hex = convert.rgb.hex(rgb);

        if (Math.abs(hue - lastHue) > 1) {
          if (socket.connected === true) {
            socket.emit("wled_set_color", {
              _id: location.state.id,
              clientId: location.state.clientId,
              houseId,
              color: `#${hex}`,
              dimmer: parseInt((dimmer * 255) / 100),
            });
          }
        } else if (hue - lastHue === 1) {
          let newHue = hue;
          let newLastHue = lastHue;

          if (hue % 2 === 1) {
            newHue = newHue - 1;
          }

          if (lastHue % 2 === 1) {
            newLastHue = newLastHue - 1;
          }

          if (Math.abs(newHue - newLastHue) === 2) {
            if (socket.connected === true) {
              socket.emit("wled_set_color", {
                _id: location.state.id,
                clientId: location.state.clientId,
                houseId,
                color: `#${hex}`,
                dimmer: parseInt((dimmer * 255) / 100),
              });
            }
          }
        }
      }
    }
  }, [hue]);

  // dimmer, sat 2 өөрчлөгдөх үед socket оор датагаа өгж байна
  useEffect(() => {
    if (canSendSocket) {
      if (socket.connected === true) {
        let rgb = convert.hsl.rgb(hue, sat, 50);
        let hex = convert.rgb.hex(rgb);

        socket.emit("wled_set_color", {
          _id: location.state.id,
          clientId: location.state.clientId,
          houseId,
          color: `#${hex}`,
          dimmer: parseInt((dimmer * 255) / 100),
        });
      }
    }
  }, [dimmer, sat]);

  return (
    <VStack h="full" spacing={0} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <IconButton
            transition={"ease-in-out .3s"}
            bg={colorMode === "dark" ? "#252B25" : "#fff"}
            rounded={"lg"}
            icon={
              <Icon
                as={TbArrowBackUp}
                color={colorMode === "dark" ? "#fff" : "#000"}
                w={5}
                h={5}
              />
            }
            _hover={{
              bg: "#fff",
              shadow: "md",
            }}
            _focusVisible={{
              outline: "none",
              bg: "#fff",
            }}
            onClick={() => history.push("/home")}
          />
          <Text fontWeight={"normal"}>{deviceName} - удирдлага</Text>
        </HStack>

        <HStack
          cursor={"pointer"}
          spacing={2}
          borderRadius={"3xl"}
          px="16"
          py={2}
          className="btn"
          _hover={{
            shadow: "md",
          }}
          transition={"linear"}
          transitionDuration={".3s"}
          onClick={() =>
            history.push("/home/switch-detail", {
              ...location.state,
              status: status,
              state: state,
              hue: hue,
              sat: sat,
              dimmer: dimmer,
              // COLOR гэсэн утганд заавал HEX утга явуулах ёстой!!
              color: hex,
            })
          }
        >
          <Text>Дэлгэрэнгүй</Text>
          <Icon color={"#45A735"} as={AiOutlineRight} />
        </HStack>
      </HStack>
      {/*     BODY    */}
      <HStack
        h="full"
        w="full"
        justify={"center"}
        align={"center"}
        px={20}
        spacing={["6", "6", "6", "12", "24"]}
        rounded={"md"}
      >
        <>
          <WledHuePicker
            hue={hue}
            sat={sat}
            dimmer={dimmer}
            setHue={setHue}
            color={color}
            state={state}
            setState={setState}
            blocked={location.state.blocked}
            id={location.state.id}
            setCanSendSocket={setCanSendSocket}
            status={status}
          />

          {/* Өнгөний сонголтууд */}
          <VStack spacing={5}>
            <VStack align={"start"} spacing={3} w="full">
              <Text>Өнгөний сонголтууд</Text>
              <HStack
                px={10}
                py={4}
                borderRadius={"2xl"}
                spacing={5}
                className="rgb"
              >
                <Box
                  onClick={chooseRed}
                  cursor={"pointer"}
                  h="43px"
                  w="8"
                  bg="#ff0000"
                  borderRadius={"2xl"}
                ></Box>
                <Box
                  h="43px"
                  w="8"
                  bg="hsl(275, 66%, 41%)"
                  borderRadius={"2xl"}
                  onClick={choosePurple}
                  cursor={"pointer"}
                ></Box>
                <Box
                  h="43px"
                  w="8"
                  bg="hsl(227, 98%, 37%)"
                  borderRadius={"2xl"}
                  onClick={chooseBlue}
                  cursor={"pointer"}
                ></Box>
                <Box
                  h="43px"
                  w="8"
                  bg="hsl(120, 100%, 36%)"
                  borderRadius={"2xl"}
                  onClick={chooseGreen}
                  cursor={"pointer"}
                ></Box>
                <Box
                  h="43px"
                  w="8"
                  bg="hsl(59, 100%, 50%)"
                  borderRadius={"2xl"}
                  onClick={chooseYellow}
                  cursor={"pointer"}
                ></Box>
                <Box
                  h="43px"
                  w="8"
                  bg="hsl(35, 100%, 50%)"
                  borderRadius={"2xl"}
                  onClick={chooseOrange}
                  cursor={"pointer"}
                ></Box>
              </HStack>
            </VStack>

            {/* SATURATION */}
            <VStack align={"start"} spacing={3} w="full">
              <Text>Saturation</Text>
              <HStack
                w="full"
                px={10}
                py={5}
                borderRadius={"2xl"}
                spacing={5}
                className="rgb"
              >
                <Image
                  w={4}
                  h={4}
                  objectFit={"contain"}
                  src={colorMode === "light" ? dimmerLight : dimmerDark}
                />
                <RangeSlider
                  isDisabled={status === "active" ? false : true}
                  onChange={(val) => {
                    setSat(val[0]);
                    setCanSendSocket(true);
                  }}
                  aria-label={["min", "max"]}
                  defaultValue={[sat]}
                  // value={sat ? [sat] : [100]}
                  value={status !== "active" ? [100] : sat < 1 ? [0] : [sat]}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack bg="hsl(56, 95%, 49%)" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    _focusVisible={{
                      outline: "none",
                    }}
                    bg="hsl(56, 95%, 49%)"
                    index={0}
                  />
                </RangeSlider>
                <Text fontSize={13}>{Math.round(sat)}%</Text>
              </HStack>
            </VStack>

            {/* DIMMER */}
            <VStack w="full" align={"start"} spacing={3}>
              <Text>Dimmer</Text>
              <HStack
                w="full"
                px={10}
                py={5}
                borderRadius={"2xl"}
                spacing={5}
                className="rgb"
              >
                <Image
                  w={4}
                  h={4}
                  objectFit={"contain"}
                  src={colorMode === "light" ? saturationLight : saturationDark}
                />
                <RangeSlider
                  isDisabled={status === "active" ? false : true}
                  min={1}
                  onChange={(val) => {
                    setDimmer(parseInt(val[0]));
                    setCanSendSocket(true);
                  }}
                  aria-label={["min", "max"]}
                  value={dimmer ? [dimmer] : [100]}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack bg="linear-gradient(to right,#fffddf , hsl(56, 95%, 49%))" />
                  </RangeSliderTrack>
                  <RangeSliderThumb
                    _focusVisible={{
                      outline: "none",
                    }}
                    bg="hsl(56, 95%, 49%)"
                    index={0}
                  />
                </RangeSlider>
                <Text fontSize={13}>{Math.round(dimmer)}%</Text>
              </HStack>
            </VStack>
          </VStack>
        </>
      </HStack>
      {/*     BODY   END  */}

      {/* FOOTER  */}
      <HStack justify={"end"} w="full">
        <Center
          w={"xs"}
          bg={"#45A735"}
          color={"#fff"}
          py={2}
          borderRadius={"full"}
          border={"1px solid #45A735"}
          onClick={
            status === "inActive"
              ? null
              : location.state.blocked
              ? null
              : saveLoading === false
              ? handleSave
              : null
          }
          cursor={
            status === "inActive"
              ? "not-allowed"
              : location.state.blocked
              ? "not-allowed"
              : saveLoading
              ? "not-allowed"
              : "pointer"
          }
        >
          {saveLoading ? <Spinner mr="2" size="sm" color={"#fff"} /> : null}

          <Text>Хадгалах</Text>
        </Center>
      </HStack>
      {/* ) : null} */}
    </VStack>
  );
};

export default WledControl;
