import {
  Button,
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import TtrActSwitch from "../TtrActSwitch";
import { IoIosArrowForward } from "react-icons/io";
import g4 from "../../../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../../../assets/Images/CardIcons/g4Dark.png";
import { useState, useContext } from "react";
import TtrActSwitchStatus from "../TtrActSwitchStatus";
import UserContext from "../../../../../context/UserContext";
import { FiInfo } from "react-icons/fi";

function TtrNewG4SelectChannel({
  selectedNewG4,
  room,
  setChangeState,
  setDevice,
  setSelectChannel,
  setSelectedNewG4,
  onClose,
}) {
  const { ttrAct } = useContext(UserContext);
  const { colorMode } = useColorMode();
  const [changeChannelState, setChangeChannelState] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState();
  const channels = selectedNewG4?.channels?.filter((e) => {
    return !ttrAct.some((j) => e._id === j._id);
  });

  return (
    <>
      {changeChannelState ? ( // TODO: select ON or OFF from newG4 channel
        <VStack
          w={"full"}
          spacing={4}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Text fontWeight={"normal"} fontSize={15}>
            Сонгосон төхөөрөмжийн хийгдэх төлөв
          </Text>
          <TtrActSwitchStatus
            data={selectedChannel}
            setChangeState={setChangeState}
            onClose={onClose}
            setChangeChannelState={setChangeChannelState}
            setSelectChannel={setSelectChannel}
          />
        </VStack>
      ) : channels.length > 0 ? (
        <VStack pb={"20"}>
          {channels.map((e) => (
            // <TtrActSwitch
            //   key={e._id}
            //   data={{ ...e, for: e.name, deviceType: "newG4" }}
            // roomName={
            // room.filter((f) => f._id === e.roomId).length > 0
            //   ? room.filter((f) => f._id === e.roomId)[0].name
            //   : null
            // }
            //   setChangeState={setChangeState}
            //   setDevice={setDevice}
            //   setSelectChannel={setSelectChannel}
            //   setSelectedNewG4={setSelectedNewG4}
            // />

            // TODO:    <---  CHANNELS   --->
            <HStack
              key={e._id}
              w={"full"}
              h={"20"}
              px={"4"}
              bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
              borderWidth={"medium"}
              borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              align={"center"}
              justify={"space-between"}
              onClick={() => {
                setChangeChannelState(true);
                setSelectedChannel({ ...e, deviceType: "newG4", for: e.name });
              }}
              cursor={"pointer"}
            >
              <HStack w={"full"}>
                <Image
                  src={colorMode === "dark" ? g4Dark : g4}
                  w={"6"}
                  h={"6"}
                  objectFit={"contain"}
                />
                <VStack align={"flex-start"} justify={"center"} spacing={0}>
                  {room.filter((f) => f._id === e.roomId).length > 0 ? (
                    room.filter((f) => f._id === e.roomId)[0].name
                  ) : null ? (
                    <Text color={"#919191"} fontWeight={"normal"} fontSize={12}>
                      {room.filter((f) => f._id === e.roomId).length > 0
                        ? room.filter((f) => f._id === e.roomId)[0].name
                        : null}
                    </Text>
                  ) : null}
                  <Text fontWeight={"normal"} fontSize={15}>
                    {e.name}
                  </Text>
                </VStack>
              </HStack>
              <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
            </HStack>
          ))}

          <HStack
            pos={"absolute"}
            w={"99%"}
            bottom={0}
            align={"flex-end"}
            justify={"flex-end"}
            bg={colorMode === "dark" ? "#202420" : "#fff"}
            p={"6"}
          >
            <Button
              w={"32"}
              bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
              fontWeight={"normal"}
              fontSize={15}
              onClick={() => {
                setSelectChannel(false);
              }}
              _focusVisible={{ outline: "none" }}
            >
              Буцах
            </Button>
          </HStack>
        </VStack>
      ) : (
        <VStack
          w={"full"}
          justifyContent="center"
          alignItems="center"
          color="gray.600"
        >
          <Icon as={FiInfo} w={6} h={6} />
          <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </>
  );
}

export default TtrNewG4SelectChannel;
