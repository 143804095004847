import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../../../axios";
import UserContext from "../../../../context/UserContext";
const ZbridgeAddDevice = ({ data, setDevice, setTrigger }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [houses, setHouses] = useState([]);
  const [selectHouse, setSelectHouse] = useState("");
  const [room, setRoom] = useState([]);
  const [selectRoom, setSelectRoom] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    let source = Core.CancelToken.source();
    let cancel = false;
    Core.get(
      "/user/house",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setHouses(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectHouse) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: selectHouse },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("room result", result.data.data);
          setRoom(result.data.data);
          setIsLoading(false);
          // setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          // setTrigger(false);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [selectHouse]);

  const submitHandler = () => {
    setBtnSpinner(true);
    const deviceData = {
      entityName: data.entityName,
      deviceType: data.deviceType,
      deviceId: data.deviceid,
      for: deviceName,
      house: selectHouse,
      room: selectRoom ? selectRoom : "",
    };
    Core.post("/user/zigbee_entity", deviceData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        if (!toast.isActive(id)) {
          toast({
            description: "Амжилттай!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
        setDevice({});
        setHouses([]);
        setRoom([]);
        setDeviceName("");
        setBtnSpinner(false);
        setTrigger((prev) => {
          return !prev;
        });
      })
      .catch((err) => {
        // console.log(err);
        setBtnSpinner(false);
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            description: "Алдаа гарлаа!",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
  };

  return (
    <VStack w={"full"} spacing={3}>
      <Box
        w={"full"}
        borderColor={"#45A735"}
        borderLeftWidth={"medium"}
        bg={colorMode === "dark" ? "#202420" : "#fff"}
        py={"2"}
        px={{ base: "4", md: "6" }}
      >
        <Text fontSize={"15"} fontWeight={"medium"}>
          Төхөөрөмж нэмэх
        </Text>
      </Box>
      <Divider />
      <HStack w="full">
        <Input
          w={"full"}
          defaultValue={
            data.deviceType === "Water"
              ? "Ус мэдрэгч"
              : data.deviceType === "Occupancy"
              ? "Хөдөлгөөн мэдрэгч"
              : data.deviceType === "zigbeeTh"
              ? "Температур, Чийгшил мэдрэгч"
              : data.deviceType === "Contact"
              ? "контакт мэдрэгч"
              : data.deviceType === "zigbeeSwitch"
              ? "Унтраалга"
              : data.deviceType === "RGB"
              ? "RGB"
              : data.deviceType === "Smoke"
              ? "Утаа мэдрэгч"
              : data.deviceType === "zbAqi"
              ? "Агаарын чанарын мэдрэгч"
              : data.deviceType === "zbSwitch"
              ? "Унтраалга"
              : data.deviceType === "Curtain"
              ? "Хөшиг"
              : data.deviceType === "humanDetector"
              ? "Хүн мэдрэгч"
              : null
          }
          isReadOnly
          fontSize={15}
          fontWeight={"normal"}
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          _focusVisible={{
            outline: "none",
          }}
        />
        <Input
          w={"full"}
          placeholder="Төхөөрөмжийн нэр оруулна уу."
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          fontSize={15}
          fontWeight={"normal"}
          maxLength={30}
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
          _focusVisible={{
            outline: "none",
            borderColor: "#45A735",
          }}
          _hover={{
            borderColor: "#45A735",
          }}
        />
      </HStack>

      <HStack w="full">
        <Select
          w={"full"}
          placeholder="Байшин сонгоно уу!"
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          borderWidth={"thin"}
          fontSize={15}
          fontWeight={"normal"}
          onChange={(e) => setSelectHouse(e.target.value)}
          isDisabled={houses.length > 0 ? false : true}
          _focusVisible={{
            outline: "none",
            borderColor: "#45A735",
          }}
          _hover={{
            borderColor: "#45A735",
          }}
        >
          {houses.length > 0
            ? houses.map((e, i) => (
                <option key={i} value={e._id}>
                  {e.name}
                </option>
              ))
            : null}
        </Select>
        <Select
          w={"full"}
          placeholder="Өрөө сонгоно уу!"
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          borderWidth={"thin"}
          fontSize={15}
          fontWeight={"normal"}
          onChange={(e) => setSelectRoom(e.target.value)}
          _focusVisible={{
            outline: "none",
            borderColor: "#45A735",
          }}
          _hover={{
            borderColor: "#45A735",
          }}
          isDisabled={room.length > 0 && !isLoading ? false : true}
        >
          {room.length > 0
            ? room.map((e, i) => (
                <option key={i} value={e._id}>
                  {e.name}
                </option>
              ))
            : null}
        </Select>
      </HStack>
      <Divider />
      <HStack w="full">
        <Button
          w={"full"}
          bg={"#ddd"}
          rounded={"md"}
          color={"#000"}
          onClick={() => setDevice({})}
          _focusVisible={{
            outline: "none",
          }}
        >
          Болих
        </Button>
        <Button
          w={"full"}
          bg={"#45A735"}
          rounded={"md"}
          color={"#fff"}
          onClick={submitHandler}
          isDisabled={
            data.deviceType && deviceName && selectHouse ? false : true
          }
          _focusVisible={{
            outline: "none",
          }}
          _hover={{
            bg: "#5AB54B",
          }}
        >
          {btnSpinner ? <Spinner mr={2} size="sm" /> : null}
          Хадгалах
        </Button>
      </HStack>
    </VStack>
  );
};

export default ZbridgeAddDevice;
