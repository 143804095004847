import React, { useEffect, useState, useContext } from "react";
import {
  Center,
  HStack,
  Image,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import light from "../../../assets/Images/CardIcons/light.png";
import lightDark from "../../../assets/Images/CardIcons/lightDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import axios from "../../../axios";
import socket from "../../../hooks/Socket";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";

const ZbSwitch = ({
  roomName,
  deviceName,
  switchStatus,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    switchStatus: switchStatus,
    status: status,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const token = Cookies.get("accessToken");
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("switch data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          switchStatus: value.status === "active" ? value.data : "OFF",
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log("Switch name:", value.for);
        setDatas((prevState) => ({
          ...prevState,
          title: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  // --------------   унтраах, асаах   ------------
  const switchHandler = () => {
    setIsDisable(true);
    if (datas.switchStatus) {
      // console.log("switchData", datas.switchStatus);
      const data = {
        command: datas.switchStatus === "OFF" ? "ON" : "OFF",
        _id: datas.id,
      };
      let source = Core.CancelToken.source();
      Core.post("/user_v2/command/zbswitch", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          //   console.log("switch", result.data);
          setDatas((prevState) => ({
            ...prevState,
            switchStatus: data.command,
          }));
          // setSwitchData(result.data.data.switchStatus === "ON" ? true : false);
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;
        });
    } else {
      setIsDisable(false);
    }
  };

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          w={"full"}
          onClick={() =>
            history.push("/home/switch-detail", {
              id: id,
              roomName: datas.room,
              deviceName: datas.name,
              switchStatus: datas.switchStatus,
              status: datas.status,
              notifEnabled: datas.notifEnabled,
              isOriginal,
              origin,
              wifiSignal,
              deviceType,
              blocked,
            })
          }
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? lightDark : light}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Center w={"10"} h={"10"} rounded={"md"}>
            <Switch
              colorScheme="green"
              size="md"
              isChecked={datas.switchStatus === "OFF" ? false : true}
              onChange={switchHandler}
              isDisabled={blocked || isDisable ? true : false}
              css={`
                > span:first-of-type {
                  box-shadow: unset;
                }
              `}
            />
          </Center>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/switch-detail", {
            id: id,
            roomName: datas.room,
            deviceName: datas.name,
            switchStatus: datas.switchStatus,
            status: datas.status,
            notifEnabled: datas.notifEnabled,
            isOriginal,
            origin,
            wifiSignal,
            deviceType,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default ZbSwitch;
