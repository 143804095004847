import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Icon,
  SimpleGrid,
  Center,
  Spinner,
  useToast,
  Button,
} from "@chakra-ui/react";
import BackButton from "../../../../../components/Button/BackButton";
import { AiOutlineRight } from "react-icons/ai";
import axios from "../../../../../axios";
import { useState } from "react";
import socket from "../../../../../hooks/Socket";
import NewG4Channel from "../../../../../components/Cards/G4/NewG4Channel";

const NewG4Control = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { Core } = axios();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [channels, setChannels] = useState([]); // buh channel uud
  const [datas, setDatas] = useState(location.state); // channel iin gadnah tohoormj (container)

  //   TODO: SOCKET
  useEffect(() => {
    const socketSetData = (value) => {
      //   console.log("newg4 socket data --->", value);

      setDatas((prev) => {
        return { ...prev, ...value };
      });
    };

    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  //   TODO: channelluudaa avdag huselt
  const fetchChannels = () => {
    Core.get("/user_v2/device/detail_new_g4", {
      params: { _id: datas.id },
    })
      .then((res) => {
        // console.log("newG4 channels", res.data.data);
        setIsLoading(false);
        setChannels(res.data.data);
      })
      .catch((err) => {
        // console.log("err", err);
        setIsLoading(false);

        const error =
          typeof err?.response?.data?.error === "string"
            ? err.response.data.error
            : "Алдаа гарлаа!";

        toast({
          description: error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>{datas.deviceName}</Text>
        </HStack>

        <HStack spacing={4}>
          {/* daraalal solih */}
          <Button
            bg={colorMode === "light" ? "white" : "#252B25"}
            className="btn"
            _hover={{
              shadow: "md",
            }}
            px="16"
            borderRadius={"3xl"}
            fontSize="15px"
            fontWeight={"normal"}
            gap={2}
            _focusVisible={{
              outline: "none",
            }}
            onClick={() => {
              history.push("/home/newG4-channel-settings", {
                ...datas,
              });
            }}
          >
            Тохиргоо
          </Button>

          {/* delgerengui */}
          <HStack
            cursor={"pointer"}
            spacing={2}
            borderRadius={"3xl"}
            px="16"
            py={2}
            className="btn"
            _hover={{
              shadow: "md",
            }}
            transition={"linear"}
            transitionDuration={".3s"}
            onClick={() => history.push("/home/switch-detail", datas)}
          >
            <Text>Дэлгэрэнгүй</Text>
            <Icon color={"#45A735"} as={AiOutlineRight} />
          </HStack>
        </HStack>
      </HStack>

      {/*   TODO:  BODY    */}
      {datas.status === "inActive" ? (
        <Center h="full">
          <Text fontWeight={"normal"} fontSize={15} color={"#ff0000"}>
            Холболтгүй
          </Text>
        </Center>
      ) : isLoading ? (
        <Center h="full">
          <Spinner color="#45A735" size={"lg"} />
        </Center>
      ) : (
        <SimpleGrid
          w="full"
          justify={"center"}
          align={"center"}
          spacing="8"
          columns={[1, 2, 3, 4, 4, 5]}
        >
          {channels.length > 0
            ? channels.map((e) => <NewG4Channel key={e._id} device={e} />)
            : null}
        </SimpleGrid>
      )}
    </VStack>
  );
};

export default NewG4Control;
