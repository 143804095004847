import {
  Center,
  HStack,
  Icon,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import axios from "../../axios";
import CreateAutoModal from "../../components/Automation/CreateAutoModal";
import AutoList from "../../components/Automation/List/AutoList";
import GroupList from "../../components/Automation/List/GroupList";
import TtrList from "../../components/Automation/List/TtrList";

const Automation = () => {
  const [automation, setAutomation] = useState([]);
  const [group, setGroup] = useState([]);
  const [ttr, setTtr] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterdAutomation, setFilterdAutomation] = useState([]);
  const [filterdGroup, setFilterdGroup] = useState([]);
  const [filterdTtr, setFilterdTtr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { Core } = axios();

  useEffect(() => {
    setIsLoading(true);
    let source = Core.CancelToken.source();
    let cancel = false;

    Core.get(
      "/user/automatictable",
      {
        params: { house: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        // console.log("auto", result.data);
        if (cancel) return;
        setAutomation(
          result.data.data.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
        setGroup(
          result.data.groups.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
        setFilterdAutomation(
          result.data.data.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
        setFilterdGroup(
          result.data.groups.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
        // console.log(result);
        // console.log("groups: ", result.data.groups);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status == 404) {
          setAutomation([]);
          setFilterdAutomation([]);
          setGroup([]);
          setFilterdGroup([]);
        }
        setIsLoading(false);
      });

    Core.get(
      "/user/ttr",
      {
        params: { house: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        // console.log("ttr", result.data);
        if (cancel) return;
        const res = result.data.data;
        setTtr(
          res.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
        setFilterdTtr(
          res.map((e) => {
            return {
              ...e,
              check: false,
            };
          })
        );
      })
      .catch((error) => {
        // console.log(error);
      });

    return () => {
      cancel = true;
      source.cancel();
    };
  }, [reload]);

  return (
    <VStack w={"full"} pt={4}>
      <Tabs
        variant="unstyled"
        w={"full"}
        scrollBehavior="smooth"
        transition={"ease-in-out .3s"}
      >
        <HStack w={"full"}>
          <TabList transition={"ease-in-out .3s"} w="full">
            {tabData.map((e, index) => (
              <Tab
                key={index}
                flex={"0 0 auto"}
                fontWeight={"normal"}
                fontSize={14}
                _selected={{
                  fontWeight: "medium",
                  borderBottom: "2px solid #45A735",
                }}
                _focusVisible={{ outline: "none" }}
                _active={{ background: "none" }}
                mr={{ sm: 2, md: 6, lg: 8 }}
              >
                {e.title}
              </Tab>
            ))}
          </TabList>
          <CreateAutoModal />
        </HStack>

        {isLoading ? (
          <Center w={"full"} pt={"20"}>
            <Spinner color="#45A735" size="md" />
          </Center>
        ) : (
          <TabPanels className="bgColor" w={"full"} rounded={"md"} mt={"6"}>
            <TabPanel>
              <VStack w={"full"} spacing={0}>
                <HStack
                  w={"full"}
                  bg={colorMode === "dark" ? "#1D211D" : "#F1F1F1"}
                  rounded={"md"}
                  py={"4"}
                  spacing={0}
                >
                  {AutomationHeader.map((e, i) => (
                    <HStack
                      key={i}
                      w={
                        i === 0
                          ? "60"
                          : i === 3
                          ? "lg"
                          : i === 4
                          ? "60"
                          : "full"
                      }
                      justify={i === 0 || i === 4 ? "center" : "flex-start"}
                      align={i === 0 || i === 4 ? "center" : "flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"}>
                        {e.title}
                      </Text>
                    </HStack>
                  ))}
                </HStack>
                {automation.length > 0 || group.length > 0 || ttr.length > 0 ? (
                  <VStack w={"full"} spacing={0}>
                    {automation.length > 0
                      ? automation.map((a, index) => (
                          <AutoList
                            key={a._id}
                            data={a}
                            type={"Автоматжуулалт"}
                            index={index}
                            setReload={setReload}
                            automation={automation}
                            setAutomation={setAutomation}
                          />
                        ))
                      : null}
                    {group.length > 0
                      ? group.map((a, index) => (
                          <GroupList
                            key={a._id}
                            data={a}
                            type={"Багц автоматжуулалт"}
                            // index={automation.length + index}
                            index={index}
                            setReload={setReload}
                            group={group}
                            setGroup={setGroup}
                          />
                        ))
                      : null}
                    {ttr.length > 0
                      ? ttr.map((a, index) => (
                          <TtrList
                            key={a._id}
                            data={a}
                            type={"Багц үйлдэл"}
                            index={automation.length + group.length + index}
                            setReload={setReload}
                            reload={reload}
                          />
                        ))
                      : null}
                  </VStack>
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    spacing={1}
                    pt={"4"}
                  >
                    <Icon as={FiInfo} w={7} h={7} />
                    <Text fontSize={15} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </TabPanel>

            {/* ------------------------ AUTOMATION -------------------------- */}
            <TabPanel>
              <VStack w={"full"} spacing={0}>
                <HStack
                  w={"full"}
                  bg={colorMode === "dark" ? "#1D211D" : "#E3E3E3"}
                  rounded={"md"}
                  py={"4"}
                  spacing={0}
                >
                  {AutomationHeader.map((e, i) => (
                    <HStack
                      key={i}
                      w={
                        i === 0
                          ? "60"
                          : i === 3
                          ? "lg"
                          : i === 4
                          ? "60"
                          : "full"
                      }
                      justify={i === 0 || i === 4 ? "center" : "flex-start"}
                      align={i === 0 || i === 4 ? "center" : "flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"}>
                        {e.title}
                      </Text>
                    </HStack>
                  ))}
                </HStack>
                {automation.length > 0 ? (
                  automation.map((a, index) => (
                    <AutoList
                      key={a._id}
                      data={a}
                      type={"Автоматжуулалт"}
                      index={index}
                      setReload={setReload}
                      automation={automation}
                      setAutomation={setAutomation}
                    />
                  ))
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    spacing={1}
                    pt={"4"}
                  >
                    <Icon as={FiInfo} w={7} h={7} />
                    <Text fontSize={15} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </TabPanel>
            {/* ------------------------ GROUP -------------------------- */}
            <TabPanel>
              <VStack w={"full"} spacing={0}>
                <HStack
                  w={"full"}
                  bg={colorMode === "dark" ? "#1D211D" : "#E3E3E3"}
                  rounded={"md"}
                  py={"4"}
                  spacing={0}
                >
                  {AutomationHeader.map((e, i) => (
                    <HStack
                      key={i}
                      w={
                        i === 0
                          ? "60"
                          : i === 3
                          ? "lg"
                          : i === 4
                          ? "60"
                          : "full"
                      }
                      justify={i === 0 || i === 4 ? "center" : "flex-start"}
                      align={i === 0 || i === 4 ? "center" : "flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"}>
                        {e.title}
                      </Text>
                    </HStack>
                  ))}
                </HStack>
                {group.length > 0 ? (
                  group.map((a, index) => (
                    <GroupList
                      key={a._id}
                      data={a}
                      type={"Багц автоматжуулалт"}
                      index={index}
                      setReload={setReload}
                      group={group}
                      setGroup={setGroup}
                    />
                  ))
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    spacing={1}
                    pt={"4"}
                  >
                    <Icon as={FiInfo} w={7} h={7} />
                    <Text fontSize={15} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </TabPanel>

            {/* ------------------------ TAP TO RUN -------------------------- */}
            <TabPanel>
              <VStack w={"full"} spacing={0}>
                <HStack
                  w={"full"}
                  bg={colorMode === "dark" ? "#1D211D" : "#E3E3E3"}
                  rounded={"md"}
                  py={"4"}
                  spacing={0}
                >
                  {AutomationHeader.map((e, i) => (
                    <HStack
                      key={i}
                      w={
                        i === 0
                          ? "60"
                          : i === 3
                          ? "lg"
                          : i === 4
                          ? "60"
                          : "full"
                      }
                      justify={i === 0 || i === 4 ? "center" : "flex-start"}
                      align={i === 0 || i === 4 ? "center" : "flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"}>
                        {e.title}
                      </Text>
                    </HStack>
                  ))}
                </HStack>
                {ttr.length > 0 ? (
                  ttr.map((a, index) => (
                    <TtrList
                      key={a._id}
                      data={a}
                      type={"Багц үйлдэл"}
                      index={index}
                      setReload={setReload}
                      reload={reload}
                    />
                  ))
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                    spacing={1}
                    pt={"4"}
                  >
                    <Icon as={FiInfo} w={7} h={7} />
                    <Text fontSize={15} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </VStack>
  );
};
const tabData = [
  {
    title: "Бүгд",
  },
  {
    title: "Автоматжуулалт",
  },
  {
    title: "Багц автоматжуулалт",
  },
  {
    title: "Багц үйлдэл",
  },
];

const AutomationHeader = [
  {
    title: "№",
  },
  {
    title: "Автоматжуулалтын нэр",
  },
  {
    title: "Автоматжуулалтын төрөл",
  },
  {
    title: "Идэвхтэй эсэх",
  },
  {
    title: "Засах",
  },
];

export default Automation;
