import React, { useEffect, useState, useContext } from "react";
import {
  Center,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import InvoiceList from "../../components/Payment/InvoiceList";
import UserContext from "../../context/UserContext";
const PaymentList = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const history = useHistory();
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    let source = Core.CancelToken.source();
    let cancel = false;
    Core.get("/user/qpay/invoice", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setInvoiceList(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <VStack
      w={"full"}
      h={"full"}
      className="card"
      rounded={"md"}
      p={"4"}
      spacing={0}
    >
      <HStack
        w={"full"}
        bg={colorMode === "dark" ? "#202420" : "#FBFBFB"}
        rounded={"md"}
        p={"3"}
      >
        {titleData.map((e, index) => (
          <Center
            key={index}
            w={index === 0 ? "sm" : index === 1 ? "full" : "2xl"}
            // justifyContent={index === 1 ? "flex-start" : "center"}
            // bg={index === 0 ? "#ff0000" : "blue"}
          >
            <Text fontSize={13} fontWeight={"normal"}>
              {e.title}
            </Text>
          </Center>
        ))}
      </HStack>
      {isLoading ? (
        <Center w={"full"}>
          <Spinner size="md" color="#45A735" mt="5vh" />
        </Center>
      ) : invoiceList.length > 0 ? (
        <VStack
          w={"full"}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
              borderRadius: "8px",
            },
          }}
        >
          {invoiceList.map((e, i) => (
            <InvoiceList key={i} data={e} index={i} />
          ))}
        </VStack>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          spacing={1}
          pt={"5vh"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй.
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

const titleData = [
  {
    title: "№",
  },
  {
    title: "Тайлбар",
  },
  {
    title: "Хугацаа",
  },
  {
    title: "Үнийн дүн",
  },
  {
    title: "Төлөв",
  },
  {
    title: "...",
  },
];
export default PaymentList;
