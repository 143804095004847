import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiMinusCircle } from "react-icons/fi";
import axios from "../../axios";
import Cookies from "js-cookie";
import UserContext from "../../context/UserContext";

const DeleteHouse = ({ data, setTrigger, housesCount }) => {
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setHouseTrigger, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  const deleteHandler = () => {
    if (data) {
      setIsDisable(true);
      Core.delete("/user_v2/house/delete", {
        data: { houseId: data._id },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((result) => {
          // console.log(result.data);
          if (houseId === data._id) {
            Cookies.remove("house");
          }
          setHouseTrigger(true);
          setIsDisable(false);
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай устлаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <>
      <Center
        bg={colorMode === "dark" ? "#252B25" : "#fff"}
        w={"10"}
        h={"10"}
        rounded={"lg"}
        border={
          colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
        }
        onClick={housesCount === 1 ? null : () => onOpen()}
        cursor={housesCount === 1 ? "not-allowed" : "pointer"}
      >
        <Icon as={FiMinusCircle} w={"4"} h={"4"} color={"#ff0000"} />
      </Center>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteHandler()}
              _focusVisible={{ outline: "none" }}
              isDisabled={isDisable}
            >
              {isDisable ? <Spinner mr={"2"} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteHouse;
