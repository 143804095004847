import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
  Stack,
} from "@chakra-ui/react";
import close from "../../assets/Images/icons/close.png";
import closeDark from "../../assets/Images/icons/closeDark.png";
import light from "../../assets/Images/CardIcons/light.png";
import lightDark from "../../assets/Images/CardIcons/lightDark.png";
import switchOn from "../../assets/Images/icons/switchOn.png";
import switchOnDark from "../../assets/Images/icons/switchOnDark.png";
import switchOff from "../../assets/Images/icons/switchOff.png";
import switchOffDark from "../../assets/Images/icons/switchOffDark.png";
import alarmOn from "../../assets/Images/icons/alarmOn.png";
import alarmOnDark from "../../assets/Images/icons/alarmOnDark.png";
import alarmOff from "../../assets/Images/icons/alarmOff.png";
import alarmOffDark from "../../assets/Images/icons/alarmOffDark.png";
import notifOn from "../../assets/Images/icons/notifOn.png";
import notifOnDark from "../../assets/Images/icons/notifOnDark.png";
import notifOff from "../../assets/Images/icons/notifOff.png";
import notifOffDark from "../../assets/Images/icons/notifOffDark.png";
import automation from "../../assets/Images/icons/automation.png";
import automationDark from "../../assets/Images/icons/automationDark.png";
import group from "../../assets/Images/icons/group.png";
import groupDark from "../../assets/Images/icons/groupDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import UserContext from "../../context/UserContext";

const TtrCard = ({ data, index }) => {
  const [state, setState] = useState();
  const { colorMode } = useColorMode();
  const { ttrAct, setTtrAct } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const removeHandler = () => {
    const clone = ttrAct;
    setTtrAct(clone.filter((e, i) => i !== index));
  };

  const submitHandler = (value) => {
    if (value) {
      const clone = [...ttrAct];
      clone.find(
        (e) => e._id === value._id && e.actionType === value.actionType
      ).command = state;
      setTtrAct(clone);

      setState();
      onClose();
    }
  };

  return (
    <>
      <VStack w={"full"}>
        {data.type === "device" ? (
          <Stack
            className="bgColor"
            w={"full"}
            h={"20"}
            rounded={"md"}
            px={"6"}
            position={"relative"}
            align={{ md: "flex-start", lg: "center" }}
            justify={{ md: "center", lg: "space-between" }}
            direction={{ md: "column", lg: "row" }}
            onClick={() => {
              setState(data.command);
              onOpen();
            }}
          >
            <HStack spacing={3}>
              <Image
                src={
                  data.actionType === "state"
                    ? data.command === 1 || data.command === "ON"
                      ? colorMode === "dark"
                        ? switchOnDark
                        : switchOn
                      : colorMode === "dark"
                      ? switchOffDark
                      : switchOff
                    : data.actionType === "alarm"
                    ? data.command === 1
                      ? colorMode === "dark"
                        ? alarmOnDark
                        : alarmOn
                      : colorMode === "dark"
                      ? alarmOffDark
                      : alarmOff
                    : data.actionType === "notif"
                    ? data.command === 1
                      ? colorMode === "dark"
                        ? notifOnDark
                        : notifOn
                      : colorMode === "dark"
                      ? notifOffDark
                      : notifOff
                    : null
                }
                w={"6"}
                h={"6"}
                objectFit={"contain"}
              />
              <VStack spacing={0} align={"flex-start"} justify={"center"}>
                <Text fontSize={13} color={"#919191"} fontWeight={"normal"}>
                  {data.room}
                </Text>
                <Text fontSize={15} fontWeight={"normal"}>
                  {data.device ? data.device : data.for}
                </Text>
              </VStack>
            </HStack>
            <HStack pr={"5"}>
              <Box
                w={"2"}
                h={"2"}
                bg={
                  data.command === 1 || data.command === "ON"
                    ? "#45A735"
                    : "#ff0000"
                }
                rounded={"full"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {data.actionType === "state"
                  ? data.command === 1 || data.command === "ON"
                    ? "Төхөөрөмж асаах"
                    : "Төхөөрөмж унтраах"
                  : data.actionType === "notif"
                  ? data.command === 1
                    ? "Мэдэгдэл асаах"
                    : "Мэдэгдэл унтраах"
                  : data.actionType === "alarm"
                  ? data.command === 1
                    ? "Дохио асаах"
                    : "Дохио унтраах"
                  : null}
              </Text>
            </HStack>
            <Center
              transition={"ease-in-out .3s"}
              position={"absolute"}
              top={2}
              right={2}
              bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
              p={"2"}
              rounded={"md"}
              onClick={() => removeHandler(index)}
              cursor={"pointer"}
              _hover={{
                transform: "scale(1.2)",
              }}
            >
              <Image
                src={colorMode === "dark" ? closeDark : close}
                w={"3"}
                h={"3"}
                objectFit={"contain"}
              />
            </Center>
          </Stack>
        ) : data.type === "automation" ? (
          <Stack
            className="bgColor"
            w={"full"}
            h={"20"}
            rounded={"md"}
            px={"6"}
            position={"relative"}
            align={{ md: "flex-start", lg: "center" }}
            justify={{ md: "center", lg: "space-between" }}
            direction={{ md: "column", lg: "row" }}
            onClick={() => {
              setState(data.command);
              onOpen();
            }}
          >
            <HStack spacing={3}>
              <Image
                src={colorMode === "dark" ? automationDark : automation}
                w={"6"}
                h={"6"}
                objectFit={"contain"}
              />
              <Text fontSize={15} fontWeight={"normal"}>
                {data.device}
              </Text>
            </HStack>
            <HStack pr={"5"}>
              <Box
                w={"2"}
                h={"2"}
                bg={data.command === 1 ? "#45A735" : "#ff0000"}
                rounded={"full"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {data.actionType === "state"
                  ? data.command === 1
                    ? "Автоматжуулалт асаах"
                    : "Автоматжуулалт унтраах"
                  : data.actionType === "notif"
                  ? data.command === 1
                    ? "Мэдэгдэл асаах"
                    : "Мэдэгдэл унтраах"
                  : null}
              </Text>
            </HStack>
            <Center
              transition={"ease-in-out .3s"}
              position={"absolute"}
              top={2}
              right={2}
              bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
              p={"2"}
              rounded={"md"}
              onClick={() => removeHandler(index)}
              cursor={"pointer"}
              _hover={{
                transform: "scale(1.2)",
              }}
            >
              <Image
                src={colorMode === "dark" ? closeDark : close}
                w={"3"}
                h={"3"}
                objectFit={"contain"}
              />
            </Center>
          </Stack>
        ) : (
          <Stack
            className="bgColor"
            w={"full"}
            h={"20"}
            rounded={"md"}
            px={"6"}
            position={"relative"}
            align={{ md: "flex-start", lg: "center" }}
            justify={{ md: "center", lg: "space-between" }}
            direction={{ md: "column", lg: "row" }}
            onClick={() => {
              setState(data.command);
              onOpen();
            }}
          >
            <HStack spacing={3}>
              <Image
                src={colorMode === "dark" ? groupDark : group}
                w={"6"}
                h={"6"}
                objectFit={"contain"}
              />
              <Text fontSize={15} fontWeight={"normal"}>
                {data.device}
              </Text>
            </HStack>
            <HStack pr={"5"}>
              <Box
                w={"2"}
                h={"2"}
                bg={data.command === 1 ? "#45A735" : "#ff0000"}
                rounded={"full"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {data.actionType === "state"
                  ? data.command === 1
                    ? "Багц автоматжуулалт асаах"
                    : "Багц автоматжуулалт унтраах"
                  : data.actionType === "notif"
                  ? data.command === 1
                    ? "Мэдэгдэл асаах"
                    : "Мэдэгдэл унтраах"
                  : null}
              </Text>
            </HStack>
            <Center
              transition={"ease-in-out .3s"}
              position={"absolute"}
              top={2}
              right={2}
              bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
              p={"2"}
              rounded={"md"}
              onClick={() => removeHandler(index)}
              cursor={"pointer"}
              _hover={{
                transform: "scale(1.2)",
              }}
            >
              <Image
                src={colorMode === "dark" ? closeDark : close}
                w={"3"}
                h={"3"}
                objectFit={"contain"}
              />
            </Center>
          </Stack>
        )}
      </VStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Төлөв өө сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} spacing={6}>
              <VStack w={"full"}>
                <HStack
                  w={"full"}
                  h={"20"}
                  px={"8"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  borderWidth={"medium"}
                  borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                  rounded={"md"}
                  shadow={"md"}
                  align={"center"}
                  justify={"space-between"}
                  onClick={() => setState(1)}
                  cursor={"pointer"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    {data.actionType === "state"
                      ? "Асах"
                      : data.actionType === "notif"
                      ? "Мэдэгдэл асаах"
                      : "Дохио асаах"}
                  </Text>
                  <Image
                    src={state === 1 ? checked : check}
                    w={"5"}
                    h={"5"}
                    objectFit={"contain"}
                  />
                </HStack>
                <HStack
                  w={"full"}
                  h={"20"}
                  px={"8"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  borderWidth={"medium"}
                  borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                  rounded={"md"}
                  shadow={"md"}
                  align={"center"}
                  justify={"space-between"}
                  onClick={() => setState(0)}
                  cursor={"pointer"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    {data.actionType === "state"
                      ? "Унтрах"
                      : data.actionType === "notif"
                      ? "Мэдэгдэл унтраах"
                      : "Дохио унтраах"}
                  </Text>
                  <Image
                    src={state === 0 ? checked : check}
                    w={"5"}
                    h={"5"}
                    objectFit={"contain"}
                  />
                </HStack>
              </VStack>
              <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
                <Button
                  w={"32"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  fontWeight={"normal"}
                  fontSize={15}
                  onClick={onClose}
                  _focusVisible={{ outline: "none" }}
                >
                  Болих
                </Button>
                <Button
                  w={"32"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  fontWeight={"normal"}
                  fontSize={15}
                  color={"#45A735"}
                  borderWidth={"thin"}
                  borderColor={"#45A735"}
                  onClick={() => submitHandler(data)}
                  isDisabled={state === 0 || state === 1 ? false : true}
                  _focusVisible={{ outline: "none" }}
                >
                  Хадгалах
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TtrCard;
