import React from "react";
import { HStack, Text, useColorMode, Image } from "@chakra-ui/react";
import wifiFull from "../../../assets/Images/icons/wifiFull.png";
import wifiMid from "../../../assets/Images/icons/wifiMid.png";
import wifiLow from "../../../assets/Images/icons/wifiLow.png";
import wifiInactive from "../../../assets/Images/icons/wifi-InActive.png";

const DeviceName = ({ wifiSignal }) => {
  const { colorMode } = useColorMode();

  return (
    <>
      {wifiSignal !== undefined && wifiSignal !== null ? (
        <HStack
          spacing={5}
          transition={"ease-in-out .3s"}
          w="full"
          h={"20"}
          px={"8"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          borderWidth={"medium"}
          borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
          rounded={"md"}
          shadow={"md"}
          align={"center"}
          justify={"space-between"}
        >
          <Text>Сигнал</Text>

          {wifiSignal === 1 ? (
            <Image w="5" objectFit={"contain"} src={wifiFull} />
          ) : wifiSignal === 2 ? (
            <Image w="5" objectFit={"contain"} src={wifiMid} />
          ) : wifiSignal === 3 ? (
            <Image w="5" objectFit={"contain"} src={wifiLow} />
          ) : (
            <Image w="5" objectFit={"contain"} src={wifiInactive} />
          )}
        </HStack>
      ) : null}
    </>
  );
};

export default DeviceName;
