import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import plus from "../../assets/Images/icons/plus.png";
import plusDark from "../../assets/Images/icons/plusDark.png";
import axios from "../../axios";
import Cookies from "js-cookie";
import UserContext from "../../context/UserContext";

const AddRoom = ({ setTrigger }) => {
  const [roomName, setRoomName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const submitHandler = () => {
    if (roomName && houseId) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      // @TODO stringify boliulah
      // const roomData = JSON.stringify({
      //   houseId: houseId,
      //   name: [roomName],
      // });

      Core.post(
        "/user_v2/room/create",
        { houseId: houseId, name: [roomName] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log(result.data);
          onClose();
          setRoomName("");
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          setTrigger(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <>
      <Center
        transition={"ease-in-out .3s"}
        className="btn"
        w={"xs"}
        py={2}
        rounded={"full"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "md",
        }}
      >
        <Text fontSize={15} fontWeight={"normal"}>
          Өрөө нэмэх
        </Text>
        <Image
          src={colorMode === "dark" ? plusDark : plus}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
          ml={"2"}
        />
      </Center>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Өрөө нэмэх</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Өрөөний нэрээ оруулна уу
              </FormLabel>
              <Input
                placeholder={"-"}
                maxLength={30}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                focusBorderColor={"#45A735"}
                autoFocus
                onKeyDown={(e) => {
                  if (roomName) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={roomName && !isLoading ? false : true}
            >
              {isLoading ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddRoom;
