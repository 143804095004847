import React from "react";
import {
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import light from "../../../../assets/Images/CardIcons/light.png";
import lightDark from "../../../../assets/Images/CardIcons/lightDark.png";
import rgb from "../../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../../assets/Images/CardIcons/rgbDark.png";
import knx from "../../../../assets/Images/CardIcons/knx.png";
import knxDark from "../../../../assets/Images/CardIcons/knxDark.png";
import ir from "../../../../assets/Images/CardIcons/ir.png";
import irDark from "../../../../assets/Images/CardIcons/irDark.png";
import th from "../../../../assets/Images/CardIcons/th.png";
import thDark from "../../../../assets/Images/CardIcons/thDark.png";
import water from "../../../../assets/Images/CardIcons/water.png";
import waterDark from "../../../../assets/Images/CardIcons/waterDark.png";
import motion from "../../../../assets/Images/CardIcons/motion.png";
import motionDark from "../../../../assets/Images/CardIcons/motionDark.png";
import smoke from "../../../../assets/Images/CardIcons/smoke.png";
import smokeDark from "../../../../assets/Images/CardIcons/smokeDark.png";
import bridge from "../../../../assets/Images/CardIcons/bridge.png";
import bridgeDark from "../../../../assets/Images/CardIcons/bridgeDark.png";
import contact from "../../../../assets/Images/CardIcons/contact.png";
import contactDark from "../../../../assets/Images/CardIcons/contactDark.png";
import powr from "../../../../assets/Images/CardIcons/powr.png";
import powrDark from "../../../../assets/Images/CardIcons/powrDark.png";
import smtc from "../../../../assets/Images/CardIcons/smtc.png";
import smtcDark from "../../../../assets/Images/CardIcons/smtcDark.png";
import lgt from "../../../../assets/Images/CardIcons/lgt.png";
import lgtDark from "../../../../assets/Images/CardIcons/lgtDark.png";
import co2 from "../../../../assets/Images/CardIcons/co2.png";
import co2Dark from "../../../../assets/Images/CardIcons/co2Dark.png";
import so2 from "../../../../assets/Images/CardIcons/so2.png";
import so2Dark from "../../../../assets/Images/CardIcons/so2Dark.png";
import no2 from "../../../../assets/Images/CardIcons/no2.png";
import no2Dark from "../../../../assets/Images/CardIcons/no2Dark.png";
import pm from "../../../../assets/Images/CardIcons/pm.png";
import pmDark from "../../../../assets/Images/CardIcons/pmDark.png";
import curtainClose from "../../../../assets/Images/icons/curtainClose.png";
import curtainCloseDark from "../../../../assets/Images/icons/curtainCloseDark.png";
import g4 from "../../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../../assets/Images/CardIcons/g4Dark.png";
import zbAqi from "../../../../assets/Images/CardIcons/zbAqi.png";
import zbAqiDark from "../../../../assets/Images/CardIcons/zbAqiDark.png";

const TtrActNotif = ({ data, roomName, index, setChangeState, setDevice }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        setDevice(data);
        setChangeState(true);
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={
            data.deviceType === "switch" ||
            data.deviceType === "Switchsc" ||
            data.deviceType === "zbSwitch"
              ? colorMode === "dark"
                ? lightDark
                : light
              : data.deviceType === "RGB" || data.deviceType === "WLED"
              ? colorMode === "dark"
                ? rgbDark
                : rgb
              : data.deviceType === "knx_switch" ||
                data.deviceType === "knx_dimmer"
              ? colorMode === "dark"
                ? knxDark
                : knx
              : data.deviceType === "irSub"
              ? colorMode === "dark"
                ? irDark
                : ir
              : data.deviceType === "th" ||
                data.deviceType === "zigbeeTh" ||
                data.deviceType === "lth" ||
                data.deviceType === "thsc"
              ? colorMode === "dark"
                ? thDark
                : th
              : data.deviceType === "zbridge"
              ? colorMode === "dark"
                ? bridgeDark
                : bridge
              : data.deviceType === "Contact"
              ? colorMode === "dark"
                ? contactDark
                : contact
              : data.deviceType === "Occupancy"
              ? colorMode === "dark"
                ? motionDark
                : motion
              : data.deviceType === "Smoke"
              ? colorMode === "dark"
                ? smokeDark
                : smoke
              : data.deviceType === "Water"
              ? colorMode === "dark"
                ? waterDark
                : water
              : data.deviceType === "ir"
              ? colorMode === "dark"
                ? irDark
                : ir
              : data.deviceType === "powr"
              ? colorMode === "dark"
                ? powrDark
                : powr
              : data.deviceType === "smtc"
              ? colorMode === "dark"
                ? smtcDark
                : smtc
              : data.deviceType === "lgt"
              ? colorMode === "dark"
                ? lgtDark
                : lgt
              : data.deviceType === "co"
              ? colorMode === "dark"
                ? co2Dark
                : co2
              : data.deviceType === "Co2"
              ? colorMode === "dark"
                ? co2Dark
                : co2
              : data.deviceType === "So2"
              ? colorMode === "dark"
                ? so2Dark
                : so2
              : data.deviceType === "No2"
              ? colorMode === "dark"
                ? no2Dark
                : no2
              : data.deviceType === "Dust"
              ? colorMode === "dark"
                ? pmDark
                : pm
              : data.deviceType === "Curtain"
              ? colorMode === "dark"
                ? curtainCloseDark
                : curtainClose
              : data.deviceType === "g4" ||
                data.deviceType === "G4ADB" ||
                data.deviceType === "newG4"
              ? colorMode === "dark"
                ? g4Dark
                : g4
              : data.deviceType === "zbAqi"
              ? colorMode === "dark"
                ? zbAqiDark
                : zbAqi
              : null
          }
          w={"6"}
          h={"6"}
          objectFit={"contain"}
        />
        <VStack align={"flex-start"} justify={"center"} spacing={0}>
          {roomName ? (
            <Text color={"#919191"} fontWeight={"normal"} fontSize={12}>
              {roomName}
            </Text>
          ) : null}
          <Text fontWeight={"normal"} fontSize={15}>
            {data.for}
          </Text>
        </VStack>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default TtrActNotif;
