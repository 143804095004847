import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Center,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TbArrowBackUp } from "react-icons/tb";
import InsertName from "../../components/Automation/Create/InsertName";
import group from "../../assets/Images/icons/group.png";
import groupDark from "../../assets/Images/icons/groupDark.png";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import automation from "../../assets/Images/icons/automation.png";
import automationDark from "../../assets/Images/icons/automationDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import UserContext from "../../context/UserContext";

const CreateGroup = () => {
  const [name, setName] = useState("");
  const [automations, setAutomations] = useState([]);
  const [autoId, setAutoId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [dataId, setDataId] = useState("");
  const [dataIndex, setDataIndex] = useState();
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (houseId) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user/automation_for_group", {
        params: { house: houseId, groupId: null },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("group", result.data.data);
          setAutomations(
            result.data.data.map((e) => {
              return {
                _id: e._id,
                isSub: e.isSub,
                groupName: e.groupName,
                name: e.name,
                check: false,
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log("group", err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, []);

  const addCheck = (id, index) => {
    const clone = [...automations];
    clone[index].check = !clone[index].check;
    setAutomations(clone);

    setAutoId((prevState) => {
      return [...prevState, id];
    });

    setDataId("");
    setDataIndex();
  };

  const removeCheck = (id, index) => {
    const clone = [...automations];
    clone[index].check = !clone[index].check;
    setAutomations(clone);

    setAutoId((prevState) => prevState.filter((prevItem) => prevItem !== id));
  };

  const submitHandler = () => {
    if (houseId && name) {
      setBtnSpinner(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.post(
        "user/automation_group",
        {
          name: name,
          automations: autoId,
          house: houseId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (cancel) return;
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          setAutoId([]);
          history.push("/automation");
          setBtnSpinner(false);
        })
        .catch((err) => {
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Багц автоматжуулалтын нэрийг оруулна уу!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const warningHandler = (id, i) => {
    onOpen();
    setDataId(id);
    setDataIndex(i);
  };

  return (
    <VStack w={"full"} h={"full"} spacing={6}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4} w={"full"}>
          <IconButton
            transition={"ease-in-out .3s"}
            bg={colorMode === "dark" ? "#252B25" : "#fff"}
            rounded={"lg"}
            icon={
              <Icon
                as={TbArrowBackUp}
                color={colorMode === "dark" ? "#fff" : "#000"}
                w={5}
                h={5}
              />
            }
            _hover={{
              bg: "#fff",
              shadow: "md",
            }}
            _focusVisible={{
              outline: "none",
              bg: "#fff",
            }}
            onClick={() => history.goBack()}
            // onClick={() => {
            //   automation.length > 0 ? onOpen() : history.goBack();
            // }}
          />
          <Text fontSize={15} fontWeight={"normal"}>
            Багц автоматжуулалт үүсгэх
          </Text>
        </HStack>

        <InsertName
          title={"Багц автоматжуулалтын нэр"}
          img={group}
          imgDark={groupDark}
          value={name}
          onChange={setName}
        />
      </HStack>

      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"xl"}
        p={"6"}
        align={"center"}
        justify={"center"}
        spacing={4}
      >
        <VStack w={"full"} h={"full"}>
          {isLoading ? (
            <Center w={"full"}>
              <Spinner color="#45A735" size="md" />
            </Center>
          ) : automations.length > 0 ? (
            <Grid
              w={"full"}
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={4}
              p={"2"}
              maxH={["70vh", "70vh", "70vh", "70vh", "65vh"]}
              overflowY="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              {automations.map((e, i) => (
                <HStack
                  key={i}
                  transition={"ease-in-out .3s"}
                  w={"full"}
                  h={"20"}
                  px={"8"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  borderWidth={"medium"}
                  borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                  rounded={"md"}
                  shadow={"md"}
                  align={"center"}
                  justify={"space-between"}
                  onClick={() => {
                    e.check
                      ? removeCheck(e._id, i)
                      : e.isSub === true
                      ? warningHandler(e._id, i)
                      : addCheck(e._id, i);
                  }}
                  cursor={"pointer"}
                  _hover={{
                    shadow: "lg",
                  }}
                  _focusVisible={{
                    outline: "none",
                  }}
                >
                  <HStack>
                    <Image
                      src={colorMode === "dark" ? automationDark : automation}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                    <Text fontSize={14} fontWeight={"normal"}>
                      {e.name}
                    </Text>
                  </HStack>
                  <HStack>
                    {e.isSub === true ? (
                      <Text fontSize={13} fontWeight={"normal"}>
                        {e.groupName}
                      </Text>
                    ) : null}
                    <Image
                      src={e.check === true ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </HStack>
              ))}
            </Grid>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              spacing={1}
              pt={"4"}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>

        <HStack w={"full"} align={"flex-end"} justify={"flex-end"} spacing={4}>
          <Button
            className="btn"
            w={"64"}
            borderWidth={"thin"}
            borderColor={"#45A735"}
            color={"#45A735"}
            fontSize={15}
            fontWeight={"medium"}
            onClick={submitHandler}
            isDisabled={btnSpinner}
            _focusVisible={{
              outline: "none",
            }}
          >
            {btnSpinner ? <Spinner mr={2} size="sm" /> : null}
            Хадгалах
          </Button>
        </HStack>
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>Нэмэх үү?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody>
            <Text fontSize={15} fontWeight={"normal"}>
              Энэ автоматжуулалт өөр багцад байгаа тул нэмсэн тохиолдолд өмнөх
              багцаас хасагдахыг анхаарна уу!
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                addCheck(dataId, dataIndex);
                onClose();
              }}
              _focusVisible={{ outline: "none" }}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default CreateGroup;
