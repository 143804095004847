import { useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UseAuth from "./hooks/UseAuth";
import UserContext from "./context/UserContext";

function useAxios() {
  const userAuth = useContext(UseAuth);
  const source = axios.CancelToken.source();
  const [token, setToken] = useState(Cookies.get("accessToken"));
  const { setRestricted } = useContext(UserContext);

  let Auth;
  let Core;

  Core = axios.create({
    baseURL: "https://apps.viot.mn:9000",
    // baseURL: "http://192.168.1.127:9000",
    // baseURL: "http://192.168.1.247:9000",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  });
  Core.CancelToken = axios.CancelToken;
  Core.isCancel = axios.isCancel;

  Auth = axios.create({
    baseURL: "https://auth.viot.mn:9001",
    // baseURL: "http://192.168.1.247:9001",
    // baseURL: "http://192.168.1.127:9001",
  });

  const refreshToken = async () => {
    const refToken = Cookies.get("refreshToken");

    try {
      const response = await Auth.post("/auth/refresh_token", {
        refreshToken: refToken,
      });

      if (response.data.success) {
        // console.log("Refresh token success!!!");

        Cookies.set("accessToken", response.data.accessToken);
        Cookies.set("refreshToken", response.data.refreshToken);
        setToken(response.data.accessToken);

        return response.data.accessToken;
      }
    } catch (error) {
      // console.log("refresh token error", error);
      if (userAuth) userAuth.logoutHandler();
    }

    return false;
  };

  Core.interceptors.request.use(
    async (config) => {
      // console.log(config.url);
      // console.log(config);
      //naba test
      if (config.url !== "/auth/authenticate") {
        try {
          await Auth.post("/auth/authenticate", null, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (err) {
          // console.log("AUTH ERROR", err);
          const refreshedToken = await refreshToken();

          if (refreshedToken) {
            // console.log("refreshed token ", refreshedToken);

            config.headers.Authorization = `Bearer ${refreshedToken}`;
            return config;
          } else {
            return;
          }
        }
      }

      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Core.interceptors.response.use(
    (response) => {
      // console.log(response.data);
      return response;
    },
    (error) => {
      // console.log(error.config.url);
      // console.log(error);

      // !!!
      // энд handle хийсэн http status code-уудыг UserContext-ын
      // handledHttpErrors array-д заавал нэмэх ёстой жү😘
      // !!!

      if (error.response.status === 418) {
        setRestricted(true);
      }

      return Promise.reject(error);
    }
  );

  return { Core, Auth };
}

export default useAxios;
