import React, { useEffect, useState, useContext } from "react";
import {
  Center,
  HStack,
  Icon,
  IconButton,
  Input,
  SkeletonText,
  Stack,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useLocation } from "react-router-dom";
import BackButton from "../../components/Button/BackButton";
import { FiEdit3, FiInfo } from "react-icons/fi";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import AddAuto from "../../components/Automation/AddAuto";
import GroupDetailList from "../../components/Automation/List/GroupDetailList";
import UserContext from "../../context/UserContext";
const GroupDetail = () => {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [automations, setAutomations] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const location = useLocation();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (reload) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get(
        "/user/automation_group",
        {
          params: { _id: location.state._id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log("Group detail", result.data);
          if (cancel) return;
          setAutomations(result.data.data);
          setIsLoading(false);
          setReload(false);
        })
        .catch((err) => {
          // console.log("GroupDetail", err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [reload]);

  const editHandler = () => {
    if (name !== location.state.name) {
      if (name.length > 0) {
        setIsDisable(true);
        let source = Core.CancelToken.source();
        Core.put(
          "user/automation_group_rename",
          {
            _id: location.state._id,
            newName: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
          .then((result) => {
            if (!toast.isActive(id)) {
              toast({
                description: "Амжилттай!",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
            setEdit(false);
            setIsDisable(false);
          })
          .catch((err) => {
            setIsDisable(false);
            if (handledHttpErrors.includes(err.response.status)) return;

            if (!toast.isActive(id)) {
              toast({
                description: "Алдаа гарлаа!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
          });
      } else {
        if (!toast.isActive(id)) {
          toast({
            description: "Талбарыг бөглөнө үү!",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Одоогийн нэр байна!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };
  return (
    <VStack w={"full"} h={"full"} spacing={8}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontSize={15} fontWeight={"normal"}>
            Багц автоматжуулалтын дэлгэрэнгүй
          </Text>
        </HStack>
        {/* <ShareDevice setTrigger={setTrigger} /> */}
      </HStack>

      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"md"}
        px={"6"}
        py={"4"}
        spacing={6}
      >
        <HStack w={"full"} align={"center"} justify={"space-between"}>
          <VStack spacing={0} align={"flex-start"} justify={"flex-start"}>
            <Text fontSize={14} fontWeight={"normal"}>
              Багц автоматжуулалтын нэр
            </Text>
            <HStack>
              <Input
                placeholder={location.state.name}
                _placeholder={{
                  color: "#838383",
                  fontWeight: "normal",
                }}
                borderColor={"#45A735"}
                fontSize={15}
                fontWeight={"normal"}
                rounded={"xl"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus={edit ? true : false}
                isDisabled={edit ? false : true}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              />
              {edit ? (
                <HStack>
                  <IconButton
                    bg={"#45A735"}
                    rounded={"xl"}
                    _focusVisible={{
                      outline: "none",
                    }}
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    icon={<Icon as={IoMdCheckmark} color={"#fff"} />}
                    onClick={() => editHandler()}
                    isDisabled={isDisable}
                  />
                  <IconButton
                    bg={"#D62121"}
                    rounded={"xl"}
                    _focusVisible={{
                      outline: "none",
                    }}
                    _hover={{
                      bg: "#E71111",
                    }}
                    icon={<Icon as={IoMdClose} color={"#fff"} />}
                    onClick={() => setEdit(false)}
                    isDisabled={isDisable}
                  />
                </HStack>
              ) : (
                <IconButton
                  bg={"#45A735"}
                  rounded={"xl"}
                  _focusVisible={{
                    outline: "none",
                  }}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  icon={<Icon as={FiEdit3} color={"#fff"} />}
                  onClick={() => setEdit(true)}
                />
              )}
            </HStack>
          </VStack>
          <AddAuto groupId={location.state._id} setReload={setReload} />
        </HStack>

        <VStack
          w={"full"}
          overflowY="auto"
          maxH="65vh"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#45A735",
              borderRadius: "24px",
            },
          }}
        >
          <HStack
            w={"full"}
            bg={colorMode === "dark" ? "#1D211D" : "#E3E3E3"}
            rounded={"xl"}
            py={"4"}
            spacing={0}
          >
            {AutomationHeader.map((e, i) => (
              <HStack
                key={i}
                w={i === 0 ? "60" : i === 3 ? "lg" : i === 4 ? "60" : "full"}
                justify={i === 0 || i === 4 ? "center" : "flex-start"}
                align={i === 0 || i === 4 ? "center" : "flex-start"}
              >
                <Text fontSize={14} fontWeight={"normal"}>
                  {e.title}
                </Text>
              </HStack>
            ))}
          </HStack>
          {isLoading ? (
            <HStack
              w={"full"}
              align={"center"}
              justify={"center"}
              bg={"transparent"}
              rounded={"md"}
              py={"4"}
              spacing={0}
            >
              <Stack w={"60"} align={"center"} justify={"center"}>
                <SkeletonText
                  w={"8"}
                  h={"2"}
                  noOfLines={1}
                  skeletonHeight="2"
                />
              </Stack>
              <Stack w={"full"}>
                <SkeletonText
                  w={"32"}
                  h={"2"}
                  noOfLines={1}
                  skeletonHeight="2"
                />
              </Stack>
              <Stack w={"full"}>
                <SkeletonText
                  w={"32"}
                  h={"2"}
                  noOfLines={1}
                  skeletonHeight="2"
                />
              </Stack>
              <HStack w={"lg"}>
                <SkeletonText
                  w={"16"}
                  h={"2"}
                  noOfLines={1}
                  skeletonHeight="2"
                />
              </HStack>
              <Stack w={"60"} align={"center"} justify={"center"}>
                <Center
                  w={"6"}
                  h={"6"}
                  rounded={"md"}
                  borderColor={"#ababab"}
                  borderWidth={"thin"}
                >
                  <Text>...</Text>
                </Center>
              </Stack>
            </HStack>
          ) : automations.length > 0 ? (
            automations.map((a, index) => (
              <GroupDetailList
                key={a._id}
                data={a}
                type={"Автоматжуулалт"}
                index={index}
                setReload={setReload}
                automation={automations}
                setAutomation={setAutomations}
              />
            ))
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              spacing={1}
              pt={"4"}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};
const AutomationHeader = [
  {
    title: "№",
  },
  {
    title: "Автоматжуулалтын нэр",
  },
  {
    title: "Автоматжуулалтын төрөл",
  },
  {
    title: "Идэвхтэй эсэх",
  },
  {
    title: "Засах",
  },
];
export default GroupDetail;
