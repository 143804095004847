import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import plus from "../../assets/Images/icons/plus.png";
import plusDark from "../../assets/Images/icons/plusDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import UserContext from "../../context/UserContext";

const ShareDevice = ({ setTrigger }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [room, setRoom] = useState([]);
  const [devices, setDevices] = useState([]);
  const [sharedDevice, setSharedDevice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const houseId = Cookies.get("house");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  //----------------------- get room -------------------------
  useEffect(() => {
    setRoom([]);
    if (houseId) {
      const token = Cookies.get("accessToken");
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          setRoom(result.data.data);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  }, []);

  //----------------------- get Shareble Device -------------------------
  useEffect(() => {
    setDevices([]);
    setSharedDevice([]);
    if (phoneNumber.length === 8 && houseId) {
      setIsLoading(true);
      const token = Cookies.get("accessToken");
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/device_share/share_detail", {
        params: {
          houseId: houseId,
          username: phoneNumber,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("shareble", result.data.data);
          const data = result.data.data;
          setSharedDevice(data.filter((f) => f.shared === true));
          setDevices(data.filter((f) => f.shared !== true));
          //   setIsDisable(false);
          setIsLoading(false);
        })
        .catch((err) => {
          //   setIsDisable(true);
          setIsLoading(false);
          if (
            err.response &&
            err.response.status == 404 &&
            !toast.isActive(id)
          ) {
            toast({
              id,
              description: "Энэ дугаар бүртгэлгүй байна!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          } else {
            if (!toast.isActive(id)) {
              toast({
                id,
                description:
                  err && err.response && err.response.status == 400
                    ? "Энэ хэрэглэгчид төхөөрөмж хуваалцах боломжгүй!"
                    : "Алдаа гарлаа!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [phoneNumber]);

  // --- Add, remove device ---
  const addHandler = (item, index) => {
    const clone = devices;
    setDevices(clone.filter((e, i) => i !== index));
    setSharedDevice((prevState) => [...prevState, item]);
  };

  const removeHandler = (item, index) => {
    const clone = sharedDevice;
    setSharedDevice(clone.filter((e, i) => i !== index));
    setDevices((prevState) => [...prevState, item]);
  };
  // --- Add, remove device ---

  const submitHandler = () => {
    if (sharedDevice.length > 0 && houseId) {
      setDisabled(true);
      let source = Core.CancelToken.source();
      const data = {
        accounts: phoneNumber.split("-"),
        devices: sharedDevice.map((e) => e._id),
        houseId: houseId,
      };
      Core.post(
        "/user_v2/device_share/share",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          setPhoneNumber("");
          setDevices([]);
          setSharedDevice([]);
          setRoom([]);
          onClose();
          setDisabled(false);
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              id,
              description: "Амжилттай хадгаллаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setDisabled(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          description: "Төхөөрөмж сонгоно уу!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <>
      <Center
        transition={"ease-in-out .3s"}
        className="btn"
        w={"xs"}
        py={2}
        rounded={"full"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "md",
        }}
      >
        <Text fontSize={15} fontWeight={"normal"}>
          Төхөөрөмж хуваалцах
        </Text>
        <Image
          src={colorMode === "dark" ? plusDark : plus}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
          ml={"2"}
        />
      </Center>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Төхөөрөмж сонгох</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} spacing={4}>
              <FormControl>
                <FormLabel fontSize={15} fontWeight={"normal"}>
                  Хэрэглэгчийн утасны дугаар
                </FormLabel>
                <Input
                  placeholder="-"
                  maxLength={8}
                  type={"text"}
                  fontSize={15}
                  fontWeight={"normal"}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  focusBorderColor={"#45A735"}
                />
              </FormControl>
              {!isLoading && phoneNumber.length < 8 ? (
                <VStack py={12}>
                  <Icon as={BsFillInfoCircleFill} color="#c4c4c4" w={6} h={6} />
                  <Text color="gray.500" fontSize={14} ml={2}>
                    Хэрэглэгчийн утасны дугаарыг оруулна уу!
                  </Text>
                </VStack>
              ) : null}

              <VStack w={"full"} align={"flex-start"}>
                {isLoading ? (
                  <Spinner size="lg" color="#45A735" alignSelf={"center"} />
                ) : (phoneNumber.length === 8 && devices.length > 0) ||
                  sharedDevice.length > 0 ? (
                  <VStack w={"full"} spacing={4}>
                    <VStack w={"full"} align={"flex-start"}>
                      <Text fontSize={15} fontWeight={"medium"}>
                        Хуваалцсан төхөөрөмжүүд
                      </Text>
                      {sharedDevice.length > 0 ? (
                        sharedDevice.map((e, index) => (
                          <HStack
                            key={index}
                            w={"full"}
                            py={"1"}
                            px={"4"}
                            bg={
                              index % 2 === 0
                                ? colorMode === "dark"
                                  ? "#1D211D"
                                  : "#fff"
                                : colorMode === "dark"
                                ? "#202420"
                                : "#F9F9F9"
                            }
                            rounded={"md"}
                            justify={"space-between"}
                            align={"center"}
                            onClick={() => removeHandler(e, index)}
                            cursor={"pointer"}
                          >
                            <Text fontSize={15} fontWeight={"normal"}>
                              {e.for}
                            </Text>
                            <Image
                              src={checked}
                              w={"4"}
                              h={"4"}
                              objectFit="contain"
                            />
                          </HStack>
                        ))
                      ) : (
                        <VStack
                          justifyContent="center"
                          alignItems="center"
                          alignSelf={"center"}
                          color="gray.600"
                          spacing={1}
                        >
                          <Icon as={FiInfo} w={6} h={6} />
                          <Text fontSize={14} fontWeight={"normal"}>
                            Мэдээлэл олдсонгүй.
                          </Text>
                        </VStack>
                      )}
                    </VStack>
                    <VStack w={"full"} align={"flex-start"}>
                      <Text fontSize={15} fontWeight={"medium"}>
                        Миний төхөөрөмжүүд
                      </Text>
                      {devices.length > 0 ? (
                        devices.map((e, index) => (
                          <HStack
                            key={index}
                            w={"full"}
                            py={"1"}
                            px={"4"}
                            bg={
                              index % 2 === 0
                                ? colorMode === "dark"
                                  ? "#1D211D"
                                  : "#fff"
                                : colorMode === "dark"
                                ? "#202420"
                                : "#F9F9F9"
                            }
                            rounded={"md"}
                            justify={"space-between"}
                            align={"center"}
                            onClick={() => addHandler(e, index)}
                            cursor={"pointer"}
                          >
                            <Text fontSize={15} fontWeight={"normal"}>
                              {e.for}
                            </Text>
                            <HStack spacing={3}>
                              <Text fontSize={11} mb={-3} isTruncated>
                                {room.find((f) => f._id === e.room)
                                  ? room.find((f) => f._id === e.room).name
                                  : null}
                              </Text>
                              <Image
                                src={check}
                                w={"4"}
                                h={"4"}
                                objectFit="contain"
                              />
                            </HStack>
                          </HStack>
                        ))
                      ) : (
                        <VStack
                          justifyContent="center"
                          alignItems="center"
                          alignSelf={"center"}
                          color="gray.600"
                          spacing={1}
                        >
                          <Icon as={FiInfo} w={6} h={6} />
                          <Text fontSize={14} fontWeight={"normal"}>
                            Мэдээлэл олдсонгүй.
                          </Text>
                        </VStack>
                      )}
                    </VStack>
                  </VStack>
                ) : (
                  phoneNumber.length === 8 && (
                    <VStack
                      justifyContent="center"
                      alignItems="center"
                      alignSelf={"center"}
                      color="gray.600"
                      mt={"16"}
                      spacing={1}
                    >
                      <Icon as={FiInfo} w={7} h={7} />
                      <Text fontSize={15} fontWeight={"normal"}>
                        Мэдээлэл олдсонгүй.
                      </Text>
                    </VStack>
                  )
                )}
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={
                isLoading || phoneNumber.length < 8 || disabled ? true : false
              }
            >
              {disabled ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareDevice;
