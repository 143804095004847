import React, { useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import payment from "../../assets/Images/icons/payment.png";
import paymentDark from "../../assets/Images/icons/paymentDark.png";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";

function Restriction({ isOpen, onClose }) {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const { setRestricted } = useContext(UserContext);

  return (
    <Modal size="lg" isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded={"lg"} overflow={"hidden"} borderRadius={"md"}>
        <ModalHeader className="modalCard">
          <Text fontSize={18} fontWeight={"medium"}>
            Хязгаарлалт
          </Text>
        </ModalHeader>
        <ModalCloseButton
          _focusVisible={{
            outline: "none",
          }}
        />
        <ModalBody
          className="modalCard"
          flexDirection={"column"}
          display={"flex"}
          pb={8}
        >
          <VStack>
            <Text fontWeight={"medium"}>Уучлаарай</Text>
            <Text>энэ үйлдлийг хийх боломжгүй</Text>
          </VStack>
          <Image
            mt={8}
            h={16}
            objectFit={"contain"}
            src={colorMode === "light" ? payment : paymentDark}
          />

          <Text
            rounded={"md"}
            px={6}
            py={4}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"center"}
            mt={12}
          >
            Та{" "}
            <Text
              cursor={"pointer"}
              onClick={() => {
                setRestricted(false);
                history.push("/payment");
              }}
              color="#45A735"
              as={"span"}
            >
              {" "}
              төлбөрөө төлснөөр{" "}
            </Text>{" "}
            энэ үйлдлийг хийх боломжтой.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Restriction;
