import React, { useContext, useState, useEffect } from "react";
import {
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import tap from "../../assets/Images/icons/tap.png";
import tapDark from "../../assets/Images/icons/tapDark.png";
import UserContext from "../../context/UserContext";
const TaptoRun = ({ isLoading, setIsLoading }) => {
  const [ttrData, setTtrData] = useState([]);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (houseId) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user/ttr", {
        params: { house: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("Tap to ruin ----", result.data.data);
          setTtrData(result.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [houseId]);

  const submitHandler = (value) => {
    Core.post(
      "/user/ttr/run",
      {
        _id: value,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (!toast.isActive(id)) {
          toast({
            description: "Амжилттай",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
  };

  return (
    <VStack w={"full"}>
      {isLoading ? (
        <Spinner size="lg" color="#45A735" mt={"28"} />
      ) : ttrData.length > 0 ? (
        <SimpleGrid w={"full"} columns={[null, null, 3, null, 5]} spacing={8}>
          {ttrData.map((e, i) => (
            <VStack
              transition={"ease-in-out .3s"}
              className="btn"
              key={i}
              w={"full"}
              shadow={"md"}
              rounded={"md"}
              p={"4"}
              align={"flex-start"}
              justify={"flex-start"}
              onClick={() => submitHandler(e._id)}
              _hover={{
                shadow: "lg",
              }}
              cursor={"pointer"}
            >
              <HStack w={"full"} justify={"space-between"} align={"flex-start"}>
                <Image
                  src={colorMode === "dark" ? tapDark : tap}
                  w={"6"}
                  h={"8"}
                  objectFit={"contain"}
                />
                <HStack>
                  <Text fontSize={14} fontWeight={"normal"}>
                    Үйлдэл:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"}>
                    {e.actionNumber}
                  </Text>
                </HStack>
              </HStack>

              <Text fontSize={15} fontWeight={"medium"}>
                {e.name}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.600"
          spacing={1}
          mt={"28"}
        >
          <Icon as={FiInfo} w={7} h={7} />
          <Text fontSize={15} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй.
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default TaptoRun;
