import React, { useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Spinner,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import RoomControlCard from "../../../components/Room/RoomControlCard";
import Cookies from "js-cookie";
import axios from "../../../axios";
import AddRoom from "../../../components/Room/AddRoom";

const RoomControl = () => {
  const [rooms, setRooms] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { Core } = axios();

  useEffect(() => {
    if (houseId && trigger === true) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("room Control result", result.data.data);
          setRooms(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setTrigger(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  return (
    <VStack
      w={"full"}
      h={"full"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={4}
    >
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontSize={15} fontWeight={"normal"}>
            Өрөөний удирдлага
          </Text>
        </HStack>
        <AddRoom setTrigger={setTrigger} />
      </HStack>

      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"md"}
        py={"6"}
        align={"center"}
        justify={"flex-start"}
        spacing={4}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px",
            borderRadius: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
            borderRadius: "8px",
          },
        }}
      >
        {trigger ? (
          <Spinner size="lg" color="#45A735" />
        ) : (
          rooms.map((e, index) => (
            <RoomControlCard key={index} data={e} setTrigger={setTrigger} />
          ))
        )}
      </VStack>
    </VStack>
  );
};

export default RoomControl;
