import React from "react";
import {
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import contact from "../../../../assets/Images/CardIcons/contact.png";
import contactDark from "../../../../assets/Images/CardIcons/contactDark.png";
import motion from "../../../../assets/Images/CardIcons/motion.png";
import motionDark from "../../../../assets/Images/CardIcons/motionDark.png";
import smoke from "../../../../assets/Images/CardIcons/smoke.png";
import smokeDark from "../../../../assets/Images/CardIcons/smokeDark.png";
import water from "../../../../assets/Images/CardIcons/water.png";
import waterDark from "../../../../assets/Images/CardIcons/waterDark.png";

const TtrActAlarm = ({ data, roomName, index, setChangeState, setDevice }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      //   py={"4"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        setDevice(data);
        setChangeState(true);
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={
            data.deviceType === "Contact"
              ? colorMode === "dark"
                ? contactDark
                : contact
              : data.deviceType === "Occupancy"
              ? colorMode === "dark"
                ? motionDark
                : motion
              : data.deviceType === "Smoke"
              ? colorMode === "dark"
                ? smokeDark
                : smoke
              : data.deviceType === "Water"
              ? colorMode === "dark"
                ? waterDark
                : water
              : null
          }
          w={"6"}
          h={"6"}
          objectFit={"contain"}
        />
        <VStack align={"flex-start"} justify={"center"} spacing={0}>
          {roomName ? (
            <Text color={"#919191"} fontWeight={"normal"} fontSize={12}>
              {roomName}
            </Text>
          ) : null}
          <Text fontWeight={"normal"} fontSize={15}>
            {data.for}
          </Text>
        </VStack>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default TtrActAlarm;
