import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Image,
  Center,
  Spinner,
  useToast,
  Stack,
  RadioGroup,
  Radio,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useContext } from "react";
import UserContext from "../../../context/UserContext";
import axios from "../../../axios";
import socket from "../../../hooks/Socket";
import notifOff from "../../../assets/Images/icons/notifOff.png";
import notifOffDark from "../../../assets/Images/icons/notifOffDark.png";
import switchNotifOn from "../../../assets/Images/CardIcons/notifOn.png";
import switchNotifOnDark from "../../../assets/Images/CardIcons/notifOnDark.png";
import switchNotifOff from "../../../assets/Images/CardIcons/notifOff.png";
import switchNotifOffDark from "../../../assets/Images/CardIcons/notifOffDark.png";

// TODO: THIS COMPONENT IS A SINGLE newG4 CHANNEL CARD
function NewG4ChannelAlarm({ device }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(device);
  const [value, setValue] = useState(datas?.alarmValue);
  const toggleRef = useRef();
  const { handledHttpErrors } = useContext(UserContext);

  const switchHandler = (alarmValue, clickedFromRadio) => {
    if (isLoading) return;
    setIsLoading(true);

    // alarm asaah uyd alarmValue nuhtsul n defautl oor "on" baidag bileeeeeee
    const data = {
      channelId: datas._id,
      isAlarm: clickedFromRadio ? true : !datas.isAlarm,
      alarmValue: clickedFromRadio
        ? alarmValue
        : !datas.isAlarm
        ? "on"
        : alarmValue,
    };

    Core.put("/user_v2/device/g4_config_alarm", { ...data })
      .then((result) => {
        // console.log("resuuuuuuult =============>", result);

        // alarm asaah uyd alarmValue nuhtsul n defautl oor "on" baidag bileeeeeeee
        setDatas((prev) => {
          return {
            ...prev,
            isAlarm: clickedFromRadio ? true : !prev.isAlarm,
            alarmValue: clickedFromRadio
              ? alarmValue
              : !datas.isAlarm
              ? "on"
              : alarmValue,
          };
        });

        setValue(
          clickedFromRadio ? alarmValue : !datas.isAlarm ? "on" : alarmValue
        );
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("error ------>", err.response);

        setIsLoading(false);
        if (handledHttpErrors?.includes(err?.response?.status)) return;

        toast({
          description: "Алдаа гарлаа!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <VStack
      className="card"
      w={"full"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      px={"6"}
      py={5}
    >
      <HStack justify="space-between" w="full">
        <Center
          w={"12"}
          h={"12"}
          rounded={"md"}
          bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
          alignSelf={"flex-start"}
        >
          <Image
            src={colorMode === "dark" ? notifOffDark : notifOff}
            w={"5"}
            objectFit={"contain"}
          />
        </Center>

        <Box
          bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
          w={"24"}
          h={"12"}
          p={1}
          rounded="xl"
          onClick={() => {
            switchHandler(value, false);
          }}
          cursor={isLoading ? "not-allowed" : "pointer"}
        >
          <HStack w="full" h="full" pos="relative" spacing={0}>
            <Center w="50%">
              <Image
                src={
                  colorMode === "dark"
                    ? switchNotifOffDark
                    : datas.alarmStatus === "inActive"
                    ? switchNotifOffDark
                    : switchNotifOff
                }
                w={"4"}
                objectFit={"contain"}
                zIndex={1}
              />
            </Center>

            <Center w="50%">
              <Image
                src={
                  colorMode === "dark"
                    ? switchNotifOnDark
                    : datas.alarmStatus === "active"
                    ? switchNotifOnDark
                    : switchNotifOn
                }
                w={"4"}
                objectFit={"contain"}
                zIndex={1}
              />
            </Center>
            <Box
              ref={toggleRef}
              right={datas.isAlarm ? "0" : !datas.isAlarm ? "50%" : null}
              position={"absolute"}
              w="50%"
              h="full"
              bg="#45A735"
              transition={"all 0.2s ease-in-out"}
              rounded="lg"
              zIndex={isLoading ? 1 : "unset"}
              display={"flex"}
              alignItems="center"
              justifyContent="center"
            >
              {isLoading ? <Spinner color="#fff" size="sm" /> : null}
            </Box>
          </HStack>
        </Box>
      </HStack>

      <HStack
        spacing={0}
        align={"flex-start"}
        justify={"space-between"}
        w={"full"}
      >
        <Text w="full" fontSize={15} fontWeight={"medium"} cursor="unset">
          {datas.name}
        </Text>
      </HStack>

      <RadioGroup
        isDisabled={isLoading ? true : datas.isAlarm === false}
        alignSelf={"end"}
        onChange={(e) => {
          if (isLoading) return;
          setValue(e);
          switchHandler(e, true);
        }}
        value={value}
      >
        <Stack direction="row">
          <Radio
            _focusVisible={{
              outline: "none",
            }}
            size="sm"
            colorScheme={"red"}
            value="off"
          >
            <Text fontSize={13}>OFF</Text>
          </Radio>
          <Radio
            _focusVisible={{
              outline: "none",
            }}
            size="sm"
            colorScheme={"green"}
            value="on"
          >
            <Text fontSize={13}>ON</Text>
          </Radio>
        </Stack>
      </RadioGroup>
    </VStack>
  );
}

export default NewG4ChannelAlarm;
