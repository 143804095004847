import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  useColorMode,
  VStack,
  Icon,
  Spinner,
  SimpleGrid,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import BackButton from "../../../../components/Button/BackButton";
import { AiOutlineRight } from "react-icons/ai";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import pencil from "../../../../assets/Images/icons/pencil.png";
import pencilDark from "../../../../assets/Images/icons/pencilDark.png";
import { FiInfo } from "react-icons/fi";
import UserContext from "../../../../context/UserContext";

const IrDash = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = Cookies.get("accessToken");
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    let source = Core.CancelToken.source();
    let cancel = false;
    //   Core.get("user/devicedata", {
    Core.get("/user/ir_controller_sub", {
      params: { _id: location.state.id },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data.data);
        if (cancel) return;
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  const switchHandler = (value) => {
    // console.log(value);
    Core.post(
      "/user/ir_controller_sub",
      {
        _id: value.id,
        command: value.command,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (!toast.isActive(id)) {
          toast({
            title: "Амжилттай",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            title: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
  };

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>{location.state.deviceName}</Text>
        </HStack>

        <HStack
          cursor={"pointer"}
          spacing={2}
          borderRadius={"3xl"}
          px="16"
          py={2}
          className="btn"
          _hover={{
            shadow: "md",
          }}
          transition={"linear"}
          transitionDuration={".3s"}
          onClick={() =>
            history.push("/home/ir-detail", {
              roomName: location.state.roomName,
              status: location.state.status,
              id: location.state.id,
              deviceName: location.state.deviceName,
              isOriginal: location.state.isOriginal,
              origin: location.state.origin,
              deviceType: location.state.deviceType,
              wifiSignal: location.state.wifiSignal,
              subDeviceCount: location.state.subDeviceCount,
              notifEnabled: location.state.notifEnabled,
              blocked: location.state.blocked,
            })
          }
        >
          <Text>Дэлгэрэнгүй</Text>
          <Icon color={"#45A735"} as={AiOutlineRight} />
        </HStack>
      </HStack>

      {/*     BODY    */}
      <HStack
        sx={{
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colorMode === "dark" ? "#1D211D" : "#f3f3f3",
            borderRadius: "md",
          },
        }}
        overflow={"auto"}
        justify={"center"}
        alignItems={"center"}
        w="full"
        h="full"
      >
        <SimpleGrid h="full" columns={[null, null, 2, 3, 4]} spacing={10}>
          {isLoading ? (
            <Spinner size="lg" color="#45A735" mt="10vh" />
          ) : data.length > 0 ? (
            data.map((subDev, index) => (
              //   CARD
              <VStack
                key={index}
                py={2.5}
                borderRadius={"lg"}
                className="card"
                h="fit-content"
                w="320px"
              >
                {/* CARD  HEADER */}
                <HStack py={1} pl={6} pr={4} w="full" justify={"space-between"}>
                  <Text>{subDev.model}</Text>
                  <Box
                    transition={"ease-in-out .3s"}
                    cursor={"pointer"}
                    className="gray"
                    p={"2"}
                    borderRadius={"base"}
                    onClick={() =>
                      history.push("/home/ir-setup", {
                        ...subDev,
                      })
                    }
                    _hover={{
                      shadow: "md",
                    }}
                  >
                    <Image
                      w={3.5}
                      objectFit={"contain"}
                      src={colorMode === "dark" ? pencilDark : pencil}
                    />
                  </Box>
                </HStack>

                {/* HORIZONTAL LINE */}
                <Box w="full" h="0.5" className="gray"></Box>

                {/* CARD BODY */}
                <VStack spacing={0} pt={1} px={4} w="full">
                  <HStack
                    borderRadius={"md"}
                    px={6}
                    py={2}
                    fontSize={13}
                    w="full"
                    className="gray"
                    justify={"space-between"}
                  >
                    <Text>Температур:</Text>
                    <Text>{subDev.currentConfig.temp} C</Text>
                  </HStack>

                  <HStack
                    borderRadius={"md"}
                    px={6}
                    py={2}
                    fontSize={13}
                    w="full"
                    justify={"space-between"}
                  >
                    <Text>Горим:</Text>
                    <Text>{subDev.currentConfig.mode}</Text>
                  </HStack>

                  <HStack
                    borderRadius={"md"}
                    px={6}
                    py={2}
                    fontSize={13}
                    w="full"
                    className="gray"
                    justify={"space-between"}
                  >
                    <Text>Сэнсний хүч:</Text>
                    <Text>{subDev.currentConfig.fanSpeed}</Text>
                  </HStack>

                  <HStack
                    borderRadius={"md"}
                    px={6}
                    py={2}
                    fontSize={13}
                    w="full"
                    justify={"space-between"}
                  >
                    <Text>Swing V:</Text>
                    <Text>{subDev.currentConfig.swingV}</Text>
                  </HStack>

                  <HStack
                    borderRadius={"md"}
                    px={6}
                    py={2}
                    fontSize={13}
                    w="full"
                    className="gray"
                    justify={"space-between"}
                  >
                    <Text>Swing H:</Text>
                    <Text>{subDev.currentConfig.swingH}</Text>
                  </HStack>
                </VStack>

                {/* CARD FOOTER */}
                <HStack
                  px={6}
                  py={3.5}
                  justify={"center"}
                  spacing={4}
                  fontSize={13}
                  w="full"
                >
                  <Center
                    transition={"ease-in-out .3s"}
                    py={1}
                    w={"45%"}
                    borderRadius={"3xl"}
                    borderWidth={"thin"}
                    borderColor={"#45A735"}
                    onClick={() =>
                      switchHandler({ id: subDev._id, command: "ON" })
                    }
                    _hover={{
                      shadow: "md",
                    }}
                    cursor={location.state.blocked ? "not-allowed" : "pointer"}
                  >
                    <Text fontWeight={"normal"}>Асаах</Text>
                  </Center>
                  <Center
                    transition={"ease-in-out .3s"}
                    py={1}
                    w={"45%"}
                    borderRadius={"3xl"}
                    borderWidth={"thin"}
                    borderColor={"#ff0000"}
                    onClick={() =>
                      switchHandler({ id: subDev._id, command: "OFF" })
                    }
                    _hover={{
                      shadow: "md",
                    }}
                    cursor={location.state.blocked ? "not-allowed" : "pointer"}
                  >
                    <Text fontWeight={"normal"}>Унтраах</Text>
                  </Center>
                </HStack>
              </VStack>
            ))
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              // w={{ base: "72", md: "xs", lg: "full" }}
              spacing={1}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </SimpleGrid>
      </HStack>
    </VStack>
  );
};

export default IrDash;
