import React, { useState } from "react";
import {
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Box,
} from "@chakra-ui/react";
import pro from "../../assets/Images/pro.png";
import Cookies from "js-cookie";
import ChangePassword from "../../components/Profile/ChangePassword";
import ChangeNickname from "../../components/Profile/ChangeNickname";
import ChangeUsername from "../../components/Profile/ChangeUsername";
import feedback from "../../assets/Images/icons/feedback.png";
import feedbackDark from "../../assets/Images/icons/feedbackDark.png";
import { useHistory } from "react-router-dom";

const Profile = () => {
  const [hovering, setHovering] = useState(false);
  const username = Cookies.get("username");
  const nickname = Cookies.get("nickname");
  const { colorMode } = useColorMode();
  const history = useHistory();

  return (
    <HStack
      className="card"
      w={"full"}
      h={"85vh"}
      rounded={"md"}
      align={"center"}
      justify={"center"}
    >
      <HStack spacing={10} align={"start"}>
        <VStack spacing={4}>
          <Image src={pro} w={"36"} h={"36"} objectFit={"contain"} />
          <VStack spacing={1}>
            <Text
              fontSize={17}
              fontWeight={"medium"}
              maxW={"48"}
              textAlign={"center"}
            >
              VIOT
            </Text>
            <Text fontSize={15} fontWeight={"normal"}>
              {username}
            </Text>
          </VStack>
        </VStack>
        <VStack spacing={4}>
          <ChangeNickname username={username} />
          <ChangeUsername username={username} />
          <ChangePassword username={username} />
          <Box w="full" pt={2}>
            <HStack
              cursor={"pointer"}
              justify="center"
              p={2}
              bg={colorMode === "light" ? "#fbfbfb" : "#1d211d"}
              rounded={"xl"}
              border="1px solid #45A735"
              w="full"
              spacing={2}
              _hover={{
                bg: "#45A735",
                color: "#fff",
              }}
              onMouseEnter={() => {
                setHovering(true);
              }}
              onMouseLeave={() => {
                setHovering(false);
              }}
              transition="all .3s ease-in-out"
              onClick={() => {
                history.push("/feedback");
              }}
            >
              <Image
                w={6}
                h={6}
                objectFit={"contain"}
                src={
                  hovering === true
                    ? feedbackDark
                    : colorMode === "light"
                    ? feedback
                    : feedbackDark
                }
              />
              <Text>Санал хүсэлт илгээх</Text>
            </HStack>
          </Box>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default Profile;
