import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
  Img,
  Input,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { FiInfo } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import deviceIcon from "../../../assets/Images/icons/deviceIcon.png";
import deviceIconDark from "../../../assets/Images/icons/deviceIconDark.png";
import UserContext from "../../../context/UserContext";
import { useLocation, useHistory } from "react-router-dom";
import pencil from "../../../assets/Images/icons/pencil.png";
import pencilDark from "../../../assets/Images/icons/pencilDark.png";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import NewG4ChannelAlarm from "../../../components/Cards/G4/NewG4ChannelAlarm";

const Device = ({ colorMode, provided, room, e, toast, Core }) => {
  // e gedeg n ene tohooromj yum shuu
  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(e.name);
  const [isLoading, setIsLoading] = useState(false);
  const [actualName, setActualName] = useState(e.name);

  const handleChangeName = () => {
    setIsLoading(true);

    Core.put("/user_v2/device/g4_channel_name", {
      channelId: e._id,
      name: newName,
    })
      .then((res) => {
        // console.log("newG4 channel name change result ==>", res);
        setIsLoading(false);

        setEdit(false);
        setActualName(newName);

        toast({
          description: "Амжилттай солигдлоо",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        // console.log("newG4 channel name change error ==>", err);
        setIsLoading(false);

        const error =
          typeof err?.response?.data?.error === "string"
            ? err.response.data.error
            : "Алдаа гарлаа!";

        toast({
          description: error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <HStack
      className="card"
      boxShadow={"md"}
      rounded="md"
      py={3}
      px={4}
      w={"full"}
      justifyContent="space-between"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      pos="relative"
    >
      <HStack w="50%">
        <Image
          src={colorMode === "dark" ? deviceIconDark : deviceIcon}
          w={4}
          pointerEvents="none"
        />
        {edit ? (
          <Input
            defaultValue={actualName}
            fontSize={15}
            fontWeight={"normal"}
            size="sm"
            borderRadius={"md"}
            onChange={(e) => setNewName(e.target.value)}
            autoFocus={true}
            _focusVisibleVisible={{
              outline: "none",
              borderColor: "#45A735",
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                isLoading === false &&
                newName.length > 0
              ) {
                handleChangeName();
              }

              if (e.key === "Escape") {
                setEdit(false);
              }
            }}
          />
        ) : (
          <Text isTruncated fontSize={15} fontWeight={"normal"}>
            {actualName}
          </Text>
        )}
      </HStack>
      <HStack w="40%" justifyContent="flex-end">
        <Text fontSize={11} mr={1} mb={-3} isTruncated>
          {room.find((f) => f._id === e.roomId)
            ? room.find((f) => f._id === e.roomId).name
            : null}
        </Text>
      </HStack>

      {edit ? (
        <>
          <IconButton
            _focusVisible={{
              outline: "none",
            }}
            colorScheme={"green"}
            size={"sm"}
            icon={
              isLoading ? (
                <Spinner size={"sm"} />
              ) : (
                <MdOutlineCheck fontSize={"16"} />
              )
            }
            onClick={handleChangeName}
            isDisabled={isLoading === true || newName.length < 1}
          />

          <IconButton
            _focusVisible={{
              outline: "none",
            }}
            colorScheme={"red"}
            size={"sm"}
            icon={<MdOutlineClose />}
            onClick={() => {
              setEdit(false);
            }}
          />
        </>
      ) : (
        <Center
          h="full"
          onClick={() => {
            setEdit((prev) => !prev);
          }}
          px="1"
          pos={"absolute"}
          right="16px"
          top={0}
        >
          <Img
            src={colorMode === "dark" ? pencilDark : pencil}
            objectFit="contain"
            w={4}
            h={4}
          />
        </Center>
      )}
    </HStack>
  );
};

const NewG4ChannelSettings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [room, setRoom] = useState([]);
  const [devices, setDevices] = useState([]);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [showAlarm, setShowAlarm] = useState(false);

  // TODO: newG4 tuhuurumjiin dotorh channel uudiig avch baina
  useEffect(() => {
    Core.get("/user_v2/device/detail_new_g4", {
      params: { _id: location.state.id },
    })
      .then((res) => {
        // console.log("newG4 channels", res.data.data);
        setIsLoading(false);

        const data = res.data.data;

        setDevices(
          data.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0))
        );
      })
      .catch((err) => {
        // console.log("err", err);
        setIsLoading(false);

        const error =
          typeof err?.response?.data?.error === "string"
            ? err.response.data.error
            : "Алдаа гарлаа!";

        toast({
          description: error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(devices);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setDevices(items);
  };

  const submitHandler = () => {
    if (devices.length > 0) {
      setIsDisable(true);
      let source = Core.CancelToken.source();
      const data = devices.map((e, i) => {
        return {
          channelId: e._id,
          index: i,
        };
      });

      Core.put(
        "/user_v2/device/g4_channel_index",
        { channel: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((res) => {
          //   console.log("new g4 channel reorder result", res);
          setIsDisable(false);
          onClose();
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай хадгаллаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          //   console.log("new g4 channel reorder error", err);
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <VStack
      flex={1}
      w={"full"}
      spacing={8}
      align={"flex-start"}
      justify={"flex-start"}
      // bg="red"
    >
      {/* TODO: HEADER */}
      <HStack w="full" spacing={4} justifyContent="space-between">
        <HStack>
          <BackButton />
          <Text fontSize={15} fontWeight={"normal"}>
            Тохиргоо
          </Text>
        </HStack>

        <HStack>
          {/* Мэдэгдэл (button) */}
          <Button
            bg={colorMode === "light" ? "white" : "#252B25"}
            className="btn"
            _hover={{
              shadow: "md",
            }}
            px="16"
            borderRadius={"3xl"}
            fontSize="15px"
            fontWeight={"normal"}
            gap={2}
            _focusVisible={{
              outline: "none",
            }}
            onClick={() => setShowAlarm(false)}
            isDisabled={!showAlarm}
          >
            Нэр, дараалал
          </Button>

          {/* Мэдэгдэл (button) */}
          <Button
            bg={colorMode === "light" ? "white" : "#252B25"}
            className="btn"
            _hover={{
              shadow: "md",
            }}
            px="16"
            borderRadius={"3xl"}
            fontSize="15px"
            fontWeight={"normal"}
            gap={2}
            _focusVisible={{
              outline: "none",
            }}
            onClick={() => setShowAlarm(true)}
            isDisabled={showAlarm}
          >
            Alarm
          </Button>
        </HStack>
      </HStack>

      {/* TODO: BODY */}

      {!showAlarm ? (
        // TODO: NER DARAALAL SOLIDOG HESEG
        <VStack flex={1} w={"full"} maxH={"75vh"} h="80%">
          {isLoading ? (
            <Center w={"full"} pt={"10"}>
              <Spinner size="md" color={"#45A735"} />
            </Center>
          ) : devices.length > 0 ? (
            <VStack spacing={8}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="devices">
                  {(provided) => (
                    <VStack
                      flex={1}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      w="lg"
                      px={"2"}
                      pb={"2"}
                      maxH={"65vh"}
                      overflowY="auto"
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          borderRadius: "8px",
                          backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: `rgba(69, 167, 53, 0.5)`,
                          borderRadius: "8px",
                        },
                      }}
                    >
                      {devices.map((e, i) => (
                        <Draggable key={e._id} draggableId={e._id} index={i}>
                          {(provided) => (
                            <Device
                              colorMode={colorMode}
                              provided={provided}
                              room={room}
                              e={e}
                              toast={toast}
                              Core={Core}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </VStack>
                  )}
                </Droppable>
              </DragDropContext>

              <Button
                w={"md"}
                bg={"#45A735"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#fff"}
                onClick={onOpen}
                _focusVisible={{
                  outline: "none",
                }}
                _hover={{
                  bg: "#5AB54B",
                }}
              >
                Хадгалах
              </Button>
              <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered={true}
                closeOnOverlayClick={false}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Хадгалах уу?</ModalHeader>
                  <ModalCloseButton _focusVisible={{ outline: "none" }} />
                  <ModalFooter>
                    <Button
                      onClick={onClose}
                      mr={3}
                      _focusVisible={{ outline: "none" }}
                    >
                      Болих
                    </Button>
                    <Button
                      colorScheme="green"
                      _focusVisible={{ outline: "none" }}
                      isDisabled={isDisable}
                      onClick={submitHandler}
                    >
                      {isDisable ? <Spinner mr={2} size="sm" /> : null}
                      Тийм
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </VStack>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              spacing={1}
              mt={"10"}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>
      ) : (
        <>
          {devices.length > 0 ? (
            <SimpleGrid
              w="full"
              columns={[1, 1, 2, 2, 4, 5]}
              spacingY={6}
              spacingX={8}
            >
              {devices.map((e) => (
                <NewG4ChannelAlarm key={e._id} device={e} />
              ))}
            </SimpleGrid>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              spacing={1}
              mt={"10"}
              w="full"
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </>
      )}
    </VStack>
  );
};

export default NewG4ChannelSettings;
