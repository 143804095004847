import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  Grid,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import powr from "../../../../assets/Images/CardIcons/powr.png";
import powrDark from "../../../../assets/Images/CardIcons/powrDark.png";
import share from "../../../../assets/Images/icons/share.png";
import shareDark from "../../../../assets/Images/icons/shareDark.png";
import powrA from "../../../../assets/Images/CardIcons/powrA.png";
import powrADark from "../../../../assets/Images/CardIcons/powrADark.png";
import powrV from "../../../../assets/Images/CardIcons/powrV.png";
import powrVDark from "../../../../assets/Images/CardIcons/powrVDark.png";
import powrW from "../../../../assets/Images/CardIcons/powrW.png";
import powrWDark from "../../../../assets/Images/CardIcons/powrWDark.png";
import socket from "../../../../hooks/Socket";
import Blocked from "../../Blocked";
import UserContext from "../../../../context/UserContext";

const Powr = ({
  roomName,
  status,
  id,
  deviceName,
  isOriginal,
  origin,
  deviceType,
  wifiSignal,
  notifEnabled,
  subDeviceCount,
  voltage,
  current,
  power,
  ApparentPower,
  ReactivePower,
  Factor,
  Today,
  Yesterday,
  Total,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    subDevice: subDeviceCount,
    voltage: voltage,
    current: current,
    power: power,
    ApparentPower: ApparentPower,
    ReactivePower: ReactivePower,
    Factor: Factor,
    Today: Today,
    Yesterday: Yesterday,
    Total: Total,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("POWR data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          voltage: value.data.Voltage,
          current: value.data.Current,
          power: value.data.Power,
          ApparentPower: value.data.ApparentPower,
          ReactivePower: value.data.ReactivePower,
          Factor: value.data.Factor,
          Today: value.data.Today,
          Yesterday: value.data.Yesterday,
          Total: value.data.Total,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('POWR name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      onClick={() =>
        history.push("/home/powr-dash", {
          roomName: datas.room,
          status: datas.status,
          id,
          deviceName: datas.name,
          isOriginal,
          origin,
          deviceType,
          wifiSignal,
          subDeviceCount: datas.subDeviceCount,
          voltage: datas.voltage,
          current: datas.current,
          power: datas.power,
          ApparentPower: datas.ApparentPower,
          ReactivePower: datas.ReactivePower,
          Factor: datas.Factor,
          Today: datas.Today,
          Yesterday: datas.Yesterday,
          Total: datas.Total,
          notifEnabled: datas.notifEnabled,
          blocked,
        })
      }
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack
        w={"full"}
        align={"center"}
        justify={"space-between"}
        pt={"5"}
        px={"6"}
      >
        <HStack w={"full"}>
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? powrDark : powr}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Grid
            pt={blocked ? 2 : 0}
            w={"56"}
            templateColumns={"repeat(2, 1fr)"}
            gap={0}
          >
            <HStack>
              <Image
                src={colorMode === "dark" ? powrVDark : powrV}
                w={"4"}
                objectFit={"contain"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.voltage}
              </Text>
            </HStack>
            <HStack>
              <Image
                src={colorMode === "dark" ? powrADark : powrA}
                w={"4"}
                objectFit={"contain"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.current}
              </Text>
            </HStack>
            <HStack>
              <Image
                src={colorMode === "dark" ? powrWDark : powrW}
                w={"4"}
                objectFit={"contain"}
              />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.power}
              </Text>
            </HStack>
          </Grid>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Powr;
