import React, { useEffect } from "react";
import { Center, HStack, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const TimePicker = ({ title, value, setValue }) => {
  const countUpHandler = () => {
    if (title === "Цаг" && parseInt(value) === 23) {
      setValue(0);
    } else if (title === "Минут" && parseInt(value) === 59) {
      setValue(0);
    } else if (title === "Секунд" && parseInt(value) === 59) {
      setValue(0);
    } else {
      setValue(parseInt(value) + 1);
    }
  };

  const countDownHandler = () => {
    if (title === "Цаг" && parseInt(value) === 0) {
      setValue(23);
    } else if (title === "Минут" && parseInt(value) === 0) {
      setValue(59);
    } else if (title === "Секунд" && parseInt(value) === 0) {
      setValue(59);
    } else {
      setValue(parseInt(value) - 1);
    }
  };

  useEffect(() => {
    if (value == "") setValue(0);
  }, [value]);

  return (
    <VStack>
      <Text>{title}</Text>
      <HStack
        w={"full"}
        h={"16"}
        borderWidth={"thin"}
        borderColor={"#eee"}
        rounded={"xl"}
        spacing={0}
      >
        <Input
          min={0}
          w={"16"}
          h={"full"}
          fontSize={15}
          fontWeight={"medium"}
          type={"number"}
          value={value}
          textAlign={"center"}
          onChange={(e) => {
            let newValue = e.target.value;

            if (
              newValue.toString().length > 1 &&
              newValue.toString()[0] === "0"
            ) {
              newValue = newValue.toString().replace(/^0+/, "");
            }

            if (title === "Цаг" && newValue < 24) return setValue(newValue);

            if (title === "Минут" && newValue < 60) return setValue(newValue);

            if (title === "Секунд" && newValue < 60) return setValue(newValue);
          }}
          border={"none"}
          _focusVisible={{
            outline: "none",
          }}
        />
        <VStack spacing={0}>
          <Center
            w={"12"}
            h={"8"}
            roundedTopRight={"xl"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            onClick={() => countUpHandler()}
            cursor={"pointer"}
          >
            <Icon as={IoIosArrowUp} w={"5"} h={"5"} />
          </Center>
          <Center
            w={"12"}
            h={"8"}
            roundedBottomRight={"xl"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            onClick={() => countDownHandler()}
            cursor={"pointer"}
          >
            <Icon as={IoIosArrowDown} w={"5"} h={"5"} />
          </Center>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default TimePicker;
