import React, { useContext, useEffect } from "react";
import { Box, Flex, Stack, useToast, VStack } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import UserContext from "../context/UserContext";
import firebase from "firebase/compat/app";
import { getMessaging, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import { NotifAlert } from "./NotifAlert";
import Restriction from "../components/Modal/Restriction";
import {
  isMobileOnly,
  isMobileSafari,
  isSamsungBrowser,
} from "react-device-detect";
import RestrictMobile from "./RestrictMobile";
import { useLocation } from "react-router-dom/";

const Main = ({ children }) => {
  const location = useLocation();
  const toast = useToast();
  const id = "toast";
  const {
    notifBadge,
    setNotifBadge,
    setNotifData,
    setNotification,
    restricted,
    setRestricted,
    setAutoEditId,
  } = useContext(UserContext);

  const firebaseConfig = {
    apiKey: "AIzaSyDyj38KAo_xOHn24lzISHcj25W7Ilamq7Y",
    authDomain: "viot-f2c45.firebaseapp.com",
    projectId: "viot-f2c45",
    storageBucket: "viot-f2c45.appspot.com",
    messagingSenderId: "904573792111",
    appId: "1:904573792111:web:ba5c53bb35cf91500f429a",
    measurementId: "G-2PVNP6EVQF",
  };
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      // console.log(payload);
      if (payload.notification) {
        const title = payload.notification.title;
        const body = payload.notification.body;
        if (payload) {
          setNotifBadge((prev) => prev + 1);
          setNotifData(payload.notification);
          const date = new Date().getTime() + 28800000;
          const myRealDate = new Date(date).toISOString();
          setNotification({
            date: myRealDate.slice(0, 10),
            notification: [
              {
                title: title,
                body: body,
                time: "Now",
                _id: "",
              },
            ],
          });
          if (!toast.isActive(id)) {
            toast({
              position: "bottom-right",
              duration: 10000,
              render: () => <NotifAlert title={title} body={body} />,
            });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (notifBadge > 0) {
      Cookies.set("badge", notifBadge);
    }
  }, [notifBadge]);

  // nogoo neg bagts avtomatjuulalt zasah masah daraad butsaad orood shine avtomatjuulalt uusgenguut ter n sayhan zasah gej orson avtomatjuulalt iig edit hiigeed baisniig sergiilj bui code bilee минуузээ
  useEffect(() => {
    if (
      ["/automation/create-automation", "/automation/create-ttr"].includes(
        location.pathname
      ) === false
    ) {
      setAutoEditId("");
    }

    // if (location.pathname !== "/automation/create-automation") {
    //   setAutoEditId("");
    // }
  }, [location]);

  if (isSamsungBrowser || isMobileSafari || isMobileOnly) {
    return <RestrictMobile />;
  }

  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      position={"relative"}
      spacing={0}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Sidebar />
      <Box
        className="header"
        position={"absolute"}
        w={"full"}
        h={"36"}
        top={0}
        zIndex={0}
      />
      <VStack
        w={"full"}
        zIndex={1}
        spacing={2}
        px={12}
        pb={4}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px",
            borderRadius: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
            borderRadius: "8px",
          },
        }}
      >
        <Header />
        <Stack w={"full"} h={"85vh"}>
          {children}
        </Stack>
      </VStack>
      <Restriction isOpen={restricted} onClose={() => setRestricted(false)} />
    </Flex>
  );
};

export default Main;
