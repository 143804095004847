export const SideData = [
  {
    title: "Дашбоард",
    toLink: "/home",
    icon: require("../../assets/Images/icons/home.png"),
    iconDark: require("../../assets/Images/icons/homeDark.png"),
  },
  {
    title: "Автомат",
    toLink: "/automation",
    icon: require("../../assets/Images/icons/automation.png"),
    iconDark: require("../../assets/Images/icons/automationDark.png"),
  },
  {
    title: "Мэдэгдэл",
    toLink: "/notification",
    icon: require("../../assets/Images/icons/notifOn.png"),
    iconDark: require("../../assets/Images/icons/notifOnDark.png"),
  },
  {
    title: "Тохиргоо",
    toLink: "/settings",
    icon: require("../../assets/Images/icons/user.png"),
    iconDark: require("../../assets/Images/icons/userDark.png"),
  },
  {
    title: "Төлбөр",
    toLink: "/payment",
    icon: require("../../assets/Images/icons/payment.png"),
    iconDark: require("../../assets/Images/icons/paymentDark.png"),
  },
];
