import React, { useState, useContext } from "react";
import {
  Center,
  HStack,
  Image,
  Spinner,
  Text,
  useColorMode,
  VStack,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import axios from "../../../axios";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";
import g4 from "../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../assets/Images/CardIcons/g4Dark.png";

const G4 = ({
  roomName,
  deviceName,
  switchStatus,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
  data,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    switchStatus: switchStatus,
    status: status,
    notifEnabled: notifEnabled,
    data: data,
  });
  const [onLoading, setOnLoading] = useState(false);
  const [offLoading, setOffLoading] = useState(false);
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const toast = useToast();
  const { Core } = axios();

  // -----------------    унтрааж, асаах комманд     --------------------
  const handleSwitch = (command) => {
    if (onLoading || offLoading) return;

    if (command === "on") setOnLoading(true);
    if (command === "off") setOffLoading(true);

    Core.post("/user_v2/command/g4", {
      _id: id,
      command: command,
    })
      .then((response) => {
        // console.log("response  --->", response);
        setOnLoading(false);
        setOffLoading(false);
      })
      .catch((err) => {
        setOnLoading(false);
        setOffLoading(false);
        // console.log("error  --->", err);
        if (handledHttpErrors.includes(err.response.status)) return;

        toast({
          description: "Алдаа гарлаа!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          w={"full"}
          onClick={() =>
            history.push("/home/switch-detail", {
              id: id,
              roomName: datas.room,
              deviceName: datas.name,
              status: datas.status,
              notifEnabled: datas.notifEnabled,
              isOriginal,
              origin,
              wifiSignal,
              deviceType,
              blocked,
            })
          }
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? g4Dark : g4}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <HStack h={"10"}>
            <HStack
              bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
              px={3.5}
              py={1}
              rounded={"xl"}
              transition="all 0.3s ease-in-out"
              _hover={{
                cursor:
                  offLoading === true || onLoading === true
                    ? "not-allowed"
                    : "pointer",
                bg: colorMode === "dark" ? "#4b504b" : "#f1efef",
              }}
              onClick={() => handleSwitch("off")}
            >
              <HStack>
                {offLoading ? (
                  <Spinner size="xs" color="#45A735" />
                ) : (
                  <Box w={1.5} h={1.5} rounded={"full"} bg="#ff0000"></Box>
                )}
                <Text fontWeight={"normal"} fontSize="13">
                  OFF
                </Text>
              </HStack>
            </HStack>

            <HStack
              bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
              px={3.5}
              py={1}
              rounded={"xl"}
              transition="all 0.3s ease-in-out"
              _hover={{
                cursor:
                  offLoading === true || onLoading === true
                    ? "not-allowed"
                    : "pointer",
                bg: colorMode === "dark" ? "#4b504b" : "#f1efef",
              }}
              onClick={() => handleSwitch("on")}
            >
              <HStack>
                {onLoading ? (
                  <Spinner size="xs" color="#45A735" />
                ) : (
                  <Box w={1.5} h={1.5} rounded={"full"} bg="#45A735"></Box>
                )}
                <Text fontWeight={"normal"} fontSize="13">
                  ON
                </Text>
              </HStack>
            </HStack>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/switch-detail", {
            id: id,
            roomName: datas.room,
            deviceName: datas.name,
            status: datas.status,
            notifEnabled: datas.notifEnabled,
            isOriginal,
            origin,
            wifiSignal,
            deviceType,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default G4;
