import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../../components/Button/BackButton";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ac from "../../../../assets/Images/CardIcons/AC.png";
import cool from "../../../../assets/Images/CardIcons/irCool.png";
import coolDark from "../../../../assets/Images/CardIcons/irCoolDark.png";
import fan from "../../../../assets/Images/CardIcons/irFan.png";
import fanDark from "../../../../assets/Images/CardIcons/irFanDark.png";
import heat from "../../../../assets/Images/CardIcons/irHeat.png";
import heatDark from "../../../../assets/Images/CardIcons/irHeatDark.png";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import axios from "../../../../axios";
import UserContext from "../../../../context/UserContext";

const IrSetup = () => {
  const location = useLocation();
  const { model, config, currentConfig, _id } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(currentConfig.temp);
  const [maxTemp, setMaxTemp] = useState(config.tempMax);
  const [minTemp, setMinTemp] = useState(config.tempMin);
  const [fanSpeedArray, setFanSpeedArray] = useState([]);
  const [mode, setMode] = useState(currentConfig.mode);
  const [fanSpeed, setFanSpeed] = useState(currentConfig.fanSpeed);
  const [swingV, setSwingV] = useState(currentConfig.swingV);
  const [swingH, setSwingH] = useState(currentConfig.swingH);
  const history = useHistory();
  const { colorMode } = useColorMode();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (config) {
      let min = config.fanSpeedMin;
      let max = config.fanSpeedMax;
      let fanSpeed = Array(max - min + 1)
        .fill()
        .map(() => min++);

      setFanSpeedArray(fanSpeed);
    }
  }, []);

  const setupHandler = () => {
    const data = {
      _id: _id,
      type: "AC",
      currentConfig: {
        temp: count,
        fanSpeed: fanSpeed,
        swingH: swingH,
        swingV: swingV,
        mode: mode,
      },
    };
    // console.log("send DATa------", data);
    let source = Core.CancelToken.source();
    Core.put(
      "/user/ir_controller_sub",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        // console.log(result.data);
        toast({
          title: "Амжилттай",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        // console.log(err);
        if (handledHttpErrors.includes(err.response.status)) return;

        toast({
          title: "Алдаа гарлаа",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <VStack
      w={"full"}
      h={"full"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={4}
    >
      <HStack w={"full"} spacing={4}>
        <BackButton />
        <Text fontSize={15} fontWeight={"normal"}>
          {model}
        </Text>
      </HStack>

      <HStack
        w="full"
        align={"center"}
        justify={"center"}
        pt={"10vh"}
        spacing={20}
      >
        <VStack spacing={8} w={"sm"}>
          <Image src={ac} w={"sm"} objectFit={"contain"} />
          <HStack spacing={4}>
            {config.mode.map((e, index) => (
              <Center
                className="btn"
                transition={"ease-in-out .3s"}
                key={index}
                shadow={"md"}
                rounded={"full"}
                w={"14"}
                h={"14"}
                borderWidth={"thin"}
                borderColor={
                  e === mode
                    ? "#45A735"
                    : colorMode === "dark"
                    ? "#2f2f2f"
                    : "#fff"
                }
                cursor={"pointer"}
                _hover={{
                  shadow: "lg",
                }}
                onClick={() => setMode(e)}
              >
                <Image
                  src={
                    e === "cool"
                      ? colorMode === "dark"
                        ? coolDark
                        : cool
                      : e === "heat"
                      ? colorMode === "dark"
                        ? heatDark
                        : heat
                      : colorMode === "dark"
                      ? fanDark
                      : fan
                  }
                  w={"8"}
                  h={"8"}
                  objectFit={"contain"}
                />
              </Center>
            ))}
          </HStack>
          <HStack>
            <Button
              bg={"transparent"}
              p={0}
              _focusVisible={{
                outline: "none",
              }}
              isDisabled={count > minTemp ? false : true}
              onClick={() => setCount(count - 1)}
            >
              <Icon as={IoIosArrowBack} w={8} h={8} />
            </Button>

            <HStack spacing={0} align={"flex-start"} justify={"center"}>
              <Text fontSize={25} fontWeight={"medium"}>
                {count}
              </Text>
              <Text
                fontSize={14}
                fontWeight={"medium"}
                color={"#45A735"}
                pt={"1"}
              >
                °C
              </Text>
            </HStack>
            <Button
              bg={"transparent"}
              p={0}
              _focusVisible={{
                outline: "none",
              }}
              isDisabled={count < maxTemp ? false : true}
              onClick={() => setCount(count + 1)}
            >
              <Icon as={IoIosArrowForward} w={8} h={8} />
            </Button>
          </HStack>
        </VStack>

        <VStack p={"6"} spacing={6} w={"sm"}>
          <VStack w={"full"} justify={"center"} align={"center"}>
            <HStack w={"full"}>
              <Text fontSize={15} fontWeight={"medium"}>
                Fan speed
              </Text>
              <Divider w={"52"} />
            </HStack>
            <HStack
              spacing={3}
              w={"full"}
              pb={"4"}
              px={"0.5"}
              flexWrap={"nowrap"}
              display={"flex"}
              overflowX={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  height: "6px",
                  borderRadius: "8px",
                  backgroundColor:
                    colorMode === "dark"
                      ? `rgba(255, 255, 255, 0.3)`
                      : `rgba(0, 0, 0, 0.05)`,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor:
                    colorMode === "dark"
                      ? `rgba(255, 255, 255, 0.8)`
                      : `rgba(0, 0, 0, 0.05)`,
                  borderRadius: "8px",
                },
              }}
            >
              {fanSpeedArray.map((e, index) => (
                <Center
                  className="btn"
                  transition={"ease-in-out .3s"}
                  key={index}
                  flex={"0 0 auto"}
                  w={"28"}
                  shadow={"md"}
                  rounded={"full"}
                  py={1}
                  cursor={"pointer"}
                  borderWidth={"thin"}
                  borderColor={
                    e === fanSpeed
                      ? "#45A735"
                      : colorMode === "dark"
                      ? "#2f2f2f"
                      : "#fff"
                  }
                  _hover={{
                    shadow: "lg",
                  }}
                  onClick={() => setFanSpeed(e)}
                >
                  <Text fontSize={13} fontWeight={"normal"}>
                    {e === 1
                      ? "Lowest"
                      : e === 2
                      ? "Low"
                      : e === 3
                      ? "Medium"
                      : e === 4
                      ? "High"
                      : "Highest"}
                  </Text>
                </Center>
              ))}
            </HStack>
          </VStack>
          <VStack w={"full"} justify={"center"} align={"center"}>
            <HStack w={"full"}>
              <Text fontSize={15} fontWeight={"medium"}>
                Swing vertical
              </Text>
              <Divider w={"52"} />
            </HStack>
            <HStack spacing={4} w={"full"}>
              {config.swingV.map((e, index) => (
                <Center
                  className="btn"
                  transition={"ease-in-out .3s"}
                  key={index}
                  w={"full"}
                  shadow={"md"}
                  rounded={"full"}
                  py={1}
                  borderWidth={"thin"}
                  borderColor={
                    e === swingV
                      ? "#45A735"
                      : colorMode === "dark"
                      ? "#2f2f2f"
                      : "#fff"
                  }
                  cursor={"pointer"}
                  onClick={() => setSwingV(e)}
                  _hover={{
                    shadow: "lg",
                  }}
                >
                  <Text fontSize={13} fontWeight={"normal"}>
                    {e}
                  </Text>
                </Center>
              ))}
            </HStack>
          </VStack>
          <VStack w={"full"} justify={"center"} align={"center"}>
            <HStack w={"full"}>
              <Text fontSize={15} fontWeight={"medium"}>
                Swing horizontal
              </Text>
              <Divider w={"52"} />
            </HStack>
            <HStack spacing={4} w={"full"}>
              {config.swingH.map((e, index) => (
                <Center
                  className="btn"
                  transition={"ease-in-out .3s"}
                  key={index}
                  w={"full"}
                  shadow={"md"}
                  rounded={"full"}
                  py={1}
                  borderWidth={"thin"}
                  borderColor={
                    e === swingH
                      ? "#45A735"
                      : colorMode === "dark"
                      ? "#2f2f2f"
                      : "#fff"
                  }
                  cursor={"pointer"}
                  onClick={() => setSwingH(e)}
                  _hover={{
                    shadow: "lg",
                  }}
                >
                  <Text fontSize={13} fontWeight={"normal"}>
                    {e}
                  </Text>
                </Center>
              ))}
            </HStack>
          </VStack>

          <Center
            transition={"ease-in-out .3s"}
            bg={"#45A735"}
            w={"full"}
            rounded={"md"}
            shadow={"md"}
            p={1.5}
            cursor={"pointer"}
            onClick={setupHandler}
            _hover={{
              shadow: "lg",
            }}
          >
            <Text fontSize={15} color={"#fff"} fontWeight={"normal"}>
              Хадгалах
            </Text>
          </Center>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default IrSetup;
