import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

const PowrValue = ({ value, valueUnit, sm = true }) => {
  return (
    <HStack spacing={2} alignItems={"start"}>
      <Text fontSize={sm ? 26 : 27}>{value}</Text>

      <VStack pt="2">
        <Text fontSize={12} color="#45A735">
          {valueUnit}
        </Text>
      </VStack>
    </HStack>
  );
};

export default PowrValue;
