import React, { useContext, useState } from "react";
import {
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import axios from "../../../axios";
import ttrStatus from "../../../assets/Images/icons/ttrStatus.png";
import ttrStatusDark from "../../../assets/Images/icons/ttrStatusDark.png";
import UserContext from "../../../context/UserContext";
import TtrActSwitch from "./Cards/TtrActSwitch";
import TtrActSwitchStatus from "./Cards/TtrActSwitchStatus";
import TtrNewG4SelectChannel from "./Cards/newG4/TtrNewG4SelectChannel";

const TtrDeviceState = ({ room }) => {
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { ttrAct, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();
  const [selectChannel, setSelectChannel] = useState(false); // if true channeluudiig render hiine (newG4 device deer darahad true bolno gesen ug)
  const [selectedNewG4, setSelectedNewG4] = useState();

  const getDeviceHandler = (value) => {
    if (houseId && value === "state") {
      setIsLoading(true);
      onOpen();
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user/ttr/devices_switch", {
        params: { house: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("device state", result.data.data);
          // console.log("ttrAct", ttrAct);
          const data = result.data.data.filter((e) => {
            if (
              !ttrAct.some((a) => a._id === e._id && a.actionType === "state")
            ) {
              return e;
            }
          });
          setDevices(data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        h={"14"}
        px={"8"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        rounded={"md"}
        align={"center"}
        justify={"space-between"}
        onClick={() => getDeviceHandler("state")}
        cursor={"pointer"}
        _hover={{
          shadow: "md",
        }}
      >
        <HStack>
          <Image
            src={colorMode === "dark" ? ttrStatusDark : ttrStatus}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
          <Text fontSize={15} fontWeight={"normal"}>
            Төлөв
          </Text>
        </HStack>

        <Icon as={IoIosArrowForward} w={"5"} h={"5"} color={"#45A735"} />
      </HStack>

      {/* TODO: zunduu yum hiideg modal shuu lol */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>
            {changeState ? "Төлөв өө сонгоно уу!" : "Төхөөрөмжөө сонгоно уу!"}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setChangeState(false);
              setSelectChannel(false);
            }}
            _focusVisible={{ outline: "none" }}
          />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            {!changeState && !selectChannel ? (
              // TODO: tap to run hiigdeh bolomjtoi tuhuurumjuud
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Center w={"full"} bg={"#C1E8BC"} p={"3"}>
                  <Text as={"i"} fontWeight={"normal"} fontSize={13}>
                    Тухайн төхөөрөмж (автоматжуулалт, багц автоматжуулалт)-г
                    асах, унтрах үйлдлийг удирдах.
                  </Text>
                </Center>
                {isLoading ? (
                  <Center w={"full"}>
                    <Spinner color="#45A735" size="md" />
                  </Center>
                ) : devices.length > 0 ? (
                  devices.map((e, i) => (
                    <TtrActSwitch
                      key={i}
                      data={e}
                      index={i}
                      roomName={
                        room.filter((f) => f._id === e.roomId).length > 0
                          ? room.filter((f) => f._id === e.roomId)[0].name
                          : null
                      }
                      setChangeState={setChangeState}
                      setDevice={setDevice}
                      setSelectChannel={setSelectChannel}
                      setSelectedNewG4={setSelectedNewG4}
                    />
                  ))
                ) : (
                  <VStack
                    w={"full"}
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                  >
                    <Icon as={FiInfo} w={6} h={6} />
                    <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
                  </VStack>
                )}
              </VStack>
            ) : selectChannel ? (
              <TtrNewG4SelectChannel
                selectedNewG4={selectedNewG4}
                room={room}
                setChangeState={setChangeState}
                setDevice={setDevice}
                setSelectChannel={setSelectChannel}
                setSelectedNewG4={setSelectedNewG4}
                onClose={onClose}
              />
            ) : (
              // TODO: select ON or OFF
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Text fontWeight={"normal"} fontSize={15}>
                  Сонгосон төхөөрөмжийн хийгдэх төлөв
                </Text>
                <TtrActSwitchStatus
                  data={device}
                  setChangeState={setChangeState}
                  onClose={onClose}
                />
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TtrDeviceState;
