import React, { useEffect, useState, useContext } from "react";
import {
  HStack,
  Text,
  VStack,
  RadioGroup,
  Radio,
  Input,
  SkeletonText,
  Stack,
  Icon,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../components/Button/BackButton";
import PayButton from "../../components/Payment/PayButton";
import { useLocation } from "react-router-dom";
import { BsInfoCircleFill } from "react-icons/bs";
import axios from "../../axios";
import Cookies from "js-cookie";
import UserContext from "../../context/UserContext";

const PayScreen = () => {
  const [value, setValue] = useState("0");
  const [organizationId, setOrganizationId] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const location = useLocation();
  const toast = useToast();
  const id = "toast";
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (organizationId.length === 7) {
      setIsLoading(true);
      Core.post(
        "/user/qpay/invoice_validate",
        {
          organizationId: organizationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          // console.log("check Id", result.data);
          if (result.data.data) {
            const data = result.data.data;
            setOrganizationName(data.name);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log("err", err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (err.response.status === 400) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Мэдээлэл олдсонгүй",
              });
            }
          } else {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Алдаа гарлаа!",
              });
            }
          }
        });
    } else {
      setOrganizationName("");
    }
  }, [organizationId]);

  return (
    <VStack
      w={"full"}
      h={"full"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack spacing={4}>
        <BackButton />
        <Text fontSize={15} fontWeight={"normal"}>
          Төлбөр
        </Text>
      </HStack>

      <VStack
        w={"full"}
        h={"full"}
        className="btn"
        rounded={"md"}
        align={"center"}
        justify={"center"}
        spacing={4}
      >
        <HStack
          className="settingsInput"
          w={"lg"}
          px={"10"}
          py={"4"}
          align={"center"}
          justify={"space-between"}
          rounded={"md"}
        >
          <Text fontSize={14} fontWeight={"normal"}>
            Программ хөгжүүлэлтийн төлбөр
          </Text>
          <Text fontSize={14} fontWeight={"medium"}>
            {location.state.amount}₮
          </Text>
        </HStack>
        <HStack
          className="settingsInput"
          w={"lg"}
          px={"10"}
          py={"4"}
          align={"center"}
          justify={"space-between"}
          rounded={"md"}
        >
          <Text fontSize={14} fontWeight={"normal"}>
            Хамрагдах хугацаа
          </Text>
          <Text fontSize={14} fontWeight={"medium"}>
            {location.state.expireDate}
          </Text>
        </HStack>
        <RadioGroup onChange={setValue} value={value}>
          <HStack w={"lg"} spacing={4}>
            <HStack
              className="settingsInput"
              w={"full"}
              px={"10"}
              py={"4"}
              align={"center"}
              justify={"space-between"}
              rounded={"md"}
            >
              <Radio
                value="1"
                colorScheme="green"
                _focusVisible={{
                  outline: "none",
                }}
              >
                <Text fontSize={14} fontWeight={"normal"}>
                  Хувь хүн
                </Text>
              </Radio>
            </HStack>
            <HStack
              className="settingsInput"
              w={"full"}
              px={"10"}
              py={"4"}
              align={"center"}
              justify={"space-between"}
              rounded={"md"}
            >
              <Radio
                value="2"
                colorScheme="green"
                _focusVisible={{
                  outline: "none",
                }}
              >
                <Text fontSize={14} fontWeight={"normal"}>
                  Байгууллага
                </Text>
              </Radio>
            </HStack>
          </HStack>
        </RadioGroup>
        {value === "2" ? (
          <HStack
            w={"lg"}
            bg={"#D7FCD0"}
            borderWidth={"thin"}
            borderColor={"#45A735"}
            px={"10"}
            py={"4"}
            align={"center"}
            justify={"center"}
            rounded={"md"}
          >
            <Icon as={BsInfoCircleFill} w={5} h={5} color={"#6A6A6A"} />
            <Text
              as={"i"}
              color={"#565656"}
              fontSize={13}
              fontWeight={"normal"}
            >
              Таны И-баримт ажлын 3-5хоногт шивэгдэхийг анхаарна уу!
            </Text>
          </HStack>
        ) : null}
        {value === "2" ? (
          <HStack
            className="settingsInput"
            w={"lg"}
            px={"10"}
            py={"4"}
            align={"center"}
            justify={"space-between"}
            rounded={"md"}
            spacing={4}
          >
            <Input
              w={"full"}
              placeholder="-"
              type={"number"}
              maxLength={7}
              fontSize={15}
              fontWeight={"normal"}
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              autoFocus
              _focusVisible={{
                outline: "none",
                borderColor: "#45A735",
              }}
              _hover={{
                borderColor: "#45A735",
              }}
              isDisabled={isLoading ? true : false}
            />
            {isLoading ? (
              <Stack w={"full"} justify={"center"} align={"flex-end"}>
                <SkeletonText
                  w={"20"}
                  noOfLines={1}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Stack>
            ) : organizationName.length > 0 ? (
              <Text
                w={"full"}
                textAlign={"right"}
                fontSize={15}
                fontWeight={"medium"}
              >
                {organizationName}
              </Text>
            ) : (
              <Text
                w={"full"}
                textAlign={"right"}
                fontSize={13}
                fontWeight={""}
                lineHeight={"5"}
              >
                Байгууллагын регистрын дугаараа оруулна уу!
              </Text>
            )}
          </HStack>
        ) : null}
        <PayButton
          radio={value}
          organizationId={organizationId}
          setOrganizationId={setOrganizationId}
          organizationName={organizationName}
          invoiceId={location.state.id}
        />
      </VStack>
    </VStack>
  );
};

export default PayScreen;
