import React, { useContext, useState } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import home from "../../assets/Images/icons/home.png";
import homeDark from "../../assets/Images/icons/homeDark.png";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import Cookies from "js-cookie";
import axios from "../../axios";
import UserContext from "../../context/UserContext";
import DeleteHouse from "./DeleteHouse";

const ControlCard = ({ data, setTrigger, housesCount }) => {
  const [houseName, setHouseName] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setHouseTrigger, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = () => {
    if (houseName) {
      setIsLoading(true);

      let source = Core.CancelToken.source();
      Core.post(
        "/user_v2/house/update",
        {
          houseId: data._id,
          name: houseName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log(res.data);
          onClose();
          setTrigger(true);
          setHouseTrigger(true);
          setHouseName("");
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай засагдлаа",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <HStack
      className="settingsInput"
      w={"lg"}
      justify={"space-between"}
      align={"center"}
      shadow={"md"}
      rounded={"xl"}
      py={4}
      px={6}
    >
      <HStack>
        <Center
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          w={"10"}
          h={"10"}
          rounded={"lg"}
          border={
            colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
          }
        >
          <Image
            src={colorMode === "dark" ? homeDark : home}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </Center>
        <Text fontSize={15} fontWeight={"normal"}>
          {data.name}
        </Text>
      </HStack>
      <HStack>
        <Center
          bg={colorMode === "dark" ? "#252B25" : "#fff"}
          w={"10"}
          h={"10"}
          rounded={"lg"}
          border={
            colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
          }
          onClick={() => onOpen()}
          cursor={"pointer"}
        >
          <Image
            src={colorMode === "dark" ? pencilDark : pencil}
            w={"4"}
            h={"4"}
            objectFit={"contain"}
          />
        </Center>
        <DeleteHouse
          housesCount={housesCount}
          data={data}
          setTrigger={setTrigger}
        />
      </HStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Байшингийн нэр солих</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Солих нэрээ оруулна уу
              </FormLabel>
              <Input
                placeholder={data.name}
                maxLength={30}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={houseName}
                onChange={(e) => setHouseName(e.target.value)}
                focusBorderColor={"#45A735"}
                autoFocus
                isDisabled={isLoading}
                onKeyDown={(e) => {
                  if (houseName && !isLoading) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={houseName && !isLoading ? false : true}
              isLoading={isLoading}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default ControlCard;
