import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  Flex,
  Stack,
} from "@chakra-ui/react";
import tap from "../../assets/Images/icons/tap.png";
import tapDark from "../../assets/Images/icons/tapDark.png";
import sun from "../../assets/Images/icons/sun.png";
import moon from "../../assets/Images/icons/moon.png";
import settings from "../../assets/Images/icons/automation.png";
import settingsDark from "../../assets/Images/icons/automationDark.png";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import Cookies from "js-cookie";
import axios from "../../axios";
import UserContext from "../../context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import DeviceControl from "./DeviceControl";
import AuthContext from "../../hooks/UseAuth";
import socket from "../../hooks/Socket";
import { BiMenuAltLeft } from "react-icons/bi";
import SideBarMobile from "../Sidebar/SideBarMobile";

const Header = () => {
  const [houses, setHouses] = useState([]);
  const { colorMode, toggleColorMode } = useColorMode();
  const token = Cookies.get("accessToken");
  const location = useLocation();
  const history = useHistory();
  const {
    house,
    setHouse,
    houseTrigger,
    setHouseTrigger,
    showTtr,
    setShowTtr,
    setAutoCon,
    setAutoAct,
  } = useContext(UserContext);
  const { logoutHandler } = useContext(AuthContext);
  const [showSideBar, setShowSidebar] = useState(false);
  const { Core } = axios();
  const houseId = Cookies.get("house");

  useEffect(() => {
    if (
      (location.pathname === "/home" ||
        location.pathname === "/home/newG4-control") &&
      socket &&
      socket.connected === false
    ) {
      socket.connect();
      socket.emit("initial", houseId);
    }
    if (
      location.pathname !== "/home" &&
      location.pathname !== "/home/wled-control" &&
      location.pathname !== "/home/switch-detail" &&
      location.pathname !== "/home/zbAqi-control" &&
      location.pathname !== "/home/sensor-detail" &&
      location.pathname !== "/home/aqs-control" &&
      location.pathname !== "/home/sensor-detail" &&
      location.pathname !== "/home/aqs-chart" &&
      location.pathname !== "/home/aqi-chart" &&
      location.pathname !== "/home/newG4-control" &&
      socket &&
      socket.connected === true
    ) {
      socket.disconnect();
      console.log("disconnect", socket.connected);
    }
  }, [location]);

  // ----------------------------------- Get house -----------------------------------
  useEffect(() => {
    if (houseTrigger) {
      const houseId = Cookies.get("house");
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get(
        "/user/house",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log(result.data.data);
          if (cancel) return;
          setHouses(result.data.data);
          setHouseTrigger(false);
          if (houseId) {
            const data = result.data.data.find((e) => e._id === houseId);
            if (data) {
              setHouse(data);
              Cookies.set("house", data._id);
            } else {
              const houseData = result.data.data.find(
                (e) => e.isInitial === false
              );
              setHouse(houseData);
              Cookies.set("house", houseData._id);
            }
          } else {
            const data = result.data.data.find((e) => e.isInitial === true);
            if (data) {
              setHouse(data);
              Cookies.set("house", data._id);
            } else {
              const houseData = result.data.data.find(
                (e) => e.isInitial === false
              );
              setHouse(houseData);
              Cookies.set("house", houseData._id);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [houseTrigger]);

  const selectHouseHandler = (value) => {
    setHouse(value);
    Cookies.set("house", value._id);
    setHouseTrigger(true);
    history.push("/home");

    setAutoCon([]);
    setAutoAct([]);
  };

  const toggleSidebar = () => {
    if (showSideBar === true) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  };

  return (
    <>
      <SideBarMobile showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
      <HStack w={"full"} h={"20"} align={"center"} justify={"space-between"}>
        <Stack
          spacing={4}
          direction={["row", "row", "row", "row", "row-reverse"]}
        >
          <Flex display={["flex", "flex", "flex", "flex", "none"]}>
            <IconButton
              transition={"ease-in-out .3s"}
              bg={colorMode === "dark" ? "#252B25" : "#fff"}
              rounded={"lg"}
              icon={<Icon as={BiMenuAltLeft} w={6} h={6} color="#000" />}
              _hover={{
                bg: colorMode === "dark" ? "#252B25" : "#fff",
                shadow: "md",
              }}
              _focusVisible={{
                outline: "none",
                bg: colorMode === "dark" ? "#252B25" : "#fff",
              }}
              onClick={toggleSidebar}
            />
          </Flex>
          <HStack spacing={4}>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    transition={"ease-in-out .3s"}
                    bg={colorMode === "dark" ? "#252B25" : "#fff"}
                    fontSize={15}
                    fontWeight={"medium"}
                    rounded={"lg"}
                    isActive={isOpen}
                    as={Button}
                    rightIcon={
                      <Icon
                        as={
                          isOpen
                            ? MdOutlineKeyboardArrowUp
                            : MdOutlineKeyboardArrowDown
                        }
                      />
                    }
                    _hover={{
                      bg: colorMode === "dark" ? "#252B25" : "#fff",
                      shadow: "md",
                    }}
                    _focusVisible={{
                      outline: "none",
                      bg: colorMode === "dark" ? "#252B25" : "#fff",
                    }}
                    _expanded={{
                      bg: colorMode === "dark" ? "#252B25" : "#fff",
                    }}
                  >
                    {house?.name}
                  </MenuButton>
                  <MenuList bg={colorMode === "dark" ? "#252B25" : "#fff"}>
                    {houses
                      .filter((e) => e._id !== house._id)
                      .map((e, index) => (
                        <MenuItem
                          key={index}
                          fontSize={15}
                          fontWeight={"normal"}
                          onClick={() => selectHouseHandler(e)}
                        >
                          {e.name}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
            <IconButton
              transition={"ease-in-out .3s"}
              bg={colorMode === "dark" ? "#252B25" : "#fff"}
              rounded={"lg"}
              icon={
                <Image
                  src={colorMode === "dark" ? settingsDark : settings}
                  w={"5"}
                  h={"5"}
                  objectFit={"contain"}
                />
              }
              _hover={{
                bg: colorMode === "dark" ? "#252B25" : "#fff",
                shadow: "md",
              }}
              _focusVisible={{
                outline: "none",
                bg: colorMode === "dark" ? "#252B25" : "#fff",
              }}
              onClick={() => history.push("/home/house-control")}
            />
          </HStack>
        </Stack>
        <HStack spacing={4}>
          <DeviceControl />
          <Center
            className="headerBtn"
            transition={"ease-in-out .3s"}
            w={"12"}
            h={"12"}
            rounded={"full"}
            onClick={() => setShowTtr(!showTtr)}
            cursor={"pointer"}
            _focusVisible={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
            display={location.pathname === "/home" ? "flex" : "none"}
          >
            <Image
              src={colorMode === "dark" ? tapDark : tap}
              w={"6"}
              h={"6"}
              objectFit={"contain"}
            />
          </Center>
          <Center
            className="headerBtn"
            transition={"ease-in-out .3s"}
            w={"12"}
            h={"12"}
            rounded={"full"}
            onClick={() => toggleColorMode()}
            cursor={"pointer"}
            _focusVisible={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Image
              src={colorMode === "dark" ? moon : sun}
              w={"6"}
              h={"6"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      </HStack>
    </>
  );
};

export default Header;
