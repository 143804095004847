import React, { useContext, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ir from "../../../assets/Images/CardIcons/ir.png";
import irDark from "../../../assets/Images/CardIcons/irDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";

const Ir = ({
  roomName,
  status,
  id,
  deviceName,
  isOriginal,
  origin,
  deviceType,
  wifiSignal,
  notifEnabled,
  subDeviceCount,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    subDevice: subDeviceCount,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  const history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      onClick={() =>
        history.push("/home/ir-dash", {
          roomName: datas.room,
          status: datas.status,
          id,
          deviceName: datas.name,
          isOriginal,
          origin,
          deviceType,
          wifiSignal,
          notifEnabled: datas.notifEnabled,
          subDeviceCount: datas.subDevice,
          blocked,
        })
      }
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack w={"full"}>
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? irDark : ir}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Text
            pt={2}
            w={"full"}
            fontWeight={"normal"}
            fontSize={15}
            align={"end"}
          >
            Нийт төхөөрөмж:{" "}
            <Text as={"span"} fontWeight={"medium"} fontSize={15}>
              {datas.subDevice}
            </Text>
          </Text>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Ir;
