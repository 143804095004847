import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        color: mode("#000", "#fff")(props),
        bg: mode("#FBFBFB", "#202420")(props),
        fontSize: mode("15", "15")(props),
      },
      ".header": {
        bg: mode("#F3F3F3", "#1D211D")(props),
      },
      ".headerBtn": {
        bg: mode("#fff", "#252B25")(props),
        color: mode("#000", "#fff")(props),
      },
      ".sidebar": {
        bg: mode("#FFFFFF", "#252B25")(props),
        color: mode("#000", "#fff")(props),
      },
      ".bgColor": {
        bg: mode("#F8F8F8", "#252B25")(props),
      },
      ".btn": {
        bg: mode("#fff", "#252B25")(props),
        color: mode("#000", "#fff")(props),
      },
      ".card": {
        bg: mode("#FFF", "#252B25")(props),
        color: mode("#000", "#FFF")(props),
      },
      ".settingsInput": {
        bg: mode("#FBFBFB", "#1D211D")(props),
        color: mode("#000", "#fff")(props),
      },
      ".notifCard": {
        bg: mode("#fff", "#252B25")(props),
        color: mode("#000", "#fff")(props),
      },
      ".gray": {
        bg: mode("#FBFBFB", "#1D211D")(props),
        color: mode("#000", "#fff")(props),
      },
      ".rgb": {
        bg: mode("#fff", "#252B25")(props),
      },
    }),
  },
});

export default theme;
