import React, { useContext, useEffect, useRef, useState } from "react";
import {
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import SimpleSwitch from "../../components/Cards/Regular/SimpleSwitch";
import SwitchKnx from "../../components/Cards/Knx/SwitchKnx";
import Th from "../../components/Cards/Regular/Th";
import Bridge from "../../components/Cards/Zigbee/Bridge";
import Contact from "../../components/Cards/Zigbee/Contact";
import Smoke from "../../components/Cards/Zigbee/Smoke";
import ZigbeeTh from "../../components/Cards/Zigbee/ZigbeeTh";
import Ir from "../../components/Cards/Ir/Ir";
import Powr from "../../components/Cards/Regular/Powr/Powr";
import Motion from "../../components/Cards/Zigbee/Motion";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Water from "../../components/Cards/Zigbee/Water";
import RGB from "../../components/Cards/Zigbee/RGB";
import LoraSMTC from "../../components/Cards/Lora/LoraSMTC";
import LoraTh from "../../components/Cards/Lora/LoraTh";
import LoraLight from "../../components/Cards/Lora/LoraLight";
import socket from "../../hooks/Socket";
import UserContext from "../../context/UserContext";
import TaptoRun from "./TaptoRun";
import AuthContext from "../../hooks/UseAuth";
import Co2 from "../../components/Cards/Zlan/Co2";
import So2 from "../../components/Cards/Zlan/So2";
import No2 from "../../components/Cards/Zlan/No2";
import Dust from "../../components/Cards/Zlan/Dust";
import Carbon from "../../components/Cards/Regular/Carbon";
import Curtain from "../../components/Cards/Zigbee/Curtain";
import G4 from "../../components/Cards/G4/G4";
import Switchsc from "../../components/Cards/Diy/Switchsc";
import G4adb from "../../components/Cards/G4/G4adb";
import Wled from "../../components/Cards/Regular/Wled";
import ZbSwitch from "../../components/Cards/Zigbee/ZbSwitch";
import ZbAqi from "../../components/Cards/Zigbee/ZbAqi";
import ThSc from "../../components/Cards/Diy/ThSc";
import Aqs from "../../components/Cards/Regular/Sensor/Aqs";
import NewG4 from "../../components/Cards/G4/NewG4";
import HumanDetector from "../../components/Cards/Zigbee/HumanDetector";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [room, setRoom] = useState([]);
  const [devices, setDevices] = useState([]);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { showTtr } = useContext(UserContext);
  const { logoutHandler } = useContext(AuthContext);
  const { Core } = axios();

  // --------------------------- Get room -----------------------------
  useEffect(() => {
    setRoom([]);
    setIsLoading(true);
    if (houseId) {
      // setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // setIsLoading(false);
          // console.log("Room--------->", result.data.data);
          const data = [
            {
              _id: "all",
              contractId: null,
              key: "1",
              name: "Бүгд",
              house: null,
            },
          ];

          const datas = result.data.data.map(
            ({ _id, contractId, key, name, house }) => {
              return {
                house,
                _id,
                contractId,
                key: (key + 2).toString(),
                name,
              };
            }
          );

          const rooms = data.concat(datas);
          rooms.sort((a, b) => (a.key, b.key ? 1 : b.key > a.key ? -1 : 0));
          setRoom(rooms);
        })
        .catch((err) => {
          // setIsLoading(false);
          // console.log(err.response);
          // if (err.response.status === 401) {
          //   logoutHandler();
          // }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [houseId]);

  // ---------------------------- Get device --------------------------------
  useEffect(() => {
    if (houseId && room.length > 0) {
      // setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/device/device_data_new", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          // console.log(result.data.data);
          if (cancel) return;
          const deviceData = result.data.data.sort((a, b) =>
            a.key > b.key ? 1 : b.key > a.key ? -1 : 0
          );
          // setDevices(deviceData);
          const dataArr = room.map((e) => {
            return {
              id: e._id,
              title: e.name,
              datas:
                e._id === "all"
                  ? deviceData
                  : deviceData.filter((f) => f.roomId === e._id),
            };
          });
          setDevices(dataArr);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          // if (err.response.status === 401) {
          //   logoutHandler();
          // }
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [room]);

  const ref = useRef(null);

  const scroll = (value) => {
    ref.current.scrollLeft += value;
  };

  useEffect(() => {
    if (houseId) {
      if (socket.connected) {
        socket.emit("initial", houseId);
      } else {
        const tryAgainMech = setInterval(() => {
          if (socket.connected === true && houseId) {
            socket.emit("initial", houseId);
            clearInterval(tryAgainMech);
          }
        }, 2000);
      }

      // -------------------- TODO: Tohooromj nemhed sonsoh heseg ----------------------------
      const socketSetData = (data) => {
        // console.log("new Device", data);

        setDevices((prevState) => {
          let allDevices = prevState.find((e) => e.id === "all").datas;
          allDevices.push(data);

          let roomDevices;

          if (prevState.find((e) => e.id === data.roomId)) {
            roomDevices = prevState.find((e) => e.id === data.roomId).datas;
            roomDevices.push(data);
          }

          const updatedDevices = prevState.map((d) => {
            if (d.id === "all") {
              return { ...d, datas: allDevices };
            }

            if (d.id === data.roomId) {
              return { ...d, datas: roomDevices };
            }
            return d;
          });

          return updatedDevices;
        });

        socket.emit("initial", houseId);
      };
      socket.on("new", socketSetData);
      return () => {
        socket.off("new", socketSetData);
      };
    }
  }, [houseId]);

  return (
    <VStack w={"full"} pt={4}>
      {showTtr ? (
        <TaptoRun isLoading={isLoading} setIsLoading={setIsLoading} />
      ) : (
        <VStack w={"full"}>
          {isLoading ? (
            <Spinner size="lg" color="#45A735" mt="25vh" />
          ) : houseId && room.length > 0 && devices.length > 0 ? (
            <Tabs variant="unstyled" w={"full"} scrollBehavior="smooth">
              <HStack
                justifyContent="space-between"
                alignItems="center"
                transition={"ease-in-out .3s"}
                w={"full"}
              >
                <IconButton
                  icon={<IoIosArrowBack color="#45A735" />}
                  rounded="md"
                  bg={colorMode === "dark" ? "#252B25" : "#fff"}
                  boxShadow="md"
                  onClick={() => scroll(-200)}
                  size="xs"
                  _focusVisible={{ outline: "none" }}
                />
                <TabList ref={ref} overflowX="hidden" w="full">
                  {room.map((e, index) => (
                    <Tab
                      key={index}
                      flex={"0 0 auto"}
                      fontWeight={"normal"}
                      fontSize={14}
                      _selected={{
                        fontWeight: "medium",
                        borderBottom: "2px solid #45A735",
                      }}
                      _focusVisible={{ outline: "none" }}
                      _active={{ background: "none" }}
                      mr={{ sm: 2, md: 6, lg: 8 }}
                    >
                      {e.name}
                    </Tab>
                  ))}
                </TabList>
                <IconButton
                  icon={<IoIosArrowForward color="#45A735" />}
                  rounded="md"
                  bg={colorMode === "dark" ? "#252B25" : "#fff"}
                  boxShadow="md"
                  onClick={() => scroll(200)}
                  size="xs"
                  _focusVisible={{ outline: "none" }}
                />
              </HStack>

              <TabPanels>
                {devices.map((e, index) => (
                  <TabPanel
                    // h="80vh"
                    key={index}
                    pb={12}
                    px={1}
                    // overflowY="scroll"
                  >
                    {e.datas.length > 0 ? (
                      <SimpleGrid
                        columns={[1, 1, 2, 2, 4, 5]}
                        spacingY={6}
                        spacingX={8}
                      >
                        {e.datas.map((a, i) =>
                          a.deviceType === "switch" ? (
                            <SimpleSwitch
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              switchStatus={a.switchStatus}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              deviceType={a.deviceType}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "th" ? (
                            <Th
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              tempValue={
                                a.status === "active" ? a.data.Temperature : 0
                              }
                              humiValue={
                                a.status === "active" ? a.data.Humidity : 0
                              }
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              deviceType={a.deviceType}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "co" ? (
                            <Carbon
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              carbonValue={
                                a.status === "active" ? a.data.CarbonDioxide : 0
                              }
                              tempValue={
                                a.status === "active" ? a.data.Temperature : 0
                              }
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              deviceType={a.deviceType}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "knx_switch" ? (
                            <SwitchKnx
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              switchStatus={a.switchStatus}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              deviceType={a.deviceType}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "zbridge" ? (
                            <Bridge
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              subDeviceCount={a.subDeviceCount}
                            />
                          ) : a.deviceType === "Contact" ? (
                            <Contact
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              alarmStatus={
                                a.status === "active" ? a.alarmStatus : null
                              }
                              contactStatus={
                                a.status === "active" ? a.contactStatus : null
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              batteryPercentage={a.batteryPercentage}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Smoke" ? (
                            <Smoke
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              alarmStatus={
                                a.status === "active" ? a.alarmStatus : null
                              }
                              smokeStatus={
                                a.status === "active" ? a.smokeStatus : null
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              batteryPercentage={a.batteryPercentage}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Occupancy" ? (
                            <Motion
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              alarmStatus={
                                a.status === "active" ? a.alarmStatus : null
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              batteryPercentage={a.batteryPercentage}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "zigbeeTh" ? (
                            <ZigbeeTh
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              tempValue={
                                a.status === "active" ? a.data.Temperature : "0"
                              }
                              humiValue={
                                a.status === "active" ? a.data.Humidity : "0"
                              }
                              alarmStatus={
                                a.status === "active" ? a.alarmStatus : null
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              batteryPercentage={a.batteryPercentage}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Water" ? (
                            <Water
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              alarmStatus={
                                a.status === "active" ? a.alarmStatus : null
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              batteryPercentage={a.batteryPercentage}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "RGB" ? (
                            <RGB
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              power={
                                a.status === "active" ? a.data.Power : null
                              }
                              dimmer={
                                a.status === "active" ? a.data.dimmer : null
                              }
                              hue={a.status === "active" ? a.data.hue : null}
                              sat={a.status === "active" ? a.data.sat : null}
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "ir" ? (
                            <Ir
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              subDeviceCount={a.subDeviceCount}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "powr" ? (
                            <Powr
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              voltage={
                                a.status === "active" ? a.data.Voltage : "0"
                              }
                              current={
                                a.status === "active" ? a.data.Current : "0"
                              }
                              power={a.status === "active" ? a.data.Power : "0"}
                              ApparentPower={
                                a.status === "active"
                                  ? a.data.ApparentPower
                                  : "0"
                              }
                              ReactivePower={
                                a.status === "active"
                                  ? a.data.ReactivePower
                                  : "0"
                              }
                              Factor={
                                a.status === "active" ? a.data.Factor : "0"
                              }
                              Today={a.status === "active" ? a.data.Today : "0"}
                              Yesterday={
                                a.status === "active" ? a.data.Yesterday : "0"
                              }
                              Total={a.status === "active" ? a.data.Total : "0"}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              subDeviceCount={a.subDeviceCount}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "smtc" ? (
                            <LoraSMTC
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              temp={
                                a.status === "active" ? a.data.temperature : "0"
                              }
                              humi={
                                a.status === "active" ? a.data.humidity : "0"
                              }
                              ec={a.status === "active" ? a.data.ec : "0"}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "lth" ? (
                            <LoraTh
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              temp={
                                a.status === "active" ? a.data.temperature : "0"
                              }
                              humi={
                                a.status === "active" ? a.data.humidity : "0"
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "lgt" ? (
                            <LoraLight
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              illumination={
                                a.status === "active"
                                  ? a.data.illumination
                                  : "0"
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Co2" ? (
                            <Co2
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              data={a.status === "active" ? a.data.data : null}
                              notifEnabled={a.notifEnabled}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "So2" ? (
                            <So2
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              data={a.status === "active" ? a.data.data : null}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "No2" ? (
                            <No2
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              data={a.status === "active" ? a.data.data : null}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Dust" ? (
                            <Dust
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              data={a.status === "active" ? a.data.data : null}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Curtain" ? (
                            <Curtain
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "g4" ? (
                            <G4
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "G4ADB" ? (
                            <G4adb
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "Switchsc" ? (
                            <Switchsc
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              switchStatus={a.data}
                            />
                          ) : a.deviceType === "WLED" ? (
                            <Wled
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              color={a.data.color}
                              dimmer={a.data.dimmer}
                              mode={a.data.mode}
                              state={a.data.state}
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              clientId={a.clientId}
                            />
                          ) : a.deviceType === "zbSwitch" ? (
                            <ZbSwitch
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              switchStatus={a.data}
                            />
                          ) : a.deviceType === "zbAqi" ? (
                            <ZbAqi
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              data={a.data}
                            />
                          ) : a.deviceType === "thsc" ? (
                            <ThSc
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              origin={a.origin}
                              deviceType={a.deviceType}
                              temp={
                                a.status === "active" ? a.data.Temperature : "0"
                              }
                              humi={
                                a.status === "active" ? a.data.Humidity : "0"
                              }
                              Sensor={
                                a.status === "active" ? a.data.Sensor : ""
                              }
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                            />
                          ) : a.deviceType === "aqs" ? (
                            <Aqs
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              data={a.data}
                            />
                          ) : a.deviceType === "newG4" ? (
                            <NewG4
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              wifiSignal={
                                a.status === "active" ? a.wifiSignal : null
                              }
                              notifEnabled={a.notifEnabled}
                              blocked={a.blocked}
                              totalChannels={a.totalChannels}
                              totalOff={a.totalOff}
                              totalOn={a.totalOn}
                            />
                          ) : a.deviceType === "humanDetector" ? (
                            <HumanDetector
                              key={a._id}
                              roomName={
                                room.filter((f) => f._id === a.roomId).length >
                                0
                                  ? room.filter((f) => f._id === a.roomId)[0]
                                      .name
                                  : null
                              }
                              deviceName={a.for}
                              status={a.status}
                              id={a._id}
                              isOriginal={a.isOriginal}
                              deviceType={a.deviceType}
                              origin={a.origin}
                              notifEnabled={a.notifEnabled}
                              alarmStatus={a.alarmStatus}
                              blocked={a.blocked}
                              data={a.data}
                            />
                          ) : null
                        )}
                      </SimpleGrid>
                    ) : (
                      <VStack
                        justifyContent="center"
                        alignItems="center"
                        color="gray.600"
                        // w={{ base: "72", md: "xs", lg: "full" }}
                        spacing={1}
                        mt={"28"}
                      >
                        <Icon as={FiInfo} w={7} h={7} />
                        <Text fontSize={15} fontWeight={"normal"}>
                          Мэдээлэл олдсонгүй.
                        </Text>
                      </VStack>
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              color="gray.600"
              // w={{ base: "72", md: "xs", lg: "full" }}
              spacing={1}
            >
              <Icon as={FiInfo} w={7} h={7} />
              <Text fontSize={15} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default Home;
