import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Switch,
  Box,
  Icon,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../../components/Button/BackButton";
import DeviceName from "../../../../../components/Cards/DeviceDetail/DeviceName";
import DeviceWifiSignal from "../../../../../components/Cards/DeviceDetail/DeviceWifiSignal";
import DeviceRoom from "../../../../../components/Cards/DeviceDetail/DeviceRoom";
import DeviceType from "../../../../../components/Cards/DeviceDetail/DeviceType";
import DeviceShared from "../../../../../components/Cards/DeviceDetail/DeviceShared";
import DeviceNotif from "../../../../../components/Cards/DeviceDetail/DeviceNotif";

import { TbArrowBackUp } from "react-icons/tb";
import { useHistory } from "react-router-dom";

const CurtainDetail = () => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const [color, setColor] = useState(location.state.color);
  const [power, setPower] = useState(location.state.power);
  const history = useHistory();

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack spacing={4} w="full" justify={"start"}>
        {location.state.deviceType === "RGB" ? (
          <IconButton
            transition={"ease-in-out .3s"}
            bg={colorMode === "dark" ? "#252B25" : "#fff"}
            rounded={"lg"}
            icon={
              <Icon
                as={TbArrowBackUp}
                color={colorMode === "dark" ? "#fff" : "#000"}
                w={5}
                h={5}
              />
            }
            _hover={{
              bg: "#fff",
              shadow: "md",
            }}
            _focusVisible={{
              outline: "none",
              bg: "#fff",
            }}
            onClick={() =>
              history.push("/home/rgb-dash", {
                roomName: location.state.roomName,
                deviceName: location.state.deviceName,
                power: power,
                dimmer: (location.state.dimmer * 254) / 100,
                hue: location.state.hue,
                sat: location.state.sat,
                status: location.state.status,
                id: location.state.id,
                isOriginal: location.state.isOriginal,
                origin: location.state.origin,
                wifiSignal: location.state.wifiSignal,
                notifEnabled: location.state.notifEnabled,
                deviceType: location.state.deviceType,
              })
            }
          />
        ) : (
          <BackButton />
        )}

        <Text fontWeight={"normal"}>Төхөөрөмжийн дэлгэрэнгүй</Text>
      </HStack>

      {/*     BODY    */}
      <Stack
        justify={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "center",
        ]}
        rounded={"md"}
        px={20}
        h="full"
        alignItems={"start"}
        pt={[6, 6, 6, 6, 10]}
        w="full"
        spacing={[4, 4, 4, 4, 8]}
        direction={["column", "column", "column", "column", "row"]}
      >
        {/* COLUMN 1  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceName deviceName={location.state.deviceName} />
          {/*    Утга    */}
          {location.state.status === "active" ? null : (
            <HStack
              spacing={5}
              transition={"ease-in-out .3s"}
              maxW={["full", "full", "full", "full", "96"]}
              w="full"
              h={"20"}
              px={"8"}
              bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
              borderWidth={"medium"}
              borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              align={"center"}
              justify={"space-between"}
              _focusVisible={{
                outline: "none",
              }}
            >
              <Text>Утга</Text>

              <Text color="#ff0000" fontWeight={"medium"}>
                Холболтгүй
              </Text>
            </HStack>
          )}
        </VStack>

        {/* COLUMN 2 */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceWifiSignal wifiSignal={location.state.wifiSignal} />
          <DeviceRoom roomName={location.state.roomName} />
          <DeviceType deviceType="Удирдлага" />
        </VStack>

        {/* COLUMN 3  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceShared
            isOriginal={location.state.isOriginal}
            origin={location.state.origin}
          />
          <DeviceNotif
            blocked={location.state.blocked}
            id={location.state.id}
            notifEnabled={location.state.notifEnabled}
          />
        </VStack>

        {/*   BODY  END    */}
      </Stack>
    </VStack>
  );
};

export default CurtainDetail;
