import React, { useContext, useState } from "react";
import {
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import axios from "../../../axios";
import UserContext from "../../../context/UserContext";
import notifOn from "../../../assets/Images/icons/notifOn.png";
import notifOnDark from "../../../assets/Images/icons/notifOnDark.png";
import TtrActNotifStatus from "./Cards/TtrActNotifStatus";
import TtrActNotif from "./Cards/TtrActNotif";

const TtrDeviceNotif = ({ room }) => {
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { ttrAct, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  const getDeviceHandler = (value) => {
    if (houseId && value === "notif") {
      setIsLoading(true);
      onOpen();
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user/ttr/devices_notification", {
        params: { house: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("device notif", result.data.data);

          const data = result.data.data.filter((e) => {
            if (
              !ttrAct.some((a) => a._id === e._id && a.actionType === "notif")
            ) {
              return e;
            }
          });
          setDevices(data);

          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };
  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        h={"14"}
        px={"8"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        rounded={"md"}
        align={"center"}
        justify={"space-between"}
        onClick={() => getDeviceHandler("notif")}
        cursor={"pointer"}
        _hover={{
          shadow: "md",
        }}
      >
        <HStack>
          <Image
            src={colorMode === "dark" ? notifOnDark : notifOn}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
          <Text fontSize={15} fontWeight={"normal"}>
            Мэдэгдэл
          </Text>
        </HStack>

        <Icon as={IoIosArrowForward} w={"5"} h={"5"} color={"#45A735"} />
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>
            {changeState ? "Төлөв өө сонгоно уу!" : "Төхөөрөмжөө сонгоно уу!"}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setChangeState(false);
            }}
            _focusVisible={{ outline: "none" }}
          />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            {!changeState ? (
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Center w={"full"} bg={"#C1E8BC"} p={"3"}>
                  <Text as={"i"} fontWeight={"normal"} fontSize={13}>
                    Тухайн төхөөрөмж (автоматжуулалт, багц автоматжуулалт)-с
                    ирэх мэдэгдлийг асаах, унтраах
                  </Text>
                </Center>
                {isLoading ? (
                  <Center w={"full"}>
                    <Spinner color="#45A735" size="md" />
                  </Center>
                ) : devices.length > 0 ? (
                  devices.map((e, i) => (
                    <TtrActNotif
                      key={i}
                      data={e}
                      index={i}
                      roomName={
                        room.filter((f) => f._id === e.roomId).length > 0
                          ? room.filter((f) => f._id === e.roomId)[0].name
                          : null
                      }
                      setChangeState={setChangeState}
                      setDevice={setDevice}
                    />
                  ))
                ) : (
                  <VStack
                    w={"full"}
                    justifyContent="center"
                    alignItems="center"
                    color="gray.600"
                  >
                    <Icon as={FiInfo} w={6} h={6} />
                    <Text fontSize={15}>Мэдээлэл олдсонгүй</Text>
                  </VStack>
                )}
              </VStack>
            ) : (
              <VStack
                w={"full"}
                spacing={4}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Text fontWeight={"normal"} fontSize={15}>
                  Сонгосон төхөөрөмжийн хийгдэх төлөв
                </Text>
                <TtrActNotifStatus
                  data={device}
                  setChangeState={setChangeState}
                  onClose={onClose}
                />
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TtrDeviceNotif;
