import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import AutoDevice from "../../../assets/Images/icons/AutoDevice.png";
import AutoDeviceDark from "../../../assets/Images/icons/AutoDeviceDark.png";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import TtrDeviceState from "./TtrDeviceState";
import TtrDeviceNotif from "./TtrDeviceNotif";
import TtrDeviceAlarm from "./TtrDeviceAlarm";
import UserContext from "../../../context/UserContext";

const TtrAddDevice = () => {
  const [room, setRoom] = useState([]);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (houseId) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          let data = result.data.data;
          setRoom(data);
        })
        .catch((err) => {
          // console.log("room", err);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, []);

  return (
    <Accordion w={"full"} allowMultiple>
      <AccordionItem w={{ md: "full", lg: "sm" }} border={"none"}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              transition={"ease-in-out .3s"}
              w={"full"}
              h={"20"}
              px={"8"}
              bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
              borderWidth={"medium"}
              borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
              rounded={"md"}
              shadow={"md"}
              align={"center"}
              justify={"space-between"}
              cursor={"pointer"}
              _hover={{
                shadow: "lg",
              }}
              _focusVisible={{
                outline: "none",
              }}
            >
              <HStack flex={1} textAlign="left">
                <Image
                  src={colorMode === "dark" ? AutoDeviceDark : AutoDevice}
                  w={"5"}
                  h={"5"}
                  objectFit={"contain"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  Төхөөрөмж
                </Text>
              </HStack>
              {isExpanded ? (
                <Icon as={IoIosArrowDown} w={"6"} h={"6"} color={"#45A735"} />
              ) : (
                <Icon
                  as={IoIosArrowForward}
                  w={"6"}
                  h={"6"}
                  color={"#45A735"}
                />
              )}
            </AccordionButton>
            <AccordionPanel py={4}>
              <VStack w={"full"}>
                <TtrDeviceState room={room} />
                <TtrDeviceNotif room={room} />
                <TtrDeviceAlarm room={room} />
              </VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TtrAddDevice;
