import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { FiInfo } from "react-icons/fi";
import { TbGridDots } from "react-icons/tb";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import deviceIcon from "../../../assets/Images/icons/deviceIcon.png";
import deviceIconDark from "../../../assets/Images/icons/deviceIconDark.png";
import UserContext from "../../../context/UserContext";

const DeviceReorder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [room, setRoom] = useState([]);
  const [devices, setDevices] = useState([]);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    if (houseId) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/room/get", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          const data = result.data.data;
          // console.log(data);
          setRoom(data);
        })
        .catch((err) => {
          // console.log(err.response);
        });

      Core.get("/user_v2/device/device_data_new", {
        params: {
          houseId: houseId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          const data = result.data.data;
          // console.log(data);
          setDevices(
            data.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(devices);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setDevices(items);
  };

  const submitHandler = () => {
    if (devices.length > 0) {
      setIsDisable(true);
      let source = Core.CancelToken.source();
      const data = devices.map((e, i) => {
        return {
          id: e._id,
          key: i,
        };
      });
      Core.put(
        "user/device",
        { data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((res) => {
          setIsDisable(false);
          onClose();
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай хадгаллаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <VStack
      flex={1}
      w={"full"}
      spacing={8}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack spacing={4}>
        <BackButton />
        <Text fontSize={15} fontWeight={"normal"}>
          Төхөөрөмжийн дараалал солих
        </Text>
      </HStack>
      <VStack flex={1} w={"full"} maxH={"75vh"} h="80%">
        {isLoading ? (
          <Center w={"full"} pt={"10"}>
            <Spinner size="md" color={"#45A735"} />
          </Center>
        ) : devices.length > 0 ? (
          <VStack spacing={8}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="devices">
                {(provided) => (
                  <VStack
                    flex={1}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    w="lg"
                    px={"2"}
                    pb={"2"}
                    maxH={"65vh"}
                    overflowY="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        borderRadius: "8px",
                        backgroundColor: `rgba(0, 0, 0, 0.05)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: `rgba(69, 167, 53, 0.5)`,
                        borderRadius: "8px",
                      },
                    }}
                  >
                    {devices.map((e, i) => (
                      <Draggable key={e._id} draggableId={e._id} index={i}>
                        {(provided) => (
                          <HStack
                            className="card"
                            boxShadow={"md"}
                            rounded="md"
                            py={3}
                            px={4}
                            w={"full"}
                            justifyContent="space-between"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <HStack w="50%">
                              <Image
                                src={
                                  colorMode === "dark"
                                    ? deviceIconDark
                                    : deviceIcon
                                }
                                w={4}
                                pointerEvents="none"
                              />
                              <Text
                                isTruncated
                                fontSize={15}
                                fontWeight={"normal"}
                              >
                                {e.for}
                              </Text>
                            </HStack>
                            <HStack w="40%" justifyContent="flex-end">
                              <Text fontSize={11} mr={1} mb={-3} isTruncated>
                                {room.find((f) => f._id === e.roomId)
                                  ? room.find((f) => f._id === e.roomId).name
                                  : null}
                              </Text>
                            </HStack>
                          </HStack>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              w={"md"}
              bg={"#45A735"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#fff"}
              onClick={onOpen}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#5AB54B",
              }}
            >
              Хадгалах
            </Button>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size="sm"
              isCentered={true}
              closeOnOverlayClick={false}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Хадгалах уу?</ModalHeader>
                <ModalCloseButton _focusVisible={{ outline: "none" }} />
                <ModalFooter>
                  <Button
                    onClick={onClose}
                    mr={3}
                    _focusVisible={{ outline: "none" }}
                  >
                    Болих
                  </Button>
                  <Button
                    colorScheme="green"
                    _focusVisible={{ outline: "none" }}
                    isDisabled={isDisable}
                    onClick={submitHandler}
                  >
                    {isDisable ? <Spinner mr={2} size="sm" /> : null}
                    Тийм
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </VStack>
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            spacing={1}
            mt={"10"}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default DeviceReorder;
