import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import Cookies from "js-cookie";
import axios from "../../axios";
import UserContext from "../../context/UserContext";

const EditButton = ({ phoneNumber, setTrigger }) => {
  const [sharedDevice, setSharedDevice] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const deviceHandler = () => {
    if (phoneNumber) {
      onOpen();
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/device_share/share_detail", {
        params: {
          houseId: houseId,
          username: phoneNumber,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("share/single", result.data.data);
          const data = result.data.data;
          setSharedDevice(data.filter((f) => f.shared === true));
          setDevices(data.filter((f) => f.shared !== true));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          if (handledHttpErrors.includes(err.response.status)) return;

          // console.log('error --->', err.response.status);
          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  // --- Add, remove device ---
  const addHandler = (item, index) => {
    const clone = devices;
    setDevices(clone.filter((e, i) => i !== index));
    setSharedDevice((prevState) => [...prevState, item]);
  };

  const removeHandler = (item, index) => {
    const clone = sharedDevice;
    setSharedDevice(clone.filter((e, i) => i !== index));
    setDevices((prevState) => [...prevState, item]);
  };
  // --- Add, remove device ---

  sharedDevice.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
  devices.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));

  const submitHandler = () => {
    if (phoneNumber && houseId) {
      setDisabled(true);
      let source = Core.CancelToken.source();
      const data = {
        accounts: phoneNumber.split("-"),
        devices: sharedDevice.map((e) => e._id),
        houseId: houseId,
      };
      Core.post(
        "/user_v2/device_share/share",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          onClose();
          setTrigger(true);
          setDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          setDisabled(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <>
      <Center
        transition={"ease-in-out .3s"}
        bg={colorMode === "dark" ? "#252B25" : "#fff"}
        w={"10"}
        h={"10"}
        rounded={"lg"}
        border={
          colorMode === "dark" ? "1px solid #707070" : "1px solid #DCDCDC"
        }
        onClick={deviceHandler}
        cursor={"pointer"}
        _hover={{
          shadow: "md",
        }}
      >
        <Image
          src={colorMode === "dark" ? pencilDark : pencil}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
        />
      </Center>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>Төхөөрөмж сонгох</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack>
              {isLoading ? (
                <Spinner size="lg" color="#45A735" alignSelf={"center"} />
              ) : (
                <VStack w={"full"} spacing={4}>
                  <VStack w={"full"} align={"flex-start"}>
                    <Text fontSize={15} fontWeight={"medium"}>
                      Хуваалцсан төхөөрөмжүүд
                    </Text>
                    {sharedDevice.length > 0 ? (
                      sharedDevice.map((e, index) => (
                        <HStack
                          key={index}
                          w={"full"}
                          py={"1"}
                          px={"4"}
                          bg={
                            index % 2 === 0
                              ? colorMode === "dark"
                                ? "#1D211D"
                                : "#fff"
                              : colorMode === "dark"
                              ? "#202420"
                              : "#F9F9F9"
                          }
                          rounded={"md"}
                          justify={"space-between"}
                          align={"center"}
                          onClick={() => removeHandler(e, index)}
                          cursor={"pointer"}
                        >
                          <Text fontSize={15} fontWeight={"normal"}>
                            {e.for}
                          </Text>
                          <Image
                            src={checked}
                            w={"4"}
                            h={"4"}
                            objectFit="contain"
                          />
                        </HStack>
                      ))
                    ) : (
                      <Text>Төхөөрөмж байхгүй байна</Text>
                    )}
                  </VStack>
                  <VStack w={"full"} align={"flex-start"}>
                    <Text fontSize={15} fontWeight={"medium"}>
                      Миний төхөөрөмжүүд
                    </Text>
                    {devices.length > 0 ? (
                      devices.map((e, index) => (
                        <HStack
                          key={index}
                          w={"full"}
                          py={"1"}
                          px={"4"}
                          bg={
                            index % 2 === 0
                              ? colorMode === "dark"
                                ? "#1D211D"
                                : "#fff"
                              : colorMode === "dark"
                              ? "#202420"
                              : "#F9F9F9"
                          }
                          rounded={"md"}
                          justify={"space-between"}
                          align={"center"}
                          onClick={() => addHandler(e, index)}
                          cursor={"pointer"}
                        >
                          <Text fontSize={15} fontWeight={"normal"}>
                            {e.for}
                          </Text>
                          <Image
                            src={check}
                            w={"4"}
                            h={"4"}
                            objectFit="contain"
                          />
                        </HStack>
                      ))
                    ) : (
                      <Text>Төхөөрөмж байхгүй байна</Text>
                    )}
                  </VStack>
                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={disabled ? true : false}
            >
              {disabled ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditButton;
