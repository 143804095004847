import React from "react";
import { HStack, Text, useColorMode, Box } from "@chakra-ui/react";

const DeviceName = ({ deviceName }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      spacing={5}
      transition={"ease-in-out .3s"}
      w="full"
      minH={"20"}
      px={"8"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
    >
      <Text>Нэр</Text>
      <Box overflowWrap={"break-word"} py={"4"} w="85%">
        <Text textAlign={"end"} fontWeight="medium">
          {deviceName ? deviceName : "-"}
        </Text>
      </Box>
    </HStack>
  );
};

export default DeviceName;
