import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import time from "../../assets/Images/icons/time.png";
import timeDark from "../../assets/Images/icons/timeDark.png";
import close from "../../assets/Images/icons/close.png";
import closeDark from "../../assets/Images/icons/closeDark.png";
import light from "../../assets/Images/CardIcons/light.png";
import lightDark from "../../assets/Images/CardIcons/lightDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import UserContext from "../../context/UserContext";
import TimePicker from "./Create/Automation/TimePicker";
import curtainClose from "../../assets/Images/icons/curtainClose.png";
import curtainCloseDark from "../../assets/Images/icons/curtainCloseDark.png";

const ActionCard = ({ data, index }) => {
  const [state, setState] = useState();
  const [timeValue, setTimeValue] = useState(0);
  const [minuteValue, setMinuteValue] = useState(0);
  const [secondValue, setSecondValue] = useState(0);
  const { colorMode } = useColorMode();
  const { autoAct, setAutoAct } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const typeData = [
    {
      title: "Цаг",
      value: timeValue,
      changeData: setTimeValue,
    },
    {
      title: "Минут",
      value: minuteValue,
      changeData: setMinuteValue,
    },
    {
      title: "Секунд",
      value: secondValue,
      changeData: setSecondValue,
    },
  ];

  const removeHandler = () => {
    const clone = autoAct;
    setAutoAct(clone.filter((e, i) => i !== index));
  };

  const submitHandler = (value) => {
    if (
      value.actionType === "switch" ||
      value.actionType === "Curtain" ||
      value.actionType === "g4" ||
      value.actionType === "Switchsc" ||
      value.actionType === "zbSwitch" ||
      value.actionType === "WLED"
    ) {
      const clone = [...autoAct];
      clone.find((e) => e === value).command = state;
      setAutoAct(clone);
    }
    if (value.actionType === "delay") {
      const clone = [...autoAct];
      clone.find((e) => e === value).command =
        parseInt(timeValue) * 3600 +
        parseInt(minuteValue) * 60 +
        parseInt(secondValue);
      clone.find((e) => e === value).time = timeValue;
      clone.find((e) => e === value).minute = minuteValue;
      clone.find((e) => e === value).second = secondValue;
      setAutoAct(clone);

      setTimeValue(0);
      setMinuteValue(0);
      setSecondValue(0);
    }
    setState();
    onClose();
  };

  return (
    <VStack w={"full"}>
      {data.actionType === "delay" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          onClick={() => {
            setTimeValue(data && data.time ? data.time : 0);
            setMinuteValue(data && data.minute ? data.minute : 0);
            setSecondValue(data && data.second ? data.second : 0);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={4}>
            <Image
              src={colorMode === "dark" ? timeDark : time}
              w={"7"}
              h={"5"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.time +
                " цаг " +
                data.minute +
                " минут " +
                data.second +
                " секунд "}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={() => removeHandler(index)}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.actionType === "Curtain" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          justify={"space-between"}
          align={"center"}
          onClick={() => {
            setState(data.command);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={4}>
            <Image
              src={colorMode === "dark" ? curtainCloseDark : curtainClose}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Box
              w={"2"}
              h={"2"}
              bg={data.command === "open" ? "#45A735" : "#ff0000"}
              rounded={"full"}
            />
            <Text fontSize={14} fontWeight={"normal"}>
              {data.command === "open" ? "Нээх" : "Хаах"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={() => removeHandler(index)}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          justify={"space-between"}
          align={"center"}
          onClick={() => {
            setState(data.command);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={4}>
            <Image
              src={colorMode === "dark" ? lightDark : light}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Box
              w={"2"}
              h={"2"}
              bg={data.command === "ON" ? "#45A735" : "#ff0000"}
              rounded={"full"}
            />
            <Text fontSize={14} fontWeight={"normal"}>
              {data.command === "ON" ? "Асах" : "Унтрах"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={() => removeHandler(index)}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      )}

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Төлөв өө сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack>
              {data.actionType === "delay" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Үйлдэл хийгдэх цагаа сонгоно.
                  </Text>
                  <HStack
                    w={"full"}
                    spacing={6}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {typeData.map((e, i) => (
                      <TimePicker
                        key={i}
                        title={e.title}
                        value={e.value}
                        setValue={e.changeData}
                      />
                    ))}
                  </HStack>
                </VStack>
              ) : data.actionType === "Curtain" ? (
                <VStack w={"full"}>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("open")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Нээх
                    </Text>
                    <Image
                      src={state === "open" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("close")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Хаах
                    </Text>
                    <Image
                      src={state === "close" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </VStack>
              ) : data.actionType === "g4" ? (
                <VStack w={"full"}>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("ON")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Асах
                    </Text>
                    <Image
                      src={state === "ON" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("OFF")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Унтрах
                    </Text>
                    <Image
                      src={state === "OFF" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </VStack>
              ) : (
                <VStack w={"full"}>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("ON")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Асах
                    </Text>
                    <Image
                      src={state === "ON" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("OFF")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Унтрах
                    </Text>
                    <Image
                      src={state === "OFF" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </VStack>
              )}
              <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
                <Button
                  w={"32"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  fontWeight={"normal"}
                  fontSize={15}
                  onClick={onClose}
                  _focusVisible={{ outline: "none" }}
                >
                  Болих
                </Button>
                <Button
                  w={"32"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  fontWeight={"normal"}
                  fontSize={15}
                  color={"#45A735"}
                  borderWidth={"thin"}
                  borderColor={"#45A735"}
                  onClick={() => submitHandler(data)}
                  isDisabled={false}
                  _focusVisible={{ outline: "none" }}
                >
                  Хадгалах
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ActionCard;
