import React from "react";
import {
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import light from "../../../../assets/Images/CardIcons/light.png";
import lightDark from "../../../../assets/Images/CardIcons/lightDark.png";
import th from "../../../../assets/Images/CardIcons/th.png";
import thDark from "../../../../assets/Images/CardIcons/thDark.png";
import water from "../../../../assets/Images/CardIcons/water.png";
import waterDark from "../../../../assets/Images/CardIcons/waterDark.png";
import motion from "../../../../assets/Images/CardIcons/motion.png";
import motionDark from "../../../../assets/Images/CardIcons/motionDark.png";
import smoke from "../../../../assets/Images/CardIcons/smoke.png";
import smokeDark from "../../../../assets/Images/CardIcons/smokeDark.png";
import zbAqi from "../../../../assets/Images/CardIcons/zbAqi.png";
import zbAqiDark from "../../../../assets/Images/CardIcons/zbAqiDark.png";
import rgb from "../../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../../assets/Images/CardIcons/rgbDark.png";

const ConditionDevices = ({
  data,
  roomName,
  index,
  setChangeState,
  setDevice,
}) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      //   py={"4"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        setDevice(data);
        setChangeState(true);
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={
            data.deviceType === "switch" || data.deviceType === "zbSwitch"
              ? colorMode === "dark"
                ? lightDark
                : light
              : data.deviceType === "th" ||
                data.deviceType === "zigbeeTh" ||
                data.deviceType === "thsc"
              ? colorMode === "dark"
                ? thDark
                : th
              : data.deviceType === "Occupancy"
              ? colorMode === "dark"
                ? motionDark
                : motion
              : data.deviceType === "Water"
              ? colorMode === "dark"
                ? waterDark
                : water
              : data.deviceType === "Smoke"
              ? colorMode === "dark"
                ? smokeDark
                : smoke
              : data.deviceType === "Switchsc"
              ? colorMode === "dark"
                ? lightDark
                : light
              : data.deviceType === "WLED"
              ? colorMode === "dark"
                ? rgbDark
                : rgb
              : data.deviceType === "zbAqi"
              ? colorMode === "dark"
                ? zbAqiDark
                : zbAqi
              : null
          }
          w={"6"}
          h={"6"}
          objectFit={"contain"}
        />
        <VStack align={"flex-start"} justify={"center"} spacing={0}>
          {roomName ? (
            <Text color={"#919191"} fontWeight={"normal"} fontSize={12}>
              {roomName}
            </Text>
          ) : null}
          <Text fontWeight={"normal"} fontSize={15}>
            {data.for}
          </Text>
        </VStack>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default ConditionDevices;
