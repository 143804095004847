import React, { useContext, useState } from "react";
import {
  VStack,
  Button,
  PinInput,
  PinInputField,
  HStack,
  IconButton,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import UserContext from "../../context/UserContext";
import axios from "../../axios";
import RoundedButton from "../../components/Button/RoundedButton";

const ForgotVerify = () => {
  const [code, setCode] = useState("");
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const id = "err";
  const { btnSpinner, setBtnSpinner, handledHttpErrors } =
    useContext(UserContext);
  const { Auth } = axios();

  const sendSmsHandler = () => {
    if (location.state) {
      setBtnSpinner(true);
      Auth.get("/message/reset_password", {
        phoneNo: location.state,
      })
        .then((result) => {
          // console.log("result -->", result.data);
          setBtnSpinner(false);
        })
        .catch((err) => {
          // console.log("err -->", err);
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error,
            });
          }
        });
    }
  };

  const checkSmsHandler = () => {
    if (code) {
      setBtnSpinner(true);
      Auth.put("/message", {
        phoneNo: location.state,
        authenticationCode: code,
      })
        .then((result) => {
          // console.log("result -->", result.data);
          setBtnSpinner(false);
          history.push("/forgot-password", {
            phoneNumber: location.state,
            authenticationKey: result.data.authenticationKey,
          });
        })
        .catch((err) => {
          // console.log("err -->", err);
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error,
            });
          }
        });
    }
  };

  return (
    <VStack w={"xs"} align={"flex-start"} spacing={10}>
      <HStack w={"full"} spacing={4}>
        <IconButton
          bg={"#F7F7F7"}
          icon={<Icon as={IoIosArrowBack} w={5} h={5} />}
          onClick={() => history.goBack()}
          _focusVisible={{
            outline: "none",
          }}
        />
        <Text color={"#45A735"} fontWeight={"medium"} fontSize={23}>
          Баталгаажуулах
        </Text>
      </HStack>
      <HStack w={"full"} align="center" justify={"center"} spacing={3}>
        <PinInput size="lg" autoFocus value={code} onChange={(e) => setCode(e)}>
          <PinInputField
            _focusVisible={{ outline: "none", borderColor: "#45A735" }}
          />
          <PinInputField
            _focusVisible={{ outline: "none", borderColor: "#45A735" }}
          />
          <PinInputField
            _focusVisible={{ outline: "none", borderColor: "#45A735" }}
          />
          <PinInputField
            _focusVisible={{ outline: "none", borderColor: "#45A735" }}
          />
          <PinInputField
            _focusVisible={{ outline: "none", borderColor: "#45A735" }}
          />
        </PinInput>
      </HStack>

      <VStack w={"full"} spacing={8}>
        <RoundedButton
          title={"Үргэлжлүүлэх"}
          width={"full"}
          onClick={() => checkSmsHandler()}
          disable={code.length === 5 && !btnSpinner ? false : true}
        />
        <Button
          variant={"link"}
          rounded={"full"}
          w={"full"}
          fontWeight={"normal"}
          fontSize={15}
          onClick={() => sendSmsHandler()}
          alignSelf={"center"}
        >
          Дахин код авах
        </Button>
      </VStack>
    </VStack>
  );
};

export default ForgotVerify;
