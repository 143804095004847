import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import pm from "../../../assets/Images/CardIcons/pm.png";
import pmDark from "../../../assets/Images/CardIcons/pmDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import co2Unit from "../../../assets/Images/icons/co2Unit.png";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";

const Dust = ({
  roomName,
  deviceName,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  data,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    notifEnabled: notifEnabled,
    data: data,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("Dust data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          data: value.status === "active" ? value.data.data : 0,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('Dust name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      onClick={() =>
        history.push("/home/sensor-detail", {
          roomName: datas.room,
          deviceName: datas.name,
          status: datas.status,
          id,
          isOriginal,
          origin,
          wifiSignal,
          notifEnabled: datas.notifEnabled,
          deviceType: deviceType,
          data: datas.data,
          blocked,
        })
      }
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack>
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? pmDark : pm}
              w={6}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <HStack maxW="65%" justify={"end"} h={"10"} rounded={"md"}>
            <Image
              src={colorMode === "dark" ? co2Unit : co2Unit}
              w={"5"}
              objectFit={"contain"}
            />
            <VStack spacing={1}>
              <Text wordBreak={"break-all"}>{datas.data + " μg/m³"}</Text>
              <Box
                w="full"
                borderRadius={"sm"}
                h="0.5"
                bg={
                  datas.data < 51
                    ? "#45A735"
                    : datas.data > 50 && datas.data < 101
                    ? "yellow"
                    : datas.data > 100 && datas.data < 251
                    ? "orange"
                    : datas.data > 250
                    ? "red"
                    : null
                }
              ></Box>
            </VStack>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={4}
      >
        <Text w="full" fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text w="full" fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Dust;
