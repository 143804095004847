import React from "react";
import {
  Button,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import plus from "../../assets/Images/icons/plus.png";
import plusDark from "../../assets/Images/icons/plusDark.png";
import automation from "../../assets/Images/icons/automation.png";
import automationDark from "../../assets/Images/icons/automationDark.png";
import group from "../../assets/Images/icons/group.png";
import groupDark from "../../assets/Images/icons/groupDark.png";
import tap from "../../assets/Images/icons/tap.png";
import tapDark from "../../assets/Images/icons/tapDark.png";
import { useHistory } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const CreateAutoModal = () => {
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const typeData = [
    {
      title: "Автоматжуулалт үүсгэх",
      img: automation,
      imgDark: automationDark,
      onClick: "/automation/create-automation",
    },
    {
      title: "Багц автоматжуулалт үүсгэх",
      img: group,
      imgDark: groupDark,
      onClick: "/automation/create-group",
    },
    {
      title: "Багц үйлдэл үүсгэх",
      img: tap,
      imgDark: tapDark,
      onClick: "/automation/ttr-category",
    },
  ];

  return (
    <>
      <HStack
        className="btn"
        w={["36", "36", "36", "xs", "md"]}
        align={"center"}
        justify={"center"}
        rounded={"xl"}
        py={"2"}
        onClick={() => onOpen()}
        cursor={"pointer"}
      >
        <Text
          fontSize={15}
          fontWeight={"normal"}
          display={["none", "none", "none", "none", "flex"]}
        >
          Автоматжуулалт нэмэх
        </Text>
        <Image
          src={colorMode === "dark" ? plusDark : plus}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
        />
      </HStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={"md"}
          pb={"6"}
          px={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Автоматжуулалт нэмэх</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody>
            <VStack w={"full"} spacing={4}>
              {typeData.map((e, i) => (
                <HStack
                  transition={"ease-in-out .3s"}
                  key={i}
                  w={"full"}
                  h={"20"}
                  px={"4"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  borderWidth={"medium"}
                  borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                  rounded={"md"}
                  shadow={"md"}
                  align={"center"}
                  justify={"space-between"}
                  onClick={() => history.push(e.onClick)}
                  cursor={"pointer"}
                  _hover={{
                    shadow: "lg",
                  }}
                >
                  <HStack>
                    <Image
                      src={colorMode === "dark" ? e.imgDark : e.img}
                      w={"6"}
                      h={"6"}
                      objectFit={"contain"}
                    />
                    <Text fontWeight={"normal"} fontSize={15}>
                      {e.title}
                    </Text>
                  </HStack>
                  <Icon
                    as={IoIosArrowForward}
                    w={"6"}
                    h={"6"}
                    color={"#45A735"}
                  />
                </HStack>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateAutoModal;
