import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InsertName from "../../components/Automation/Create/InsertName";
import automation from "../../assets/Images/icons/automation.png";
import automationDark from "../../assets/Images/icons/automationDark.png";
import AddSun from "../../components/Automation/Create/Automation/AddSun";
import AddTime from "../../components/Automation/Create/Automation/AddTime";
import AddConditionDevice from "../../components/Automation/Create/Automation/AddConditionDevice";
import AddDelay from "../../components/Automation/Create/Automation/AddDelay";
import UserContext from "../../context/UserContext";
import ConditionCard from "../../components/Automation/ConditionCard";
import ActionCard from "../../components/Automation/ActionCard";
import AddActionDevice from "../../components/Automation/Create/Automation/AddActionDevice";
import Cookies from "js-cookie";
import axios from "../../axios";
import { TbArrowBackUp } from "react-icons/tb";
import { useHistory, useLocation } from "react-router-dom";

const CreateAutomation = () => {
  const location = useLocation();
  const [name, setName] = useState(
    location && location.state ? location.state : ""
  );
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { colorMode } = useColorMode();
  const {
    autoCon,
    setAutoCon,
    autoAct,
    setAutoAct,
    autoEditId,
    setAutoEditId,
    handledHttpErrors,
  } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();

  // ------------------ Create automation ------------------------
  const submitHandler = () => {
    if (
      autoCon.length > 0 &&
      autoAct.length > 0 &&
      name &&
      houseId &&
      !autoAct.every((e) => e.actionType === "delay")
    ) {
      let source = Core.CancelToken.source();
      if (autoEditId) {
        if (
          autoCon.filter(
            (e) =>
              e.automationTriggerType === "time" ||
              e.automationTriggerType === "sun" ||
              e.automationTriggerType === "Water" ||
              e.automationTriggerType === "Smoke" ||
              e.automationTriggerType === "Occupancy"
          ).length <= 1
        ) {
          let data = {
            name: name,
            onlyTimeBased: autoCon.some(
              (e) => e.automationTriggerType === "time"
            ),
            sunBased: autoCon.some((e) => e.automationTriggerType === "sun"),
            triggers: autoCon,
            actions: autoAct,
            houseId: houseId,
            _id: autoEditId,
          };
          // console.log("Automation Edit -------------->", data);
          setIsDisable(true);
          Core.put(
            "user/automatictable",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            { cancelToken: source.token }
          )
            .then((result) => {
              // console.log("edit auto", result.data);
              if (result.data.success) {
                if (!toast.isActive(id)) {
                  toast({
                    description: "Амжилттай!",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                  });
                }
                setIsDisable(false);
                setAutoCon([]);
                setAutoAct([]);
                // history.push("/automation");
                history.goBack();
              }
            })
            .catch((err) => {
              // console.log("edit auto", err);
              setIsDisable(false);
              if (handledHttpErrors.includes(err.response.status)) return;

              if (!toast.isActive(id)) {
                toast({
                  description:
                    err.response.status === 400
                      ? err.response.data.message
                      : "Алдаа гарлаа!",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
              }
            });
        } else {
          if (!toast.isActive(id)) {
            toast({
              description: "Нөхцөлд нэг цаг оруулна уу!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        }
      } else {
        if (
          autoCon.filter(
            (e) =>
              e.automationTriggerType === "time" ||
              e.automationTriggerType === "sun" ||
              e.automationTriggerType === "Water" ||
              e.automationTriggerType === "Smoke" ||
              e.automationTriggerType === "Occupancy"
          ).length <= 1
        ) {
          let data = {
            name: name,
            onlyTimeBased: autoCon.some(
              (e) => e.automationTriggerType === "time"
            ),
            sunBased: autoCon.some((e) => e.automationTriggerType === "sun"),
            triggers: autoCon,
            actions: autoAct,
            houseId: houseId,
          };
          // console.log("Automation -------------->", data);
          setIsDisable(true);
          Core.post(
            "user/newautomatic",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            { cancelToken: source.token }
          )
            .then((result) => {
              // console.log(result.data);
              if (result.data.success) {
                if (!toast.isActive(id)) {
                  toast({
                    description: "Амжилттай!",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                  });
                }
                setIsDisable(false);
                setAutoCon([]);
                setAutoAct([]);
                // history.push("/automation");
                history.goBack();
              }
            })
            .catch((err) => {
              // console.log(err);
              setIsDisable(false);
              if (handledHttpErrors.includes(err.response.status)) return;

              if (!toast.isActive(id)) {
                toast({
                  description:
                    err.response.status === 400
                      ? err.response.data.message
                      : "Алдаа гарлаа!",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
              }
            });
        } else {
          if (!toast.isActive(id)) {
            toast({
              description: "Нөхцөлд нэг цаг оруулна уу!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        }
      }
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Автоматжуулалт дутуу байна!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <VStack w={"full"} h={"full"} spacing={6}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4} w={"full"}>
          <IconButton
            transition={"ease-in-out .3s"}
            bg={colorMode === "dark" ? "#252B25" : "#fff"}
            rounded={"lg"}
            icon={
              <Icon
                as={TbArrowBackUp}
                color={colorMode === "dark" ? "#fff" : "#000"}
                w={5}
                h={5}
              />
            }
            _hover={{
              bg: "#fff",
              shadow: "md",
            }}
            _focusVisible={{
              outline: "none",
              bg: "#fff",
            }}
            onClick={() => {
              autoCon.length > 0 || autoAct.length > 0
                ? onOpen()
                : history.goBack();
            }}
          />
          <Text fontSize={15} fontWeight={"normal"}>
            Автоматжуулалт үүсгэх
          </Text>
        </HStack>

        <InsertName
          title={"Автоматжуулалтын нэр"}
          img={automation}
          imgDark={automationDark}
          value={name}
          onChange={setName}
        />
      </HStack>

      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"xl"}
        p={"6"}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={4}
      >
        <Stack
          w={"full"}
          h={"full"}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={6}
          direction={["column", "column", "column", "column", "row"]}
        >
          {/* -------------------- Automation Condition --------------------- */}
          <HStack
            w={"full"}
            h={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            // bg={"Red"}
            spacing={4}
          >
            <VStack
              w={"96"}
              align={"flex-start"}
              justify={"flex-start"}
              spacing={4}
            >
              <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
                <Text fontSize={15} fontWeight={"medium"}>
                  Нөхцөл
                </Text>
                <Text fontSize={13} fontWeight={"normal"}>
                  Хэрэв нөхцөл биелвэл
                </Text>
              </VStack>
              <AddSun />
              <AddTime />
              <AddConditionDevice />
            </VStack>
            <VStack
              w={"full"}
              h={"full"}
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"xl"}
              p={"8"}
              spacing={4}
              overflowY="auto"
              maxH={["35vh", "35vh", "35vh", "35vh", "65vh"]}
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              {autoCon.length > 0
                ? autoCon.map((e, i) => (
                    <ConditionCard key={i} data={e} index={i} />
                  ))
                : null}
            </VStack>
          </HStack>

          {/* -------------------- Automation Action --------------------- */}
          <HStack
            w={"full"}
            h={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            // bg={"blue"}
            spacing={4}
          >
            <VStack
              w={"96"}
              align={"flex-start"}
              justify={"flex-start"}
              spacing={4}
            >
              <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
                <Text fontSize={15} fontWeight={"medium"}>
                  Үйлдэл
                </Text>
                <Text fontSize={13} fontWeight={"normal"}>
                  Энэ үйлдэл хийгдэнэ.
                </Text>
              </VStack>
              <AddDelay />
              <AddActionDevice />
            </VStack>

            <VStack
              w={"full"}
              h={"full"}
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"xl"}
              p={"8"}
              spacing={4}
              overflowY="auto"
              maxH={["35vh", "35vh", "35vh", "35vh", "65vh"]}
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              {autoAct.length > 0
                ? autoAct.map((e, i) => (
                    <ActionCard key={i} data={e} index={i} />
                  ))
                : null}
            </VStack>
          </HStack>
        </Stack>

        <HStack w={"full"} align={"flex-end"} justify={"flex-end"} pr={"12"}>
          <Button
            className="btn"
            w={"xs"}
            borderColor={"#45A735"}
            borderWidth={"thin"}
            rounded={"full"}
            shadow={"md"}
            fontWeight={"normal"}
            fontSize={15}
            onClick={() => submitHandler()}
            disabled={isDisable}
            _hover={{
              bg: "#5AB54B",
              color: "#fff",
            }}
            _focusVisible={{
              outline: "none",
            }}
          >
            {isDisable ? <Spinner mr={2} size="sm" /> : null}
            Хадгалах
          </Button>
        </HStack>
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>Гарахдаа итгэлтэй байна уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                onClose();
                setAutoCon([]);
                setAutoAct([]);
                setName("");
                setAutoEditId("");
                history.goBack();
              }}
              _focusVisible={{ outline: "none" }}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default CreateAutomation;
