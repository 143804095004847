// TODO: check if automation condition has already time or sun
export const ifAutoConHasTimeOrSun = (autoCon) => {
  let hasTimeOrSun = false;

  for (let con of autoCon) {
    if (
      con.automationTriggerType === "sun" ||
      con.automationTriggerType === "time"
    ) {
      hasTimeOrSun = true;
      break;
    }
  }

  if (hasTimeOrSun) {
    return true;
  }
};
