import React, { useContext, useState } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import Cookies from "js-cookie";
import axios from "../../../axios";
import pencil from "../../../assets/Images/icons/pencil.png";
import pencilDark from "../../../assets/Images/icons/pencilDark.png";
import UserContext from "../../../context/UserContext";
import { useHistory } from "react-router-dom";

const TtrList = ({ data, index, type, setReload, reload }) => {
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setTtrAct, setAutoEditId, handledHttpErrors } =
    useContext(UserContext);
  const { Core } = axios();

  // ---------------------------- DELETE ---------------------------------
  const deleteHandler = (value) => {
    if (value) {
      setIsDisable(true);
      Core.delete("/user/ttr", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: { _id: [value] },
      })
        .then((result) => {
          // console.log(result.data);
          setIsDisable(false);
          setReload(!reload);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай устлаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  // -------------------- Tap to Run Edit ------------------------
  const editHandler = (value) => {
    if (value) {
      setAutoEditId(value._id);
      if (value) {
        let source = Core.CancelToken.source();
        let cancel = false;
        Core.get("/user/ttr/detail", {
          params: {
            _id: value._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
          .then((result) => {
            if (cancel) return;
            // console.log("ttrEdit dotor set hiilee ", result.data.data);
            const data = result.data.data;
            setTtrAct(
              data.actions.map((e, i) => {
                return e.type === "device"
                  ? {
                      actionType: e.actionType,
                      command: e.command,
                      room: e.room,
                      type: e.type,
                      _id: e._id,
                      device: e.for,
                    }
                  : {
                      actionType: e.actionType,
                      command: e.command,
                      type: e.type,
                      _id: e._id,
                      device: e.name,
                    };
              })
            );
            history.push("/automation/create-ttr", value.name);
          })
          .catch((err) => {
            // console.log(err);
            if (handledHttpErrors.includes(err.response.status)) return;

            if (!toast.isActive(id)) {
              toast({
                description: "Алдаа гарлаа!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
          });
      }
    }
  };

  return (
    <HStack
      w={"full"}
      align={"center"}
      justify={"center"}
      bg={
        index % 2 === 0
          ? "transparent"
          : colorMode === "dark"
          ? "#1D211D"
          : "#fff"
      }
      rounded={"md"}
      py={"4"}
      spacing={0}
    >
      <Stack w={"60"} align={"center"} justify={"center"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {index + 1}
        </Text>
      </Stack>
      <Stack w={"full"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {data.name}
        </Text>
      </Stack>
      <Stack w={"full"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {type}
        </Text>
      </Stack>
      <HStack w={"lg"}>
        {/* <Text fontSize={15} fontWeight={"normal"}>
          -
        </Text> */}
      </HStack>
      <Stack w={"60"} align={"center"} justify={"center"}>
        <Menu>
          <MenuButton
            transition={"ease-in-out .3s"}
            rounded={"full"}
            _focusVisible={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Center
              w={"6"}
              h={"6"}
              rounded={"md"}
              borderColor={"#ababab"}
              borderWidth={"thin"}
            >
              <Text>...</Text>
            </Center>
          </MenuButton>
          <MenuList bg={"#fff"}>
            <MenuItem onClick={() => editHandler(data)}>
              <HStack align={"center"} justify={"center"}>
                <Image
                  src={colorMode === "dark" ? pencilDark : pencil}
                  w={"3.5"}
                  objectFit={"contain"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  Засах
                </Text>
              </HStack>
            </MenuItem>
            <MenuItem onClick={() => onOpen()}>
              <HStack align={"center"} justify={"center"}>
                <Icon as={FiTrash2} w={"4"} h={"4"} color={"#ff0000"} />
                <Text fontSize={15} fontWeight={"normal"} color={"#ff0000"}>
                  Устгах
                </Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.name}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteHandler(data._id)}
              isDisabled={isDisable}
              _focusVisible={{ outline: "none" }}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default TtrList;
