import React from "react";
import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

const InvoiceList = ({ data, index }) => {
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      //   w={index === 0 ? "sm" : index === 5 ? "5xl" : "full"}
      //   bg={"#FBFBFB"}
      rounded={"md"}
      p={"3"}
    >
      <Center w={"sm"}>
        <Text fontWeight={"normal"} fontSize={13}>
          {index + 1}
        </Text>
      </Center>
      <Stack w={"full"}>
        <Text fontWeight={"normal"} fontSize={13}>
          {data.description}
        </Text>
      </Stack>
      <Center w={"2xl"}>
        <Text fontWeight={"normal"} fontSize={13}>
          {data.expireDate}
        </Text>
      </Center>
      <Center w={"2xl"}>
        <Text fontWeight={"normal"} fontSize={13}>
          {data.amount}₮
        </Text>
      </Center>
      <Center w={"2xl"}>
        <Center
          w={"32"}
          rounded={"full"}
          borderWidth={"thin"}
          borderColor={data.paid ? "#45A735" : "#ff0000"}
        >
          <Text
            fontWeight={"normal"}
            fontSize={13}
            color={data.paid ? "#45A735" : "#ff0000"}
          >
            {data.paid ? "Төлөгдсөн" : "Төлөгдөөгүй"}
          </Text>
        </Center>
      </Center>
      <Center w={"2xl"}>
        <Center
          w={"32"}
          py={"0.5"}
          rounded={"full"}
          bg={
            data.paid
              ? colorMode === "dark"
                ? "#202420"
                : "#F2F2F2"
              : "#45A735"
          }
          onClick={() => {
            data.paid
              ? data.ebarimt
                ? onOpen()
                : toast({
                    description: "И-баримт шивэгдээгүй байна. Түр хүлээнэ үү!",
                    status: "warning",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                  })
              : history.push("/payment/pay", {
                  expireDate: data.expireDate,
                  amount: data.amount,
                  id: data._id,
                });
          }}
          cursor={"pointer"}
        >
          <Text
            fontWeight={"normal"}
            fontSize={13}
            color={
              data.paid ? (colorMode === "dark" ? "#fff" : "#000") : "#fff"
            }
          >
            {data.paid ? "И-баримт" : "Төлөх"}
          </Text>
        </Center>
      </Center>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>И-баримт</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={"10"}>
            <VStack w={"full"} spacing={3}>
              <HStack w={"full"} justify={"space-between"} align={"center"}>
                <Text fontSize={14} fontWeight={"normal"}>
                  Сугалааны дугаар:
                </Text>
                {data.ebarimt ? (
                  <Text fontSize={14} fontWeight={"medium"}>
                    {data.ebarimt.ebarimt_lottery}
                  </Text>
                ) : null}
              </HStack>
              <HStack w={"full"} justify={"space-between"} align={"center"}>
                <Text fontSize={14} fontWeight={"normal"}>
                  И-баримтад бүртгүүлэх дүн:
                </Text>
                {data.ebarimt ? (
                  <Text fontSize={14} fontWeight={"medium"}>
                    {data.ebarimt.vat_amount}₮
                  </Text>
                ) : null}
              </HStack>
              {data.ebarimt ? (
                <QRCodeSVG value={data.ebarimt.ebarimt_qr_data} size={250} />
              ) : null}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default InvoiceList;
