import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  MenuItem,
  useColorMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  useToast,
  Spinner,
  HStack,
  Text,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import roomDevice from "../../assets/Images/icons/roomDevice.png";
import roomDeviceDark from "../../assets/Images/icons/roomDeviceDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import UserContext from "../../context/UserContext";

const AddDevice = ({ data, setTrigger }) => {
  const [devices, setDevices] = useState([]);
  const [myDevices, setMyDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { btnSpinner, setBtnSpinner, handledHttpErrors } =
    useContext(UserContext);
  const { Core } = axios();

  const AddDeviceHandler = () => {
    if (houseId) {
      onOpen();
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;

      //==========get device data=========
      Core.get("/user_v2/device/device_data_new", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          const roomData = result.data.data;
          // console.log("room devices", roomData);
          setMyDevices(roomData.filter((f) => f.roomId === data._id));
          setDevices(roomData.filter((f) => f.roomId !== data._id));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
              description: "Алдаа гарлаа",
            });
          }
        });

      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  // --- Add, remove device ---
  const addHandler = (item, index) => {
    const clone = devices;
    setDevices(clone.filter((e, i) => i !== index));
    setMyDevices((prevState) => [...prevState, item]);
  };

  const removeHandler = (item, index) => {
    const clone = myDevices;
    setMyDevices(clone.filter((e, i) => i !== index));
    setDevices((prevState) => [...prevState, item]);
  };

  // --------------------- Өрөөнд төхөөрөмж нэмэх --------------------------
  const submitHandler = () => {
    setBtnSpinner(true);
    let source = Core.CancelToken.source();
    Core.put(
      "user/room/devices",
      {
        roomId: data._id,
        deviceId: myDevices.map((e) => {
          return e._id;
        }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        // console.log(result.data);
        onClose();
        setDevices([]);
        setMyDevices([]);
        setBtnSpinner(false);
        setTrigger(true);
        onClose();
        if (!toast.isActive(id)) {
          toast({
            description: "Амжилттай",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .catch((err) => {
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
  };

  return (
    <>
      <MenuItem
        icon={
          <Image
            src={colorMode === "dark" ? roomDeviceDark : roomDevice}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        }
        fontSize={15}
        fontWeight={"normal"}
        onClick={() => AddDeviceHandler()}
      >
        Өрөө хооронд төхөөрөмж шилжүүлэх
      </MenuItem>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>Төхөөрөмж сонгох</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} spacing={4}>
              <VStack w={"full"} align={"flex-start"}>
                {
                  isLoading ? (
                    <Spinner size="lg" color="#45A735" alignSelf={"center"} />
                  ) : (
                    <VStack w={"full"} spacing={4}>
                      <VStack w={"full"} align={"flex-start"}>
                        <Text fontSize={15} fontWeight={"medium"}>
                          Өрөөнд байгаа төхөөрөмжүүд
                        </Text>
                        {myDevices.length > 0 ? (
                          myDevices.map((e, index) => (
                            <HStack
                              key={index}
                              w={"full"}
                              py={"1"}
                              px={"4"}
                              bg={
                                index % 2 === 0
                                  ? colorMode === "dark"
                                    ? "#1D211D"
                                    : "#fff"
                                  : colorMode === "dark"
                                  ? "#202420"
                                  : "#F9F9F9"
                              }
                              rounded={"md"}
                              justify={"space-between"}
                              align={"center"}
                              onClick={() => removeHandler(e, index)}
                              cursor={"pointer"}
                            >
                              <Text fontSize={15} fontWeight={"normal"}>
                                {e.for}
                              </Text>
                              <Image
                                src={checked}
                                w={"4"}
                                h={"4"}
                                objectFit="contain"
                              />
                            </HStack>
                          ))
                        ) : (
                          <VStack
                            justifyContent="center"
                            alignItems="center"
                            alignSelf={"center"}
                            color="gray.600"
                            spacing={1}
                          >
                            <Icon as={FiInfo} w={6} h={6} />
                            <Text fontSize={14} fontWeight={"normal"}>
                              Мэдээлэл олдсонгүй.
                            </Text>
                          </VStack>
                        )}
                      </VStack>
                      <VStack w={"full"} align={"flex-start"}>
                        <Text fontSize={15} fontWeight={"medium"}>
                          Бусад төхөөрөмжүүд
                        </Text>
                        {devices.length > 0 ? (
                          devices.map((e, index) => (
                            <HStack
                              key={index}
                              w={"full"}
                              py={"1"}
                              px={"4"}
                              bg={
                                index % 2 === 0
                                  ? colorMode === "dark"
                                    ? "#1D211D"
                                    : "#fff"
                                  : colorMode === "dark"
                                  ? "#202420"
                                  : "#F9F9F9"
                              }
                              rounded={"md"}
                              justify={"space-between"}
                              align={"center"}
                              onClick={() => addHandler(e, index)}
                              cursor={"pointer"}
                            >
                              <Text fontSize={15} fontWeight={"normal"}>
                                {e.for}
                              </Text>
                              <Image
                                src={check}
                                w={"4"}
                                h={"4"}
                                objectFit="contain"
                              />
                            </HStack>
                          ))
                        ) : (
                          <VStack
                            justifyContent="center"
                            alignItems="center"
                            alignSelf={"center"}
                            color="gray.600"
                            spacing={1}
                          >
                            <Icon as={FiInfo} w={6} h={6} />
                            <Text fontSize={14} fontWeight={"normal"}>
                              Мэдээлэл олдсонгүй.
                            </Text>
                          </VStack>
                        )}
                      </VStack>
                    </VStack>
                  )
                  // ) : (
                  //   <VStack
                  //     justifyContent="center"
                  //     alignItems="center"
                  //     color="gray.600"
                  //     mt={"16"}
                  //     spacing={1}
                  //   >
                  //     <Icon as={FiInfo} w={7} h={7} />
                  //     <Text fontSize={15} fontWeight={"normal"}>
                  //       Мэдээлэл олдсонгүй.
                  //     </Text>
                  //   </VStack>
                  // )
                }
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={btnSpinner ? true : false}
            >
              {btnSpinner ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDevice;
