function LineSkeleton() {
  return (
    <svg
      id="line"
      xmlns="http://www.w3.org/2000/svg"
      width="1310"
      height="443.105"
      viewBox="0 0 1310 443.105"
    >
      <line
        style={{ animationDelay: "0.1s" }}
        id="Line_177"
        data-name="Line 177"
        x2="220"
        transform="translate(0 258.057)"
      />
      <line
        style={{ animationDelay: "0.1s" }}
        id="Line_178"
        data-name="Line 178"
        x1="6"
        y2="40"
        transform="translate(220 218.057)"
      />
      <line
        style={{ animationDelay: "0.1s" }}
        id="Line_179"
        data-name="Line 179"
        x1="6"
        y1="40"
        transform="translate(226 218.057)"
      />
      <line
        style={{ animationDelay: "0.2s" }}
        id="Line_180"
        data-name="Line 180"
        x1="22"
        transform="translate(232 258.057)"
      />
      <line
        style={{ animationDelay: "0.2s" }}
        id="Line_181"
        data-name="Line 181"
        x1="19"
        y2="150"
        transform="translate(254 108.057)"
      />
      <line
        style={{ animationDelay: "0.2s" }}
        id="Line_182"
        data-name="Line 182"
        x1="17"
        y1="223"
        transform="translate(273 108.057)"
      />
      <line
        style={{ animationDelay: "0.3s" }}
        id="Line_183"
        data-name="Line 183"
        x1="8"
        y2="56"
        transform="translate(356 202.057)"
      />
      <line
        style={{ animationDelay: "0.3s" }}
        id="Line_184"
        data-name="Line 184"
        x1="7"
        y1="56"
        transform="translate(364 202.057)"
      />
      <line
        style={{ animationDelay: "0.3s" }}
        id="Line_185"
        data-name="Line 185"
        x1="134"
        transform="translate(371 258.057)"
      />
      <line
        style={{ animationDelay: "0.4s" }}
        id="Line_186"
        data-name="Line 186"
        x1="6"
        y2="22"
        transform="translate(505 236.057)"
      />
      <line
        style={{ animationDelay: "0.4s" }}
        id="Line_187"
        data-name="Line 187"
        x1="5"
        y1="22"
        transform="translate(511 236.057)"
      />
      <line
        style={{ animationDelay: "0.4s" }}
        id="Line_188"
        data-name="Line 188"
        x1="22"
        transform="translate(517 258.057)"
      />
      <line
        style={{ animationDelay: "0.5s" }}
        id="Line_189"
        data-name="Line 189"
        x1="15"
        y2="257"
        transform="translate(539 1.057)"
      />
      <line
        style={{ animationDelay: "0.5s" }}
        id="Line_190"
        data-name="Line 190"
        x1="22"
        y1="441"
        transform="translate(554 1.057)"
      />
      <line
        style={{ animationDelay: "0.5s" }}
        id="Line_191"
        data-name="Line 191"
        y1="73"
        x2="6"
        transform="translate(290 258.057)"
      />
      <line
        style={{ animationDelay: "0.6s" }}
        id="Line_192"
        data-name="Line 192"
        x2="60"
        transform="translate(296 258.057)"
      />
      <line
        style={{ animationDelay: "0.6s" }}
        id="Line_193"
        data-name="Line 193"
        x1="4"
        y2="50"
        transform="translate(697 208.057)"
      />
      <line
        style={{ animationDelay: "0.6s" }}
        id="Line_194"
        data-name="Line 194"
        x1="5"
        y1="50"
        transform="translate(701 208.057)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_195"
        data-name="Line 195"
        x1="28"
        y2="0.5"
        transform="translate(706 257.557)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_196"
        data-name="Line 196"
        x1="15"
        y2="152.347"
        transform="translate(734 105.21)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_197"
        data-name="Line 197"
        x1="20"
        y1="223.5"
        transform="translate(749 105.557)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_198"
        data-name="Line 198"
        y1="74"
        x2="7"
        transform="translate(769 257.057)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_199"
        data-name="Line 199"
        y1="182"
        x2="6"
        transform="translate(576 258.057)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_200"
        data-name="Line 200"
        x2="115"
        transform="translate(582 258.057)"
      />
      <line
        style={{ animationDelay: "0.7s" }}
        id="Line_201"
        data-name="Line 201"
        x2="136"
        transform="translate(776 257.057)"
      />
      <line
        style={{ animationDelay: "0.8s" }}
        id="Line_202"
        data-name="Line 202"
        x1="6"
        y2="46"
        transform="translate(912 211.057)"
      />
      <line
        style={{ animationDelay: "0.8s" }}
        id="Line_203"
        data-name="Line 203"
        x1="5"
        y1="47"
        transform="translate(918 210.057)"
      />
      <line
        style={{ animationDelay: "0.8s" }}
        id="Line_204"
        data-name="Line 204"
        x1="22"
        transform="translate(923 257.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_205"
        data-name="Line 205"
        x1="16"
        y2="206"
        transform="translate(945 51.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_206"
        data-name="Line 206"
        x1="19"
        y1="258"
        transform="translate(961 51.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_207"
        data-name="Line 207"
        x1="5"
        y2="23"
        transform="translate(1026 234.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_208"
        data-name="Line 208"
        x1="5"
        y1="23"
        transform="translate(1031 234.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_209"
        data-name="Line 209"
        x1="273"
        transform="translate(1036 257.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_210"
        data-name="Line 210"
        y1="52"
        x2="7"
        transform="translate(980 257.057)"
      />
      <line
        style={{ animationDelay: "0.9s" }}
        id="Line_211"
        data-name="Line 211"
        x2="39"
        transform="translate(987 257.057)"
      />
    </svg>
  );
}

export default LineSkeleton;
