import React, { useState, useContext } from "react";
import {
  Alert,
  AlertIcon,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "../../axios";
import Cookies from "js-cookie";
import { FiInfo } from "react-icons/fi";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import automation from "../../assets/Images/icons/automation.png";
import automationDark from "../../assets/Images/icons/automationDark.png";
import UserContext from "../../context/UserContext";

const AddAuto = ({ groupId, setReload }) => {
  const [automations, setAutomations] = useState([]);
  const [autoId, setAutoId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const getAutoHandler = () => {
    if (groupId) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      Core.get(
        "/user/automation_for_group",
        {
          params: { house: houseId, groupId: groupId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          onOpen();
          //   console.log(result.data.data);
          const data = result.data.data;
          setAutomations(
            data.map((e) => {
              return {
                _id: e._id,
                groupName: e.groupName,
                isSub: e.isSub,
                name: e.name,
                check: false,
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  const addHandler = (data, index) => {
    const autoID = [...automations];
    autoID[index].check = !autoID[index].check;
    setAutomations(autoID);

    setAutoId((prevState) => {
      return [...prevState, data._id];
    });
  };

  const removeHandler = (data, index) => {
    const autoID = [...automations];
    autoID[index].check = !autoID[index].check;
    setAutomations(autoID);

    setAutoId((prevState) =>
      prevState.filter((prevItem) => prevItem !== data._id)
    );
  };

  const submitHandler = () => {
    if (autoId.length > 0) {
      setIsDisable(true);
      let source = Core.CancelToken.source();
      Core.post(
        "user/automation_group_add",
        {
          groupId: groupId,
          automations: autoId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай хадгаллаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          setReload(true);
          setAutoId([]);
          onClose();
          setIsDisable(false);
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Автоматжуулалт сонгоно уу!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <>
      <Button
        w={"xs"}
        bg={"#45A735"}
        rounded={"xl"}
        fontSize={15}
        fontWeight={"normal"}
        color={"#fff"}
        onClick={() => {
          // onOpen();
          getAutoHandler();
        }}
        _focusVisible={{
          outline: "none",
        }}
        _hover={{
          bg: "#5AB54B",
        }}
      >
        Автоматжуулалт нэмэх
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size="lg">
        <ModalOverlay />
        <ModalContent
          pb={"6"}
          px={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Багц автоматжуулалтад автоматжуулалт нэмэх</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} spacing={4}>
              {isLoading ? (
                <Center w={"full"}>
                  <Spinner color="#45A735" size="md" />
                </Center>
              ) : automations.length > 0 ? (
                <VStack w={"full"} spacing={6}>
                  <VStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"flex-start"}
                  >
                    {automations.find((e) => e.check === true && e.isSub) ? (
                      <ScaleFade initialScale={0.7} in={true}>
                        <Alert status="error" fontSize="sm" mb={4}>
                          <AlertIcon />
                          <Text as={"i"} fontSize={13} fontWeight={"normal"}>
                            Энэ автоматжуулалт өөр багц автоматжуулалтад байгаа
                            тул нэмсэн тохиолдолд өмнөх багц автоматжуулалтаас
                            хасагдахыг анхаарна уу!
                          </Text>
                        </Alert>
                      </ScaleFade>
                    ) : null}
                    <Text fontSize={15} fontWeight={"normal"}>
                      Автоматжуулалт сонгоно уу!
                    </Text>
                    {automations.map((e, i) => (
                      <HStack
                        transition={"ease-in-out .3s"}
                        key={i}
                        w={"full"}
                        bg={colorMode === "dark" ? "#252B25" : "#fff"}
                        shadow={"md"}
                        rounded={"md"}
                        py={"3"}
                        px={"4"}
                        align={"center"}
                        justify={"space-between"}
                        onClick={() => {
                          e.check ? removeHandler(e, i) : addHandler(e, i);
                        }}
                        cursor={"pointer"}
                        _hover={{
                          shadow: "lg",
                        }}
                      >
                        <HStack>
                          <Image
                            src={
                              colorMode === "dark" ? automationDark : automation
                            }
                            w={"5"}
                            h={"5"}
                            objectFit={"contain"}
                          />
                          <Text fontSize={15} fontWeight={"normal"}>
                            {e.name}
                          </Text>
                        </HStack>
                        <HStack>
                          {e.groupName ? (
                            <Text fontSize={13} fontWeight={"normal"}>
                              {e.groupName}
                            </Text>
                          ) : null}
                          <Image
                            src={e.check ? checked : check}
                            w={"5"}
                            h={"5"}
                            objectFit={"contain"}
                          />
                        </HStack>
                      </HStack>
                    ))}
                  </VStack>
                  <HStack w={"full"}>
                    <Button
                      w={"full"}
                      onClick={onClose}
                      _focusVisible={{ outline: "none" }}
                      isDisabled={isDisable}
                    >
                      Болих
                    </Button>
                    <Button
                      w={"full"}
                      bg={"#45A735"}
                      color={"#fff"}
                      onClick={() => submitHandler()}
                      _focusVisible={{
                        outline: "none",
                      }}
                      _hover={{
                        bg: "#5AB54B",
                      }}
                      isDisabled={
                        isDisable || autoId.length === 0 ? true : false
                      }
                    >
                      {isDisable ? <Spinner mr={2} size="sm" /> : null}
                      Хадгалах
                    </Button>
                  </HStack>
                </VStack>
              ) : (
                <VStack
                  w={"full"}
                  justifyContent="center"
                  alignItems="center"
                  color="gray.600"
                  spacing={1}
                >
                  <Icon as={FiInfo} w={7} h={7} />
                  <Text fontSize={15} fontWeight={"normal"}>
                    Мэдээлэл олдсонгүй.
                  </Text>
                </VStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAuto;
