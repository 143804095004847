import React, { useState, useContext } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Icon,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import axios from "../../../../../axios";
import UserContext from "../../../../../context/UserContext";
import curtain from "../../../../../assets/Images/icons/curtain.png";
import curtainDark from "../../../../../assets/Images/icons/curtainDark.png";
import curtainOpen from "../../../../../assets/Images/icons/curtainOpen.png";
import curtainOpenDark from "../../../../../assets/Images/icons/curtainOpenDark.png";
import curtainClose from "../../../../../assets/Images/icons/curtainClose.png";
import curtainCloseDark from "../../../../../assets/Images/icons/curtainCloseDark.png";
import pause from "../../../../../assets/Images/icons/pause.png";
import BackButton from "../../../../../components/Button/BackButton";

const CurtainControl = () => {
  const [closeLoading, setCloseLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const history = useHistory();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const [status, setStatus] = useState(location.state.status);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);
  const buttonSize = "75px";
  const [hovering, setHovering] = useState(false);

  // --------   хөшиг нээх, хаах, зогсоох   --------
  const handleCommand = (command) => {
    if (isLoading) return;
    setIsLoading(true);

    Core.post("/user_v2/command/curtain", {
      _id: location.state.id,
      command,
    })
      .then((result) => {
        // console.log(result.data);
        setIsLoading(false);
        setCloseLoading(false);
        setStopLoading(false);
        setOpenLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        setCloseLoading(false);
        setStopLoading(false);
        setOpenLoading(false);
        if (handledHttpErrors.includes(err.response.status)) return;

        toast({
          description: "Алдаа гарлаа!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <VStack h="full" spacing={0} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>
            {location.state.deviceName} - удирдлага
          </Text>
        </HStack>

        <HStack
          cursor={"pointer"}
          spacing={2}
          borderRadius={"3xl"}
          px="16"
          py={2}
          className="btn"
          _hover={{
            shadow: "md",
          }}
          transition={"linear"}
          transitionDuration={".3s"}
          onClick={() =>
            history.push("/home/curtain-detail", {
              roomName: location.state.roomName,
              status: status,
              id: location.state.id,
              deviceName: location.state.deviceName,
              isOriginal: location.state.isOriginal,
              origin: location.state.origin,
              deviceType: location.state.deviceType,
              wifiSignal: location.state.wifiSignal,
              notifEnabled: location.state.notifEnabled,
              blocked: location.state.blocked,
            })
          }
        >
          <Text>Дэлгэрэнгүй</Text>
          <Icon color={"#45A735"} as={AiOutlineRight} />
        </HStack>
      </HStack>

      {/*     BODY    */}
      <HStack
        h="full"
        w="full"
        justify={"center"}
        align={"center"}
        rounded={"md"}
      >
        {status === "active" ? (
          <VStack spacing={12}>
            <Image
              w={"lg"}
              objectFit={"contain"}
              src={colorMode === "light" ? curtain : curtainDark}
            />

            <HStack spacing={12}>
              <VStack>
                <Center
                  w={buttonSize}
                  h={buttonSize}
                  boxShadow="0 2px 5px 1px rgba(0, 0, 0, 0.1)"
                  rounded={"full"}
                  bg={colorMode === "light" ? "#fff" : "#373737"}
                  onClick={() => {
                    if (isLoading) return;
                    setCloseLoading(true);
                    handleCommand("close");
                  }}
                  cursor={isLoading ? "not-allowed" : "pointer"}
                  transition="all 0.3s ease-in"
                  _hover={
                    isLoading === true
                      ? {}
                      : {
                          bg: colorMode === "light" ? "#f0f0f0" : "#45A735",
                        }
                  }
                  onMouseEnter={() => {
                    setHovering(true);
                  }}
                  onMouseLeave={() => {
                    setHovering(false);
                  }}
                >
                  {closeLoading ? (
                    <Spinner
                      size="sm"
                      color={
                        colorMode === "dark" && hovering === true
                          ? "#000"
                          : "#45A735"
                      }
                    />
                  ) : (
                    <Image
                      w={8}
                      h={8}
                      objectFit={"contain"}
                      src={
                        colorMode === "light" ? curtainClose : curtainCloseDark
                      }
                    />
                  )}
                </Center>

                <Text fontSize={17}>Хаах</Text>
              </VStack>

              <VStack>
                <Center
                  w={buttonSize}
                  h={buttonSize}
                  border="1px solid #45A735"
                  boxShadow="0 2px 5px 1px rgba(0, 0, 0, 0.1)"
                  rounded={"full"}
                  bg={colorMode === "light" ? "#fff" : "#373737"}
                  onClick={() => {
                    if (isLoading) return;
                    setStopLoading(true);
                    handleCommand("stop");
                  }}
                  cursor={isLoading ? "not-allowed" : "pointer"}
                  transition="all 0.3s ease-in"
                  _hover={
                    isLoading === true
                      ? {}
                      : {
                          border: "1px solid rgba(69, 167, 53, 0.5)",
                          bg: "rgba(255, 255, 255, 0.16)",
                        }
                  }
                >
                  {stopLoading ? (
                    <Spinner size="sm" color="#45A735" />
                  ) : (
                    <Image w={6} h={6} objectFit={"contain"} src={pause} />
                  )}
                </Center>

                <Text fontSize={17}>Зогсоох</Text>
              </VStack>

              <VStack>
                <Center
                  w={buttonSize}
                  h={buttonSize}
                  boxShadow="0 2px 5px 1px rgba(0, 0, 0, 0.1)"
                  rounded={"full"}
                  bg={colorMode === "light" ? "#fff" : "#373737"}
                  onClick={() => {
                    if (isLoading) return;
                    setOpenLoading(true);
                    handleCommand("open");
                  }}
                  cursor={isLoading ? "not-allowed" : "pointer"}
                  transition="all 0.3s ease-in"
                  _hover={
                    isLoading === true
                      ? {}
                      : {
                          bg: colorMode === "light" ? "#f0f0f0" : "#45A735",
                        }
                  }
                  onMouseEnter={() => {
                    setHovering(true);
                  }}
                  onMouseLeave={() => {
                    setHovering(false);
                  }}
                >
                  {openLoading ? (
                    <Spinner
                      size="sm"
                      color={
                        colorMode === "dark" && hovering === true
                          ? "#000"
                          : "#45A735"
                      }
                    />
                  ) : (
                    <Image
                      w={8}
                      h={8}
                      objectFit={"contain"}
                      src={
                        colorMode === "light" ? curtainOpen : curtainOpenDark
                      }
                    />
                  )}
                </Center>

                <Text fontSize={17}>Нээх</Text>
              </VStack>
            </HStack>
          </VStack>
        ) : (
          <Text fontWeight={"medium"} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      {/*     BODY   END  */}
    </VStack>
  );
};

export default CurtainControl;
