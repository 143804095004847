import React, { useContext } from "react";
import {
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import sun from "../../../../assets/Images/icons/sun.png";
import sunDark from "../../../../assets/Images/icons/sunDark.png";
import sunrise from "../../../../assets/Images/icons/sunrise.png";
import sunset from "../../../../assets/Images/icons/sunset.png";
import UserContext from "../../../../context/UserContext";
import { ifAutoConHasTimeOrSun } from "../../../../util/AutoConTimeCheck";

const AddSun = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { autoCon, setAutoCon } = useContext(UserContext);

  const typeData = [
    {
      mode: "sunrise",
      title: "Нар мандах",
      img: sunrise,
    },
    {
      mode: "sunset",
      title: "Нар жаргах",
      img: sunset,
    },
  ];

  const submitHandler = (value) => {
    // if (edit) {
    //   //   const autoCon = [...state.autoCon];
    //   //   autoCon.find(e => e === edit).condition = isTurnOn ? 'sunrise' : 'sunset';
    //   //   state.setAutoCon(autoCon);
    //   //   navigation.navigate('AutoConAct', {
    //   //     name: route.params.name,
    //   //     house: route.params.house,
    //   //   });
    // } else {

    if (ifAutoConHasTimeOrSun(autoCon)) {
      onClose();
      return toast({
        description: "Нөхцөлд нэг цаг оруулна уу.",
        duration: 3000,
        status: "error",
        position: "top",
      });
    }

    setAutoCon((prevState) => [
      ...prevState,
      {
        automationTriggerType: "sun",
        condition: value,
      },
    ]);
    // }
    onClose();
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        py={"4"}
        px={"4"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        borderWidth={"medium"}
        borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
        rounded={"md"}
        shadow={"md"}
        align={"center"}
        justify={"center"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "lg",
        }}
      >
        <Image
          src={colorMode === "dark" ? sunDark : sun}
          w={"5"}
          h={"5"}
          objectFit={"contain"}
        />
        <Text>Нар</Text>
      </HStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Та нөхцөлөө сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody w={"full"}>
            <VStack
              w={"full"}
              spacing={4}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Text fontWeight={"normal"} fontSize={15}>
                Нарнаас хамааруулан автоматжуулалт хийгдэнэ.
              </Text>
              {typeData.map((e, i) => (
                <VStack
                  key={i}
                  w={"full"}
                  py={"4"}
                  px={"4"}
                  bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                  borderWidth={"medium"}
                  borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                  rounded={"md"}
                  shadow={"md"}
                  align={"center"}
                  justify={"center"}
                  onClick={() => submitHandler(e.mode)}
                  cursor={"pointer"}
                >
                  <Image src={e.img} w={"6"} h={"6"} objectFit={"contain"} />
                  <Text fontWeight={"normal"} fontSize={15}>
                    {e.title}
                  </Text>
                </VStack>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSun;
