import React from "react";
import { HStack, Icon, Image, Text, useColorMode } from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import group from "../../../../assets/Images/icons/group.png";
import groupDark from "../../../../assets/Images/icons/groupDark.png";

const TtrGrpSwitch = ({ data, setChangeState, setGroup }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w={"full"}
      h={"20"}
      px={"4"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
      onClick={() => {
        setGroup(data);
        setChangeState(true);
      }}
      cursor={"pointer"}
    >
      <HStack w={"full"}>
        <Image
          src={colorMode === "dark" ? groupDark : group}
          w={"5"}
          h={"5"}
          objectFit={"contain"}
        />
        <Text fontWeight={"normal"} fontSize={15}>
          {data.name}
        </Text>
      </HStack>
      <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
    </HStack>
  );
};

export default TtrGrpSwitch;
