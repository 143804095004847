import React, { useContext } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiMinusCircle, FiTrash2 } from "react-icons/fi";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { useState } from "react";
import pencil from "../../../assets/Images/icons/pencil.png";
import pencilDark from "../../../assets/Images/icons/pencilDark.png";
import { useHistory } from "react-router-dom";
import UserContext from "../../../context/UserContext";

const GroupDetailList = ({
  data,
  index,
  type,
  setReload,
  automation,
  setAutomation,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [minusModal, setMinusModal] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setAutoCon, setAutoAct, setAutoEditId, handledHttpErrors } =
    useContext(UserContext);
  const { Core } = axios();

  const removeHandler = (id) => {
    setMinusModal(false);
    let source = Core.CancelToken.source();
    Core.post(
      "user/automation_group_remove",
      {
        automationId: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      { cancelToken: source.token }
    )
      .then((result) => {
        if (!toast.isActive(id)) {
          toast({
            description: "Амжилттай!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
        setReload(true);
      })
      .catch((err) => {
        if (handledHttpErrors.includes(err.response.status)) return;

        if (!toast.isActive(id)) {
          toast({
            description: "Алдаа гарлаа!",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      });
  };

  // -------------------- Automation delete --------------------------
  const deleteHandler = (value) => {
    if (value) {
      // @TODO stringify
      setIsDisable(true);
      const automation = JSON.stringify({
        _id: [value],
      });

      Core.delete("user/automatictable", {
        data: { automation },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((result) => {
          // console.log(result.data);
          setIsDisable(false);
          setReload(true);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай устлаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  // -------------------- Automation Edit ------------------------
  const editHandler = (value) => {
    if (value) {
      setAutoCon(value.triggers);
      setAutoAct(value.actions);
      setAutoEditId(value._id);
      history.push("/automation/create-automation", value.name);
    }
  };

  return (
    <HStack
      w={"full"}
      align={"center"}
      justify={"center"}
      bg={
        index % 2 === 0
          ? "transparent"
          : colorMode === "dark"
          ? "#1D211D"
          : "#fff"
      }
      rounded={"xl"}
      py={"4"}
      spacing={0}
    >
      <Stack w={"60"} align={"center"} justify={"center"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {index + 1}
        </Text>
      </Stack>
      <Stack w={"full"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {data.name}
        </Text>
      </Stack>
      <Stack w={"full"}>
        <Text fontSize={15} fontWeight={"normal"}>
          {type}
        </Text>
      </Stack>
      <HStack w={"lg"}>
        <HStack
          transition={"ease-in-out .3s"}
          bg={data.isActive ? "#D2E7C9" : "#FFD1D1"}
          h={"7"}
          rounded={"full"}
          px={"3"}
        >
          <Box
            w={"2"}
            h={"2"}
            bg={data.isActive ? "#45A735" : "#ff0000"}
            rounded={"full"}
          />
          <Text
            color={data.isActive ? "#45A735" : "#ff0000"}
            fontSize={13}
            fontWeight={"normal"}
          >
            {data.isActive ? "Идэвхтэй" : "Идэвхгүй"}
          </Text>
        </HStack>
      </HStack>
      <Stack w={"60"} align={"center"} justify={"center"}>
        <Menu>
          <MenuButton
            transition={"ease-in-out .3s"}
            rounded={"full"}
            _focusVisible={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Center
              w={"6"}
              h={"6"}
              rounded={"md"}
              borderColor={"#ababab"}
              borderWidth={"thin"}
            >
              <Text>...</Text>
            </Center>
          </MenuButton>
          <MenuList bg={"#fff"}>
            <MenuItem onClick={() => editHandler(data)}>
              <HStack align={"center"} justify={"center"}>
                <Image
                  src={colorMode === "dark" ? pencilDark : pencil}
                  w={"3.5"}
                  objectFit={"contain"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  Засах
                </Text>
              </HStack>
            </MenuItem>
            <MenuItem onClick={() => setMinusModal(true)}>
              <HStack align={"center"} justify={"center"}>
                <Icon
                  as={FiMinusCircle}
                  w={"4"}
                  h={"4"}
                  color={colorMode === "dark" ? "#fff" : "#000"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  Хасах
                </Text>
              </HStack>
            </MenuItem>
            <MenuItem onClick={() => onOpen()}>
              <HStack align={"center"} justify={"center"}>
                <Icon as={FiTrash2} w={"4"} h={"4"} color={"#ff0000"} />
                <Text fontSize={15} fontWeight={"normal"} color={"#ff0000"}>
                  Устгах
                </Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      <Modal isCentered onClose={!minusModal} isOpen={minusModal}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Та хасахдаа итгэлтэй байна уу?</ModalHeader>
          <ModalCloseButton
            _focusVisible={{ outline: "none" }}
            onClick={() => setMinusModal(false)}
          />
          <ModalBody>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.name}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setMinusModal(false)}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => removeHandler(data._id)}
              isDisabled={isDisable}
              _focusVisible={{ outline: "none" }}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.name}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteHandler(data._id)}
              isDisabled={isDisable}
              _focusVisible={{ outline: "none" }}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default GroupDetailList;
