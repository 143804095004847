import React, { useState, useContext } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import pass from "../../assets/Images/icons/pass.png";
import passDark from "../../assets/Images/icons/passDark.png";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import EyeIcon from "../../assets/Images/icons/eyeIcon.png";
import EyeIconLight from "../../assets/Images/icons/eyeIconLight.png";
import EyeCloseIcon from "../../assets/Images/icons/eyeCloseIcon.png";
import EyeCloseIconLight from "../../assets/Images/icons/eyeCloseIconLight.png";
import axios from "../../axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";

const ChangePassword = ({ username }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const history = useHistory();
  const { handledHttpErrors } = useContext(UserContext);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { Core } = axios();

  const submitHandler = () => {
    if (password === confirmPassword) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      Core.post(
        "user/profile",
        {
          username: username,
          type: "changePassword",
          currentPassword: oldPassword,
          newPassword: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (result.data.success) {
            setIsLoading(false);
            history.push("/settings");
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "success",
                duration: 2000,
                position: "top",
                description: "Амжилттай хадгаллаа",
              });
            }
            onClose();
          } else {
            setIsLoading(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "error",
                duration: 2000,
                position: "top",
                description: "Алдаа гарлаа",
              });
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description:
                err.response.data.error === "wrong password"
                  ? "Хуучин нууц үг буруу байна!"
                  : "Алдаа гарлаа",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status: "error",
          description: "Нууц үг тохирохгүй байна",
        });
      }
    }
  };

  // Жижиг үсэг, Тоо, Тусгай тэмдэгт заавал байна. Том үсэг байж болно.
  const validPassword = new RegExp(
    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&.,])[a-zA-Z\d@$!%*#?&.,]{8,}$/
  );

  return (
    <VStack align={"flex-start"} spacing={0}>
      <Text fontSize={15} fontWeight={"normal"}>
        Нууц үг
      </Text>
      <InputGroup
        className="settingsInput"
        rounded={"lg"}
        onClick={() => onOpen()}
        cursor={"pointer"}
      >
        <InputLeftElement
          pointerEvents="none"
          children={
            <Image
              src={colorMode === "dark" ? passDark : pass}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
        <Input
          placeholder="************"
          w={"xs"}
          fontSize={15}
          fontWeight={"normal"}
          border={"none"}
          isReadOnly
        />
        <InputRightElement
          children={
            <Image
              src={colorMode === "dark" ? pencilDark : pencil}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
      </InputGroup>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Нууц үг солих</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Хуучин нууц үг
              </FormLabel>
              <InputGroup
                w={"full"}
                justifyContent={"center"}
                alignItems={"center"}
                ref={initialRef}
              >
                <Input
                  placeholder="-"
                  maxLength={30}
                  fontSize={15}
                  fontWeight={"normal"}
                  type={show ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  focusBorderColor={"#45A735"}
                />
                <InputRightElement right={0}>
                  <Button
                    p={0}
                    bg={"transparent"}
                    _hover={{ bg: "transparent" }}
                    _focusVisible={{ outline: "none" }}
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <Image
                        src={
                          colorMode === "dark"
                            ? EyeCloseIcon
                            : EyeCloseIconLight
                        }
                        w={5}
                      />
                    ) : (
                      <Image
                        src={colorMode === "dark" ? EyeIcon : EyeIconLight}
                        w={5}
                      />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Шинэ нууц үг
              </FormLabel>
              <InputGroup
                w={"full"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Input
                  placeholder="-"
                  maxLength={30}
                  fontSize={15}
                  fontWeight={"normal"}
                  type={show2 ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor={"#45A735"}
                />
                <InputRightElement right={0}>
                  <Button
                    p={0}
                    bg={"transparent"}
                    _hover={{ bg: "transparent" }}
                    _focusVisible={{ outline: "none" }}
                    onClick={() => setShow2(!show2)}
                  >
                    {show2 ? (
                      <Image
                        src={
                          colorMode === "dark"
                            ? EyeCloseIcon
                            : EyeCloseIconLight
                        }
                        w={5}
                      />
                    ) : (
                      <Image
                        src={colorMode === "dark" ? EyeIcon : EyeIconLight}
                        w={5}
                      />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Нууц үг давтах
              </FormLabel>
              <InputGroup
                w={"full"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Input
                  placeholder="-"
                  maxLength={30}
                  fontSize={15}
                  fontWeight={"normal"}
                  type={show3 ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  focusBorderColor={"#45A735"}
                />
                <InputRightElement right={0}>
                  <Button
                    p={0}
                    bg={"transparent"}
                    _hover={{ bg: "transparent" }}
                    _focusVisible={{ outline: "none" }}
                    onClick={() => setShow3(!show3)}
                  >
                    {show3 ? (
                      <Image
                        src={
                          colorMode === "dark"
                            ? EyeCloseIcon
                            : EyeCloseIconLight
                        }
                        w={5}
                      />
                    ) : (
                      <Image
                        src={colorMode === "dark" ? EyeIcon : EyeIconLight}
                        w={5}
                      />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={
                oldPassword &&
                validPassword.test(password) &&
                validPassword.test(confirmPassword)
                  ? false
                  : true
              }
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ChangePassword;
