import React, { useState, useContext } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import DeleteRoom from "./DeleteRoom";
import AddDevice from "./AddDevice";
import UserContext from "../../context/UserContext";

const EditButton = ({ data, setTrigger }) => {
  const [roomName, setRoomName] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const submitHandler = () => {
    if (roomName) {
      // const roomData = JSON.stringify({
      //   _id: data._id,
      //   newName: roomName,
      // });
      let source = Core.CancelToken.source();
      Core.put(
        "/user_v2/room/update",
        { roomId: data._id, newName: roomName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          // console.log(res.data);
          onClose();
          setTrigger(true);
          setRoomName("");
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай засагдлаа",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          transition={"ease-in-out .3s"}
          rounded={"full"}
          _focusVisible={{
            outline: "none",
          }}
          _hover={{
            shadow: "md",
          }}
        >
          <Center
            w={"6"}
            h={"6"}
            rounded={"md"}
            borderColor={"#ababab"}
            borderWidth={"thin"}
          >
            <Text>...</Text>
          </Center>
        </MenuButton>
        <MenuList bg={colorMode === "dark" ? "#252B25" : "#fff"}>
          <MenuItem
            icon={
              <Image
                src={colorMode === "dark" ? pencilDark : pencil}
                w={"4"}
                h={"4"}
                objectFit={"contain"}
              />
            }
            fontSize={15}
            fontWeight={"normal"}
            onClick={() => onOpen()}
          >
            Өрөөний нэр солих
          </MenuItem>
          <AddDevice data={data} setTrigger={setTrigger} />
          <DeleteRoom data={data} setTrigger={setTrigger} />
        </MenuList>
      </Menu>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Өрөөний нэр солих</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Солих нэрээ оруулна уу
              </FormLabel>
              <Input
                placeholder={data.name}
                maxLength={30}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                focusBorderColor={"#45A735"}
                autoFocus
                onKeyDown={(e) => {
                  if (roomName) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={roomName ? false : true}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditButton;
