import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Auth from "./pages/Auth";
import Main from "./pages/Main";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home/Home";
import Cookies from "js-cookie";
import UserContext from "./context/UserContext";
import SignUp from "./pages/Auth/SignUp";
import SignUpVerify from "./pages/Auth/SignUpVerify";
import SignUpPassword from "./pages/Auth/SignUpPassword";
import Forgot from "./pages/Auth/Forgot";
import ForgotVerify from "./pages/Auth/ForgotVerify";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Automation from "./pages/Automate/Automation";
import Notification from "./pages/Notif/Notification";
import Profile from "./pages/Profile/Profile";
import HouseControl from "./pages/Home/House/HouseControl";
import SharedUserList from "./pages/Home/ShareDevice/SharedUserList";
import DeviceChangeName from "./pages/Home/EditDevice/DeviceChangeName";
import RoomControl from "./pages/Home/Room/RoomControl";
import CreateAutomation from "./pages/Automate/CreateAutomation";
import CreateGroup from "./pages/Automate/CreateGroup";
import CreateTtr from "./pages/Automate/TaptoRun/CreateTtr";
import TtrCategory from "./pages/Automate/TaptoRun/TtrCategory";
import SwitchDetail from "./pages/Home/Card/Regular/SwitchDetail";
import SensorDetail from "./pages/Home/Card/Regular/SensorDetail";
import GroupDetail from "./pages/Automate/GroupDetail";
import ZbridgeDetail from "./pages/Home/Card/Zbridge/ZbridgeDetail";
import PowrDetail from "./pages/Home/Card/Regular/Powr/PowrDetail";
import IrDetail from "./pages/Home/Card/Ir/IrDetail";
import PowrDash from "./pages/Home/Card/Regular/Powr/PowrDash";
import ThChart from "./pages/Home/Card/Chart/ThChart";
import SmtcChart from "./pages/Home/Card/Chart/SmtcChart";
import IrDash from "./pages/Home/Card/Ir/IrDash";
import RgbDash from "./pages/Home/Card/Rgb/RgbDash";
import IrSetup from "./pages/Home/Card/Ir/IrSetup";
import PaymentList from "./pages/Payment/PaymentList";
import PayScreen from "./pages/Payment/PayScreen";
import LgtChart from "./pages/Home/Card/Chart/LgtChart";
import LthChart from "./pages/Home/Card/Chart/LthChart";
import ZbridgeDevices from "./pages/Home/Card/Zbridge/ZbridgeDevices";
import DeviceReorder from "./pages/Home/DeviceReorder/DeviceReorder";
import CurtainControl from "./pages/Home/Card/Zigbee/Curtain/CurtainControl";
import CurtainDetail from "./pages/Home/Card/Zigbee/Curtain/CurtainDetail";
import CarbonChart from "./pages/Home/Card/Chart/CarbonChart";
import ZlanChart from "./pages/Home/Card/Chart/ZlanChart";
import Feedback from "./pages/Profile/Feedback/Feedback";
import WledControl from "./pages/Home/Card/Regular/Wled/WledControl";
import ZbAqiControl from "./pages/Home/Card/Zigbee/ZbAqi/ZbAqiControl";
import ZbAqiChart from "./pages/Home/Card/Chart/ZbAqiChart";
import AqsControl from "./pages/Home/Card/Regular/Sensor/AqsControl";
import AqsChart from "./pages/Home/Card/Chart/AqsChart";
import NewG4Control from "./pages/Home/Card/g4/newG4/NewG4Control";
import NewG4ChannelSettings from "./pages/Home/NewG4/NewG4ChannelSettings";

const App = () => {
  const context = useContext(UserContext);
  const accessToken = Cookies.get("accessToken");
  const isLog = Cookies.get("isLoggedIn");

  return (
    <Router>
      {isLog && accessToken ? (
        <Main>
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>

            {/* DEVICE DETAIL */}
            <Route path="/home/switch-detail">
              <SwitchDetail />
            </Route>
            <Route path="/home/zbridge-detail">
              <ZbridgeDetail />
            </Route>
            <Route path="/home/sensor-detail">
              <SensorDetail />
            </Route>
            <Route path="/home/power-detail">
              <PowrDetail />
            </Route>
            <Route path="/home/ir-detail">
              <IrDetail />
            </Route>
            <Route path="/home/curtain-detail">
              <CurtainDetail />
            </Route>
            <Route path="/home/th-chart">
              <ThChart />
            </Route>
            <Route path="/home/smtc-chart">
              <SmtcChart />
            </Route>
            <Route path="/home/lgt-chart">
              <LgtChart />
            </Route>
            <Route path="/home/lth-chart">
              <LthChart />
            </Route>
            <Route path="/home/carbon-chart">
              <CarbonChart />
            </Route>
            <Route path="/home/zlan-chart">
              <ZlanChart />
            </Route>
            <Route path="/home/aqi-chart">
              <ZbAqiChart />
            </Route>
            <Route path="/home/aqs-chart">
              <AqsChart />
            </Route>

            {/* DASH / CONTROL */}
            <Route path="/home/rgb-dash">
              <RgbDash />
            </Route>
            <Route path="/home/wled-control">
              <WledControl />
            </Route>
            <Route path="/home/powr-dash">
              <PowrDash />
            </Route>
            <Route path="/home/ir-dash">
              <IrDash />
            </Route>
            <Route path="/home/ir-setup/">
              <IrSetup />
            </Route>
            <Route path="/home/zbridge-devices">
              <ZbridgeDevices />
            </Route>
            <Route path="/home/curtain-control">
              <CurtainControl />
            </Route>
            <Route path="/home/zbAqi-control">
              <ZbAqiControl />
            </Route>
            <Route path="/home/aqs-control">
              <AqsControl />
            </Route>
            <Route path="/home/newG4-control">
              <NewG4Control />
            </Route>

            {/* CONTROL and OTHERS */}
            <Route path="/home/house-control">
              <HouseControl />
            </Route>
            <Route path="/home/room-control">
              <RoomControl />
            </Route>
            <Route path="/home/device-control">
              <DeviceChangeName />
            </Route>
            <Route exact path="/home/device-reorder">
              <DeviceReorder />
            </Route>
            <Route exact path="/home/newG4-channel-settings">
              <NewG4ChannelSettings />
            </Route>
            <Route path="/home/shared-user">
              <SharedUserList />
            </Route>
            <Route exact path="/automation">
              <Automation />
            </Route>
            <Route path="/automation/create-automation">
              <CreateAutomation />
            </Route>
            <Route path="/automation/create-group">
              <CreateGroup />
            </Route>
            <Route path="/automation/group-detail">
              <GroupDetail />
            </Route>
            <Route path="/automation/ttr-category">
              <TtrCategory />
            </Route>
            <Route path="/automation/create-ttr">
              <CreateTtr />
            </Route>
            <Route exact path="/notification">
              <Notification />
            </Route>
            <Route exact path="/settings">
              <Profile />
            </Route>
            <Route exact path="/feedback">
              <Feedback />
            </Route>
            <Route exact path="/payment">
              <PaymentList />
            </Route>
            <Route path="/payment/pay">
              <PayScreen />
            </Route>
            <Redirect to="/home" />
          </Switch>
        </Main>
      ) : (
        <Auth>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signup">
              <SignUp />
            </Route>
            <Route exact path="/signup-verify">
              <SignUpVerify />
            </Route>
            <Route exact path="/signup-password">
              <SignUpPassword />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/forgot-verify">
              <ForgotVerify />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Redirect to="/login" />
          </Switch>
        </Auth>
      )}
    </Router>
  );
};

export default App;
