import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Image,
  Center,
  Switch,
  Spinner,
  useToast,
  Input,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import socket from "../../../hooks/Socket";
import g4 from "../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../assets/Images/CardIcons/g4Dark.png";

// TODO: THIS COMPONENT IS A SINGLE newG4 CHANNEL CARD
function NewG4Channel({ device }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { Core } = axios();
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(device);

  //   TODO: switch on off handler
  const handleToggle = (command) => {
    // console.log(datas._id, command);

    setIsLoading(true);
    Core.post("/user_v2/command/new_g4", {
      _id: datas._id,
      command,
    })
      .then((res) => {
        // console.log("channel command", res);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("channel command", err);
        setIsLoading(false);

        const error =
          typeof err?.response?.data?.error === "string"
            ? err.response.data.error
            : "Алдаа гарлаа!";

        toast({
          description: error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      });
  };

  //   TODO: SOCKET
  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("newG4 channel socket data --->", value);

      if (datas?._id === value?.channelId) {
        setDatas((prev) => ({
          ...prev,
          isOn: value.isOn,
        }));
      }
    };

    socket.on(device._id, socketSetData);
    return () => {
      socket.off(device._id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      px={"6"}
      py={5}
    >
      <HStack justify="space-between" w="full">
        <Center
          w={"12"}
          h={"12"}
          rounded={"md"}
          bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
          alignSelf={"flex-start"}
        >
          <Image
            src={colorMode === "dark" ? g4Dark : g4}
            w={"5"}
            objectFit={"contain"}
          />
        </Center>

        {isLoading ? (
          <Spinner size="sm" colorScheme="green" color="#45A735" />
        ) : (
          <Switch
            css={`
              > span:first-of-type {
                box-shadow: unset;
              }
            `}
            colorScheme={"green"}
            onChange={(event) =>
              handleToggle(event.target.checked ? "on" : "off")
            }
            isChecked={datas.isOn}
          />
        )}
      </HStack>

      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
      >
        <Text fontSize={15} fontWeight={"medium"} cursor="unset">
          {datas.name}
        </Text>
      </VStack>
    </VStack>
  );
}

export default NewG4Channel;
