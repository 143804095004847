import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import rgb from "../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../assets/Images/CardIcons/rgbDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import axios from "../../../axios";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";

const RGB = ({
  roomName,
  deviceName,
  power,
  dimmer,
  hue,
  sat,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    power: power,
    dimmer: dimmer,
    hue: hue,
    sat: sat,
    notifEnabled: notifEnabled,
    test: 0,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const token = Cookies.get("accessToken");
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("rgb data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          power: value.status === "active" ? value.data.Power : 0,
          hue: value.status === "active" ? value.data.hue : 0,
          sat: value.status === "active" ? value.data.sat : 100,
          dimmer: value.status === "active" ? value.data.dimmer : 254,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log("rgb name:", value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  const switchHandler = () => {
    setIsDisable(true);
    if (datas.power === 0 || datas.power === 1) {
      // console.log("powerData", datas.power);
      const data = {
        command: datas.power === 0 ? 1 : 0,
        _id: datas.id,
      };
      // console.log("data", data);
      let source = Core.CancelToken.source();
      Core.post(
        "/user/rgb_switch",
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          // console.log("switch", result.data.data);
          setDatas((prevState) => ({
            ...prevState,
            power: datas.power === 1 ? 0 : 1,
          }));
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;
        });
    } else {
      setIsDisable(false);
    }
  };

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          w={"full"}
          onClick={() =>
            history.push("/home/rgb-dash", {
              roomName: datas.room,
              deviceName: datas.name,
              power: datas.power,
              dimmer: datas.dimmer,
              hue: datas.hue,
              sat: datas.sat,
              status: datas.status,
              id,
              isOriginal,
              origin,
              wifiSignal,
              notifEnabled: datas.notifEnabled,
              deviceType: deviceType,
              blocked,
            })
          }
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? rgbDark : rgb}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Center w={"10"} h={"10"} rounded={"md"}>
            <Switch
              isChecked={datas.power === 1 ? true : false}
              colorScheme="green"
              size="md"
              onChange={blocked === false ? switchHandler : null}
              isDisabled={blocked || isDisable ? true : false}
              css={`
                > span:first-of-type {
                  box-shadow: unset;
                }
              `}
            />
          </Center>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/rgb-dash", {
            roomName: datas.room,
            deviceName: datas.name,
            power: datas.power,
            dimmer: datas.dimmer,
            hue: datas.hue,
            sat: datas.sat,
            status: datas.status,
            id,
            isOriginal,
            origin,
            wifiSignal,
            notifEnabled: datas.notifEnabled,
            deviceType: deviceType,
            blocked,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default RGB;
