import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useToast,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../components/Button/BackButton";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { FiInfo, FiMinusCircle } from "react-icons/fi";
import { BiSearchAlt } from "react-icons/bi";
import motion from "../../../../assets/Images/CardIcons/motion.png";
import motionDark from "../../../../assets/Images/CardIcons/motionDark.png";
import contact from "../../../../assets/Images/CardIcons/contact.png";
import contactDark from "../../../../assets/Images/CardIcons/contactDark.png";
import rgb from "../../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../../assets/Images/CardIcons/rgbDark.png";
import th from "../../../../assets/Images/CardIcons/th.png";
import thDark from "../../../../assets/Images/CardIcons/thDark.png";
import water from "../../../../assets/Images/CardIcons/water.png";
import waterDark from "../../../../assets/Images/CardIcons/waterDark.png";
import smoke from "../../../../assets/Images/CardIcons/smoke.png";
import smokeDark from "../../../../assets/Images/CardIcons/smokeDark.png";
import ZbridgeSearch from "./ZbridgeSearch";
import UserContext from "../../../../context/UserContext";
import curtainClose from "../../../../assets/Images/icons/curtainClose.png";
import curtainCloseDark from "../../../../assets/Images/icons/curtainCloseDark.png";
import zbAqi from "../../../../assets/Images/CardIcons/zbAqi.png";
import zbAqiDark from "../../../../assets/Images/CardIcons/zbAqiDark.png";
import light from "../../../../assets/Images/CardIcons/light.png";
import lightDark from "../../../../assets/Images/CardIcons/lightDark.png";

const ZbridgeDevices = () => {
  const [trigger, setTrigger] = useState(true);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const location = useLocation();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  useEffect(() => {
    let source = Core.CancelToken.source();
    let cancel = false;
    Core.get("/user/zigbee_entity", {
      params: {
        _id: location.state,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        setDevices(result.data.data);
        setTrigger(false);
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, [trigger]);

  const deleteHandler = () => {
    if (deviceId) {
      setIsDisable(true);
      Core.delete("/user/zigbee_entity", {
        data: { _id: deviceId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай устлаа!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
          setDeviceId("");
          setIsDisable(false);
          setTrigger(true);
        })
        .catch((err) => {
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <VStack h="full" spacing={10} w="full">
      <HStack w="full" justify={"start"}>
        <BackButton />
        <Text fontWeight={"normal"}>Төхөөрөмжийн жагсаалт</Text>
      </HStack>

      <Stack
        w={"full"}
        h={"full"}
        rounded={"md"}
        className="bgColor"
        py={["6", "6", "6", "6", "28"]}
        px={"36"}
        spacing={["10", "10", "10", "10", "20"]}
        align={"flex-start"}
        justify={"flex-start"}
        direction={["column", "column", "column", "column", "row"]}
      >
        <VStack w={"full"} spacing={6}>
          <ZbridgeSearch DeviceId={location.state} setTrigger={setTrigger} />
          <VStack
            w={"full"}
            bg={colorMode === "dark" ? "#395533" : "#ECFFE8"}
            borderWidth={"thin"}
            borderColor={"#45A735"}
            px={"10"}
            py={"4"}
            align={"flex-start"}
            justify={"flex-start"}
            rounded={"md"}
          >
            <Text fontSize={17} fontWeight={"medium"} alignSelf={"center"}>
              Зөвлөмж
            </Text>
            <Text fontSize={14} fontWeight={"normal"}>
              Pairing mode (Гэрэл анивчиж байгаа үед ) холбогдоно.
            </Text>
            <Text fontSize={14}>
              Хэрвээ төхөөрөмжин дээрх гэрэл анивчихгүй байгаа бол та
              төхөөрөмжөө reset хийх шаардлагатай.
            </Text>
            <Text fontSize={14}>Төхөөрөмжөө reset хийхийн тулд:</Text>
            <Text pl={"4"} fontSize={14}>
              1. Мэдрэгч төхөөрөмжөө асаан RESET гэсэн товчийг 5 секунд удаан
              дарна.
            </Text>
            <Text pl={"4"} fontSize={14}>
              2. Залгуураа асаан RESET гэсэн товчийг 5 секунд удаан дарна.
            </Text>
            <Text pl={"4"} fontSize={14}>
              3. Гэрлийн эх үүсвэрээ асаан дараа нь дахиад унтрааж асаана.
            </Text>
          </VStack>
        </VStack>
        <VStack w={"full"}>
          {trigger ? (
            <Center w={"full"}>
              <Spinner size="md" color="#45A735" mt={"10vh"} />
            </Center>
          ) : devices.length > 0 ? (
            <VStack
              w={"full"}
              spacing={3}
              p={"1"}
              maxH={["35vh", "35vh", "35vh", "45vh", "50vh"]}
              overflowY="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              {devices.map((e, i) => (
                <HStack
                  key={i}
                  transition={"ease-in-out .3s"}
                  w={"full"}
                  bg={colorMode === "dark" ? "#202420" : "#fff"}
                  rounded={"md"}
                  py={"3"}
                  px={"8"}
                  align={"center"}
                  justify={"space-between"}
                  onClick={() => {
                    onOpen();
                    setDeviceId(e._id);
                  }}
                  cursor={"pointer"}
                  _hover={{
                    shadow: "md",
                  }}
                >
                  <HStack>
                    <Image
                      src={
                        e.deviceType === "Water"
                          ? colorMode === "dark"
                            ? waterDark
                            : water
                          : e.deviceType === "Occupancy" ||
                            e.deviceType === "humanDetector"
                          ? colorMode === "dark"
                            ? motionDark
                            : motion
                          : e.deviceType === "zigbeeTh"
                          ? colorMode === "dark"
                            ? thDark
                            : th
                          : e.deviceType === "Contact"
                          ? colorMode === "dark"
                            ? contactDark
                            : contact
                          : e.deviceType === "Smoke"
                          ? colorMode === "dark"
                            ? smokeDark
                            : smoke
                          : e.deviceType === "RGB"
                          ? colorMode === "dark"
                            ? rgbDark
                            : rgb
                          : e.deviceType === "Curtain"
                          ? colorMode === "dark"
                            ? curtainCloseDark
                            : curtainClose
                          : e.deviceType === "zbSwitch"
                          ? colorMode === "dark"
                            ? lightDark
                            : light
                          : e.deviceType === "zbAqi"
                          ? colorMode === "dark"
                            ? zbAqiDark
                            : zbAqi
                          : null
                      }
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                    <Text>{e.for}</Text>
                  </HStack>
                  <Icon as={FiMinusCircle} w={"5"} h={"5"} color={"#ff0000"} />
                </HStack>
              ))}
            </VStack>
          ) : (
            <VStack
              justifyContent="center"
              alignItems="center"
              alignSelf={"center"}
              color="gray.600"
              spacing={1}
            >
              <Icon as={FiInfo} w={6} h={6} />
              <Text fontSize={14} fontWeight={"normal"}>
                Мэдээлэл олдсонгүй.
              </Text>
            </VStack>
          )}
        </VStack>
      </Stack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Устгах уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteHandler()}
              _focusVisible={{ outline: "none" }}
              isDisabled={isDisable}
            >
              {isDisable ? <Spinner mr={"2"} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ZbridgeDevices;
