import { io } from "socket.io-client";

const socket = new io(
  "https://micro.viot.mn:4550",
  // "http://192.168.1.127:4550",
  // "http://192.168.1.247:4550",
  { transports: ["websocket"] }
);

socket.on("connect", () => {
  console.log("connect", socket.id);
});

socket.on("connect_error", (error) => {
  console.log("error", error);
});

export default socket;
