import React from "react";
import { HStack, Image, VStack } from "@chakra-ui/react";
import bg from "../assets/Images/authBg.png";
import logo from "../assets/Images/logo.png";
import {
  isMobileOnly,
  isSamsungBrowser,
  isMobileSafari,
} from "react-device-detect";
import RestrictMobile from "./RestrictMobile";

const Auth = ({ children }) => {
  if (isSamsungBrowser || isMobileSafari || isMobileOnly) {
    return <RestrictMobile />;
  }

  return (
    <HStack
      w={"100vw"}
      h={"100vh"}
      bg={"#fff"}
      position={"relative"}
      spacing={0}
    >
      <Image
        src={logo}
        w={"52"}
        objectFit={"contain"}
        pos={"absolute"}
        top={10}
        left={10}
      />
      <VStack
        w={"full"}
        h={"full"}
        display={["none", "none", "none", "flex", "flex"]}
      >
        <Image src={bg} w={"full"} h={"full"} objectFit={"cover"} />
      </VStack>
      <VStack
        w={"full"}
        h={"full"}
        align={"center"}
        justify={"center"}
        zIndex={99}
      >
        {children}
      </VStack>
    </HStack>
  );
};

export default Auth;
