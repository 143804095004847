import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import axios from "../../axios";
import phone from "../../assets/Images/icons/phone.png";
import phoneDark from "../../assets/Images/icons/phoneDark.png";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import EyeIcon from "../../assets/Images/icons/eyeIcon.png";
import EyeIconLight from "../../assets/Images/icons/eyeIconLight.png";
import EyeCloseIcon from "../../assets/Images/icons/eyeCloseIcon.png";
import EyeCloseIconLight from "../../assets/Images/icons/eyeCloseIconLight.png";
import UserContext from "../../context/UserContext";

const ChangeUsername = ({ username }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [checkData, setCheckData] = useState(false);
  const [code, setCode] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setAccessToken, setRefreshToken, handledHttpErrors } =
    useContext(UserContext);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { Core } = axios();

  const phoneHandler = () => {
    if (phoneNumber && password) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      Core.post(
        "user/profile",
        {
          username: username,
          currentPassword: password,
          newPhone: phoneNumber,
          type: "changePhone",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (result.data.success) {
            setCheckData(true);
          } else {
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "error",
                duration: 2000,
                position: "top",
                description: "Алдаа гарлаа!",
              });
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description:
                err.response.data.error === "wrong password"
                  ? "Хуучин нууц үг буруу байна!"
                  : err.response.status == 400
                  ? "Энэ утас бүртгэлтэй байна!"
                  : "Алдаа гарлаа",
            });
          }
        });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          status: "error",
          duration: 2000,
          position: "top",
          description: "Талбарыг бөглөнө үү!",
        });
      }
    }
  };

  const submitHandler = () => {
    if (code) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.post(
        "user/setnewphone",
        {
          phoneNumber: phoneNumber,
          oldPhoneNumber: username,
          message: parseInt(code),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((res) => {
          if (cancel) return;
          // console.log(res);
          if (res.data.success) {
            onClose();
            setCheckData(false);
            Cookies.set("username", phoneNumber);
            Cookies.set("accessToken", res.data.accessToken);
            Cookies.set("refreshToken", res.data.refreshToken);
            setAccessToken(res.data.accessToken);
            setRefreshToken(res.data.refreshToken);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: "Таны оруулсан код буруу байна!",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  };

  return (
    <VStack align={"flex-start"} spacing={0}>
      <Text fontSize={15} fontWeight={"normal"}>
        Утасны дугаар
      </Text>
      <InputGroup
        className="settingsInput"
        rounded={"lg"}
        onClick={() => onOpen()}
        cursor={"pointer"}
      >
        <InputLeftElement
          pointerEvents="none"
          children={
            <Image
              src={colorMode === "dark" ? phoneDark : phone}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
        <Input
          placeholder={username}
          w={"xs"}
          fontSize={15}
          fontWeight={"normal"}
          border={"none"}
          isReadOnly
        />
        <InputRightElement
          children={
            <Image
              src={colorMode === "dark" ? pencilDark : pencil}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
      </InputGroup>
      <Modal
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>
            {checkData ? "Баталгаажуулах код" : "Утасны дугаар солих"}
          </ModalHeader>
          <ModalCloseButton
            _focusVisible={{ outline: "none" }}
            onClick={() => {
              onClose();
              setCheckData(false);
              setPhoneNumber("");
              setPassword("");
            }}
          />
          {checkData ? (
            <ModalBody>
              <VStack>
                <Text fontSize={15} fontWeight={"normal"}>
                  Таны ( +976 {phoneNumber} ) дугаарлуу баталгаажуулах кодыг
                  илгээлээ.
                </Text>
                <HStack
                  w={"full"}
                  align="center"
                  justify={"center"}
                  spacing={3}
                >
                  <PinInput
                    size="lg"
                    autoFocus
                    value={code}
                    onChange={(e) => setCode(e)}
                  >
                    <PinInputField
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                    <PinInputField
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                    <PinInputField
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                    <PinInputField
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                    <PinInputField
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#45A735",
                      }}
                    />
                  </PinInput>
                </HStack>
              </VStack>
            </ModalBody>
          ) : (
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel fontSize={15} fontWeight={"normal"}>
                  Шинэ утасны дугаар
                </FormLabel>
                <Input
                  placeholder="-"
                  maxLength={8}
                  type={"tel"}
                  fontSize={15}
                  fontWeight={"normal"}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  focusBorderColor={"#45A735"}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontSize={15} fontWeight={"normal"}>
                  Нууц үг
                </FormLabel>
                <InputGroup
                  w={"full"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  ref={initialRef}
                >
                  <Input
                    placeholder="-"
                    maxLength={30}
                    fontSize={15}
                    fontWeight={"normal"}
                    type={show ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    focusBorderColor={"#45A735"}
                    onKeyDown={(e) => {
                      if (phoneNumber && password) {
                        if (e.key === "Enter") {
                          phoneHandler();
                        }
                      }
                    }}
                  />
                  <InputRightElement right={0}>
                    <Button
                      p={0}
                      bg={"transparent"}
                      _hover={{ bg: "transparent" }}
                      _focusVisible={{ outline: "none" }}
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <Image
                          src={
                            colorMode === "dark"
                              ? EyeCloseIcon
                              : EyeCloseIconLight
                          }
                          w={5}
                        />
                      ) : (
                        <Image
                          src={colorMode === "dark" ? EyeIcon : EyeIconLight}
                          w={5}
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setCheckData(false);
                setPhoneNumber("");
                setPassword("");
              }}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            {checkData ? (
              <Button
                colorScheme="green"
                onClick={submitHandler}
                _focusVisible={{ outline: "none" }}
                isDisabled={code.length > 4 && !isLoading ? false : true}
              >
                {isLoading ? <Spinner mr={2} size="sm" /> : null}
                Хадгалах
              </Button>
            ) : (
              <Button
                colorScheme="green"
                onClick={phoneHandler}
                _focusVisible={{ outline: "none" }}
                isDisabled={
                  phoneNumber.length > 7 && password && !isLoading
                    ? false
                    : true
                }
              >
                {isLoading ? <Spinner mr={2} size="sm" /> : null}
                Үргэлжлүүлэх
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ChangeUsername;
