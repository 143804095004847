import ColorPicker from "@radial-color-picker/react-color-picker";
import "@radial-color-picker/react-color-picker/dist/react-color-picker.min.css";
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Center,
  HStack,
  Text,
  useColorMode,
  VStack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import UserContext from "../../../../../context/UserContext";
import socket from "../../../../../hooks/Socket";
import convert from "color-convert";

function WledHuePicker({
  hue,
  sat,
  dimmer,
  color,
  setHue,
  state,
  setState,
  blocked,
  id,
  setCanSendSocket,
  status,
}) {
  const [updatedColor, setUpdatedColor] = useState("");
  const location = useLocation();
  const { colorMode } = useColorMode();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const [onLoading, setOnLoading] = useState(false);
  const [offLoading, setOffLoading] = useState(false);
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);
  const [lastHue, setLastHue] = useState(0);
  const [lastDate, setLastDate] = useState(1);

  useEffect(() => {
    setLastHue(hue);
  }, []);

  const [colorValue, setColorValue] = React.useState({
    hue: hue,
    saturation: sat,
    luminosity: 50,
    alpha: 1,
  });

  useEffect(() => {
    // console.log("child color changed to " + color);
    setUpdatedColor(color);
  }, [color]);

  // --------------     RGB  АСААХ   --------------------
  const rgbOnHandler = () => {
    setOnLoading(true);
    if (state === "off") {
      let source = Core.CancelToken.source();
      Core.post(
        "/user_v2/command/wled_switch",
        { command: "on", _id: location.state.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          // console.log("switch", result.data.data);
          setState(result.data.data);
          setOnLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setOnLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            position: "top",
          });
        });
    } else {
      setOnLoading(false);
    }
  };

  // -----------------   RGB  УНТРААХ     --------------------
  const rgbOffHandler = () => {
    setOffLoading(true);
    if (state === "on") {
      let source = Core.CancelToken.source();
      Core.post(
        "/user_v2/command/wled_switch",
        { command: "off", _id: location.state.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          // console.log("switch", result.data.data);
          setState(result.data.data);
          setOffLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setOffLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;
          toast({
            description: "Алдаа гарлаа",
            status: "error",
            duration: 2000,
            position: "top",
          });
        });
    } else {
      setOffLoading(false);
    }
  };

  // ---------    choosing hue  ( happen when choose color from the round wheel )     ------------
  const onInput = (hue) => {
    setCanSendSocket(true);
    setHue(parseInt(hue));
    setColorValue((prev) => {
      return {
        ...prev,
        hue,
      };
    });
  };

  // ----------------------------------------------------------------
  // useEffect(() => {
  //   // console.log("LAST HUE: ", lastHue);
  //   // console.log(parseInt(hue));

  //   let cDate = new Date();
  //   cDate = cDate.getTime();

  //   setLastHue(parseInt(hue));

  //   if (Math.abs(parseInt(hue) - parseInt(lastHue)) > 2) {
  //     // console.log("lastDate", lastDate);
  //     // console.log("cDate", cDate);

  //     if (lastDate === 1) {
  //       console.log("send socket", Math.abs(parseInt(hue) - parseInt(lastHue)));
  //       setLastDate(cDate);
  //     } else if (cDate - lastDate > 100) {
  //       console.log("send socket", Math.abs(parseInt(hue) - parseInt(lastHue)));
  //       setLastDate(cDate);
  //     }

  //     // console.log("send socket", Math.abs(parseInt(hue) - parseInt(lastHue)));
  //   }

  //   // socket.emit("wled_set_color", { id, color: updatedColor });
  // }, [hue]);
  // ----------------------------------------------------------------

  // useEffect(() => {
  //   setLastHue(hue);

  //   if (Math.abs(hue - lastHue) > 1) {
  //     // socket.emit("wled_set_color", { id, color: updatedColor, dimmer });
  //     console.log("send socket", hue);
  //   } else if (hue - lastHue === 1) {
  //     let newHue = hue;
  //     let newLastHue = lastHue;

  //     if (hue % 2 === 1) {
  //       newHue = newHue - 1;
  //     }

  //     if (lastHue % 2 === 1) {
  //       newLastHue = newLastHue - 1;
  //     }

  //     if (Math.abs(newHue - newLastHue) === 2) {
  //       // socket.emit("wled_set_color", { id, color: updatedColor, dimmer });
  //       console.log("send socket", hue);
  //     }
  //   }
  // }, [hue]);

  useEffect(() => {
    setColorValue({
      ...colorValue,
      hue: hue,
      sat: sat,
      dimmer: dimmer,
    });
  }, [hue, sat, dimmer]);

  return (
    <>
      <VStack px="12" py="6" borderRadius={"3xl"} className="rgb">
        <Box w="fit-content" h="fit-content" pos={"relative"}>
          <ColorPicker
            disabled={status !== "active" ? true : false}
            luminosity={50}
            sat={sat}
            hue={hue}
            {...colorValue}
            onInput={onInput}
          />
          <Box
            w="85%"
            h="85%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            borderRadius="50%"
            className="rgb"
          ></Box>
          <Box
            width="55%"
            height="55%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            bg="black"
            borderRadius="50%"
            backgroundColor={color}
          ></Box>
        </Box>

        {/*  FOOTER,  2 BUTTONS  */}
        <HStack pt={4} justify={"center"} spacing={6} w="full">
          <Center
            py={2}
            w={"45%"}
            borderRadius={"3xl"}
            borderWidth={"thin"}
            borderColor={state === "off" ? "#45A735" : null}
            color={state === "off" ? "#45A735" : "#adacac"}
            transition={"all .2s ease-in-out"}
            _hover={
              status === "active"
                ? state === "off"
                  ? {
                      bg: "#45A735",
                      color: "white",
                    }
                  : null
                : null
            }
            cursor={
              status !== "active"
                ? "not-allowed"
                : blocked
                ? "not-allowed"
                : onLoading === true || state === "on"
                ? "not-allowed"
                : "pointer"
            }
            onClick={
              status === "active"
                ? blocked
                  ? null
                  : onLoading
                  ? null
                  : rgbOnHandler
                : null
            }
            boxShadow={
              colorMode === "dark"
                ? "0px 0px 5px rgba(255, 255, 255, 0.2)"
                : "0px 0px 5px rgba(0, 0, 0, 0.2)"
            }
          >
            {onLoading ? (
              <Spinner
                mr="2"
                size="sm"
                color={colorMode === "light" ? "black" : "white"}
              />
            ) : null}
            <Text fontSize={13}>Асаах</Text>
          </Center>
          <Center
            py={2}
            w={"45%"}
            borderRadius={"3xl"}
            borderWidth={"thin"}
            borderColor={state === "on" ? "#ff0000" : null}
            color={state === "on" ? "#ff0000" : "#adacac"}
            transition={"all .2s ease-in-out"}
            _hover={
              status === "active"
                ? state === "on"
                  ? {
                      bg: "#e02828",
                      color: "white",
                    }
                  : null
                : null
            }
            cursor={
              status !== "active"
                ? "not-allowed"
                : blocked
                ? "not-allowed"
                : offLoading === true || state === "off"
                ? "not-allowed"
                : "pointer"
            }
            onClick={
              status === "active"
                ? blocked
                  ? null
                  : offLoading
                  ? null
                  : rgbOffHandler
                : null
            }
            boxShadow={
              colorMode === "dark"
                ? "0px 0px 5px rgba(255, 255, 255, 0.2)"
                : "0px 0px 5px rgba(0, 0, 0, 0.2)"
            }
          >
            {offLoading ? (
              <Spinner
                mr="2"
                size="sm"
                color={colorMode === "light" ? "black" : "white"}
              />
            ) : null}

            <Text fontSize={13}>Унтраах</Text>
          </Center>
        </HStack>
      </VStack>
      <style>{`
        .rcp__well {
            position: absolute;
            width: 50%;
            height: 50%;
            top: 25%;
            left: 25%;
            padding: 0;
            margin: 0;
            border-radius: 50%;
            background-color: red;
            outline: 0;
            overflow: visible;
            border: none;
            box-shadow: none;
        }

        .rcp__knob {
            box-shadow: 0 0 10px rgba(0,0,0,.12), 0 0 5px rgba(0,0,0,.16);
            border-radius: 50%;
            position: absolute;
            width: 6%;
            height: 6%;
            top: 0.5%;
            left: 46.5%;
            background-color: white;
            transition: transform .4s cubic-bezier(.35,0,.25,1);
            outline: 0;
            border-style: none;
            z-index: 10;
        }

        .rcp {
            display: block;
            overflow: hidden;
            width: 270px;
            height: 270px;
            position: relative;
            transition: transform .15s cubic-bezier(.68,0,.47,2);
            cursor: pointer;
        }

        .rcp.dragging {
            transform: none;
        }
      `}</style>
    </>
  );
}

export default WledHuePicker;
