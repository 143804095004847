import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  HStack,
  Icon,
  Image,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import house from "../../assets/Images/icons/house.png";
import houseDark from "../../assets/Images/icons/houseDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import Cookies from "js-cookie";
import axios from "../../axios";
import { FiInfo } from "react-icons/fi";
import UserContext from "../../context/UserContext";

const DeviceChangeHouse = () => {
  const [focusedModal, setFocusedModal] = useState(false);
  const [houses, setHouses] = useState([]);
  const [selectHouse, setSelectHouse] = useState();
  const [devices, setDevices] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setHouseTrigger, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    if (houseId && focusedModal === true) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get(
        "/user/house",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (cancel) return;
          // console.log("changeHouse", result.data.data);
          const houses = result.data.data.filter((e) => {
            return e._id !== houseId;
          });
          setHouses(houses);
        })
        .catch((err) => {
          // console.log(err);
          if (handledHttpErrors.includes(err.response.status)) return;
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [focusedModal]);

  useEffect(() => {
    if (houseId && focusedModal === true) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get(
        "/user/device_shared_change_house",
        {
          params: {
            house: houseId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          setDevices(
            result.data.data.map((e) => {
              return {
                _id: e._id,
                for: e.for,
                deviceType: e.deviceType,
                check: false,
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          if (handledHttpErrors.includes(err.response.status)) return;
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [focusedModal]);

  const selectHandler = ({ id, index }) => {
    const clone = [...devices];
    clone[index].check = !clone[index].check;
    setDevices(clone);

    setSelectDevices((prevState) => {
      return [...prevState, id];
    });
  };

  const removeHandler = ({ id, index }) => {
    const clone = [...devices];
    clone[index].check = !clone[index].check;
    setDevices(clone);

    setSelectDevices((prevState) => prevState.filter((e) => e !== id));
  };

  const submitHandler = () => {
    if (selectHouse && selectDevices.length > 0) {
      setDisabled(true);
      const data = {
        oldHouse: houseId,
        newHouse: selectHouse,
        deviceId: selectDevices,
      };
      Core.post("/user/device_shared_change_house", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((result) => {
          // console.log(result.data);
          onClose();
          setSelectDevices([]);
          setSelectHouse();
          setHouseTrigger(true);
          setDisabled(false);
          setFocusedModal(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              description: "Амжилттай!",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setDisabled(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              description: "Алдаа гарлаа!",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <>
      <MenuItem
        icon={
          <Image
            src={colorMode === "dark" ? houseDark : house}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        }
        fontSize={15}
        fontWeight={"normal"}
        onClick={() => {
          onOpen();
          setFocusedModal(true);
        }}
      >
        Байшин хооронд төхөөрөмж шилжүүлэх
      </MenuItem>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Байшин хооронд төхөөрөмж шилжүүлэх</ModalHeader>
          <ModalCloseButton
            _focusVisible={{ outline: "none" }}
            onClick={() => {
              onClose();
              setFocusedModal(false);
            }}
          />
          <ModalBody
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} align={"flex-start"} spacing={4}>
              <Select
                placeholder="Байшин сонгоно уу!"
                size="md"
                fontSize={15}
                fontWeight={"normal"}
                onChange={(e) => setSelectHouse(e.target.value)}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                {houses.length > 0
                  ? houses.map((e, index) => (
                      <option key={index} value={e._id}>
                        {e.name}
                      </option>
                    ))
                  : null}
              </Select>

              <VStack w={"full"}>
                {isLoading ? (
                  <Spinner size="lg" color="#45A735" alignSelf={"center"} />
                ) : devices.length > 0 ? (
                  devices.map((e, index) => (
                    <HStack
                      key={index}
                      w={"full"}
                      py={"1"}
                      px={"4"}
                      bg={
                        index % 2 === 0
                          ? colorMode === "dark"
                            ? "#1D211D"
                            : "#fff"
                          : colorMode === "dark"
                          ? "#202420"
                          : "#F9F9F9"
                      }
                      rounded={"md"}
                      justify={"space-between"}
                      align={"center"}
                      onClick={() =>
                        e.check
                          ? removeHandler({ id: e._id, index })
                          : selectHandler({ id: e._id, index })
                      }
                      cursor={"pointer"}
                    >
                      <Text fontSize={15} fontWeight={"normal"}>
                        {e.for}
                      </Text>
                      <Image
                        src={e.check ? checked : check}
                        w={"4"}
                        h={"4"}
                        objectFit="contain"
                      />
                    </HStack>
                  ))
                ) : (
                  <VStack
                    justifyContent="center"
                    alignItems="center"
                    alignSelf={"center"}
                    color="gray.600"
                    spacing={1}
                  >
                    <Icon as={FiInfo} w={6} h={6} />
                    <Text fontSize={14} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setFocusedModal(false);
              }}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              isDisabled={
                selectDevices && selectHouse && !disabled ? false : true
              }
              _focusVisible={{ outline: "none" }}
            >
              {disabled ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeviceChangeHouse;
