import React from "react";
import { Image, Text, VStack, Link } from "@chakra-ui/react";
import logo from "../assets/Images/logo.png";
import earth from "../assets/Images/earth.png";
import AppStore from "../assets/Images/AppStore.png";
import PlayStore from "../assets/Images/PlayStore.png";

const RestrictMobile = () => {
  return (
    <VStack
      w={"full"}
      h={"88vh"}
      bg={"#fff"}
      backgroundImage={earth}
      backgroundRepeat="no-repeat"
      backgroundSize={"contain"}
      backgroundPosition={"bottom"}
      align={"center"}
      justify={"flex-start"}
      mt={"10"}
      spacing={12}
    >
      <Image src={logo} w={"56"} objectFit={"contain"} />
      <VStack spacing={10} w={"xs"}>
        <Text
          fontSize={15}
          color={"#000"}
          fontWeight={"medium"}
          textAlign={"center"}
        >
          Гар утаснаас хандах боломжгүй тул аппликейшн татна уу!
        </Text>
      </VStack>
      <VStack spacing={4}>
        <Link
          href="https://apps.apple.com/us/app/virtuoso-iot/id1617732575"
          w={"full"}
          _focusVisible={{
            outline: "none",
          }}
          _hover={{
            textDecoration: "none",
          }}
          isExternal
        >
          <Image src={AppStore} w={"48"} objectFit={"contain"} />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.viot"
          w={"full"}
          _focusVisible={{
            outline: "none",
          }}
          _hover={{
            textDecoration: "none",
          }}
          isExternal
        >
          <Image src={PlayStore} w={"48"} objectFit={"contain"} />
        </Link>
      </VStack>
    </VStack>
  );
};

export default RestrictMobile;
