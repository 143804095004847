import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { UserStore } from "./context/UserContext";
import { UserAuth } from "./hooks/UseAuth";
import theme from "./theme";

const root = createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <UserStore>
      <UserAuth>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      </UserAuth>
    </UserStore>
  </ChakraProvider>
  // </React.StrictMode>
);
