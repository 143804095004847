import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import sunset from "../../assets/Images/icons/sunset.png";
import sunrise from "../../assets/Images/icons/sunrise.png";
import time from "../../assets/Images/icons/time.png";
import timeDark from "../../assets/Images/icons/timeDark.png";
import close from "../../assets/Images/icons/close.png";
import closeDark from "../../assets/Images/icons/closeDark.png";
import light from "../../assets/Images/CardIcons/light.png";
import lightDark from "../../assets/Images/CardIcons/lightDark.png";
import th from "../../assets/Images/CardIcons/th.png";
import thDark from "../../assets/Images/CardIcons/thDark.png";
import water from "../../assets/Images/CardIcons/water.png";
import waterDark from "../../assets/Images/CardIcons/waterDark.png";
import smoke from "../../assets/Images/CardIcons/smoke.png";
import smokeDark from "../../assets/Images/CardIcons/smokeDark.png";
import motion from "../../assets/Images/CardIcons/motion.png";
import motionDark from "../../assets/Images/CardIcons/motionDark.png";
import check from "../../assets/Images/icons/check.png";
import checked from "../../assets/Images/icons/checked.png";
import UserContext from "../../context/UserContext";
import TimePicker from "./Create/Automation/TimePicker";

const ConditionCard = ({ data, index }) => {
  const [state, setState] = useState();
  const [thValue, setThValue] = useState("");
  const [thCompare, setThCompare] = useState("=<");
  const [timeValue, setTimeValue] = useState(
    data && data.automationTriggerType === "time"
      ? data.condition.slice(0, 2)
      : 0
  );
  const [minuteValue, setMinuteValue] = useState(
    data && data.automationTriggerType === "time"
      ? data.condition.slice(5, 7)
      : 0
  );

  const [co2Value, setCo2Value] = useState("");
  const [co2Compare, setCo2Compare] = useState("=<");

  const [vocValue, setVocValue] = useState("");
  const [vocCompare, setVocCompare] = useState("=<");

  const [HCHOValue, setHCHOValue] = useState("");
  const [HCHOCompare, setHCHOCompare] = useState("=<");

  const [week, setWeek] = useState([
    {
      day: "Даваа",
      name: "Monday",
      check: data.days ? (data.days.includes("Monday") ? true : false) : true,
    },
    {
      day: "Мягмар",
      name: "Tuesday",
      check: data.days ? (data.days.includes("Tuesday") ? true : false) : true,
    },
    {
      day: "Лхагва",
      name: "Wednesday",
      check: data.days
        ? data.days.includes("Wednesday")
          ? true
          : false
        : true,
    },
    {
      day: "Пүрэв",
      name: "Thursday",
      check: data.days ? (data.days.includes("Thursday") ? true : false) : true,
    },
    {
      day: "Баасан",
      name: "Friday",
      check: data.days ? (data.days.includes("Friday") ? true : false) : true,
    },
    {
      day: "Бямба",
      name: "Saturday",
      check: data.days ? (data.days.includes("Saturday") ? true : false) : true,
    },
    {
      day: "Ням",
      name: "Sunday",
      check: data.days ? (data.days.includes("Sunday") ? true : false) : true,
    },
  ]);
  const { colorMode } = useColorMode();
  const { autoCon, setAutoCon } = useContext(UserContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const typeData = [
    {
      mode: "sunrise",
      title: "Нар мандах",
      img: sunrise,
    },
    {
      mode: "sunset",
      title: "Нар жаргах",
      img: sunset,
    },
  ];

  const timeData = [
    {
      title: "Цаг",
      value: timeValue,
      changeData: setTimeValue,
    },
    {
      title: "Минут",
      value: minuteValue,
      changeData: setMinuteValue,
    },
  ];

  const updateCheck = (i) => {
    const clone = [...week];
    clone[i].check = !week[i].check;
    setWeek(clone);
  };

  const removeHandler = () => {
    const clone = autoCon;
    setAutoCon(clone.filter((e, i) => i !== index));
  };

  const submitHandler = (value, mode) => {
    if (value.automationTriggerType === "state") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = state;
      setAutoCon(clone);
    }
    if (value.automationTriggerType === "temperature") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = thValue;
      clone.find((e) => e === value).compareType = thCompare;
      setAutoCon(clone);
      setThValue("");
      setThCompare("=<");
    }
    if (value.automationTriggerType === "humidity") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = thValue;
      clone.find((e) => e === value).compareType = thCompare;
      setAutoCon(clone);
      setThValue("");
      setThCompare("=<");
    }
    if (value.automationTriggerType === "sun") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = mode;
      setAutoCon(clone);
    }
    if (value.automationTriggerType === "time") {
      const clone = [...autoCon];

      let newTimeValue = timeValue.toString();
      let newMinuteValue = minuteValue.toString();

      if (newTimeValue.length < 2) newTimeValue = `0${newTimeValue}`;
      if (newMinuteValue.length < 2) newMinuteValue = `0${newMinuteValue}`;
      clone.find((e) => e === value).condition = (
        newTimeValue +
        " : " +
        newMinuteValue
      ).toString();
      clone.find((e) => e === value).days = week
        .filter((e) => e.check === true)
        .map((f) => {
          return f.name;
        });
      setAutoCon(clone);
    }

    if (
      value.automationTriggerType === "Water" ||
      value.automationTriggerType === "Occupancy"
    ) {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = state;
      setAutoCon(clone);
    }

    if (value.automationTriggerType === "Co2") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = co2Value;
      clone.find((e) => e === value).compareType = co2Compare;
      setAutoCon(clone);
      setThValue("");
      setThCompare("=<");
    }

    if (value.automationTriggerType === "VOC") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = vocValue;
      clone.find((e) => e === value).compareType = vocCompare;
      setAutoCon(clone);
      setThValue("");
      setThCompare("=<");
    }

    if (value.automationTriggerType === "Formaldehyde") {
      const clone = [...autoCon];
      clone.find((e) => e === value).condition = HCHOValue;
      clone.find((e) => e === value).compareType = HCHOCompare;
      setAutoCon(clone);
      setThValue("");
      setThCompare("=<");
    }

    setState();
    onClose();
  };

  return (
    <VStack w={"full"}>
      {data.automationTriggerType === "time" ? (
        //  TODO: TIME
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setState(data);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? timeDark : time}
              w={"7"}
              h={"5"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              Цаг
            </Text>
          </HStack>
          <Text fontSize={15} fontWeight={"normal"} pr={"5"}>
            {data.condition}
          </Text>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
          {data.days ? (
            <HStack position={"absolute"} bottom={2} right={2}>
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((e, i) => (
                <Text
                  key={i}
                  fontSize={11}
                  fontWeight={"normal"}
                  color={
                    data.days.includes(e)
                      ? "#45A735"
                      : colorMode === "dark"
                      ? "#fff"
                      : "#000"
                  }
                >
                  {e === "Monday"
                    ? "Да"
                    : e === "Tuesday"
                    ? "Мя"
                    : e === "Wednesday"
                    ? "Лх"
                    : e === "Thursday"
                    ? "Пү"
                    : e === "Friday"
                    ? "Ба"
                    : e === "Saturday"
                    ? "Бя"
                    : "Ня"}
                </Text>
              ))}
            </HStack>
          ) : null}
        </HStack>
      ) : data.automationTriggerType === "sun" ? (
        // TODO: SUN
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          onClick={() => {
            setState(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={data.condition === "sunset" ? sunset : sunrise}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition === "sunset" ? "Нар жаргах" : "Нар мандах"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "Water" ? (
        // TODO: WATER
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setState(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? waterDark : water}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition === 1 ? "Ус мэдрэх үед" : "Ус мэдрэхээ болих үед"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "Occupancy" ? (
        // TODO: OCCUPANCY
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setState(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? motionDark : motion}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"} textAlign={"right"}>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition === 1
                ? "Хөдөлгөөн мэдрэх үед"
                : "Хөдөлгөөн мэдрэхээ болих үед"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "Smoke" ? (
        // TODO: SMOKE
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? smokeDark : smoke}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition === 1
                ? "Утаа мэдрэх үед"
                : "Утаа мэдрэхээ болих үед"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "temperature" ||
        data.automationTriggerType === "humidity" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setThCompare(data.compareType);
            setThValue(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? thDark : th}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Text fontSize={15} fontWeight={"normal"}>
              {`${data.compareType} ${data.condition}`}
              {data.automationTriggerType === "temperature" ? "°C" : "%"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "Co2" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setCo2Compare(data.compareType);
            setCo2Value(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? thDark : th}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>

          <HStack pr={"5"}>
            <Text>Co2</Text>
            <Text>{data.compareType}</Text>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition}
              ppm
            </Text>
          </HStack>

          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "VOC" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setVocCompare(data.compareType);
            setVocValue(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? thDark : th}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>

          <HStack pr={"5"}>
            <Text>VOC</Text>
            <Text>{data.compareType}</Text>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition}
              ppm
            </Text>
          </HStack>

          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : data.automationTriggerType === "Formaldehyde" ? (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          align={"center"}
          justify={"space-between"}
          onClick={() => {
            setHCHOCompare(data.compareType);
            setHCHOValue(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? thDark : th}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>

          <HStack pr={"5"}>
            <Text>HCHO</Text>
            <Text>{data.compareType}</Text>
            <Text fontSize={15} fontWeight={"normal"}>
              {data.condition}
              mg/m3
            </Text>
          </HStack>

          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      ) : (
        <HStack
          className="bgColor"
          w={"full"}
          h={"20"}
          rounded={"md"}
          px={"6"}
          position={"relative"}
          justify={"space-between"}
          align={"center"}
          onClick={() => {
            setState(data.condition);
            onOpen();
          }}
          cursor={"pointer"}
          transition={"ease-in-out .3s"}
          _hover={{
            shadow: "md",
          }}
        >
          <HStack spacing={3}>
            <Image
              src={colorMode === "dark" ? lightDark : light}
              w={"7"}
              h={"7"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              {data.device}
            </Text>
          </HStack>
          <HStack pr={"5"}>
            <Box
              w={"2"}
              h={"2"}
              bg={data.condition === "ON" ? "#45A735" : "#ff0000"}
              rounded={"full"}
            />
            <Text fontSize={14} fontWeight={"normal"}>
              {data.condition === "ON" ? "Асах" : "Унтрах"}
            </Text>
          </HStack>
          <Center
            transition={"ease-in-out .3s"}
            position={"absolute"}
            top={2}
            right={2}
            bg={colorMode === "dark" ? "#1D211D" : "#ddd"}
            p={"2"}
            rounded={"md"}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler(index);
            }}
            cursor={"pointer"}
            _hover={{
              transform: "scale(1.2)",
            }}
          >
            <Image
              src={colorMode === "dark" ? closeDark : close}
              w={"3"}
              h={"3"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
      )}

      {/* TODO: ЗАСАХ MODAL */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          pb={"4"}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Төлөв өө сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody
            w={"full"}
            overflowY="auto"
            maxH="60vh"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#45A735",
                borderRadius: "24px",
              },
            }}
          >
            <VStack w={"full"} spacing={6}>
              {data.automationTriggerType === "time" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Үйлдэл хийгдэх цагаа сонгоно.
                  </Text>
                  <HStack
                    w={"full"}
                    spacing={6}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {timeData.map((e, i) => (
                      <TimePicker
                        key={i}
                        title={e.title}
                        value={e.value}
                        setValue={e.changeData}
                      />
                    ))}
                  </HStack>

                  <VStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"flex-start"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Давтамж
                    </Text>
                    <VStack w={"full"} py={"4"}>
                      {week.map((e, i) => (
                        <HStack
                          key={i}
                          w={"full"}
                          // bg={i % 2 === 0 ? "#f5f5f5" : "#fff"}
                          bg={
                            colorMode === "light"
                              ? i % 2 === 0
                                ? "#f5f5f5"
                                : "#fff"
                              : i % 2 === 0
                              ? "#1A1C1A"
                              : "#202420"
                          }
                          align={"center"}
                          justify={"space-between"}
                          px={"4"}
                          py={"2"}
                          rounded={"md"}
                          onClick={() => updateCheck(i)}
                          cursor={"pointer"}
                        >
                          <Text fontWeight={"normal"} fontSize={13}>
                            {e.day}
                          </Text>
                          <Image
                            src={e.check ? checked : check}
                            w={"5"}
                            h={"5"}
                            objectFit={"contain"}
                          />
                        </HStack>
                      ))}
                    </VStack>
                  </VStack>
                </VStack>
              ) : data.automationTriggerType === "Water" ||
                data.automationTriggerType === "Occupancy" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Сонгосон төхөөрөмжийн хийгдэх төлөв.
                  </Text>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState(1)}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      {data.automationTriggerType === "Water"
                        ? "Ус мэдрэх үед"
                        : "Хөдөлгөөн мэдрэх үед"}
                    </Text>
                    <Image
                      src={state === 1 ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState(0)}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      {data.automationTriggerType === "Water"
                        ? "Ус мэдрэхээ болих үед"
                        : "Хөдөлгөөн мэдрэхээ болих үед"}
                    </Text>
                    <Image
                      src={state === 0 ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </VStack>
              ) : data.automationTriggerType === "sun" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Нарнаас хамааруулан автоматжуулалт хийгдэнэ.
                  </Text>
                  {typeData.map((e, i) => (
                    <VStack
                      key={i}
                      w={"full"}
                      py={"4"}
                      px={"4"}
                      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                      borderWidth={"medium"}
                      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                      rounded={"md"}
                      shadow={"md"}
                      align={"center"}
                      justify={"center"}
                      onClick={() => submitHandler(data, e.mode)}
                      cursor={"pointer"}
                    >
                      <Image
                        src={e.img}
                        w={"6"}
                        h={"6"}
                        objectFit={"contain"}
                      />
                      <Text fontWeight={"normal"} fontSize={15}>
                        {e.title}
                      </Text>
                    </VStack>
                  ))}
                </VStack>
              ) : data.automationTriggerType === "humidity" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Чийгшлээс хамааруулан автоматжуулалт хийгдэнэ.
                  </Text>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    spacing={10}
                  >
                    <Text w={"40"} fontWeight={"normal"} fontSize={15}>
                      Чийгшил
                    </Text>
                    <HStack w={"full"}>
                      <Select
                        w={"full"}
                        rounded="xl"
                        value={thCompare}
                        onChange={(e) => {
                          setThCompare(e.target.value);
                        }}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#45A735",
                        }}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                      >
                        <option label="=<" value="=<"></option>
                        <option label=">=" value=">="></option>
                        <option label="<" value="<"></option>
                        <option label=">" value=">"></option>
                      </Select>

                      <InputGroup w={"full"}>
                        <Input
                          rounded={"xl"}
                          value={thValue}
                          onChange={(e) => setThValue(e.target.value)}
                          _focusVisible={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                        />
                        <InputRightElement
                          children={
                            <Text fontWeight={"medium"} fontSize={15}>
                              %
                            </Text>
                          }
                        />
                      </InputGroup>
                    </HStack>
                  </HStack>
                </VStack>
              ) : data.automationTriggerType === "temperature" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Температураас хамааруулан автоматжуулалт хийгдэнэ.
                  </Text>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    spacing={10}
                  >
                    <Text w={"40"} fontWeight={"normal"} fontSize={15}>
                      Температур
                    </Text>
                    <HStack w={"full"}>
                      <Select
                        w={"full"}
                        rounded="xl"
                        value={thCompare}
                        onChange={(e) => {
                          setThCompare(e.target.value);
                        }}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#45A735",
                        }}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                      >
                        <option label="=<" value="=<"></option>
                        <option label=">=" value=">="></option>
                        <option label="<" value="<"></option>
                        <option label=">" value=">"></option>
                      </Select>

                      <InputGroup w={"full"}>
                        <Input
                          rounded={"xl"}
                          value={thValue}
                          onChange={(e) => setThValue(e.target.value)}
                          _focusVisible={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                        />
                        <InputRightElement
                          children={
                            <Text fontWeight={"medium"} fontSize={15}>
                              °C
                            </Text>
                          }
                        />
                      </InputGroup>
                    </HStack>
                  </HStack>
                </VStack>
              ) : data.automationTriggerType === "Co2" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    spacing={10}
                  >
                    <Text w={"40"} fontWeight={"normal"} fontSize={15}>
                      Co2
                    </Text>
                    <HStack w={"full"}>
                      <Select
                        w={"full"}
                        rounded="xl"
                        value={co2Compare}
                        onChange={(e) => {
                          setCo2Compare(e.target.value);
                        }}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#45A735",
                        }}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                      >
                        <option label="=<" value="=<"></option>
                        <option label=">=" value=">="></option>
                        <option label="<" value="<"></option>
                        <option label=">" value=">"></option>
                      </Select>

                      <InputGroup w={"full"}>
                        <Input
                          rounded={"xl"}
                          value={co2Value}
                          onChange={(e) => setCo2Value(e.target.value)}
                          _focusVisible={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                        />
                        <InputRightElement
                          children={
                            <Text fontWeight={"medium"} fontSize={13}>
                              ppm
                            </Text>
                          }
                        />
                      </InputGroup>
                    </HStack>
                  </HStack>
                </VStack>
              ) : data.automationTriggerType === "VOC" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    spacing={10}
                  >
                    <Text w={"40"} fontWeight={"normal"} fontSize={15}>
                      Co2
                    </Text>
                    <HStack w={"full"}>
                      <Select
                        w={"full"}
                        rounded="xl"
                        value={vocCompare}
                        onChange={(e) => {
                          setVocCompare(e.target.value);
                        }}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#45A735",
                        }}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                      >
                        <option label="=<" value="=<"></option>
                        <option label=">=" value=">="></option>
                        <option label="<" value="<"></option>
                        <option label=">" value=">"></option>
                      </Select>

                      <InputGroup w={"full"}>
                        <Input
                          rounded={"xl"}
                          value={vocValue}
                          onChange={(e) => setVocValue(e.target.value)}
                          _focusVisible={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                        />
                        <InputRightElement
                          children={
                            <Text fontWeight={"medium"} fontSize={13}>
                              ppm
                            </Text>
                          }
                        />
                      </InputGroup>
                    </HStack>
                  </HStack>
                </VStack>
              ) : data.automationTriggerType === "Formaldehyde" ? (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    spacing={10}
                  >
                    <Text w={"40"} fontWeight={"normal"} fontSize={15}>
                      HCHO
                    </Text>
                    <HStack w={"full"}>
                      <Select
                        w={"full"}
                        rounded="xl"
                        value={HCHOCompare}
                        onChange={(e) => {
                          setHCHOCompare(e.target.value);
                        }}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#45A735",
                        }}
                        _hover={{
                          borderColor: "#45A735",
                        }}
                      >
                        <option label="=<" value="=<"></option>
                        <option label=">=" value=">="></option>
                        <option label="<" value="<"></option>
                        <option label=">" value=">"></option>
                      </Select>

                      <InputGroup w={"full"}>
                        <Input
                          rounded={"xl"}
                          value={HCHOValue}
                          onChange={(e) => setHCHOValue(e.target.value)}
                          _focusVisible={{
                            outline: "none",
                            borderColor: "#45A735",
                          }}
                          _hover={{
                            borderColor: "#45A735",
                          }}
                        />
                        <InputRightElement
                          children={
                            <Text fontWeight={"medium"} fontSize={13}>
                              mg/m3
                            </Text>
                          }
                        />
                      </InputGroup>
                    </HStack>
                  </HStack>
                </VStack>
              ) : (
                <VStack
                  w={"full"}
                  spacing={4}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text fontWeight={"normal"} fontSize={15}>
                    Сонгосон төхөөрөмжийн хийгдэх төлөв.
                  </Text>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("ON")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Асах
                    </Text>
                    <Image
                      src={state === "ON" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                  <HStack
                    w={"full"}
                    h={"20"}
                    px={"8"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    borderWidth={"medium"}
                    borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                    rounded={"md"}
                    shadow={"md"}
                    align={"center"}
                    justify={"space-between"}
                    onClick={() => setState("OFF")}
                    cursor={"pointer"}
                  >
                    <Text fontWeight={"normal"} fontSize={15}>
                      Унтрах
                    </Text>
                    <Image
                      src={state === "OFF" ? checked : check}
                      w={"5"}
                      h={"5"}
                      objectFit={"contain"}
                    />
                  </HStack>
                </VStack>
              )}

              {data.automationTriggerType === "sun" ? null : (
                <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
                  <Button
                    w={"32"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    fontWeight={"normal"}
                    fontSize={15}
                    onClick={onClose}
                    _focusVisible={{ outline: "none" }}
                  >
                    Болих
                  </Button>
                  <Button
                    w={"32"}
                    bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                    fontWeight={"normal"}
                    fontSize={15}
                    color={"#45A735"}
                    borderWidth={"thin"}
                    borderColor={"#45A735"}
                    onClick={() => submitHandler(data)}
                    isDisabled={
                      data.automationTriggerType === "humidity" ||
                      data.automationTriggerType === "temperature"
                        ? thValue.length > 0
                          ? false
                          : true
                        : data.automationTriggerType === "Co2"
                        ? co2Value.length > 0
                          ? false
                          : true
                        : data.automationTriggerType === "VOC"
                        ? vocValue.length > 0
                          ? false
                          : true
                        : data.automationTriggerType === "Formaldehyde"
                        ? HCHOValue.length > 0
                          ? false
                          : true
                        : false
                    }
                    _focusVisible={{ outline: "none" }}
                  >
                    Хадгалах
                  </Button>
                </HStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ConditionCard;
