import React, { useRef, useState, useEffect } from "react";
import {
  HStack,
  Text,
  VStack,
  Menu,
  MenuButton,
  useColorMode,
  Button,
  MenuList,
  MenuItem,
  Icon,
  useBreakpointValue,
  Flex,
  Spinner,
  Center,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import BackButton from "../../../../components/Button/BackButton";
import { useLocation } from "react-router-dom";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdReplay,
} from "react-icons/md";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "../../../../axios";
import ChartSkeleton from "../../../../components/Skeleton/ChartSkeleton";
import CsvDownloader from "react-csv-downloader";

Chart.register(zoomPlugin);
const ZbAqiChart = () => {
  const [chartData, setChartData] = useState({
    day: "1хоног",
    time: "1",
  });
  const [chartMeasurement, setChartMeasurement] = useState({
    displayName: "Дундаж",
    key: "average",
  });
  const [allData, setAllData] = useState({}); // this variable stores all max, avg and min datas
  const [datas, setDatas] = useState([]);
  const [selectedData, setSelectedData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("accessToken");
  const location = useLocation();
  const { colorMode } = useColorMode();
  const { Core } = axios();

  const variant = useBreakpointValue({ base: false, md: true });

  const timeData = [
    {
      day: "1хоног",
      time: "1",
    },
    {
      day: "7хоног",
      time: "7",
    },
    {
      day: "1сар",
      time: "30",
    },
  ];

  const measurementTypes = [
    {
      displayName: "Хамгийн их",
      key: "maxData",
    },
    {
      displayName: "Дундаж",
      key: "average",
    },
    {
      displayName: "Хамгийн бага",
      key: "minData",
    },
  ];

  const selectDayHandler = (value) => {
    setChartData(value);
  };

  useEffect(() => {
    if (location.state.data.id && chartData) {
      setIsLoading(true);
      setDatas([]);
      let cancel = false;
      let source = Core.CancelToken.source();
      Core.get("/user_v2/chart/get", {
        params: {
          _id: location.state.data.id,
          day: chartData.time,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((result) => {
          if (cancel) return;
          // console.log(result.data.data);
          const res = result.data.data[chartMeasurement.key];
          setDatas(res);

          setAllData({
            average: result.data.data.average.reverse(),
            minData: result.data.data.minData.reverse(),
            maxData: result.data.data.maxData.reverse(),
          }); // this variable stores all max, avg and min datas
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setDatas([]);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [chartData]);

  // TODO: menu gees min, average, and max songoh uyd ene ajillana
  useEffect(() => {
    if (Object.keys(allData).length > 0) {
      setDatas(allData[chartMeasurement.key]);
    }
  }, [chartMeasurement]);

  //Chart data, option set
  const labelAdjusted = datas.map((el) => {
    return (
      el.time.toString().slice(5, 10) + "--" + el.time.toString().slice(11, 16)
    );
  });

  const ChartDatas = {
    labels: labelAdjusted.map((el) => el.split("--")),
    datasets: [
      {
        label:
          selectedData === 0
            ? "Температур"
            : selectedData === 1
            ? "Чийгшил"
            : selectedData === 2
            ? "Нүүрстөрөгч"
            : selectedData === 3
            ? "Формальдегид"
            : "VOC",
        // Формальдегид
        // VOC
        fill: true,
        // lineTension: 0.1,
        borderColor:
          selectedData === 0
            ? "#FFB900"
            : selectedData === 1
            ? "#2DABFF"
            : "#45A735",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const tempGradient = ctx.createLinearGradient(0, 0, 0, 1000);
          tempGradient.addColorStop(0, "rgba(255,209,0,1)");
          tempGradient.addColorStop(1, "rgba(255,255,255,0)");
          const humiGradient = ctx.createLinearGradient(0, 0, 0, 1000);
          humiGradient.addColorStop(0, "rgba(45,226,255,1)");
          humiGradient.addColorStop(1, "rgba(255,255,255,0)");
          const carbonGradient = ctx.createLinearGradient(0, 0, 0, 1000);
          carbonGradient.addColorStop(0, "rgba(117,214,101,1)");
          carbonGradient.addColorStop(1, "rgba(255,255,255,0)");
          const formaldehydeGradient = ctx.createLinearGradient(0, 0, 0, 1000);
          formaldehydeGradient.addColorStop(0, "rgba(117,214,101,1)");
          formaldehydeGradient.addColorStop(1, "rgba(255,255,255,0)");
          const vocGradient = ctx.createLinearGradient(0, 0, 0, 1000);
          vocGradient.addColorStop(0, "rgba(117,214,101,1)");
          vocGradient.addColorStop(1, "rgba(255,255,255,0)");
          return selectedData === 0
            ? tempGradient
            : selectedData === 1
            ? humiGradient
            : selectedData === 2
            ? carbonGradient
            : selectedData === 3
            ? formaldehydeGradient
            : vocGradient;
        },
        borderJoinStyle: "#000",
        pointBorderColor: "#000",
        pointBackgroundColor:
          selectedData === 0
            ? "#FFB900"
            : selectedData === 1
            ? "#2DABFF"
            : "#45A735",
        borderWidth: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor:
          selectedData === 0
            ? "#FFB900"
            : selectedData === 1
            ? "#2DABFF"
            : "#45A735",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 3,
        pointRadius: 0,
        pointHitRadius: 10,
        data:
          location.state.data.deviceType &&
          location.state.data.deviceType === "zbAqi"
            ? selectedData === 0
              ? datas.map((el) => el.temperature)
              : selectedData === 1
              ? datas.map((el) => el.humidity)
              : selectedData === 2
              ? datas.map((el) => el.co2)
              : selectedData === 3
              ? datas.map((el) => el.formaldehyde)
              : datas.map((el) => el.VOC)
            : null,
        spanGaps: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    spanGaps: true,
    animation: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 6,
          min: 2,
          precision: 4,
          // minTicksLimit: 2,
          callback: function (val, index) {
            return index != 0 ? this.getLabelForValue(val) : "";
          },
          color: colorMode === "light" ? "#707070" : "#FFF",
        },
        grid: {
          // borderDash: [3, 3],
          color: colorMode === "light" ? "#F3F3F3" : "#4A5568",
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        display: variant,
        beginAtZero: false,
        grid: {
          borderDash: [3, 3],
          color: colorMode === "light" ? "#F3F3F3" : "#4A5568",
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          // maxTicksLimit: 3,
          display: true,
          mirror: true,
          labelOffset: -10,
          padding: 5,
          count: 3,
          color: colorMode === "light" ? "#707070" : "#FFF",
          callback: function (val, index) {
            return index === 0
              ? "Min " + this.getLabelForValue(val)
              : index === 1
              ? "Avg " + this.getLabelForValue(val)
              : "Max " + this.getLabelForValue(val);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const def1 =
              context.dataset.label === "Чийгшил"
                ? "Чийгшил: "
                : context.dataset.label === "Температур"
                ? "Температур: "
                : context.dataset.label === "Нүүрстөрөгч"
                ? "Нүүрстөрөгч: "
                : context.dataset.label === "Формальдегид"
                ? "Формальдегид: "
                : context.dataset.label === "VOC"
                ? "VOC: "
                : null;

            const def2 =
              context.dataset.label === "Чийгшил"
                ? "%"
                : context.dataset.label === "Температур"
                ? "°C"
                : context.dataset.label === "Нүүрстөрөгч"
                ? "ppm"
                : context.dataset.label === "Формальдегид"
                ? "mg/m3"
                : context.dataset.label === "VOC"
                ? "ppm"
                : null;
            return def1 + context.raw + def2;
          },
        },
      },
      title: {
        display: true,
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          // color: colorMode === "light" ? "#707070" : "#FFF",
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          pinch: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
      chartAreaBorder: {
        // borderColor: colorMode === "light" ? "#DBDBDB" : "#4A5568",
        borderColor: colorMode === "light" ? "#DBDBDB" : "#4A5568",
        borderWidth: 1,
      },
    },
  };

  const chartAreaBorder = {
    id: "chartAreaBorder",
    beforeDatasetsDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = options.borderColor;
      ctx.lineWidth = options.borderWidth;
      ctx.moveTo(right, top);
      ctx.lineTo(right, bottom);
      ctx.closePath();
      ctx.stroke();
    },
  };

  //Reset zoom chart usage
  const chartRef = useRef(null);
  const resetZoom = () => {
    if (datas.length > 0) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <VStack
      w="full"
      h="full"
      spacing={8}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <HStack w="full" spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"} fontSize={15}>
            {location.state.data.deviceName} - график
          </Text>
        </HStack>
        <HStack>
          <CsvDownloader
            title={
              location.state.data.deviceName +
              " - нэртэй төхөөрөмжийн график дата"
            }
            filename={
              "VIOT-" + location.state.data.deviceName + "-" + chartData.day
            }
            extension=".csv"
            columns={[
              {
                id: "date",
                displayName: "Огноо",
              },
              {
                id: "time",
                displayName: "Цаг",
              },
              {
                id: "temp",
                displayName: "Температур",
              },
              {
                id: "humi",
                displayName: "Чийгшил",
              },
              {
                id: "voc",
                displayName: "VOC",
              },
              {
                id: "co2",
                displayName: "Нүүрстөрөгч",
              },
              {
                id: "formaldehyde",
                displayName: "Формальдегид",
              },
            ]}
            // suffix={true}
            datas={datas.map((e) => {
              return {
                date: e.time.slice(0, 10),
                time: e.time.slice(11, 19),
                temp: e.temperature,
                humi: e.humidity,
                voc: e.VOC,
                co2: e.co2,
                formaldehyde: e.formaldehyde,
              };
            })}
          >
            <Button
              transition={"ease-in-out .3s"}
              bg={"#fff"}
              fontSize={15}
              fontWeight={"medium"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                shadow: "md",
                bg: "#fff",
              }}
              isDisabled={datas.length > 0 ? false : true}
            >
              Export
            </Button>
          </CsvDownloader>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  transition={"ease-in-out .3s"}
                  bg={colorMode === "dark" ? "#252B25" : "#fff"}
                  fontSize={15}
                  fontWeight={"medium"}
                  rounded={"lg"}
                  isActive={isOpen}
                  as={Button}
                  rightIcon={
                    <Icon
                      as={
                        isOpen
                          ? MdOutlineKeyboardArrowUp
                          : MdOutlineKeyboardArrowDown
                      }
                    />
                  }
                  _hover={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                    shadow: "md",
                  }}
                  _focusVisible={{
                    outline: "none",
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  _expanded={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  // disabled={datas.length > 0 ? false : true}
                >
                  {chartData.day}
                </MenuButton>
                <MenuList bg={colorMode === "dark" ? "#252B25" : "#fff"}>
                  {timeData.map((e, index) => (
                    <MenuItem
                      key={index}
                      fontSize={15}
                      fontWeight={"normal"}
                      onClick={() => selectDayHandler(e)}
                    >
                      {e.day}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  transition={"ease-in-out .3s"}
                  bg={colorMode === "dark" ? "#252B25" : "#fff"}
                  fontSize={15}
                  fontWeight={"medium"}
                  rounded={"lg"}
                  isActive={isOpen}
                  as={Button}
                  rightIcon={
                    <Icon
                      as={
                        isOpen
                          ? MdOutlineKeyboardArrowUp
                          : MdOutlineKeyboardArrowDown
                      }
                    />
                  }
                  _hover={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                    shadow: "md",
                  }}
                  _focusVisible={{
                    outline: "none",
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  _expanded={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  // disabled={datas.length > 0 ? false : true}
                  isDisabled={datas.length > 0 ? false : true}
                >
                  {selectedData === 0
                    ? "Температур"
                    : selectedData === 1
                    ? "Чийгшил"
                    : selectedData === 2
                    ? "Нүүрстөрөгч"
                    : selectedData === 3
                    ? "Формальдегид"
                    : "VOC"}
                </MenuButton>
                <MenuList bg={colorMode === "dark" ? "#252B25" : "#fff"}>
                  <MenuItem
                    fontSize={15}
                    fontWeight={"normal"}
                    onClick={() => setSelectedData(0)}
                  >
                    Температур
                  </MenuItem>
                  <MenuItem
                    fontSize={15}
                    fontWeight={"normal"}
                    onClick={() => setSelectedData(1)}
                  >
                    Чийгшил
                  </MenuItem>
                  <MenuItem
                    fontSize={15}
                    fontWeight={"normal"}
                    onClick={() => setSelectedData(2)}
                  >
                    Нүүрстөрөгч
                  </MenuItem>
                  <MenuItem
                    fontSize={15}
                    fontWeight={"normal"}
                    onClick={() => setSelectedData(3)}
                  >
                    Формальдегид
                  </MenuItem>
                  <MenuItem
                    fontSize={15}
                    fontWeight={"normal"}
                    onClick={() => setSelectedData(4)}
                  >
                    VOC
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>

          {/* TODO: MIN, AVG, MAX  MENU */}
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  transition={"ease-in-out .3s"}
                  bg={colorMode === "dark" ? "#252B25" : "#fff"}
                  fontSize={15}
                  fontWeight={"medium"}
                  rounded={"lg"}
                  isActive={isOpen}
                  as={Button}
                  rightIcon={
                    <Icon
                      as={
                        isOpen
                          ? MdOutlineKeyboardArrowUp
                          : MdOutlineKeyboardArrowDown
                      }
                    />
                  }
                  _hover={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                    shadow: "md",
                  }}
                  _focusVisible={{
                    outline: "none",
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  _expanded={{
                    bg: colorMode === "dark" ? "#252B25" : "#fff",
                  }}
                  // disabled={datas.length > 0 ? false : true}
                >
                  {chartMeasurement.displayName}
                </MenuButton>
                <MenuList bg={colorMode === "dark" ? "#252B25" : "#fff"}>
                  {measurementTypes.map((e, index) => (
                    <MenuItem
                      key={index}
                      fontSize={15}
                      fontWeight={"normal"}
                      onClick={() => {
                        setChartMeasurement(e);
                      }}
                    >
                      {e.displayName}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>

          <Tooltip label="Шинэчлэх" placement="right" hasArrow>
            <IconButton
              aria-label="Reset"
              icon={<Icon as={MdReplay} w={"4"} h={"4"} />}
              bg={colorMode === "dark" ? "#252B25" : "#fff"}
              onClick={resetZoom}
              size="md"
              rounded={"lg"}
              isDisabled={datas.length > 0 ? false : true}
              _focusVisible={{
                outline: "none",
              }}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <VStack w={"full"} h={"full"} className="btn" rounded={"md"}>
        {isLoading ? (
          <ChartSkeleton />
        ) : datas.length > 0 &&
          datas.some(
            (e) =>
              e.temperature != null ||
              e.humidity != null ||
              e.co2 != null ||
              e.formaldehyde != null ||
              e.VOC != null
          ) &&
          location.state.data.deviceType === "zbAqi" ? (
          <Flex
            w={"full"}
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            minH={96}
            height={{ base: "50vh", md: "100%" }}
            px={20}
            pt={5}
            pb={20}
            rounded="md"
            // boxShadow="rgba(99, 99, 99, 0.1) 0px 1px 6px 2px"
            // bg={colorMode === "light" ? "#FFF" : "#3B3B3B"}
          >
            <Line
              data={ChartDatas}
              options={options}
              ref={chartRef}
              plugins={[chartAreaBorder]}
            />
          </Flex>
        ) : (
          <VStack color="gray.400" maxH="28rem" mt={36}>
            <Icon as={FiInfo} w={6} h={6} />
            <Text fontSize={13}>Мэдээлэл олдсонгүй</Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default ZbAqiChart;
