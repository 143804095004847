import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import time from "../../../../assets/Images/icons/time.png";
import timeDark from "../../../../assets/Images/icons/timeDark.png";
import check from "../../../../assets/Images/icons/check.png";
import checked from "../../../../assets/Images/icons/checked.png";
import UserContext from "../../../../context/UserContext";
import TimePicker from "./TimePicker";
import { ifAutoConHasTimeOrSun } from "../../../../util/AutoConTimeCheck";

const AddTime = () => {
  const toast = useToast();
  const [timeValue, setTimeValue] = useState(0);
  const [minuteValue, setMinuteValue] = useState(0);
  const [week, setWeek] = useState([
    {
      day: "Даваа",
      name: "Monday",
      check: true,
    },
    {
      day: "Мягмар",
      name: "Tuesday",
      check: true,
    },
    {
      day: "Лхагва",
      name: "Wednesday",
      check: true,
    },
    {
      day: "Пүрэв",
      name: "Thursday",
      check: true,
    },
    {
      day: "Баасан",
      name: "Friday",
      check: true,
    },
    {
      day: "Бямба",
      name: "Saturday",
      check: true,
    },
    {
      day: "Ням",
      name: "Sunday",
      check: true,
    },
  ]);
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { autoCon, setAutoCon } = useContext(UserContext);

  const typeData = [
    {
      title: "Цаг",
      value: timeValue,
      changeData: setTimeValue,
    },
    {
      title: "Минут",
      value: minuteValue,
      changeData: setMinuteValue,
    },
  ];

  const updateCheck = (i) => {
    const clone = [...week];
    const checks = clone.map((e) => e.check);
    const trues = checks.reduce(
      (count, value) => count + (value === true ? 1 : 0)
    );

    if (trues === 1) {
      if (clone[i].check === false) clone[i].check = !week[i].check;
    } else {
      clone[i].check = !week[i].check;
    }
    setWeek(clone);
  };

  const submitHandler = () => {
    if (ifAutoConHasTimeOrSun(autoCon)) {
      onClose();
      return toast({
        description: "Нөхцөлд нэг цаг оруулна уу.",
        duration: 3000,
        status: "error",
        position: "top",
      });
    }

    let newTimeValue = timeValue.toString();
    let newMinuteValue = minuteValue.toString();

    if (newTimeValue.length < 2) newTimeValue = `0${newTimeValue}`;
    if (newMinuteValue.length < 2) newMinuteValue = `0${newMinuteValue}`;

    setAutoCon((prevState) => [
      ...prevState,
      {
        condition: newTimeValue + " : " + newMinuteValue,
        days: week
          .filter((e) => e.check === true)
          .map((f) => {
            return f.name;
          }),
        automationTriggerType: "time",
      },
    ]);
    onClose();
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        py={"4"}
        px={"4"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        borderWidth={"medium"}
        borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
        rounded={"md"}
        shadow={"md"}
        align={"center"}
        justify={"center"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "lg",
        }}
      >
        <Image
          src={colorMode === "dark" ? timeDark : time}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
        />
        <Text>Цаг</Text>
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Цаг сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody w={"full"}>
            <VStack
              w={"full"}
              spacing={4}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Text fontWeight={"normal"} fontSize={15}>
                Үйлдэл хийгдэх цагаа сонгоно
              </Text>
              <HStack
                w={"full"}
                spacing={6}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {typeData.map((e, i) => (
                  <TimePicker
                    key={i}
                    title={e.title}
                    value={e.value}
                    setValue={e.changeData}
                  />
                ))}
              </HStack>

              <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
                <Text fontWeight={"normal"} fontSize={15}>
                  Давтамж
                </Text>
                <VStack w={"full"} py={"4"}>
                  {week.map((e, i) => (
                    <HStack
                      key={i}
                      w={"full"}
                      bg={
                        colorMode === "light"
                          ? i % 2 === 0
                            ? "#f5f5f5"
                            : "#fff"
                          : i % 2 === 0
                          ? "#1A1C1A"
                          : "#202420"
                      }
                      align={"center"}
                      justify={"space-between"}
                      px={"4"}
                      py={"2"}
                      rounded={"md"}
                      onClick={() => updateCheck(i)}
                      cursor={"pointer"}
                    >
                      <Text fontWeight={"normal"} fontSize={13}>
                        {e.day}
                      </Text>
                      <Image
                        src={e.check ? checked : check}
                        w={"5"}
                        h={"5"}
                        objectFit={"contain"}
                      />
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={() => submitHandler()}
              _focusVisible={{ outline: "none" }}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTime;
