import React, { useEffect, useState } from "react";
import {
  Center,
  HStack,
  Icon,
  Image,
  Spinner,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import BackButton from "../../../components/Button/BackButton";
import Cookies from "js-cookie";
import axios from "../../../axios";
import { FiInfo } from "react-icons/fi";
import SharedListCard from "../../../components/ShareDevice/SharedListCard";
import ShareDevice from "../../../components/ShareDevice/ShareDevice";

const SharedUserList = () => {
  const [sharedList, setSharedList] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const { colorMode } = useColorMode();
  const { Core } = axios();

  // ------------------------- Get shared user list ----------------------------
  useEffect(() => {
    if (houseId) {
      let source = Core.CancelToken.source();
      let cancel = false;
      Core.get("/user_v2/device_share/share", {
        params: { houseId: houseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
        .then((result) => {
          if (cancel) return;
          // console.log("get shared list", result.data);
          setSharedList(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            setSharedList([]);
            setTrigger(false);
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  return (
    <VStack w={"full"} spacing={8}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4}>
          <BackButton />
          <Text fontSize={15} fontWeight={"normal"}>
            Төхөөрөмж хуваалцах
          </Text>
        </HStack>
        <ShareDevice setTrigger={setTrigger} />
      </HStack>

      <VStack w={"full"}>
        {trigger ? (
          <Spinner size="lg" color="#45A735" mt="25vh" />
        ) : sharedList.length > 0 ? (
          sharedList.map((e, index) => (
            <SharedListCard key={index} data={e} setTrigger={setTrigger} />
          ))
        ) : (
          <VStack
            justifyContent="center"
            alignItems="center"
            color="gray.600"
            mt={"16"}
            spacing={1}
          >
            <Icon as={FiInfo} w={7} h={7} />
            <Text fontSize={15} fontWeight={"normal"}>
              Мэдээлэл олдсонгүй.
            </Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default SharedUserList;
