import React from "react";
import { HStack, Image, Text } from "@chakra-ui/react";
import shareDark from "../../assets/Images/icons/shareDark.png";

const Blocked = () => {
  return (
    <HStack
      pl="6"
      pr="2"
      position={"absolute"}
      top={0}
      right={0}
      py={0.5}
      bg="#ff0000"
      color="white"
      borderBottomLeftRadius={"xl"}
      borderTopRightRadius={"xl"}
    >
      <Text fontSize={11}>Хязгаарлагдсан</Text>
      <Image src={shareDark} w={"3"} h={"3"} objectFit={"fill"} />
    </HStack>
  );
};

export default Blocked;
