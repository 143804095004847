import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  Grid,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { TbArrowBackUp } from "react-icons/tb";
import InsertName from "../../../components/Automation/Create/InsertName";
import tap from "../../../assets/Images/icons/tap.png";
import tapDark from "../../../assets/Images/icons/tapDark.png";
import UserContext from "../../../context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import TtrAddDevice from "../../../components/TaptoRun/AddBtn/TtrAddDevice";
import TtrAddAutomation from "../../../components/TaptoRun/AddBtn/TtrAddAutomation";
import TtrAddGroup from "../../../components/TaptoRun/AddBtn/TtrAddGroup";
import axios from "../../../axios";
import Cookies from "js-cookie";
import TtrCard from "../../../components/TaptoRun/TtrCard";

const CreateTtr = () => {
  const location = useLocation();
  const [name, setName] = useState(() => {
    if (location && location.state) {
      if (typeof location.state === "string") {
        return location.state;
      } else if (typeof location.state === "object" && location.state.title) {
        return location.state.title;
      }
    }
    return "";
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const houseId = Cookies.get("house");
  const history = useHistory();
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { ttrAct, setTtrAct, autoEditId, handledHttpErrors } =
    useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    if (location.state) {
      if (location.state.actionType === "state") {
        setIsLoading(true);
        let source = Core.CancelToken.source();
        let cancel = false;

        Core.get("/user/ttr/devices_switch", {
          params: { house: houseId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
          .then((result) => {
            setIsLoading(false);
            // console.log('result ---', result.data.data);

            // setTtrAct(
            //   result.data.data.map((e) => {
            //     return {
            //       _id: e._id,
            //       device: e.for,
            //       isOriginal: e.isOriginal,
            //       deviceType: e.deviceType,
            //       room: e.room,
            //       type: "device",
            //       actionType: "state",
            //       command:
            //         location.state.title === "Бүх унтраалгыг унтраах"
            //           ? 0
            //           : location.state.title === "Бүх унтраалгыг асаах"
            //           ? 1
            //           : 0,
            //     };
            //   })
            // );

            setTtrAct(
              result.data.data
                .map((e) => {
                  return e.deviceType !== "newG4"
                    ? {
                        _id: e._id,
                        device: e.for,
                        isOriginal: e.isOriginal,
                        deviceType: e.deviceType,
                        room: e.room,
                        type: "device",
                        actionType: "state",
                        command:
                          location.state.title === "Бүх унтраалгыг унтраах"
                            ? 0
                            : location.state.title === "Бүх унтраалгыг асаах"
                            ? 1
                            : 0,
                      }
                    : e.channels.map((j) => {
                        return {
                          _id: j._id,
                          device: j.name,
                          isOriginal: e.isOriginal,
                          deviceType: e.deviceType,
                          room: e.room,
                          type: "device",
                          actionType: "state",
                          command:
                            location.state.title === "Бүх унтраалгыг унтраах"
                              ? 0
                              : location.state.title === "Бүх унтраалгыг асаах"
                              ? 1
                              : 0,
                        };
                      });
                })
                .flat()
            );
          })
          .catch((err) => {
            setIsLoading(false);
            // console.log(err);
          });
      } else if (location.state.actionType === "notif") {
        setIsLoading(true);
        let source = Core.CancelToken.source();
        let cancel = false;

        Core.get("/user/ttr/devices_notification", {
          params: { house: houseId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
          .then((result) => {
            setIsLoading(false);
            // console.log('result ---', result.data.data);
            setTtrAct(
              result.data.data.map((e) => {
                return {
                  _id: e._id,
                  device: e.for,
                  isOriginal: e.isOriginal,
                  deviceType: e.deviceType,
                  room: e.room,
                  type: "device",
                  actionType: "notif",
                  command:
                    location.state.title === "Бүх мэдэгдэлийг унтраах"
                      ? 0
                      : location.state.title === "Бүх мэдэгдэлийг асаах"
                      ? 1
                      : 0,
                };
              })
            );
          })
          .catch((err) => {
            setIsLoading(false);
            // console.log(err);
          });
      } else if (location.state.actionType === "alarm") {
        setIsLoading(true);
        let source = Core.CancelToken.source();
        let cancel = false;

        Core.get("/user/ttr/devices_alarm", {
          params: { house: houseId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
          .then((result) => {
            setIsLoading(false);
            // console.log('result ---', result.data.data);
            setTtrAct(
              result.data.data.map((e) => {
                return {
                  _id: e._id,
                  device: e.for,
                  isOriginal: e.isOriginal,
                  deviceType: e.deviceType,
                  room: e.room,
                  type: "device",
                  actionType: "alarm",
                  command:
                    location.state.title === "Бүх дохиог унтраах"
                      ? 0
                      : location.state.title === "Бүх дохиог асаах"
                      ? 1
                      : 0,
                };
              })
            );
          })
          .catch((err) => {
            setIsLoading(false);
            // console.log(err);
          });
      }
    }
  }, [location]);

  const submitHandler = () => {
    if (ttrAct.length > 0 && name && houseId) {
      let source = Core.CancelToken.source();
      if (autoEditId) {
        let data = {
          name: name,
          actions: ttrAct,
          house: houseId,
          _id: autoEditId,
        };
        // console.log('TTR -------------->', data);
        setIsDisable(true);
        Core.put(
          "/user/ttr",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
          .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
              if (!toast.isActive(id)) {
                toast({
                  description: "Амжилттай!",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
              }
              setTtrAct([]);
              history.push("/automation");
            }
            setIsDisable(false);
          })
          .catch((err) => {
            // console.log(err.response);
            setIsDisable(false);
            if (handledHttpErrors.includes(err.response.status)) return;

            if (!toast.isActive(id)) {
              toast({
                description:
                  err.response.status === 400
                    ? "Нэр давхардаж байна!"
                    : "Алдаа гарлаа!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
          });
      } else {
        setIsDisable(true);
        let data = {
          name: name,
          actions: ttrAct,
          house: houseId,
        };
        // console.log('TTR -------------->', data);
        Core.post(
          "/user/ttr",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          { cancelToken: source.token }
        )
          .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
              if (!toast.isActive(id)) {
                toast({
                  description: "Амжилттай!",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
              }
              setTtrAct([]);
              history.push("/automation");
            }
            setIsDisable(false);
          })
          .catch((err) => {
            setIsDisable(false);
            // console.log(err);
            if (handledHttpErrors.includes(err.response.status)) return;

            if (!toast.isActive(id)) {
              toast({
                description:
                  err.response.status === 400
                    ? err.response.data.message
                    : "Алдаа гарлаа!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            }
          });
      }
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Багц үйлдэл дутуу байна!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <VStack w={"full"} h={"full"} spacing={6}>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack spacing={4} w={"full"}>
          <IconButton
            transition={"ease-in-out .3s"}
            bg={colorMode === "dark" ? "#252B25" : "#fff"}
            rounded={"lg"}
            icon={
              <Icon
                as={TbArrowBackUp}
                color={colorMode === "dark" ? "#fff" : "#000"}
                w={5}
                h={5}
              />
            }
            _hover={{
              bg: "#fff",
              shadow: "md",
            }}
            _focusVisible={{
              outline: "none",
              bg: "#fff",
            }}
            onClick={() => {
              ttrAct.length > 0 ? onOpen() : history.goBack();
            }}
          />
          <Text fontSize={15} fontWeight={"normal"}>
            Багц үйлдэл үүсгэх
          </Text>
        </HStack>

        <InsertName
          title={"Багц үйлдлийн нэр"}
          img={tap}
          imgDark={tapDark}
          value={name}
          onChange={setName}
        />
      </HStack>

      <VStack
        className="bgColor"
        w={"full"}
        h={"full"}
        rounded={"xl"}
        p={"6"}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={4}
      >
        <HStack
          w={"full"}
          h={"full"}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={[4, 4, 4, 4, 10]}
        >
          <VStack
            w={"full"}
            h={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={4}
          >
            <VStack align={"flex-start"} justify={"flex-start"} spacing={0}>
              <Text fontSize={15} fontWeight={"medium"}>
                Үйлдэл
              </Text>
              <Text fontSize={13} fontWeight={"normal"}>
                Энэ үйлдэл хийгдэнэ.
              </Text>
            </VStack>

            <VStack
              w={"full"}
              h={"full"}
              bg={colorMode === "dark" ? "#1D211D" : "#fff"}
              rounded={"xl"}
              p={["4", "4", "4", "4", "8"]}
              spacing={4}
              overflowY="auto"
              maxH="65vh"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              {isLoading ? (
                <Center w={"full"}>
                  <Spinner color="#45A735" size="md" />
                </Center>
              ) : ttrAct.length > 0 ? (
                <Grid
                  w={"full"}
                  templateColumns={[
                    "repeat(1, 1fr)",
                    "repeat(1, 1fr)",
                    "repeat(1, 1fr)",
                    "repeat(1, 1fr)",
                    "repeat(2, 1fr)",
                  ]}
                  gap={4}
                >
                  {ttrAct.map((e, i) => (
                    <TtrCard key={i} data={e} index={i} />
                  ))}
                </Grid>
              ) : null}
            </VStack>
          </VStack>

          <VStack
            w={["full", "full", "full", "full", "4xl"]}
            h={"Full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={4}
          >
            <VStack
              align={"flex-start"}
              justify={"flex-start"}
              px={"2"}
              spacing={0}
            >
              <Text fontSize={15} fontWeight={"medium"}>
                Нэмэх үйлдэл
              </Text>
              <Text fontSize={13} fontWeight={"normal"}>
                Та дурын төхөөрөмж болон автоматжуулалтаа нэмж болно.
              </Text>
            </VStack>
            <VStack
              w={"full"}
              px={"2"}
              pb={"2"}
              spacing={4}
              overflowY="auto"
              maxH="60vh"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#45A735",
                  borderRadius: "24px",
                },
              }}
            >
              <TtrAddDevice />
              <TtrAddAutomation />
              <TtrAddGroup />
            </VStack>
          </VStack>
        </HStack>
        <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
          <Button
            className="btn"
            w={"xs"}
            borderColor={"#45A735"}
            borderWidth={"thin"}
            rounded={"full"}
            shadow={"md"}
            fontWeight={"normal"}
            fontSize={15}
            onClick={() => submitHandler()}
            isDisabled={isDisable}
            _hover={{
              bg: "#5AB54B",
              color: "#fff",
            }}
            _focusVisible={{
              outline: "none",
            }}
          >
            {isDisable ? <Spinner mr={2} size="sm" /> : null}
            Хадгалах
          </Button>
        </HStack>
      </VStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w={"sm"} bg={colorMode === "dark" ? "#202420" : "#fff"}>
          <ModalHeader>Гарахдаа итгэлтэй байна уу?</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                onClose();
                setTtrAct([]);
                setName("");
                history.goBack();
              }}
              _focusVisible={{ outline: "none" }}
            >
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default CreateTtr;
