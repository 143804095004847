import React, { useState, useContext, useEffect } from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";
import g4 from "../../../assets/Images/CardIcons/g4.png";
import g4Dark from "../../../assets/Images/CardIcons/g4Dark.png";
import socket from "../../../hooks/Socket";

const NewG4 = ({
  roomName,
  deviceName,
  switchStatus,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
  data,
  totalChannels,
  totalOff,
  totalOn,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    switchStatus: switchStatus,
    status: status,
    notifEnabled: notifEnabled,
    data: data,
    totalChannels: totalChannels,
    totalOff: totalOff,
    totalOn: totalOn,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);

  //   TODO: SOCKET
  useEffect(() => {
    const socketSetData = (value) => {
      if (datas.id === value._id && value.status) {
        // console.log("newg4 socket data --->", value);

        setDatas((prevState) => ({
          ...prevState,
          totalOff: value.totalOff,
          totalOn: value.totalOn,
          status: value.status,
        }));
      }
    };

    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
      onClick={() =>
        history.push("/home/newG4-control", {
          id: id,
          roomName: datas.room,
          deviceName: datas.name,
          status: datas.status,
          notifEnabled: datas.notifEnabled,
          isOriginal,
          origin,
          wifiSignal,
          deviceType,
          totalChannels: datas.totalChannels,
          totalOff: datas.totalOff,
          totalOn: datas.totalOn,
        })
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          //   w={"full"}
          alignSelf={"start"}
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? g4Dark : g4}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <HStack fontSize={"14"} justify="center" wrap="wrap" align={"start"}>
            {/* ON */}
            <HStack>
              <Text>ON:</Text>
              <Text fontWeight={"medium"}>{datas.totalOn}</Text>
              <Box w={1} h={1} rounded="full" bg="#45A735"></Box>
            </HStack>

            {/* OFF */}
            <HStack>
              <Text>OFF:</Text>
              <Text fontWeight={"medium"}>{datas.totalOff}</Text>
              <Box w={1} h={1} rounded="full" bg="red"></Box>
            </HStack>

            {/* TOTAL */}
            <HStack>
              <Text>Нийт:</Text>
              <Text fontWeight={"medium"}>{datas.totalChannels}</Text>
            </HStack>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default NewG4;
