import React, { useContext, useState } from "react";
import {
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import phone from "../../assets/Images/icons/phone.png";
import UserContext from "../../context/UserContext";
import axios from "../../axios";
import RoundedButton from "../../components/Button/RoundedButton";

const SignUp = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const history = useHistory();
  const toast = useToast();
  const id = "err";
  const { btnSpinner, setBtnSpinner, handledHttpErrors } =
    useContext(UserContext);
  const { Auth } = axios();

  const sendSmsHandler = () => {
    if (phoneNumber.length === 8) {
      setBtnSpinner(true);
      Auth.get("/message", {
        params: {
          phoneNo: phoneNumber,
        },
      })
        .then((result) => {
          // console.log("result -->", result.data);
          setBtnSpinner(false);
          history.push("/signup-verify", phoneNumber);
        })
        .catch((err) => {
          // console.log("err -->", err);
          setBtnSpinner(false);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error,
            });
          }
        });
    }
  };

  return (
    <VStack w={"xs"} align={"flex-start"} spacing={10}>
      <Text color={"#45A735"} fontWeight={"medium"} fontSize={23}>
        Бүртгүүлэх
      </Text>
      <InputGroup alignItems={"center"} justifyContent={"center"}>
        <InputLeftElement
          children={<Image src={phone} w={4} h={4} objectFit={"contain"} />}
        />
        <Input
          variant={"flushed"}
          placeholder={"Утасны дугаар"}
          type={"tel"}
          maxLength={8}
          width={"full"}
          fontSize={15}
          fontWeight={"normal"}
          color={"#000"}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          _hover={{
            borderColor: "#45A735",
          }}
          _focusVisible={{
            borderColor: "#45A735",
          }}
          onKeyDown={(e) => {
            if (phoneNumber) {
              if (e.key === "Enter") {
                sendSmsHandler(phoneNumber);
              }
            }
          }}
        />
      </InputGroup>
      <VStack w={"full"} spacing={8}>
        <RoundedButton
          title={"Үргэлжлүүлэх"}
          width={"full"}
          onClick={() => sendSmsHandler()}
          disable={phoneNumber.length === 8 && !btnSpinner ? false : true}
        />
        <Button
          variant={"link"}
          rounded={"full"}
          w={"full"}
          fontWeight={"normal"}
          fontSize={15}
          // color={"#000"}
          onClick={() => history.push("/login")}
          alignSelf={"center"}
        >
          Нэвтрэх
        </Button>
      </VStack>
    </VStack>
  );
};

export default SignUp;
