import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import check from "../../../../assets/Images/icons/check.png";
import checked from "../../../../assets/Images/icons/checked.png";
import UserContext from "../../../../context/UserContext";

const ConDeviceState = ({ data, setChangeState, onClose }) => {
  const [state, setState] = useState("");
  const [waterValue, setWaterValue] = useState("");
  const [motionValue, setMotionValue] = useState("");
  const [smokeValue, setSmokeValue] = useState();
  const [tempValue, setTempValue] = useState("");
  const [tempCompare, setTempCompare] = useState("=<");
  const [humiValue, setHumiValue] = useState("");
  const [humiCompare, setHumiCompare] = useState("=<");
  const { colorMode } = useColorMode();
  const { setAutoCon } = useContext(UserContext);

  const [co2Value, setCo2Value] = useState("");
  const [co2Compare, setCo2Compare] = useState("=<");

  const [vocValue, setVocValue] = useState("");
  const [vocCompare, setVocCompare] = useState("=<");

  const [HCHOValue, setHCHOValue] = useState("");
  const [HCHOCompare, setHCHOCompare] = useState("=<");

  const submitHandler = () => {
    if (data.deviceType === "switch" || data.deviceType === "WLED") {
      setAutoCon((prevState) => [
        ...prevState,
        {
          _id: data._id,
          device: data.for,
          automationTriggerType: "state",
          condition: state,
        },
      ]);
      setState("");
      //   }
    } else if (data.deviceType === "th" || data.deviceType === "zigbeeTh") {
      if (tempValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "temperature",
            compareType: tempCompare,
            condition: tempValue,
          },
        ]);
        setTempValue("");
      }
      if (humiValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "humidity",
            compareType: humiCompare,
            condition: humiValue,
          },
        ]);
        setHumiValue("");
      }
    } else if (data.deviceType === "thsc") {
      if (tempValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "temperature",
            compareType: tempCompare,
            condition: tempValue,
          },
        ]);
        setTempValue("");
      }
      if (humiValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "humidity",
            compareType: humiCompare,
            condition: humiValue,
          },
        ]);
        setHumiValue("");
      }
    } else if (data.deviceType === "Water") {
      setAutoCon((prevState) => [
        ...prevState,
        {
          _id: data._id,
          device: data.for,
          automationTriggerType: "Water",
          condition: waterValue === "true" ? 1 : 0,
        },
      ]);
      setWaterValue("");
    } else if (data.deviceType === "Occupancy") {
      setAutoCon((prevState) => [
        ...prevState,
        {
          _id: data._id,
          device: data.for,
          automationTriggerType: "Occupancy",
          condition: motionValue === "true" ? 1 : 0,
        },
      ]);
      setMotionValue("");
    } else if (data.deviceType === "Smoke") {
      setAutoCon((prevState) => [
        ...prevState,
        {
          _id: data._id,
          device: data.for,
          automationTriggerType: "Smoke",
          condition: smokeValue,
        },
      ]);
      setSmokeValue();
    } else if (
      data.deviceType === "Switchsc" ||
      data.deviceType === "zbSwitch"
    ) {
      setAutoCon((prevState) => [
        ...prevState,
        {
          _id: data._id,
          device: data.for,
          automationTriggerType: "state",
          condition: state,
        },
      ]);
      setState("");
    } else if (data.deviceType === "zbAqi") {
      // TODO: zbAqi
      if (tempValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "temperature",
            compareType: tempCompare,
            condition: tempValue,
          },
        ]);
        setTempValue("");
      }
      if (humiValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "humidity",
            compareType: humiCompare,
            condition: humiValue,
          },
        ]);
        setHumiValue("");
      }
      if (co2Value.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "Co2",
            compareType: co2Compare,
            condition: co2Value,
          },
        ]);
        setCo2Value("");
      }
      if (vocValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "VOC",
            compareType: vocCompare,
            condition: vocValue,
          },
        ]);
        setVocValue("");
      }
      if (HCHOValue.length > 0) {
        setAutoCon((prevState) => [
          ...prevState,
          {
            _id: data._id,
            device: data.for,
            automationTriggerType: "Formaldehyde",
            compareType: HCHOCompare,
            condition: HCHOValue,
          },
        ]);
        setHCHOValue("");
      }
    }
    setChangeState(false);
    onClose();
  };

  return (
    <VStack w={"full"} spacing={6}>
      {data.deviceType === "switch" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "th" || data.deviceType === "zigbeeTh" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Температур
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={tempCompare}
                onChange={(e) => {
                  setTempCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  rounded={"xl"}
                  value={tempValue}
                  onChange={(e) => setTempValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={15}>
                      °C
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Чийгшил
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={humiCompare}
                onChange={(e) => {
                  setHumiCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  rounded={"xl"}
                  value={humiValue}
                  onChange={(e) => setHumiValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={15}>
                      %
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>
        </VStack>
      ) : data.deviceType === "Water" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setWaterValue("true")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Ус мэдрэх үед
            </Text>
            <Image
              src={waterValue === "true" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setWaterValue("false")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Ус мэдрэхээ болих үед
            </Text>
            <Image
              src={waterValue === "false" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "Occupancy" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setMotionValue("true")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Хөдөлгөөн мэдрэх үед
            </Text>
            <Image
              src={motionValue === "true" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setMotionValue("false")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Хөдөлгөөн мэдрэхээ болих үед
            </Text>
            <Image
              src={motionValue === "false" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "Smoke" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setSmokeValue(1)}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Утаа мэдрэх үед
            </Text>
            <Image
              src={smokeValue === 1 ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : data.deviceType === "Switchsc" ||
        data.deviceType === "zbSwitch" ||
        data.deviceType === "WLED" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("ON")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Асах
            </Text>
            <Image
              src={state === "ON" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            onClick={() => setState("OFF")}
            cursor={"pointer"}
          >
            <Text fontWeight={"normal"} fontSize={15}>
              Унтрах
            </Text>
            <Image
              src={state === "OFF" ? checked : check}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          </HStack>
        </VStack>
      ) : // TODO: ZB_AQI
      data.deviceType === "zbAqi" ? (
        <VStack w={"full"}>
          {/* TODO: TEMPERATURE */}
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Температур
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={tempCompare}
                onChange={(e) => {
                  setTempCompare(e.target.value);
                }}
                _f_focusVisibleocus={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  type="number"
                  rounded={"xl"}
                  value={tempValue}
                  onChange={(e) => setTempValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={13}>
                      °C
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>

          {/* TODO: ЧИЙГШИЛ */}
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Чийгшил
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={humiCompare}
                onChange={(e) => {
                  setHumiCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  type="number"
                  rounded={"xl"}
                  value={humiValue}
                  onChange={(e) => setHumiValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={15}>
                      %
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>

          {/* TODO: CO2 */}
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Co2
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={co2Compare}
                onChange={(e) => {
                  setCo2Compare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  type="number"
                  rounded={"xl"}
                  value={co2Value}
                  onChange={(e) => setCo2Value(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"normal"} fontSize={13}>
                      ppm
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>

          {/* TODO: VOC */}
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              VOC
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={vocCompare}
                onChange={(e) => {
                  setVocCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  type="number"
                  rounded={"xl"}
                  value={vocValue}
                  onChange={(e) => setVocValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"normal"} fontSize={13}>
                      ppm
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>

          {/* TODO: HCHO */}
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              HCHO
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={HCHOCompare}
                onChange={(e) => {
                  setHCHOCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  type="number"
                  rounded={"xl"}
                  value={HCHOValue}
                  onChange={(e) => setHCHOValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"normal"} fontSize={13}>
                      mg/m3
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>
        </VStack>
      ) : data.deviceType === "thsc" ? (
        <VStack w={"full"}>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Температур
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={tempCompare}
                onChange={(e) => {
                  setTempCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  rounded={"xl"}
                  value={tempValue}
                  onChange={(e) => setTempValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={15}>
                      °C
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>
          <HStack
            w={"full"}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
            spacing={10}
          >
            <Text w={"40"} fontWeight={"normal"} fontSize={15}>
              Чийгшил
            </Text>
            <HStack w={"full"}>
              <Select
                w={"full"}
                rounded="xl"
                value={humiCompare}
                onChange={(e) => {
                  setHumiCompare(e.target.value);
                }}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#45A735",
                }}
                _hover={{
                  borderColor: "#45A735",
                }}
              >
                <option label="=<" value="=<"></option>
                <option label=">=" value=">="></option>
                <option label="<" value="<"></option>
                <option label=">" value=">"></option>
              </Select>

              <InputGroup w={"full"}>
                <Input
                  rounded={"xl"}
                  value={humiValue}
                  onChange={(e) => setHumiValue(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#45A735",
                  }}
                  _hover={{
                    borderColor: "#45A735",
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontWeight={"medium"} fontSize={15}>
                      %
                    </Text>
                  }
                />
              </InputGroup>
            </HStack>
          </HStack>
        </VStack>
      ) : null}
      <HStack w={"full"} align={"flex-end"} justify={"flex-end"}>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          onClick={() => setChangeState(false)}
          _focusVisible={{ outline: "none" }}
        >
          Буцах
        </Button>
        <Button
          w={"32"}
          bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
          fontWeight={"normal"}
          fontSize={15}
          color={"#45A735"}
          borderWidth={"thin"}
          borderColor={"#45A735"}
          onClick={() => submitHandler()}
          isDisabled={
            data.deviceType === "switch" ||
            data.deviceType === "Switchsc" ||
            data.deviceType === "zbSwitch"
              ? state.length === 0
                ? true
                : false
              : data.deviceType === "th" || data.deviceType === "zigbeeTh"
              ? tempValue.length > 0 || humiValue.length > 0
                ? false
                : true
              : data.deviceType === "Water"
              ? waterValue.length > 0
                ? false
                : true
              : data.deviceType === "Occupancy"
              ? motionValue.length > 0
                ? false
                : true
              : data.deviceType === "Smoke"
              ? smokeValue === 1
                ? false
                : true
              : false
          }
          _focusVisible={{ outline: "none" }}
        >
          Хадгалах
        </Button>
      </HStack>
    </VStack>
  );
};

export default ConDeviceState;
