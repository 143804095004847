import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
const UserContext = createContext();

export const UserStore = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [username, setUsername] = useState("");
  const [notifToken, setNotifToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [house, setHouse] = useState({});
  const [notifBadge, setNotifBadge] = useState(parseInt(Cookies.get("badge")));
  const [notifData, setNotifData] = useState();
  const [notification, setNotification] = useState({});
  const [houseTrigger, setHouseTrigger] = useState(true);
  const [autoCon, setAutoCon] = useState([]);
  const [autoAct, setAutoAct] = useState([]);
  const [ttrAct, setTtrAct] = useState([]);
  const [autoEditId, setAutoEditId] = useState("");
  const [showTtr, setShowTtr] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [isInActiveDevice, setIsInActiveDevice] = useState(false);
  const [handledHttpErrors, setHandledHttpErrors] = useState([418]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        username,
        setUsername,
        isLoading,
        setIsLoading,
        btnSpinner,
        setBtnSpinner,
        house,
        setHouse,
        notifToken,
        setNotifToken,
        notifBadge,
        setNotifBadge,
        notifData,
        setNotifData,
        notification,
        setNotification,
        houseTrigger,
        setHouseTrigger,
        autoCon,
        setAutoCon,
        autoAct,
        setAutoAct,
        ttrAct,
        setTtrAct,
        autoEditId,
        setAutoEditId,
        showTtr,
        setShowTtr,
        restricted,
        setRestricted,
        isInActiveDevice,
        setIsInActiveDevice,
        handledHttpErrors,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
