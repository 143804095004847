import React from "react";
import { HStack, Text, useColorMode } from "@chakra-ui/react";

const DeviceType = ({ deviceType }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      spacing={5}
      transition={"ease-in-out .3s"}
      w="full"
      h={"20"}
      px={"8"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
    >
      <Text>Төхөөрөмжийн төрөл</Text>
      <Text fontWeight={"medium"}>{deviceType}</Text>
    </HStack>
  );
};

export default DeviceType;
