import React from "react";
import {
  Center,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import system from "../../assets/Images/icons/system.png";
import systemDark from "../../assets/Images/icons/systemDark.png";
import tap from "../../assets/Images/icons/tap.png";
import tapDark from "../../assets/Images/icons/tapDark.png";
import share from "../../assets/Images/icons/share.png";
import shareDark from "../../assets/Images/icons/shareDark.png";

const NotifCard = ({ data }) => {
  const { colorMode } = useColorMode();
  return (
    <HStack
      className="notifCard"
      w={"full"}
      justify={"flex-start"}
      align={"flex-start"}
      p={2}
      rounded={"md"}
    >
      <Center
        bg={colorMode === "dark" ? "#1D211D" : "#F8F8F8"}
        w={"10"}
        h={"10"}
        rounded={"md"}
      >
        <Image
          src={
            data.type === "share"
              ? colorMode === "dark"
                ? shareDark
                : share
              : data.type === "ttr"
              ? colorMode === "dark"
                ? tapDark
                : tap
              : colorMode === "dark"
              ? systemDark
              : system
          }
          w={"5"}
          h={"5"}
          objectFit={"contain"}
        />
      </Center>

      <VStack w={"full"} align={"flex-start"} justify={"center"} spacing={0}>
        <HStack w={"full"} justify={"space-between"} align={"center"}>
          <Text fontSize={"15"} fontWeight="medium">
            {data.title}
          </Text>
          <Text fontSize={"15"} fontWeight="medium">
            {data.time}
          </Text>
        </HStack>
        <Text fontSize={"13"} fontWeight="normal">
          {data.body}
        </Text>
      </VStack>
    </HStack>
  );
};

export default NotifCard;
