import {
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  SkeletonCircle,
  SkeletonText,
  Spinner,
  Stack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiInfo } from "react-icons/fi";
import axios from "../../axios";
import NotifCard from "../../components/Notification/NotifCard";
import UserContext from "../../context/UserContext";
import system from "../../assets/Images/icons/system.png";
import systemDark from "../../assets/Images/icons/systemDark.png";
import tap from "../../assets/Images/icons/tap.png";
import tapDark from "../../assets/Images/icons/tapDark.png";
import share from "../../assets/Images/icons/share.png";
import shareDark from "../../assets/Images/icons/shareDark.png";

const Notification = () => {
  const [notifData, setNotifData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const { setNotifBadge, notification } = useContext(UserContext);
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();
  const { Core } = axios();

  useEffect(() => {
    setNotifBadge(0);
    Cookies.set("badge", 0);
    let source = Core.CancelToken.source();
    let cancel = false;

    Core.get("user/initialnotif", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then((result) => {
        if (cancel) return;
        // console.log(result.data.data);
        const data = result.data.data.map((e) => {
          return {
            id: e._id,
            title: e.message.notification.title,
            body: e.message.notification.body,
            type: e.notifType,
            time: e.time.slice(11, 16),
            date: e.time.slice(0, 10),
          };
        });

        //Get only unique dates from data
        const date = new Set([
          ...result.data.data.map((e) => e.time.slice(0, 10)),
        ]);
        const dateArr = [...date];

        //Set notification array by dates
        setNotifData(
          dateArr.map((e) => {
            return {
              date: e,
              notification: data.filter((f) => f.date === e),
            };
          })
        );
        setSpinner(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setNotifData([]);
        setSpinner(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  const getFollowingNotification = (_id) => {
    setIsLoading(true);
    let source = Core.CancelToken.source();
    let cancel = false;
    Core.get("user/followingnotif", {
      params: { _id },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then((result) => {
        if (cancel) return;
        // result.data.data.map((e) => console.log(e));
        const data = result.data.data.map((e) => {
          return {
            id: e._id,
            title: e.message.notification.title,
            body: e.message.notification.body,
            time: e.time.slice(11, 16),
            date: e.time.slice(0, 10),
            type: e.notifType,
          };
        });

        //Get only unique dates from data
        const date = new Set([
          ...result.data.data.map((e) => e.time.slice(0, 10)),
        ]);
        const dateArr = [...date];

        //Set notification array by dates
        const newArr = dateArr.map((e) => {
          return {
            date: e,
            notification: data.filter((f) => f.date === e),
          };
        });

        //Set new notification array
        const clone = [...notifData].concat(newArr);
        setNotifData(clone);

        if (result.data.data.length < 7) {
          setIsLimit(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLimit(true);
        // console.log(err.response);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
    };
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (isLimit === false) {
            const lastIndex = notifData.length - 1;
            const lastNotificationIndex =
              notifData[lastIndex].notification.length - 1;
            const lastNotification =
              notifData[lastIndex].notification[lastNotificationIndex];
            getFollowingNotification(lastNotification.id);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, notifData]
  );

  useEffect(() => {
    if (notification) {
      setNotifData((prev) => [notification, ...prev]);
    }
  }, [notification]);

  return (
    <VStack w={"full"}>
      {spinner === true ? (
        <Center mt={36}>
          <Spinner color="#45A735" size="md" />
        </Center>
      ) : notifData.length > 0 ? (
        <>
          {notifData.map((element, index) => {
            if (notifData.length === index + 1) {
              return (
                <Stack key={index} w={"full"}>
                  <Flex
                    align={"center"}
                    w="full"
                    _after={{
                      content: '""',
                      borderBottom: "1px solid",
                      borderColor: colorMode === "dark" ? "#707070" : "#DCDCDC",
                      flexGrow: 2,
                      ml: { base: 4, md: 8 },
                    }}
                  >
                    <Text
                      color={colorMode === "dark" ? "#fff" : "#000"}
                      fontSize={15}
                      fontWeight={"medium"}
                      letterSpacing={0.3}
                    >
                      {element.date}
                    </Text>
                  </Flex>

                  {element.notification.map((item, i) => {
                    if (element.notification.length === i + 1) {
                      return (
                        <HStack
                          key={i}
                          className="notifCard"
                          w={"full"}
                          justify={"flex-start"}
                          align={"center"}
                          ref={lastBookElementRef}
                          p={2}
                          rounded={"md"}
                        >
                          <Center
                            bg={colorMode === "dark" ? "#1D211D" : "#F8F8F8"}
                            w={"10"}
                            h={"10"}
                            rounded={"md"}
                          >
                            <Image
                              src={
                                item.type === "share"
                                  ? colorMode === "dark"
                                    ? shareDark
                                    : share
                                  : item.type === "ttr"
                                  ? colorMode === "dark"
                                    ? tapDark
                                    : tap
                                  : colorMode === "dark"
                                  ? systemDark
                                  : system
                              }
                              w={"5"}
                              h={"5"}
                              objectFit={"contain"}
                            />
                          </Center>

                          <VStack
                            w={"full"}
                            align={"flex-start"}
                            justify={"center"}
                            spacing={1}
                          >
                            <HStack
                              w={"full"}
                              justify={"space-between"}
                              align={"center"}
                            >
                              <Text fontSize={"15"} fontWeight="medium">
                                {item.title}
                              </Text>
                              <Text fontSize={"15"} fontWeight="medium">
                                {item.time}
                              </Text>
                            </HStack>
                            <Text fontSize={"13"} fontWeight="normal">
                              {item.body}
                            </Text>
                          </VStack>
                        </HStack>
                      );
                    } else {
                      return <NotifCard key={i} data={item} />;
                    }
                  })}
                </Stack>
              );
            } else {
              return (
                <Stack key={index} w={"full"}>
                  <Flex
                    align={"center"}
                    w="full"
                    _after={{
                      content: '""',
                      borderBottom: "1px solid",
                      borderColor: colorMode === "dark" ? "#707070" : "#DCDCDC",
                      flexGrow: 2,
                      ml: { base: 4, md: 8 },
                    }}
                  >
                    <Text
                      color={colorMode === "dark" ? "#fff" : "#000"}
                      fontSize={15}
                      fontWeight={"medium"}
                      letterSpacing={0.3}
                    >
                      {element.date}
                    </Text>
                  </Flex>
                  {element.notification.map((item, i) => (
                    <NotifCard key={i} data={item} />
                  ))}
                </Stack>
              );
            }
          })}
          {isLoading && (
            <HStack
              px={6}
              py={2}
              spacing={4}
              bg={colorMode === "dark" ? "#3b3b3b" : "#fff"}
              borderRadius={6}
            >
              <SkeletonCircle startColor="#eee" endColor="#ddd" size="10" />
              <SkeletonText
                startColor="#eee"
                endColor="#ddd"
                noOfLines={2}
                spacing="3"
                w={"40%"}
              />
            </HStack>
          )}
        </>
      ) : (
        <VStack
          justifyContent="center"
          alignItems="center"
          color="gray.400"
          mt="15%"
        >
          <Icon as={FiInfo} w={6} h={6} />
          <Text fontSize={13}>Мэдээлэл олдсонгүй</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default Notification;
