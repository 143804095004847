import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import th from "../../../assets/Images/CardIcons/th.png";
import thDark from "../../../assets/Images/CardIcons/thDark.png";
import humidity from "../../../assets/Images/CardIcons/humidity.png";
import temperature from "../../../assets/Images/CardIcons/temperature.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import socket from "../../../hooks/Socket";
import Blocked from "../Blocked";
import UserContext from "../../../context/UserContext";

const ZigbeeTh = ({
  roomName,
  deviceName,
  status,
  tempValue,
  humiValue,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  batteryPercentage,
  deviceType,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    room: roomName,
    name: deviceName,
    temp: tempValue,
    humi: humiValue,
    id: id,
    status: status,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("zigbeeTh data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          temp: value.status === "active" ? value.data.Temperature : 0,
          humi: value.status === "active" ? value.data.Humidity : 0,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('zigbeeTh name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      // onClick={() => history.push("/home/device-detail")}
      onClick={() =>
        history.push("/home/sensor-detail", {
          roomName: datas.room,
          deviceName: datas.name,
          status: datas.status,
          tempValue: datas.temp,
          humiValue: datas.humi,
          notifEnabled: datas.notifEnabled,
          id,
          isOriginal,
          origin,
          wifiSignal,
          batteryPercentage,
          deviceType,
          blocked,
        })
      }
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <Center
          w={"12"}
          h={"12"}
          rounded={"md"}
          bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
          alignSelf={"flex-start"}
        >
          <Image
            src={colorMode === "dark" ? thDark : th}
            w={"2.5"}
            objectFit={"contain"}
          />
        </Center>
        {datas.status === "active" ? (
          <HStack>
            <HStack spacing={1}>
              <Image src={temperature} w={"4"} h={"4"} objectFit={"contain"} />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.temp}°C
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Image src={humidity} w={"4"} h={"4"} objectFit={"contain"} />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.humi}%
              </Text>
            </HStack>
          </HStack>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default ZigbeeTh;
