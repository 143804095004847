import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import rgb from "../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../assets/Images/CardIcons/rgbDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import axios from "../../../axios";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";
import curtainClose from "../../../assets/Images/icons/curtainClose.png";
import curtainCloseDark from "../../../assets/Images/icons/curtainCloseDark.png";

const Curtain = ({
  roomName,
  deviceName,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    notifEnabled: notifEnabled,
    test: 0,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();

  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          w={"full"}
          onClick={() =>
            history.push("/home/curtain-control", {
              roomName: datas.room,
              deviceName: datas.name,
              status: datas.status,
              id,
              isOriginal,
              origin,
              wifiSignal,
              notifEnabled: datas.notifEnabled,
              deviceType: deviceType,
              blocked,
            })
          }
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? curtainCloseDark : curtainClose}
              //   w={"5"}
              w={7}
              h={7}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? null : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/curtain-control", {
            roomName: datas.room,
            deviceName: datas.name,
            status: datas.status,
            id,
            isOriginal,
            origin,
            wifiSignal,
            notifEnabled: datas.notifEnabled,
            deviceType: deviceType,
            blocked,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Curtain;
