import { VStack, Box, Center } from "@chakra-ui/react";
import LineSkeleton from "./LineSkeleton";

const ChartSkeleton = () => {
  return (
    <Center w="full" h="full">
      <VStack w="full" h="82%" pb={6} alignItems={"center"}>
        <Box className="skeleton skeleton-text"></Box>

        <VStack
          boxShadow={"0 2px 7px 2px rgba(0, 0, 0, 0.03)"}
          pos="relative"
          //   h="full"
          //   w="90%"
          w="1400px"
          h="600px"
          justifyContent={"center"}
          alignItems={"center"}
          px={6}
          rounded={10}
          overflow={"hidden"}
        >
          <LineSkeleton />
        </VStack>
      </VStack>
    </Center>
  );
};

export default ChartSkeleton;
