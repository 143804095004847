import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  HStack,
  Image,
  Text,
  VStack,
  useColorMode,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../components/Button/BackButton";
import DeviceName from "../../../../components/Cards/DeviceDetail/DeviceName";
import DeviceWifiSignal from "../../../../components/Cards/DeviceDetail/DeviceWifiSignal";
import DeviceRoom from "../../../../components/Cards/DeviceDetail/DeviceRoom";
import DeviceType from "../../../../components/Cards/DeviceDetail/DeviceType";
import DeviceShared from "../../../../components/Cards/DeviceDetail/DeviceShared";
import DeviceNotif from "../../../../components/Cards/DeviceDetail/DeviceNotif";
import plusDark from "../../../../assets/Images/icons/plusDark.png";

const ZbridgeDetail = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const location = useLocation();

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <HStack w="full" justify={"start"}>
          <BackButton />
          <Text fontWeight={"normal"}>Төхөөрөмжийн дэлгэрэнгүй</Text>
        </HStack>

        <HStack
          transition={"ease-in-out .3s"}
          w={"sm"}
          py={"2"}
          bg={"#45A735"}
          rounded={"full"}
          align={"center"}
          justify={"center"}
          shadow={"md"}
          onClick={() =>
            history.push("/home/zbridge-devices", location.state.id)
          }
          cursor={"pointer"}
          _hover={{
            shadow: "lg",
          }}
        >
          <Text color={"#fff"} fontSize={15} fontWeight={"normal"}>
            Төхөөрөмж нэмэх
          </Text>
          <Image src={plusDark} w={"5"} h={"5"} objectFit={"contain"} />
        </HStack>
      </HStack>

      {/*     BODY    */}
      <Stack
        justify={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "center",
        ]}
        rounded={"md"}
        px={20}
        h="full"
        alignItems={"start"}
        pt={[6, 6, 6, 6, 10]}
        w="full"
        spacing={[4, 4, 4, 4, 8]}
        direction={["column", "column", "column", "column", "row"]}
      >
        {/* COLUMN 1  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceName deviceName={location.state.deviceName} />
          {/*    Утга    */}
          <HStack
            spacing={5}
            transition={"ease-in-out .3s"}
            w={["full", "full", "full", "full", "96"]}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
          >
            {/* <Text>Нийт төхөөрөмж</Text> */}
            <Text>Нийт төхөөрөмж</Text>
            {location.state.status === "active" ? (
              location.state.deviceType === "zbridge" ? (
                <Text fontWeight={"medium"}>
                  {location.state.subDeviceCount}
                </Text>
              ) : null
            ) : (
              <Text color="#ff0000" fontWeight={"medium"}>
                Холболтгүй
              </Text>
            )}
          </HStack>
        </VStack>

        {/* COLUMN 2 */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceWifiSignal wifiSignal={location.state.wifiSignal} />
          <DeviceRoom roomName={location.state.roomName} />
          <DeviceType deviceType="Бридж" />
        </VStack>

        {/* COLUMN 3  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceShared
            isOriginal={location.state.isOriginal}
            origin={location.state.origin}
          />
          <DeviceNotif
            blocked={location.state.blocked}
            id={location.state.id}
            notifEnabled={location.state.notifEnabled}
          />
        </VStack>

        {/*   BODY  END    */}
      </Stack>
    </VStack>
  );
};

export default ZbridgeDetail;
