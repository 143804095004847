import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import Cookies from "js-cookie";
import axios from "../../axios";
import UserContext from "../../context/UserContext";

const ChangeNameCard = ({ data, index, room, setTrigger }) => {
  const [deviceName, setDeviceName] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const submitHandler = () => {
    if (deviceName) {
      let source = Core.CancelToken.source();
      Core.put(
        "user/devicelabel",
        {
          _id: data._id,
          for: deviceName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          onClose();
          setTrigger(true);
          if (!toast.isActive(id)) {
            toast({
              description: "Амжилттай",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              description: "Алдаа гарлаа",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
          }
        });
    }
  };

  return (
    <HStack
      // bg={colorMode === "dark" ? "#F9F9F9" : "#F9F9F9"}
      bg={
        index % 2 === 0
          ? "transparent"
          : colorMode === "dark"
          ? "#1D211D"
          : "#fff"
      }
      w={"full"}
      p={"4"}
      rounded={"md"}
    >
      <Text fontSize={15} fontWeight={"normal"} w={"32"}>
        {index + 1}
      </Text>
      <Text fontSize={15} fontWeight={"normal"} w={"full"}>
        {data.for}
      </Text>
      <Text fontSize={15} fontWeight={"normal"} w={"full"}>
        {room}
      </Text>
      <HStack w={"full"} justify={"space-between"} align={"center"}>
        <HStack
          bg={data.status === "active" ? "#D2E7C9" : "#FFD1D1"}
          w={"32"}
          rounded={"full"}
          align={"center"}
          justify={"center"}
        >
          <Box
            bg={data.status === "active" ? "#45A735" : "#ff0000"}
            w={"2"}
            h={"2"}
            rounded={"full"}
          />
          <Text
            fontSize={13}
            fontWeight={"normal"}
            color={data.status === "active" ? "#45A735" : "#ff0000"}
          >
            {data.status === "active" ? "Идэвхтэй" : "Идэвхгүй"}
          </Text>
        </HStack>

        <HStack onClick={onOpen} cursor={"pointer"}>
          <Text fontSize={15} fontWeight={"normal"}>
            Засах
          </Text>
          <Image
            src={colorMode === "dark" ? pencilDark : pencil}
            w={"4"}
            h={"4"}
            objectFit={"contain"}
          />
        </HStack>
      </HStack>

      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Төхөөрөмжийн нэр солих</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Солих нэрээ оруулна уу
              </FormLabel>
              <Input
                placeholder={data.for}
                maxLength={40}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                focusBorderColor={"#45A735"}
                autoFocus
                onKeyDown={(e) => {
                  if (deviceName) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              isDisabled={deviceName ? false : true}
              _focusVisible={{ outline: "none" }}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default ChangeNameCard;
