import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  Grid,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import humidity from "../../../assets/Images/CardIcons/humidity.png";
import temperature from "../../../assets/Images/CardIcons/temperature.png";
import ecIcon from "../../../assets/Images/CardIcons/ec.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import smtc from "../../../assets/Images/CardIcons/smtc.png";
import smtcDark from "../../../assets/Images/CardIcons/smtcDark.png";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";

const LoraSMTC = ({
  roomName,
  status,
  id,
  deviceName,
  isOriginal,
  origin,
  deviceType,
  wifiSignal,
  notifEnabled,
  temp,
  humi,
  ec,
  blocked,
}) => {
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    temp: temp,
    humi: humi,
    ec: ec,
    notifEnabled: notifEnabled,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const { isInActiveDevice } = useContext(UserContext);

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("smtc data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          temp: value.status === "active" ? value.data.temperature : 0,
          humi: value.status === "active" ? value.data.humidity : 0,
          ec: value.status === "active" ? value.data.ec : 0,
          status: value.status,
        }));
      }

      if (id === value._id && value.for) {
        // console.log('smtc name:', value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      onClick={() =>
        history.push("/home/sensor-detail", {
          roomName: datas.room,
          status: datas.status,
          id,
          deviceName: datas.name,
          isOriginal,
          origin,
          deviceType,
          wifiSignal,
          notifEnabled: datas.notifEnabled,
          temp: datas.temp,
          humi: datas.humi,
          ec: datas.ec,
          blocked,
        })
      }
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack
        w={"full"}
        align={"flex-start"}
        justify={"space-between"}
        pt={"5"}
        px={"6"}
      >
        <HStack w={"40"}>
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? smtcDark : smtc}
              w={"6"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          // <Grid w={"full"} templateColumns={"repeat(2, 1fr)"} gap={0}>
          <Grid
            // pt={blocked ? 3 : 0}
            // pt={3}
            w={"56"}
            templateColumns={"repeat(2, 1fr)"}
            gap={0}
          >
            <HStack>
              <Image src={temperature} w={"2.5"} objectFit={"contain"} />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.temp}°C
              </Text>
            </HStack>
            <HStack>
              <Image src={humidity} w={"2.5"} objectFit={"contain"} />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.humi}%
              </Text>
            </HStack>
            <HStack>
              <Image src={ecIcon} w={"2.5"} objectFit={"contain"} />
              <Text fontSize={14} fontWeight={"normal"}>
                {datas.ec}
              </Text>
            </HStack>
          </Grid>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default LoraSMTC;
