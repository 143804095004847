import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import rgb from "../../../assets/Images/CardIcons/rgb.png";
import rgbDark from "../../../assets/Images/CardIcons/rgbDark.png";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";
import axios from "../../../axios";
import Blocked from "../Blocked";
import socket from "../../../hooks/Socket";
import UserContext from "../../../context/UserContext";

const Wled = ({
  roomName,
  deviceName,
  color,
  dimmer,
  mode,
  state,
  status,
  id,
  isOriginal,
  origin,
  wifiSignal,
  notifEnabled,
  deviceType,
  blocked,
  clientId,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const [datas, setDatas] = useState({
    id: id,
    room: roomName,
    name: deviceName,
    status: status,
    color: color,
    dimmer: dimmer,
    mode: mode,
    state: state,
    notifEnabled: notifEnabled,
    test: 0,
  });
  const { colorMode } = useColorMode();
  let history = useHistory();
  const token = Cookies.get("accessToken");
  const { isInActiveDevice, handledHttpErrors } = useContext(UserContext);
  const { Core } = axios();

  useEffect(() => {
    const socketSetData = (value) => {
      // console.log("WLED data --->", value);
      if (id === value._id && value.status && value.data) {
        setDatas((prevState) => ({
          ...prevState,
          state: value.status === "active" ? value.data.state : "off",
          dimmer: value.status === "active" ? value.data.dimmer : 255,
          status: value.status,
          color: value.status === "active" ? value.data.color : "",
          mode: value.status === "active" ? value.data.mode : "",
        }));
      }

      if (id === value._id && value.for) {
        // console.log("rgb name:", value.for);
        setDatas((prevState) => ({
          ...prevState,
          name: value.for,
        }));
      }
    };
    socket.on(datas.id, socketSetData);
    return () => {
      socket.off(datas.id, socketSetData);
    };
  }, []);

  const switchHandler = () => {
    setIsDisable(true);
    if (datas.state === "on" || datas.state === "off") {
      // console.log("powerData", datas.power);
      const data = {
        command: datas.state === "on" ? "off" : "on",
        _id: datas.id,
      };
      // console.log("data", data);
      let source = Core.CancelToken.source();
      Core.post(
        "/user_v2/command/wled_switch",
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
        .then((result) => {
          //   console.log("rgb switch ------------------->", result.data.data);
          setDatas((prevState) => ({
            ...prevState,
            state: result.data.data,
          }));
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDisable(false);
          if (handledHttpErrors.includes(err.response.status)) return;
        });
    } else {
      setIsDisable(false);
    }
  };

  return (
    <VStack
      className="card"
      w={"full"}
      minH={"32"}
      rounded={"xl"}
      align={"flex-start"}
      justify={"flex-start"}
      shadow={"sm"}
      position={"relative"}
      cursor={"pointer"}
      display={
        isInActiveDevice && datas.status === "inActive" ? "none" : "flex"
      }
    >
      <HStack w={"full"} justify={"space-between"} pt={"5"} px={"6"}>
        <HStack
          w={"full"}
          onClick={() =>
            history.push("/home/wled-control", {
              roomName: datas.room,
              deviceName: datas.name,
              color: datas.color,
              dimmer: datas.dimmer,
              mode: datas.mode,
              state: datas.state,
              status: datas.status,
              id,
              isOriginal,
              origin,
              wifiSignal,
              notifEnabled: datas.notifEnabled,
              deviceType: deviceType,
              blocked,
              clientId,
            })
          }
        >
          <Center
            w={"12"}
            h={"12"}
            rounded={"md"}
            bg={colorMode === "dark" ? "#323A32" : "#F8F8F8"}
            alignSelf={"flex-start"}
          >
            <Image
              src={colorMode === "dark" ? rgbDark : rgb}
              w={"5"}
              objectFit={"contain"}
            />
          </Center>
        </HStack>
        {datas.status === "active" ? (
          <Center w={"10"} h={"10"} rounded={"md"}>
            <Switch
              isChecked={datas.state === "on" ? true : false}
              colorScheme="green"
              size="md"
              onChange={blocked === false ? switchHandler : null}
              isDisabled={blocked || isDisable ? true : false}
              css={`
                > span:first-of-type {
                  box-shadow: unset;
                }
              `}
            />
          </Center>
        ) : (
          <Text fontWeight={"normal"} fontSize={13} color={"#ff0000"}>
            Холболтгүй
          </Text>
        )}
      </HStack>
      <VStack
        spacing={0}
        align={"flex-start"}
        justify={"flex-start"}
        w={"full"}
        h={"full"}
        px={"6"}
        pb={"4"}
        onClick={() =>
          history.push("/home/wled-control", {
            roomName: datas.room,
            deviceName: datas.name,
            color: datas.color,
            dimmer: datas.dimmer,
            mode: datas.mode,
            state: datas.state,
            status: datas.status,
            id,
            isOriginal,
            origin,
            wifiSignal,
            notifEnabled: datas.notifEnabled,
            deviceType: deviceType,
            blocked,
            clientId,
          })
        }
      >
        <Text fontSize={13} color={"#979797"} fontWeight={"normal"}>
          {roomName}
        </Text>
        <Text fontSize={15} fontWeight={"medium"}>
          {deviceName}
        </Text>
      </VStack>

      {isOriginal ? null : blocked ? (
        <Blocked />
      ) : (
        <Image
          src={colorMode === "dark" ? shareDark : share}
          w={"4"}
          h={"4"}
          objectFit={"fill"}
          position={"absolute"}
          top={0}
          right={2}
        />
      )}
    </VStack>
  );
};

export default Wled;
