import React, { useState } from "react";
import {
  Grid,
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import InsertName from "../../../components/Automation/Create/InsertName";
import { useHistory } from "react-router-dom";
import tap from "../../../assets/Images/icons/tap.png";
import tapDark from "../../../assets/Images/icons/tapDark.png";
import notifOn from "../../../assets/Images/icons/notifOn.png";
import notifOnDark from "../../../assets/Images/icons/notifOnDark.png";
import notifOff from "../../../assets/Images/icons/notifOff.png";
import notifOffDark from "../../../assets/Images/icons/notifOffDark.png";
import alarmOn from "../../../assets/Images/icons/alarmOn.png";
import alarmOnDark from "../../../assets/Images/icons/alarmOnDark.png";
import alarmOff from "../../../assets/Images/icons/alarmOff.png";
import alarmOffDark from "../../../assets/Images/icons/alarmOffDark.png";
import switchOn from "../../../assets/Images/icons/switchOn.png";
import switchOnDark from "../../../assets/Images/icons/switchOnDark.png";
import switchOff from "../../../assets/Images/icons/switchOff.png";
import switchOffDark from "../../../assets/Images/icons/switchOffDark.png";
import { IoIosArrowForward } from "react-icons/io";
import BackButton from "../../../components/Button/BackButton";

const TtrCategory = () => {
  const [name, setName] = useState("");
  const history = useHistory();
  const { colorMode } = useColorMode();

  const tapData = [
    {
      title: "Бүх унтраалгыг асаах",
      link: "ttrCreate",
      actionType: "state",
      icon: switchOn,
      iconDark: switchOnDark,
    },
    {
      title: "Бүх унтраалгыг унтраах",
      link: "ttrCreate",
      actionType: "state",
      icon: switchOff,
      iconDark: switchOffDark,
    },
    {
      title: "Бүх мэдэгдэлийг асаах",
      link: "ttrCreate",
      actionType: "notif",
      icon: notifOn,
      iconDark: notifOnDark,
    },
    {
      title: "Бүх мэдэгдэлийг унтраах",
      link: "ttrCreate",
      actionType: "notif",
      icon: notifOff,
      iconDark: notifOffDark,
    },
    {
      title: "Бүх дохиог асаах",
      link: "ttrCreate",
      actionType: "alarm",
      icon: alarmOn,
      iconDark: alarmOnDark,
    },
    {
      title: "Бүх дохиог унтраах",
      link: "ttrCreate",
      actionType: "alarm",
      icon: alarmOff,
      iconDark: alarmOffDark,
    },
  ];

  return (
    <VStack w={"full"} h={"full"} spacing={8}>
      <HStack spacing={4} w={"full"}>
        <BackButton />
        <Text fontSize={15} fontWeight={"normal"}>
          Багц үйлдэл үүсгэх
        </Text>
      </HStack>
      <VStack
        className="bgColor"
        w={"full"}
        h={["50vh", "50vh", "50vh", "50vh", "full"]}
        rounded={"xl"}
        p={"6"}
        align={"center"}
        justify={"center"}
        spacing={10}
      >
        <HStack
          transition={"ease-in-out .3s"}
          w={"sm"}
          h={"16"}
          px={"10"}
          className="btn"
          rounded={"md"}
          borderColor={"#45A735"}
          borderWidth={"thin"}
          align={"center"}
          justify={"space-between"}
          onClick={() => history.push("/automation/create-ttr")}
          cursor={"pointer"}
          _hover={{
            shadow: "lg",
          }}
        >
          <HStack>
            <Image
              src={colorMode === "dark" ? tapDark : tap}
              w={"6"}
              h={"6"}
              objectFit={"contain"}
            />
            <Text fontSize={15} fontWeight={"normal"}>
              Багц үйлдэл үүсгэх
            </Text>
          </HStack>
          <Icon as={IoIosArrowForward} w={"6"} h={"6"} color={"#45A735"} />
        </HStack>
        <VStack w={"2xl"} justify={"center"} align={"flex-start"} spacing={4}>
          <Text fontSize={15} fontWeight={"medium"}>
            Багц үйлдэл хялбараар үүсгэх
          </Text>
          <Grid w={"full"} templateColumns={"repeat(2, 1fr)"} gap={6}>
            {tapData.map((e, i) => (
              <HStack
                key={i}
                transition={"ease-in-out .3s"}
                w={"full"}
                h={"20"}
                px={"6"}
                bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
                borderWidth={"medium"}
                borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
                rounded={"md"}
                shadow={"md"}
                align={"center"}
                justify={"flex-start"}
                onClick={() =>
                  history.push("/automation/create-ttr", {
                    actionType: e.actionType,
                    title: e.title,
                  })
                }
                cursor={"pointer"}
                _hover={{
                  shadow: "lg",
                }}
              >
                <Image
                  src={colorMode === "dark" ? e.iconDark : e.icon}
                  w={"5"}
                  h={"5"}
                  objectFit={"contain"}
                />
                <Text fontSize={15} fontWeight={"normal"}>
                  {e.title}
                </Text>
              </HStack>
            ))}
          </Grid>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default TtrCategory;
