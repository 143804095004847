import React, { useContext, useState } from "react";
import {
  Badge,
  Button,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { SideData } from "./SidebarData";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/Images/logo.png";
import logoDark from "../../assets/Images/logoDark.png";
import logout from "../../assets/Images/icons/logout.png";
import logoutDark from "../../assets/Images/icons/logoutDark.png";
import AuthContext from "../../hooks/UseAuth";
import Cookies from "js-cookie";
import UserContext from "../../context/UserContext";

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const badge = Cookies.get("badge");
  const { logoutHandler } = useContext(AuthContext);
  const { isLoading } = useContext(UserContext);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack
      transition="all .3s"
      className="sidebar"
      shadow={"md"}
      h={"full"}
      w={"64"}
      px={"4"}
      pt={"2"}
      pb={"6"}
      align={"flex-start"}
      justify={"space-between"}
      zIndex={1}
      display={["none", "none", "none", "flex", "flex"]}
    >
      <VStack w={"full"} align={"flex-start"} spacing={14}>
        <Image
          src={colorMode === "dark" ? logoDark : logo}
          objectFit={"contain"}
          w={"full"}
        />
        <VStack spacing={3} align={"flex-start"} w={"full"}>
          {SideData.map((e, index) => (
            <HStack
              transition="all .3s"
              key={index}
              bg={
                location.pathname.split("/")[1] === e.toLink.split("/")[1]
                  ? colorMode === "dark"
                    ? "#1D211D"
                    : "#F3F3F3"
                  : "transparent"
              }
              w={"full"}
              h={"10"}
              px={"4"}
              align={"center"}
              rounded={"md"}
              onClick={() => history.push(e.toLink)}
              cursor={"pointer"}
              _hover={{
                bg: colorMode === "dark" ? "#1D211D" : "#F3F3F3",
              }}
              position={"relative"}
            >
              <Image
                src={colorMode === "dark" ? e.iconDark : e.icon}
                w={5}
                h={5}
                objectFit={"contain"}
                alignSelf={"center"}
              />
              <Text
                fontWeight={
                  location.pathname.split("/")[1] === e.toLink.split("/")[1]
                    ? "medium"
                    : "normal"
                }
                fontSize={15}
              >
                {e.title}
              </Text>

              {e.title === "Мэдэгдэл" ? (
                <Badge
                  key={index}
                  variant="solid"
                  colorScheme={"red"}
                  position={"absolute"}
                  top={1}
                  left={5}
                  borderRadius={"full"}
                  fontSize={"x-small"}
                  zIndex={100}
                >
                  {badge > 0 ? badge : null}
                </Badge>
              ) : null}
            </HStack>
          ))}
        </VStack>
      </VStack>
      <HStack w={"full"} h={"10"} px={"4"} onClick={onOpen} cursor={"pointer"}>
        <Image
          src={colorMode === "dark" ? logoutDark : logout}
          w={5}
          h={5}
          objectFit={"contain"}
          alignSelf={"center"}
        />
        <Text fontWeight={"medium"} fontSize={14}>
          Гарах
        </Text>

        <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Та гарахдаа итгэлтэй байна уу?</ModalHeader>
            <ModalCloseButton _focusVisible={{ outline: "none" }} />
            <ModalFooter>
              <Button
                onClick={onClose}
                mr={3}
                _focusVisible={{ outline: "none" }}
              >
                Болих
              </Button>
              <Button
                colorScheme="green"
                _focusVisible={{ outline: "none" }}
                onClick={() => logoutHandler()}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Тийм
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    </VStack>
  );
};

export default Sidebar;
