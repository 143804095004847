import React, { useContext } from "react";
import {
  Center,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import share from "../../assets/Images/icons/share.png";
import shareDark from "../../assets/Images/icons/shareDark.png";
import deviceControl from "../../assets/Images/icons/deviceControl.png";
import deviceControlDark from "../../assets/Images/icons/deviceControlDark.png";
import status from "../../assets/Images/icons/status.png";
import statusDark from "../../assets/Images/icons/statusDark.png";
import room from "../../assets/Images/icons/room.png";
import roomDark from "../../assets/Images/icons/roomDark.png";
import list from "../../assets/Images/icons/list.png";
import listDark from "../../assets/Images/icons/listDark.png";
import eyeCloseIcon from "../../assets/Images/icons/eyeCloseIcon.png";
import eyeCloseIconLight from "../../assets/Images/icons/eyeCloseIconLight.png";
import eyeIcon from "../../assets/Images/icons/eyeIcon.png";
import eyeIconLight from "../../assets/Images/icons/eyeIconLight.png";
import { useHistory, useLocation } from "react-router-dom";
import DeviceChangeHouse from "./DeviceChangeHouse";
import UserContext from "../../context/UserContext";

const DeviceControl = () => {
  const history = useHistory();
  const location = useLocation();
  const { colorMode } = useColorMode();
  const { isInActiveDevice, setIsInActiveDevice } = useContext(UserContext);

  return (
    <Menu>
      <MenuButton
        transition={"ease-in-out .3s"}
        rounded={"full"}
        _focusVisible={{
          outline: "none",
        }}
        _hover={{
          shadow: "md",
        }}
        display={location.pathname === "/home" ? "flex" : "none"}
      >
        <Center className="headerBtn" w={"12"} h={"12"} rounded={"full"}>
          <Image
            src={colorMode === "dark" ? listDark : list}
            w={"5"}
            h={"5"}
            objectFit={"contain"}
          />
        </Center>
      </MenuButton>
      <MenuList bg={colorMode === "dark" ? "#202420" : "#fff"}>
        <MenuItem
          icon={
            <Image
              src={colorMode === "dark" ? deviceControlDark : deviceControl}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          }
          fontSize={15}
          fontWeight={"normal"}
          onClick={() => history.push("/home/device-control")}
        >
          Төхөөрөмжийн нэр солих
        </MenuItem>
        <MenuItem
          icon={
            <Image
              src={colorMode === "dark" ? shareDark : share}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          }
          fontSize={15}
          fontWeight={"normal"}
          onClick={() => history.push("/home/shared-user")}
        >
          Төхөөрөмж хуваалцах
        </MenuItem>
        <MenuItem
          icon={
            <Image
              src={colorMode === "dark" ? statusDark : status}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          }
          fontSize={15}
          fontWeight={"normal"}
          onClick={() => history.push("/home/device-reorder")}
        >
          Төхөөрөмжийн дараалал солих
        </MenuItem>
        <MenuItem
          icon={
            <Image
              src={colorMode === "dark" ? roomDark : room}
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          }
          fontSize={15}
          fontWeight={"normal"}
          onClick={() => history.push("/home/room-control")}
        >
          Өрөөний удирдлага
        </MenuItem>
        <DeviceChangeHouse />
        <MenuItem
          icon={
            <Image
              src={
                colorMode === "dark"
                  ? isInActiveDevice
                    ? eyeIcon
                    : eyeCloseIcon
                  : isInActiveDevice
                  ? eyeIconLight
                  : eyeCloseIconLight
              }
              w={"5"}
              h={"5"}
              objectFit={"contain"}
            />
          }
          fontSize={15}
          fontWeight={"normal"}
          onClick={() => setIsInActiveDevice(!isInActiveDevice)}
        >
          {isInActiveDevice
            ? "Холболтгүй төхөөрөмжийг харуулах"
            : "Холболтгүй төхөөрөмжийг нуух"}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DeviceControl;
