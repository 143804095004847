import React from "react";
import { HStack, Text, useColorMode, Image } from "@chakra-ui/react";
import share from "../../../assets/Images/icons/share.png";
import shareDark from "../../../assets/Images/icons/shareDark.png";

const DeviceType = ({ isOriginal, origin }) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      spacing={5}
      transition={"ease-in-out .3s"}
      w="full"
      h={"20"}
      px={"8"}
      bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
      borderWidth={"medium"}
      borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
      rounded={"md"}
      shadow={"md"}
      align={"center"}
      justify={"space-between"}
    >
      <Text>Хуваалцсан</Text>
      {isOriginal ? (
        <Text fontWeight="medium">-</Text>
      ) : (
        <HStack spacing={4}>
          <Image
            objectFit="contain"
            w="4"
            src={colorMode === "dark" ? shareDark : share}
          />
          <Text fontWeight={"medium"}>{origin}</Text>
        </HStack>
      )}
    </HStack>
  );
};

export default DeviceType;
