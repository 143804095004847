import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  HStack,
  Text,
  VStack,
  useColorMode,
  Icon,
  Stack,
} from "@chakra-ui/react";
import BackButton from "../../../../components/Button/BackButton";
import DeviceName from "../../../../components/Cards/DeviceDetail/DeviceName";
import DeviceWifiSignal from "../../../../components/Cards/DeviceDetail/DeviceWifiSignal";
import DeviceRoom from "../../../../components/Cards/DeviceDetail/DeviceRoom";
import DeviceType from "../../../../components/Cards/DeviceDetail/DeviceType";
import DeviceShared from "../../../../components/Cards/DeviceDetail/DeviceShared";
import DeviceNotif from "../../../../components/Cards/DeviceDetail/DeviceNotif";
import { AiOutlineLineChart } from "react-icons/ai";

const SensorDetail = () => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const history = useHistory();

  return (
    <VStack h="full" spacing={10} w="full">
      {/*    HEADER    */}
      <HStack w="full" justify={"space-between"}>
        <HStack w="full" spacing={4}>
          <BackButton />
          <Text fontWeight={"normal"}>Төхөөрөмжийн дэлгэрэнгүй</Text>
        </HStack>

        {[
          "th",
          "zigbeeTh",
          "smtc",
          "lth",
          "lgt",
          "co",
          "So2",
          "No2",
          "Dust",
          "Co2",
          "zbAqi",
          "thsc",
          "aqs",
        ].includes(location.state.deviceType) ? (
          <HStack
            className="btn"
            transition={"ease-in-out .3s"}
            borderRadius={"full"}
            px="24"
            py={"2"}
            _hover={{
              shadow: "md",
            }}
            onClick={() => {
              location.state.deviceType === "smtc"
                ? history.push("/home/smtc-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "lgt"
                ? history.push("/home/lgt-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "lth"
                ? history.push("/home/lth-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "co"
                ? history.push("/home/carbon-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "Co2" ||
                  location.state.deviceType === "So2" ||
                  location.state.deviceType === "No2" ||
                  location.state.deviceType === "Dust"
                ? history.push("/home/zlan-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "zbAqi"
                ? history.push("/home/aqi-chart", {
                    data: location.state,
                  })
                : location.state.deviceType === "aqs"
                ? history.push("/home/aqs-chart", {
                    data: location.state,
                  })
                : history.push("/home/th-chart", {
                    data: location.state,
                  });
            }}
            cursor={"pointer"}
          >
            <Icon color={"#45A735"} w={"5"} h={"5"} as={AiOutlineLineChart} />
            <Text fontSize={15} fontWeight={"medium"}>
              Chart
            </Text>
          </HStack>
        ) : null}
      </HStack>

      {/*     BODY    */}
      <Stack
        justify={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "center",
        ]}
        rounded={"md"}
        px={20}
        h="full"
        alignItems={"start"}
        pt={[6, 6, 6, 6, 10]}
        w="full"
        spacing={[4, 4, 4, 4, 8]}
        direction={["column", "column", "column", "column", "row"]}
      >
        {/* COLUMN 1  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceName deviceName={location.state.deviceName} />
          {/*    Утга    */}
          <HStack
            spacing={5}
            transition={"ease-in-out .3s"}
            w={["full", "full", "full", "full", "96"]}
            h={"20"}
            px={"8"}
            bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
            borderWidth={"medium"}
            borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
            rounded={"md"}
            shadow={"md"}
            align={"center"}
            justify={"space-between"}
          >
            <Text>Утга</Text>
            {location.state.status === "active" ? (
              location.state.deviceType === "th" ||
              location.state.deviceType === "zigbeeTh" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>
                    {location.state.tempValue}°C
                  </Text>
                  <Text fontWeight={"medium"}>{location.state.humiValue}%</Text>
                </HStack>
              ) : location.state.deviceType === "co" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>
                    {location.state.carbonValue}ppm
                  </Text>
                  <Text fontWeight={"medium"}>
                    {location.state.tempValue}°C
                  </Text>
                </HStack>
              ) : location.state.deviceType === "Water" ||
                location.state.deviceType === "Occupancy" ||
                location.state.deviceType === "Smoke" ||
                location.state.deviceType === "humanDetector" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>-</Text>
                </HStack>
              ) : location.state.deviceType === "Contact" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>
                    {location.state.contactStatus === "inContact"
                      ? "Хаалттай"
                      : location.state.contactStatus === "noContact"
                      ? "Нээлттэй"
                      : "-"}
                  </Text>
                </HStack>
              ) : location.state.deviceType === "smtc" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>{location.state.humi}%</Text>
                  <Text fontWeight={"medium"}>{location.state.temp}°C</Text>
                  <Text fontWeight={"medium"}>{location.state.ec}С/м</Text>
                </HStack>
              ) : location.state.deviceType === "lth" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>{location.state.humi}%</Text>
                  <Text fontWeight={"medium"}>{location.state.temp}°C</Text>
                </HStack>
              ) : location.state.deviceType === "lgt" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>
                    {location.state.illumination}
                  </Text>
                </HStack>
              ) : location.state.deviceType === "Co2" ? (
                <HStack fontWeight={"medium"} spacing={1}>
                  <Text>{location.state.data}</Text>
                  <Text fontSize={13}>ppm</Text>
                </HStack>
              ) : location.state.deviceType === "So2" ? (
                <HStack fontWeight={"medium"} spacing={1}>
                  <Text>{location.state.data}</Text>
                  <Text fontSize={13}>ppm</Text>
                </HStack>
              ) : location.state.deviceType === "No2" ? (
                <HStack fontWeight={"medium"} spacing={1}>
                  <Text>{location.state.data}</Text>
                  <Text fontSize={13}>ppm</Text>
                </HStack>
              ) : location.state.deviceType === "Dust" ? (
                <HStack fontWeight={"medium"} spacing={1}>
                  <Text>{location.state.data}</Text>
                  <Text fontSize={13}>μg/m³</Text>
                </HStack>
              ) : location.state.deviceType === "zbAqi" ? (
                <VStack alignItems={"end"}>
                  <HStack spacing={6}>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Co2}ppm
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Formaldehyde}mg/m3
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Humidity}%
                    </Text>
                  </HStack>

                  <HStack spacing={6}>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Temperature}°C
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.VOC}ppm
                    </Text>
                  </HStack>
                </VStack>
              ) : location.state.deviceType === "thsc" ? (
                <HStack spacing={6}>
                  <Text fontWeight={"medium"}>{location.state.temp}°C</Text>
                  <Text fontWeight={"medium"}>{location.state.humi}%</Text>
                </HStack>
              ) : location.state.deviceType === "aqs" ? (
                <VStack alignItems={"end"}>
                  <HStack spacing={6}>
                    <Text fontWeight={"medium"}>
                      {location.state.data.CO2.toLocaleString("en-Us")}ppm
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.HCHO}mg/m3
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Humidity}%
                    </Text>
                  </HStack>

                  <HStack spacing={6}>
                    <Text fontWeight={"medium"}>
                      {location.state.data.PM25}μg/m3
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.Temperature}°C
                    </Text>
                    <Text fontWeight={"medium"}>
                      {location.state.data.TVOC}ppm
                    </Text>
                  </HStack>
                </VStack>
              ) : null
            ) : (
              <Text color="#ff0000" fontWeight={"medium"}>
                Холболтгүй
              </Text>
            )}
          </HStack>
        </VStack>

        {/* COLUMN 2 */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceWifiSignal wifiSignal={location.state.wifiSignal} />
          <DeviceRoom roomName={location.state.roomName} />
          <DeviceType deviceType="Мэдрэгч" />
        </VStack>

        {/* COLUMN 3  */}
        <VStack
          maxW={["full", "full", "full", "full", "96"]}
          w="full"
          spacing={4}
        >
          <DeviceShared
            isOriginal={location.state.isOriginal}
            origin={location.state.origin}
          />
          <DeviceNotif
            blocked={location.state.blocked}
            id={location.state.id}
            notifEnabled={location.state.notifEnabled}
          />
        </VStack>

        {/*   BODY  END    */}
      </Stack>
    </VStack>
  );
};

export default SensorDetail;
