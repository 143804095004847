import React from "react";
import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";

const BackButton = () => {
  const history = useHistory();
  const { colorMode } = useColorMode();

  return (
    <IconButton
      transition={"ease-in-out .3s"}
      bg={colorMode === "dark" ? "#252B25" : "#fff"}
      rounded={"lg"}
      icon={
        <Icon
          as={TbArrowBackUp}
          color={colorMode === "dark" ? "#fff" : "#000"}
          w={5}
          h={5}
        />
      }
      _hover={{
        bg: colorMode === "light" ? "#fff" : "gray.900",
        shadow: "md",
      }}
      _focusVisible={{
        outline: "none",
        bg: "#fff",
      }}
      onClick={() => history.goBack()}
    />
  );
};

export default BackButton;
