import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React, { useState, useContext } from "react";
import axios from "../../axios";
import user from "../../assets/Images/icons/user.png";
import userDark from "../../assets/Images/icons/userDark.png";
import pencil from "../../assets/Images/icons/pencil.png";
import pencilDark from "../../assets/Images/icons/pencilDark.png";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";

const ChangeNickname = ({ username }) => {
  const [nickname, setNickname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const history = useHistory();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { Core } = axios();
  const { handledHttpErrors } = useContext(UserContext);

  const submitHandler = () => {
    if (nickname) {
      setIsLoading(true);
      let source = Core.CancelToken.source();
      Core.put(
        "/user/nickname",
        {
          username: username,
          nickname: nickname,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        { cancelToken: source.token }
      )
        .then((result) => {
          //   console.log(result);
          if (result.data.success) {
            onClose();
            Cookies.set("nickname", nickname);
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "success",
                duration: 2000,
                position: "top",
                description: "Амжилттай хадгаллаа",
              });
            }
          } else {
            setIsLoading(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                status: "error",
                duration: 2000,
                position: "top",
                description: "Алдаа гарлаа!",
              });
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          //   console.log(err);
          if (handledHttpErrors.includes(err.response.status)) return;

          if (!toast.isActive(id)) {
            toast({
              id,
              status: "error",
              duration: 2000,
              position: "top",
              description: "Алдаа гарлаа!",
            });
          }
        });
    } else {
      setIsLoading(false);
      if (!toast.isActive(id)) {
        toast({
          id,
          status: "error",
          duration: 2000,
          position: "top",
          description: "Талбарыг бөглөнө үү!",
        });
      }
    }
  };
  return (
    <VStack align={"flex-start"} spacing={0}>
      <Text fontSize={15} fontWeight={"normal"}>
        Хэрэглэгчийн нэр
      </Text>
      <InputGroup
        className="settingsInput"
        rounded={"lg"}
        onClick={() => onOpen()}
        cursor={"pointer"}
      >
        <InputLeftElement
          pointerEvents="none"
          children={
            <Image
              src={colorMode === "dark" ? userDark : user}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
        <Input
          placeholder="************"
          w={"xs"}
          fontSize={15}
          fontWeight={"normal"}
          border={"none"}
          isReadOnly
        />
        <InputRightElement
          children={
            <Image
              src={colorMode === "dark" ? pencilDark : pencil}
              w={"4"}
              h={"4"}
              objectFit={"contain"}
            />
          }
        />
      </InputGroup>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "sm" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Нэр солих</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={15} fontWeight={"normal"}>
                Солих нэрээ оруулна уу
              </FormLabel>
              <Input
                placeholder="-"
                maxLength={30}
                type={"text"}
                fontSize={15}
                fontWeight={"normal"}
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                focusBorderColor={"#45A735"}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              onClick={submitHandler}
              _focusVisible={{ outline: "none" }}
              isDisabled={nickname ? false : true}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ChangeNickname;
