import React, { useContext, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import time from "../../../../assets/Images/icons/time.png";
import timeDark from "../../../../assets/Images/icons/timeDark.png";
import UserContext from "../../../../context/UserContext";
import TimePicker from "./TimePicker";

const AddDelay = () => {
  const [timeValue, setTimeValue] = useState(0);
  const [minuteValue, setMinuteValue] = useState(0);
  const [secondValue, setSecondValue] = useState(0);
  const toast = useToast();
  const id = "toast";
  const { colorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setAutoAct } = useContext(UserContext);

  const typeData = [
    {
      title: "Цаг",
      value: timeValue,
      changeData: setTimeValue,
    },
    {
      title: "Минут",
      value: minuteValue,
      changeData: setMinuteValue,
    },
    {
      title: "Секунд",
      value: secondValue,
      changeData: setSecondValue,
    },
  ];

  const submitHandler = () => {
    if (timeValue > 0 || minuteValue > 0 || secondValue > 0) {
      setAutoAct((prevState) => [
        ...prevState,
        {
          actionType: "delay",
          command:
            parseInt(timeValue) * 3600 +
            parseInt(minuteValue) * 60 +
            parseInt(secondValue),

          time: timeValue,
          minute: minuteValue,
          second: secondValue,
        },
      ]);
    } else {
      if (!toast.isActive(id)) {
        toast({
          description: "Цаг сонгоно уу!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
    onClose();
  };

  return (
    <>
      <HStack
        transition={"ease-in-out .3s"}
        w={"full"}
        py={"4"}
        px={"4"}
        bg={colorMode === "dark" ? "#1D211D" : "#FBFBFB"}
        borderWidth={"medium"}
        borderColor={colorMode === "dark" ? "#1A1C1A" : "#fff"}
        rounded={"md"}
        shadow={"md"}
        align={"center"}
        justify={"center"}
        onClick={() => onOpen()}
        cursor={"pointer"}
        _hover={{
          shadow: "lg",
        }}
      >
        <Image
          src={colorMode === "dark" ? timeDark : time}
          w={"4"}
          h={"4"}
          objectFit={"contain"}
        />
        <Text>Хугацаа тоолох</Text>
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "xs", md: "md" }}
          bg={colorMode === "dark" ? "#202420" : "#fff"}
        >
          <ModalHeader>Цаг сонгоно уу!</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <ModalBody w={"full"}>
            <VStack
              w={"full"}
              spacing={4}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Text fontWeight={"normal"} fontSize={15}>
                Үйлдэл хийгдэх цагаа сонгоно.
              </Text>
              <HStack
                w={"full"}
                spacing={6}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {typeData.map((e, i) => (
                  <TimePicker
                    key={i}
                    title={e.title}
                    value={e.value}
                    setValue={e.changeData}
                  />
                ))}
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              _focusVisible={{ outline: "none" }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              onClick={() => submitHandler()}
              _focusVisible={{ outline: "none" }}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDelay;
